import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { palette } from '../../utils/Palette';
import { 
  Cancel, 
  CancelOutlined, 
  CheckBox, 
  CheckBoxOutlineBlank,
  Link as LinkIcon,
  Image as ImageIcon,
  TextFields as TextIcon,
  List as ListIcon,
  Code as ObjectIcon
} from '@mui/icons-material';
import InputBox from '../../ReuseableComponets/InputBox';
import RippleButton from '../../ReuseableComponets/RippleButton';
import { createChecklist } from '../../store/Actions/SettingsAction';
import { Avatar, IconButton, Chip, Tooltip } from '@mui/material';

// Premium styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 90%;
  background-color: ${palette.white};
  border-radius: 12px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
`;

const Title = styled.h5`
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
  color: #2c3e50;
  text-align: start;
  margin-bottom: 16px;
  margin-top: 8px;
  position: relative;

`;

const ScrollView = styled.div`
  background-color: #ffffff;
  overflow-y: auto;
  padding: 12px 8px;
  max-height: 80vh;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }
`;

const ItemContainer = styled.div`
  margin-bottom: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.highlighted ? '#f8f9ff' : 'transparent'};
  padding: 12px 14px;
  border-radius: 8px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: default;
`;

const CheckboxText = styled.span`
  font-size: 15px;
  color: ${props => props.checked ? palette.blue : palette.black};
  font-weight: ${props => props.checked ? '500' : '400'};
  margin-left: 10px;
  transition: all 0.2s ease;
`;

const LinkText = styled.a`
  padding: 8px 12px;
  color: ${palette.blue};
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: rgba(0, 123, 255, 0.05);
  
  &:hover {
    background-color: rgba(0, 123, 255, 0.1);
    text-decoration: underline;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${palette.blue};
  font-size: 12px;
  width: 28px;
  height: 28px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
`;

const ArrayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
`;

const ArrayChip = styled(Chip)`
  background-color: #f1f5f9 !important;
  color: #475569 !important;
  font-weight: 500 !important;
  border-radius: 6px !important;
  transition: all 0.2s ease !important;
  
  &:hover {
    background-color: #e2e8f0 !important;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 8px 0;
`;

const ImagePreview = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ObjectContainer = styled.div`
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 12px;
  font-family: monospace;
  max-height: 200px;
  overflow-y: auto;
  color: #334155;
  font-size: 14px;
  border: 1px solid #e2e8f0;
`;

const CloseButton = styled(IconButton)`
  color: #64748b !important;
  transition: all 0.2s ease !important;
  
  &:hover {
    background-color: #f1f5f9 !important;
    color: #475569 !important;
  }
`;

const TypeIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  opacity: 0.7;
`;

const FullSizeImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
`;

const ViewChecklist = (props) => {
  const { data, filledData, bookingNo, onClose } = props;
  const [value, setValue] = useState(filledData?.[0]?.data?.[0] || {});
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Get initials for avatar
  const getName = (channel) => {
    if (!channel) return '';
    
    const trimmedChannel = channel.trim();
    const val = trimmedChannel.split(' ');

    if (val.length === 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`;
    } else {
      return `${val[0]?.charAt(0).toUpperCase()}`;
    }
  };

  const isImageUrl = async (url) => {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        const contentType = response.headers.get('content-type');
        return contentType && contentType.startsWith('image/');
    } catch (error) {
        return false;
    }
};
  // Function to render different data types
  const renderValue = (item) => {
    const itemValue = value?.[item?.value];
    
    // If no value exists
    if (itemValue === undefined || itemValue === null) {
      return null;
    }
    
    // Handle string value
    if (typeof itemValue === 'string') {
      // Check if it's an image URL
      if (itemValue.match(/\.(jpeg|jpg|gif|png|webp)$/i) || 
          itemValue.includes('s3.') || 
          itemValue.includes('/image/')) {
        return (
          <ImageContainer>
            <ImagePreview onClick={() => setSelectedImage(itemValue)}>
              <img src={itemValue} alt={item?.value} />
            </ImagePreview>
          </ImageContainer>
        );
      }
      
      // Check if it's a URL
      if (itemValue.includes('http') || itemValue.includes('www.')) {
        return (
          <LinkText
            href={itemValue.startsWith('http') ? itemValue : `https://${itemValue}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon fontSize="small" />
            {itemValue}
          </LinkText>
        );
      }
      
      // Regular string
      return (
        <InputBox
          style={{ width: '90%' }}
          header={item?.value}
          readOnly
          placeholder={item?.value}
          value={itemValue}
        />
      );
    }
    
    // Handle array value
    if (Array.isArray(itemValue)) {
      // Check if it's an array of image URLs
      
      // Regular array
      return (
        <ArrayContainer>
          {itemValue.map((val, idx) => {
            if (typeof val === 'object' && val !== null) {
              return (
             
                <ArrayChip 
                  key={idx} 
                  label={val.text || JSON.stringify(val).substring(0, 20) + (JSON.stringify(val).length > 20 ? '...' : '')}
                />
              );
            }
            return isImageUrl(val)? <ImagePreview key={idx} onClick={() => setSelectedImage(val)}>
            <img src={val} alt={`${val}`} />
          </ImagePreview>: <ArrayChip key={idx} label={val} />;
          })}
        </ArrayContainer>
      );
    }
    
    // Handle object value
    if (typeof itemValue === 'object' && itemValue !== null) {
      return (
        <p style={{margin:0,textAlign:'start',textWrap:'wrap',fontSize:'13px'}}>
         {itemValue?.text} 
        </p>
  

      );
    }
    
    // Handle boolean value
    if (typeof itemValue === 'boolean') {
      return (
       <div/>
      );
    }
    
    // Default fallback
    return (
      <p style={{margin:0,textAlign:'start',textWrap:'wrap',fontSize:'13px'}}>
      {itemValue?.text} 
     </p>
    );
  };

  // Helper to determine icon by data type
  const getTypeIcon = (item) => {
    const itemValue = value?.[item?.value];
    
    if (itemValue === undefined || itemValue === null) {
      return <TextIcon fontSize="small" />;
    }
    
    if (typeof itemValue === 'string') {
      if (itemValue.match(/\.(jpeg|jpg|gif|png|webp)$/i) || 
          itemValue.includes('s3.') || 
          itemValue.includes('/image/')) {
        return <ImageIcon fontSize="small" />;
      }
      
      if (itemValue.includes('http') || itemValue.includes('www.')) {
        return <LinkIcon fontSize="small" />;
      }
      
      return <TextIcon fontSize="small" />;
    }
    
    if (Array.isArray(itemValue)) {
  
      return <ListIcon fontSize="small" />;
    }
    
    if (typeof itemValue === 'object' && itemValue !== null) {
      return <ObjectIcon fontSize="small" />;
    }
    
    if (typeof itemValue === 'boolean') {
      return itemValue ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />;
    }
    
    return <TextIcon fontSize="small" />;
  };

  return (
    <Container>
      <Header>
        <CloseButton onClick={() => onClose()}>
          <CancelOutlined />
        </CloseButton>
        <UserInfo>
          <StyledAvatar sx={{width:'25px',height:'25px',fontSize:'11px'}}>
            {getName(`${filledData?.[0]?.firstName} ${filledData?.[0]?.lastName}`)}
          </StyledAvatar>
          <span>{filledData?.[0]?.firstName} {filledData?.[0]?.lastName}</span>
        </UserInfo>
      </Header>
      
      <Title>{data?.title}</Title>
      
      <ScrollView>
        {data?.data?.map((item, index) => (
          <ItemContainer 
            key={index}
            highlighted={Boolean(value?.[item?.value])}
          >
            <ItemLabel>
              <TypeIcon>{getTypeIcon(item)}</TypeIcon>
              {item?.value}
            </ItemLabel>
            
            {item?.type === 'Link' ? (
              <LinkText
                href={item?.value.startsWith('http') ? item.value : `https://${item.value}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon fontSize="small" />
                {item?.value}
              </LinkText>
            ) : (
              renderValue(item)
            )}
          </ItemContainer>
        ))}
      </ScrollView>
      
      {selectedImage && (
        <FullSizeImage onClick={() => setSelectedImage(null)}>
          <img src={selectedImage} alt="Full size preview" />
        </FullSizeImage>
      )}
    </Container>
  );
};

export default ViewChecklist;