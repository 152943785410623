import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CancelOutlined } from '@mui/icons-material';
import { fetchAllContacts } from '../../../../store/Actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
import { palette } from '../../../../utils/Palette';
import { formatMobileNumber } from '../../../../utils/Commonfunction';
import InputBox from '../../../../ReuseableComponets/InputBox';
import { submitBilltoContact } from '../../../../store/Actions/ContactAction';

const BillToComponent = (props) => {
    const [search, setSearch] = useState('');
    const [allContact, setAllContact] = useState([]);
    const [searchContact, setSearchContact] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const token = useSelector(state => state?.auth?.token);
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            let res = await dispatch(fetchAllContacts());
            if (res.status) {
                setAllContact(res.data);
            }
            setLoading(false);
        };
        init();
    }, [dispatch]);

    const searchFilterFunction = (text) => {
        if (text) {
            const newData = allContact.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : '';
                const numberData = item.number ? item.number.toUpperCase() : '';
                return nameData.includes(text.toUpperCase()) || numberData.includes(text.toUpperCase());
            });
            setSearchContact(newData);
        } else {
            setSearchContact([]);
        }
        setSearch(text);
    };

    const getName = (channel) => {
        if (!channel) return '';
        const val = channel.split(' ');
        return val.length === 2 
            ? `${val[0].charAt(0).toUpperCase()}${val[1].charAt(0).toUpperCase()}`
            : `${val[0].charAt(0).toUpperCase()}`;
    };

    const onPress = async (value) => {
        setSubmitting(true);
        await dispatch(submitBilltoContact(value, props?.contactId));
        setSubmitting(false);
        props?.onSubmit();
    };

    return (
        <BillingContainer>
            <Header>
                <h4>Select billing customer</h4>
                <CancelOutlined onClick={props?.onCancel} className="close-icon" />
            </Header>

            <InputBox
                onChange={(e) => searchFilterFunction(e.target.value)}
                placeholder="Enter a name or phone number"
                style={{ width: '95%', marginBottom: '15px' }}
                disabled={loading || submitting}
            />

            {loading ? (
                <CenteredSpinner />
            ) : (
                <ListContainer>
                    {searchContact.map((item) => (
                        <ListItem
                            key={item._id}
                            onClick={() =>
                                onPress({
                                    name: item.name,
                                    phoneNumber: item.number,
                                    email: item.email,
                                    fullAddress: item.address,
                                    addressDetails: item.mainAddress,
                                    businessName:item.businessName||'',
                                    billToContactId: item._id,
                                })
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ProfileIcon>{getName(item.name)}</ProfileIcon>
                                <div >
                                    <NameLabel>{item.name}</NameLabel>
                                    <NameLabel className="subtext">{formatMobileNumber(item.number)}</NameLabel>
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </ListContainer>
            )}

            {submitting && <OverlaySpinner />}
        </BillingContainer>
    );
};

export default BillToComponent;

/* Styled Components */
const BillingContainer = styled.div`
    border-radius: 8px;
    width: 440px;
    padding: 10px;
    position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h4 {
        font-weight: 500;
        margin: 0;
    }

    .close-icon {
        cursor: pointer;
        color: rgba(32, 32, 32, 0.85);
        &:hover {
            color: black;
        }
    }
`;

const ListContainer = styled.div`
    overflow-y: scroll;
    max-height: 350px;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom:1px solid #eee;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f7f7f7;
    }
`;

const ProfileIcon = styled.div`
    background: ${palette.newBlue};
    color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 5px;
    border-radius: 50%;
    font-weight: bold;
`;

const NameLabel = styled.p`
    font-weight: 500;
    color: #000;
    font-size: 14px;
    text-align: start;
    cursor: pointer;
    margin:0;

    &.subtext {
        font-size: 13px;
        font-weight: 400;
        margin-top: 3px;
    }
`;

const CenteredSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
        <Spinner />
    </div>
);

const OverlaySpinner = () => (
    <Overlay>
        <Spinner />
    </Overlay>
);

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #0f52ba;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    z-index: 10;
`;

