import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {  ClickAwayListener, useTheme } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import CustomTabs from "../../ReuseableComponets/CustomTab";
import {
  DatasetOutlined,
  CallToActionOutlined,
  Add,
  ReceiptOutlined,
  CalendarMonth,
  CalendarToday,
  CalendarViewWeek,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/MainHeader";
import {
  getOnlineBookingForm,
} from "../../store/BookingForm/bookingFormReducer";
import { useSelector } from "react-redux";
import TimesheetPage from "./TimesheetPage";
import { palette } from "../../utils/Palette";
import WeeklyTimeSheet from "./WeekTimesheet";
const Timesheet = (props) => {
  const dispatch = useDispatch();
  const { loader, allData } = useSelector((state) => state.jobForm);
  const [data, setData] = useState([]);
  const crew = useSelector((state) => state?.auth?.userData?.crewMembers);
  const [customerData, setCustomerData] = useState([]);
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(0);
  const handleChangeIndex1 = (index) => {
    setValue1(index);
  };
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const init = async () => {
    const res = await dispatch(getOnlineBookingForm());
    setData(res?.data);
    setCustomerData(res?.customerData);
  };
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleCopyClick = (item) => {
    navigator.clipboard.writeText(
      `https://app.getnotifi.com/onlinebooking/${item}`
    );
  };

  function a11yProps1(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Body>
        <FlexboxContainer>
          <Header
            searchComponent={<input type="text" placeholder="Search..." />}
            handleShowJobs={() => console.log("Show jobs")}
            nonsearchable
          />
          <StyledContactFlex>
            <div>
              <h2>Timesheet </h2>
              <p className="subtext">
            Manage and review employee timesheets, track work hours, and approve
            entries.
          </p>
            </div>
          </StyledContactFlex>

          {loader ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Player
                src={require("../../assets/loaders.json")}
                className="player"
                style={{ width: "200px", height: "200px" }}
                loop
                autoplay
              />
            </div>
          ) : (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <CustomTabs
                tabpanelWidth={"400px"}
                tabsData={[
                  {
                    label: "Day view",
                    icon: <CalendarToday style={{ fontSize: "16px" }} />,
                    content: (
                      <TimesheetPage/>
                    ),
                  },
                  {
                    label: "Week view",
                    icon: <CalendarViewWeek style={{ fontSize: "16px" }} />,
                    content: (
                    <WeeklyTimeSheet/>
                    ),
                  },
                ]}
                value={value}
              />
            </ClickAwayListener>
          )}
        </FlexboxContainer>
      </Body>
    </>
  );
};

export default Timesheet;
const Body = styled.div`
  display: flex;
  width: calc(100% - 240px);
 overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 240px;
  height:100vh;
`;
const StyledContactFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align:start;

  h2 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 3px;
    line-spacing: 0.5px;
      text-align:start;
    
  }
  span {
    color: grey;
    font-size: 15px;
    text-align: start;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .subtext {
    border-radius: 6px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    color: ${palette.grey};
  }
`;


const FlexboxContainer = styled.div`
  width: 100%;
  background: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  @media (min-width: 260px) and (max-width: 1300px) {
    flex-shrink: 0;
    overflow: auto;
  }
`;



