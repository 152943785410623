import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarMonthOutlined } from '@mui/icons-material';
import moment from 'moment';

// Styled Components
const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 3px;
`;

const DateButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  min-width: 150px;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  .react-datepicker {
    z-index: 1000;
    display: flex;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #f7f9fc;
    border-bottom: 1px solid #ddd;
  }

  .react-datepicker__month-container {
    position: relative;
    border-right: 1px solid #ddd;
  }

  .react-datepicker__day--selected {
    background-color: #536dfe !important;
    color: white !important;
  }

  .react-datepicker__day:hover {
    background-color: #e0e7ff;
  }

  .react-datepicker__time-container {
    display: none; /* Hide time container */
  }
`;
const Container = styled.div`
  .react-datepicker {
    z-index: 1000;
    display: flex;
    border:1px solid #ddd;
    border-right: 0px solid #ddd;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: #f7f9fc;
    border-bottom: 0px solid #ddd;
    background:white;
  }

  .react-datepicker__month-container {
    position: relative;
    border-right: 1px solid #ddd;
    background:white;
  }

  .react-datepicker__day--selected {
    background-color: #000 !important;
    color: white !important;
  }

  .react-datepicker__day:hover {
    background-color: #ddd;
  }

  .react-datepicker__time-container {
    display: none;
  }
`;

const ShortcutContainer = styled.div`
  width: 160px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-left:0px solid #fff;
  font-size: 13px;
  padding: 10px;
  margin-left: -1px;

  overflow-y: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 10;
  }

  li {
    margin: 25px 0;
    cursor: pointer;
    color: #536dfe;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CustomInput = ({ value, onClick, disabled, customStyle, iconStyle }) => (
  <DateButton disabled={disabled} onClick={onClick} style={customStyle}>
    {value || 'Date'}
    <CalendarMonthOutlined style={{ fontSize: '16px', ...iconStyle }} alt="calendar icon" />
  </DateButton>
);

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  iconStyle: PropTypes.object,
};

const DatePickerWithShortcuts = ({
  label,
  selectedDate,
  onDateChange,
  value,
  disabled,
  startDate,
  customStyle,
  iconStyle,
}) => {
  const shortcuts = [
    { label: "Forever", date: moment(startDate).add(10, "years").startOf("month").toDate() },
    { label: "1 cycle", date: moment(startDate).add(1, "months").startOf("month").toDate() },
    { label: "2 cycles", date: moment(startDate).add(2, "months").startOf("month").toDate() },
    { label: "3 cycles", date: moment(startDate).add(3, "months").startOf("month").toDate() },
    { label: "6 cycles", date: moment(startDate).add(6, "months").startOf("month").toDate() },
    { label: "12 cycles", date: moment(startDate).add(12, "months").startOf("month").toDate() },

  ];
  const [isOpen, setIsOpen] = useState(false);

  const handleShortcutClick = (date) => {
    setIsOpen(false)
    onDateChange(moment(date).format("YYYY-MM-DD"));
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <DateButton
      onClick={() => setIsOpen(!isOpen)}
      ref={ref}
    >
      {value || 'Date'}
      <CalendarMonthOutlined style={{ fontSize: '16px',...iconStyle }} alt="calendar icon" />
    </DateButton>
  ));
  const handleDateChange = (date) => {
    setIsOpen(false);
    onDateChange(date);

  };
  return (
    <DatePickerWrapper>
      {label && <Label>{label}</Label>}
      <StyledDatePicker

        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd MMM yyyy"
        popperPlacement="bottom-start"
        customInput={<CustomInput />}
        calendarContainer={(props) => (
          <div style={{ display: "flex", position: "relative", background: 'white', zIndex: 1000000 }}>
            <Container>
              <div className='react-datepicker'>{props.children}</div>
            </Container>
            <ShortcutContainer>
              <ul>
                {shortcuts.map((shortcut, index) => (
                  <li key={index} onClick={() => handleShortcutClick(shortcut.date)}>
                    {shortcut.label}
                    {shortcut.date && ` (${moment(shortcut.date).format("DD-MMM-YYYY")})`}
                  </li>
                ))}
              </ul>
            </ShortcutContainer>
          </div>
        )}
      />
    </DatePickerWrapper>
  );
};

DatePickerWithShortcuts.propTypes = {
  label: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  value: PropTypes.string,
};

DatePickerWithShortcuts.defaultProps = {
  label: '',
  selectedDate: null,
};

export default DatePickerWithShortcuts;
