import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { palette } from "../../utils/Palette";
import {
  ArrowDropDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import moment from "moment";
import InputPicker from "../../ReuseableComponets/InputPicker";
import { useDispatch } from "react-redux";
import {
  getAllCrew,
  getCardData,
  getWeekCardData,
  submitEditjobChange,
  submitTimeChange,
} from "../../store/Timesheet/TimesheetAction";
import {
  setselectedcrew,
  updatefields,
} from "../../store/Timesheet/TimesheetReducer";
import {
  calendarTeamColor,
  dayViewColor,
  getName,
} from "../../utils/Commonfunction";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useToast } from "../../Components/Toaster/ToasterContext";
import ErrorAnimation from "../../assets/error.json";
import RippleButton from "../../ReuseableComponets/RippleButton";

const WeeklyTimeSheet = (props) => {
  const {
    team,
    selectedCrew,
    value,
    fields,
    error,
    buttonLoader,
    weekData,
    allBooking,
  } = useSelector((state) => state.timesheet);
  const userdata = useSelector((state) => state?.auth?.userData);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const { showToast } = useToast();
  const openAssign = Boolean(anchorEl1);
  const handleClickAssign = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseAssign = () => {
    setAnchorEl1(null);
  };
  const dispatch = useDispatch();
  const init = async () => {
    const res = await dispatch(getAllCrew());
    const startDate = moment(currentDate).startOf("week").format("YYYY-MM-DD");
    const endDate = moment(startDate).endOf("week").format("YYYY-MM-DD");
    await dispatch(
      getWeekCardData({
        startDate: startDate,
        userId: selectedCrew?.userId || userdata?.userId,
        endDate: endDate,
      })
    );
  };
  useEffect(() => {
    init();
  }, [userdata?.userId]);

  useEffect(() => {}, [error]);

  const handleRemoveItem = (index) => {
    const updatedLineItems = fields.filter((_, idx) => idx !== index);
    dispatch(updatefields(updatedLineItems));
  };

  const changeDate = async (direction) => {
    const newDate = new Date(currentDate);
    if (direction === "left") {
      newDate.setDate(currentDate.getDate() - 7);
    } else if (direction === "right") {
      newDate.setDate(currentDate.getDate() + 7);
    }

    setCurrentDate(newDate);
    const startDate = moment(newDate).startOf("week").format("YYYY-MM-DD");
    const endDate = moment(newDate).endOf("week").format("YYYY-MM-DD");

    await dispatch(
      getWeekCardData({
        startDate: startDate,
        userId: selectedCrew?.userId || userdata?.userId,
        endDate: endDate,
      })
    );
  };
  const changeTeam = async (id) => {
    const startDate = moment(currentDate).startOf("week").format("YYYY-MM-DD");
    const endDate = moment(startDate).endOf("week").format("YYYY-MM-DD");
    await dispatch(
      getWeekCardData({
        startDate: startDate,
        userId: id || userdata?.userId,
        endDate: endDate,
      })
    );
  };

  const formatMillisecondsToTime = (milliseconds) => {
    if (!milliseconds) return "0 min";
  
    const totalMinutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return hours > 0 
      ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
      : `${minutes} min${minutes > 1 ? 's' : ''}`;
  };



  return (
    <>
      <BookingContainer>
        <ProfileContainer>
          <StyledAvatar>
            {(selectedCrew?.label || userdata?.businessName)
              ?.charAt(0)
              ?.toUpperCase()}
          </StyledAvatar>
          <div className="profile-info">
            <span className="name">
              {selectedCrew?.label || userdata?.businessName}
            </span>
            <span className="email">
              {selectedCrew?.email || userdata?.email}
            </span>
            {/* <span className="phone">
              {selectedCrew?.phone || userdata?.phone || "-"}
            </span> */}
          </div>
        </ProfileContainer>
        <OptionBox onClick={handleClickAssign}>
          Switch user <ArrowDropDown />
        </OptionBox>
        <Menu
          anchorEl={anchorEl1}
          id="account-menu"
          open={openAssign}
          onClose={handleCloseAssign}
          onClick={handleCloseAssign}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              minWidth: "180px",
              border: "1px solid #ddd",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.05))",
              mt: 1.0,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",

                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {[
              {
                name: `${userdata?.businessName || ""} ${
                  userdata?.lastName || ""
                }`,
                email: userdata?.email || "",
                userId: userdata?.userId || "",
                firstName: userdata?.businessName || "",
                lastName: userdata?.lastName || "",
              },
              ...team,
            ]?.map((item, index) => (
              <MenuItem
                style={{ color: palette.black, fontSize: "14px" }}
                onClick={async () => {
                  dispatch(setselectedcrew(item));
                  changeTeam(item?.userId);
                }}
              >
                <Avatar
                  style={{
                    height: "22px",
                    width: "22px",
                    background: calendarTeamColor[index]?.light,
                  }}
                  color={dayViewColor[index]}
                >
                  <Typography
                    style={{
                      fontSize: "10px",
                      fontWeight: "600",
                      color: calendarTeamColor[index]?.original,
                    }}
                  >
                    {getName(`${item?.firstName} ${item?.lastName || ""}`)}
                  </Typography>
                </Avatar>
                <Typography
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {item?.firstName} {item?.lastName || ""}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </Menu>

        {/* <InputPicker
          initial={true}
          label={"Switch user"}
          data={team}
          onChange={(val) => {
            dispatch(setselectedcrew(val));
          }}
        /> */}
      </BookingContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "25px 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <JobTitle style={{ marginRight: "10px" }}>
            {moment(currentDate).startOf("week").format("DD MMM YYYY")} -{" "}
            {moment(currentDate).endOf("week").format("DD MMM YYYY")}
          </JobTitle>

          <StyledIconBox onClick={() => changeDate("left")}>
            <KeyboardArrowLeft />
          </StyledIconBox>
          <StyledIconBox onClick={() => changeDate("right")}>
            <KeyboardArrowRight />
          </StyledIconBox>
        </div>
      </div>
      <ScrollContainer>
        {weekData?.map((item, index) => (
          <FieldContainer key={index}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
           <DayLabel>{moment(item?.date).format("dddd, MMM D")}</DayLabel>
           <DayLabel>{formatMillisecondsToTime(item?.totalHoursWorked)}</DayLabel>
           </div>

            {item?.historyData?.length > 0 ? (
              item.historyData.map((itm, idx) => (
                <InnerContainer key={idx}>
                  <div className="headerwidth">
                  <Label>{itm?.key==='checkIn'?'Clock in':itm?.key==='pause'?'Pause':itm?.key==='checkOut'?'Clock out':`${itm?.customerName} | #${itm?.jobNumber}`}</Label>
                  {itm?.tripInfo?.travelTime&&<Label  style={{fontSize:'13px',fontWeight:'400'}}>Travel time: {formatMillisecondsToTime(itm?.tripInfo?.travelTime)}</Label>}
                  </div>
                  <Label className="width">{moment(itm?.start)?.format('hh:mm A')}</Label>
                  <Label className="width">{itm?.end ?moment(itm?.end)?.format('hh:mm A'): "--"}</Label>
                </InnerContainer>
              ))
            ) : (
              <InnerContainer>
                <Label style={{ flex: 1,fontSize:'13px', textAlign: "center", color: "#777",margin:'3px 0px' }}>
                  No data available
                </Label>
              </InnerContainer>
            )}
          </FieldContainer>
        ))}
      </ScrollContainer>
    </>
  );
};

const BookingContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;

  .subtext {
    border-radius: 6px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    color: ${palette.grey};
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  }

  .info-box {
    display: flex;
    align-items: flex-start;
    background: #eaf3fc;
    padding: 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #0f52ba;
    margin-top: 10px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
  }

  .duration-container {
    margin-top: 15px;
  }

  .label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }

  .dropdowns {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 120px;
  align-items: end;

  .button {
    padding: 8px 18px;
    width: 100px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .preview {
    background-color: white;
    border: 1px solid #0f52ba;
    color: #0f52ba;
  }

  .save {
    background-color: #0f52ba;
    color: white;
  }
`;
const FieldContainer = styled.div`
  top: auto;
  left: auto;
  user-select: none;
  background: white;
  padding: 20px 35px;
  border-bottom: 1px solid #eee;

  .innerContainer {
    display: flex;
    width: 80%;
  }

  .fieldBox {
    width: 38%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;



const ScrollContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: white;
`;



const DayLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align:start;
  color: #444;
  padding: 8px 0;
`;

const InnerContainer = styled.div`
  display: flex;

  background: #f1f3f5;
  padding: 8px 12px;
  border-radius: 6px;
  margin: 4px 0;
  .headerwidth{
    width:33%;
  font-size:13px;
  font-weight:500;
  }
  .width{
  width:33%;
  font-size:13px;
  font-weight:400;
  }
`;


const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  background: #fff;
  border-radius: 8px;
  max-width: 350px;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    text-align: start;
  }

  .email,
  .phone {
    font-size: 14px;
    color: #666;
    text-align: start;
  }
`;

const StyledButton = styled.button`
  color: ${palette.newBlue};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  cursor: pointer;
  background-color: #dce8f5;
  padding: 7px 15px;
  border-radius: 4px;
  border: 1px solid ${palette.newBlue};

  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  align-text: start;
  display: flex;
  margin-left: 3px;
  align-self: flex-start;
  font-family: Inter;
  text-align: flex-start;
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  width: 90%;
  color: #344054;
  background: #fff
    url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E")
    no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
  &:disabled {
    background-color: #eee;
    color: #000;
    border-color: #ccc;
    cursor: not-allowed;
  }
`;
const JobTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;

  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc;
  }

  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.6);
    transition: color 0.3s;

    &:hover {
      color: black;
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.grey};
    font-size: 13px;
    width: 45px;
    height: 45px;
    margin-right: 18px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;
const OptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.85);
  font-weight: 600;
  background-color: #eeeeee;
  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.85);
    margin-left: 5px;
  }
  &:hover {
    color: #000;
    svg {
      color: #000;
    }
  }
`;

export default WeeklyTimeSheet;
