import React, { useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  deleteLineItem,
  deletenewBundleItems,
  getLineItems,
} from "../../store/Actions/dashboard.action";
import { Player } from "@lottiefiles/react-lottie-player";
import { sendMessage } from "../../store/Actions/Auth.action";
import { palette } from "../../utils/Palette";
import AddChecklist from "./Addchecklist";
import RightModal from "../../Components/RightSideModal";
import {
  Deletechecklist,
  getChecklistItems,
} from "../../store/Actions/SettingsAction";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Box, Typography } from "@mui/material";
import { MdChecklist } from "react-icons/md";
import AnimatedModal from "../../Components/ModalComponent/AnimatedModal";

function ChecklistData(props) {
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedlist, setSelectedList] = useState({});
  const [searchChecklist, setSearchChecklist] = useState([]);
  const containerRef = useRef(null);

  const dispatch = useDispatch();
  const init = async () => {
    setLoader(true);
    let res = await dispatch(getChecklistItems());
    if (res.status === true) {
      setLoader(false);
      setSearchChecklist(res?.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    init();
  }, []);
  const sendchat = async (val, data) => {
    await dispatch(
      sendMessage({
        to: data?.phoneNumber,
        from: `+${localStorage.getItem("number")}`,
        body: val,
      })
    );
  };

  const deleteLine = async (id) => {
    await dispatch(
      deleteLineItem({
        itemIds: [id],
      })
    );
    init();
  };

  const renderlineItem = (dataitem, type) => {
    return (
      <div
        ref={containerRef}
        style={{ overflowY: "auto", height: "99vh", paddingBottom: "100px" }}
      >
        <Table
          style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
          sx={{ width: "100%" }}
          aria-label="simple table"
        >
          <CustomHead>
            <TableRow>
              <TableCell
                className="sticky"
                size="small"
                style={{ cursor: "pointer", width: "100px" }}
              >
                #
              </TableCell>

              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Title
              </TableCell>

              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Items
              </TableCell>
              <TableCell
                style={{
                  width: "100px",
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Actions
              </TableCell>
            </TableRow>
          </CustomHead>
          {dataitem?.map((item, index) => (
            <>
              <TableBody
                key={index}
                style={{ background: "#fff", cursor: "pointer" }}
              >
                <TableRow>
                  <TableCell
                    style={{ fontWeight: "500" }}
                    className="sticky"
                    size="small"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    size="small"
                    style={{ fontWeight: "500", color: palette.newBlue }}
                  >
                    {item?.title}
                  </TableCell>

                  <TableCell size="small" style={{ fontWeight: "500" }}>
                    {item?.data?.length} checklist items
                  </TableCell>
                  <TableCell size="small">
                    <ActionIcons>
                    <AnimatedModal
              buttonStyles={{
                customStyles: { margin: 0, padding: 0 },
              }}
              modalStyles={{
                customStyles: {
                  width: "50%",
                  overflowY:'hidden',
                  maxHeight:'80vh'
                },
              }}
              buttonContent={
                <FiEdit
                style={{fontSize:'18px',color:'grey'}}
                onClick={async () => {
              
                }}
              />
              }
              modalContent={(close) => {
                return (
                  <AddChecklist
                    selectedlist={item}
                    setSubmit={() => {
                      close(false);
                      init();
                    }}
                    onClose={() => close()}
                  />
                );
              }}
            />
                    
                      <FiTrash2
                        onClick={async () => {
                          await dispatch(Deletechecklist(item?.checklistNo));
                          init();
                        }}
                      />
                    </ActionIcons>
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          ))}
        </Table>
        {dataitem?.length > 0 ? null : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="60vh"
            sx={{ color: "gray" }}
          >
            <MdChecklist sx={{ fontSize: "50px", marginBottom: 2 }} />
            <Typography variant="h5" align="center">
              No Checklist custom fields available
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              You can add new checklist fields to manage your data more
              effectively.
            </Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <>
      <Container>
        <CustomTable>
          <Header>
            <h2>Checklist</h2>
            <AnimatedModal
              buttonStyles={{
                customStyles: { margin: 0, padding: 0 },
              }}
              modalStyles={{
                customStyles: {
                  width: "50%",
                  overflowY:'hidden',
                  maxHeight:'80vh'
                },
              }}
              buttonContent={
                <AddChecklistButton
                  onClick={() => {
                    setSelectedList({});
               
                  }}
                >
                  + Checklist
                </AddChecklistButton>
              }
              modalContent={(close) => {
                return (
                  <AddChecklist
                    selectedlist={selectedlist}
                    setSubmit={() => {
                      close(false);
                      init();
                    }}
                    onClose={() => close()}
                  />
                );
              }}
            />
          </Header>

          {loader && searchChecklist?.length <= 0 ? (
            <div
              style={{
                display: "flex",
                height: "70%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Player
                src={require("../../assets/loaders.json")}
                className="player"
                style={{ width: "200px", height: "200px" }}
                loop
                autoplay
              />
            </div>
          ) : (
            <>{renderlineItem(searchChecklist)}</>
          )}
        </CustomTable>
        {modalOpen && (
          <RightModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            <AddChecklist
              selectedlist={selectedlist}
              setSubmit={() => {
                setModalOpen(false);
                init();
              }}
              onClose={() => setModalOpen(false)}
            />
          </RightModal>
        )}
      </Container>
    </>
  );
}

export default ChecklistData;

const OptionBox = styled.div`
  width: 150px;
  height: 35px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid ${palette.newBlue};
  background: ${palette.newBlue};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;

  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 30px;
    max-width: 30px;
  }
`;
const Container = styled.div`
  width: 100%;
  background: #fff;
  padding-top: 30px;

  display: flex;
  @media (min-width: 260px) and (max-width: 1300px) {
    width: 100%;
    flex-shrink: 0;
    overflow: auto;
  }
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 15px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
const AddChecklistButton = styled.button`
  background-color: #0f52ba;
  color: white;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #08417c;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;

  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
