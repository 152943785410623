import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Divider, Box, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import RippleButton from '../../../../ReuseableComponets/RippleButton';
import { formatMobileNumber } from '../../../../utils/Commonfunction';
import { DeleteOutlined } from '@mui/icons-material';
import { palette } from '../../../../utils/Palette';


const EventBox = styled(Box)`
  padding: 4px;

`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 8px;
  font-weight: 500;
  color:${palette.newBlue};
`;

const EventHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const BookingStatus = styled(Box)`
  font-weight: 500;
  font-size: 13px;
  padding: 4px;
  margin-top: 4px;
  height:15px;
  border-radius: 5px;
  color: ${({ status }) =>
    status === 'Waiting for confirmation'
      ? '#045ad0'
      : status === 'In Progress'
      ? '#29740f'
      : status === 'Completed'
      ? '#004D4D'
      : '#fa811f'};
  background-color: ${({ status }) =>
    status === 'Waiting for confirmation'
      ? 'rgba(4, 90, 208, 0.1)'
      : status === 'In Progress'
      ? 'rgba(41, 116, 15, 0.1)'
      : status === 'Completed'
      ? 'rgba(0, 77, 77, 0.1)'
      : 'rgba(250, 129, 31, 0.1)'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 7px;
  margin-top: 7px;
  font-weight:500;
  font-size:14px;
  color:rgba(32, 32, 32, 0.75);
`;

const StyledTypography = styled.div`
  padding-bottom: 7px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  
`;

const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EventDetails = ({ event, onClickTask, onClickEdit, onClickDeleteTask }) => {
  const navigate = useNavigate();
  return (
    <div onClick={(e) => e.stopPropagation()} style={{ marginTop: '5px', zIndex: 100000, marginBottom: '5px' }}>
      <EventBox>
        <EventHeader>
          <Typography variant="body2" color="textSecondary" style={{ color: 'grey', fontSize: '12px' }}>
            {`${moment(event?.start).format('DD MMM, hh:mm A')} - ${moment(event?.end).format('DD MMM, hh:mm A')}`}
          </Typography>
          {(event?.data?.type === 'task' || event?.data?.type === 'event') ? <IconButton onClick={() => onClickDeleteTask(event?.start, (event?.data?.taskId || event?.data?.eventId), event?.data?.type)}>
            <DeleteOutlined color="error" />
          </IconButton>:event?.data?.type === 'job'&&
           <Typography variant="body2" color="textSecondary" style={{  fontSize: '12px' }}>
           {`#${event?.data?.['job number']}`}
         </Typography>
          }
        </EventHeader>
        <Box display="flex" justifyContent="space-between">
        <Box>
            <EventTitle>{event?.title}</EventTitle>
            {event?.data?.type === 'multivist' && event?.data?.description && (
              <StyledTypography variant="body2" color="textSecondary">
                {formatMobileNumber(event?.data?.description)}
              </StyledTypography>
            )}
            {event?.data?.email && (
              <StyledTypography variant="body2" color="textSecondary">
                {event?.data?.email}
              </StyledTypography>
            )}
            <StyledTypography variant="body2" color="textSecondary" >
              {formatMobileNumber(event?.data?.customerNumber || event?.data?.number)}
            </StyledTypography>
         </Box>
          <BookingStatus status={event?.data?.bookingStatus}>
            {/* <span style={{ marginRight: '5px' }}>●</span> */}
            {event?.data?.type === 'task'
              ? 'Task'
              : event?.data?.type === 'event'?  'Event': event?.data?.type === 'google'?'Google'
              
                : event?.data?.bookingStatus === 'Waiting for confirmation'
                  ? 'Open'
                  : event?.data?.bookingStatus}
          </BookingStatus>
        </Box>
        <Divider />
        <LocationTitle>
          {event?.data?.type === 'task' || event?.data?.type === 'event' ? 'Description' : 'Location'}
        </LocationTitle>
        <StyledTypography>{event?.description || event?.data?.customerAddress}</StyledTypography>
        {event?.data?.team?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Crew</LocationTitle>
            {event?.data?.team.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.firstName} {item?.lastName}
              </StyledTypography>
            ))}
          </>
        )}
        {event?.data?.worker?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Crew</LocationTitle>
            {event?.data?.worker.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.firstName} {item?.lastName}
              </StyledTypography>
            ))}
          </>
        )}
        {event?.data?.items?.length > 0 && (
          <>
            <Divider />
            <LocationTitle>Line items</LocationTitle>
            {event?.data?.items.map((item) => (
              <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                {item?.name} x {item?.quantity}
              </StyledTypography>
            ))}
          </>
        )}
      </EventBox>
      {(event?.data?.type === 'task' || event?.data?.type === 'event'||event?.data?.type === 'google') ? null : (
        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '-25px', paddingTop: '15px', background: '#fff' }}>
          <RippleButton
            onClick={(e) => {
              
              navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
            }}
            variant="contained"
            color="primary"
            style={{ width: '130px', fontWeight: '500', fontSize: '13px', background: '#fff', color: 'grey', border: '0px solid #f3f3f3',textDecoration: 'underline'  }}
          >
            Edit job
          </RippleButton>
          <RippleButton
            onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
            variant="contained"
            color="primary"
            style={{ width: '140px', fontWeight: '500', fontSize: '13px' }}
          >
            View details
          </RippleButton>
        </div>
      )}
      {event?.data?.type === 'task' && (
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', background: '#fff' }}>
          <RippleButton
            onClick={() => onClickEdit(event?.start, event?.data, event?.data?.type)}
            variant="contained"
            color="primary"
            style={{ width: '130px', fontWeight: '500', fontSize: '13px',  background: '#fff', color: 'grey', border: '0px solid #f3f3f3',textDecoration: 'underline'  }}
          >
            Edit Task
          </RippleButton>
          <RippleButton
            onClick={() => onClickTask(event?.start, event?.data?._id)}
            variant="contained"
            color="primary"
            style={{ width: '130px', fontWeight: '500', fontSize: '13px' }}
          >
            Mark Done
          </RippleButton>
        </div>
      )}
      {event?.data?.type === 'event'&&event?.data?.type !== 'google' && <RippleButton
        onClick={() => onClickEdit(event?.start, event?.data, event?.data?.type)}
        variant="contained"
        color="primary"
        style={{ width: '100%', marginBottom: '15px', fontWeight: '500', fontSize: '13px' }}
      >
        Edit Event
      </RippleButton>}
      {event?.data?.type === 'google' && <RippleButton
        onClick={() => window.open(event?.data?.link)}
        variant="contained"
        color="primary"
        style={{ width: '100%', marginBottom: '-25px',marginTop:'20px', fontWeight: '500', fontSize: '13px' }}
      >
       View details
      </RippleButton>}
    </div>
  );
};

// PropTypes
EventDetails.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    data: PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      customerNumber: PropTypes.string,
      number: PropTypes.string,
      bookingStatus: PropTypes.string,
      team: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        })
      ),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ),
      referenceNo: PropTypes.string,
    }),
  }).isRequired,
  onClickTask: PropTypes.func.isRequired,
  onClickDeleteTask: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,

};

export default EventDetails;
