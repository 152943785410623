import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";

export const submitBilltoContact = (data, id) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.submitBillTO}?contactId=${id}`, data)
    return res
}
export const submitBillToDelete= (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteBillTo}?contactId=${id}`)
    return res
}