import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import user from '../../assets/profile-user.png'
import volume from '../../assets/volume.png'
import speaker from '../../assets/microphone.png'
import { useDispatch, useSelector } from "react-redux";
import { formatMobileNumber } from "../../utils/Commonfunction";
import { Call, Device } from '@twilio/voice-sdk';
import { muteIcon } from "../../assets";
import { palette } from "../../utils/Palette";

const CallingUI = (props) => {
    const token = useSelector(state => state?.auth?.token);
    const [callDuration, setCallDuration] = useState(0);
    const detailIncoming = useSelector(state => state?.auth?.incominDetail);
    const connection = useSelector(state => state?.auth?.connection);
    const dispatch = useDispatch();
    const detail = useSelector(state => state?.auth?.callDetail);
    const incomingcallShow = useSelector(state => state?.auth?.showIncomingCall);
    const [mute, setMute] = useState(false);
    const [callcal, setCallCal] = useState({});
    const timerRef = useRef(null);

    // Function to properly stop and release microphone
    const stopMicrophone = async () => {
        
        try {
            // Get all media streams and stop them
            const tracks = await navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    return devices.filter(device => device.kind === 'audioinput');
                });
                
            // Stop any active audio tracks
            if (window.localMediaStream) {
                window.localMediaStream.getTracks().forEach(track => {
                    track.stop();
                });
                window.localMediaStream = null;
            }
            
            // Get all active streams and stop them
            const streams = navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    stream.getTracks().forEach(track => track.stop());
                })
                .catch(err => console.log('No active streams to stop'));

                dispatch({ type: "CALLSHOW", payload: null });
                
        } catch (err) {
            console.log('Error stopping microphone:', err);
        }
    };

    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const clearCallTimers = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    };

    const endCall = async () => {
        // Clear timers
        clearCallTimers();
        
        // Reset state
        setCallDuration(0);
        setMute(false);
        
        // Disconnect from Twilio
        token.disconnectAll();
        
        // Release microphone
        await stopMicrophone();
        
        // Update Redux state
        dispatch({ type: "CALLSHOW", payload: null });
        dispatch({ type: "INCOMINGCALLSHOW", payload: false });
        dispatch({ type: "INCOMINGCALLDETAIL", payload: null });
    };

    // Set up event listeners when component mounts
    useEffect(() => {
        // Connect event
        token.on('connect', async (call) => {
            clearCallTimers();
            timerRef.current = setInterval(() => {
                setCallDuration(prevDuration => prevDuration + 1);
            }, 1000);
        });

        // Error event
        token.on('error', async (deviceError) => {
            console.log('Device error:', deviceError);
            dispatch({ type: "CALLSHOW", payload: false });
            dispatch({ type: "INCOMINGCALLSHOW", payload: false });
            dispatch({ type: "INCOMINGCALLDETAIL", payload: null });
            await endCall();
        });

        // Disconnect event
        token.on('disconnect', async (call) => {
            dispatch({ type: "CALLSHOW", payload: false });
            dispatch({ type: "INCOMINGCALLSHOW", payload: false });
            dispatch({ type: "INCOMINGCALLDETAIL", payload: null });
            console.log('Call disconnected');
            if (detailIncoming) {
                detailIncoming.disconnect();
            }
            await endCall();
        });

        // Start timer for duration display
        timerRef.current = setInterval(() => {
            setCallDuration(prevDuration => prevDuration + 1);
        }, 1000);

 

        // Cleanup on component unmount
        return () => {
            clearCallTimers();
       
            stopMicrophone();
            token.disconnectAll();
        };
    }, []);

    const handleMuteToggle = () => {
        if (detailIncoming) {
            setMute(!mute);
            detailIncoming.mute(!mute);
        } else if (connection) {
            setMute(!mute);
            connection?.then(call => call.mute(!mute));
        }
    };

    const handleEndCall = async () => {
        if (detailIncoming) {
            detailIncoming.disconnect();
        }
        await endCall();
    };

    return (
        <MainContainer>
            <div style={{ display: 'flex', marginTop: 10, paddingBottom: 5, alignSelf: 'start', paddingLeft: 20, borderBottom: '1px solid #F2F2F2', width: '93%' }}>
                <Text>{detailIncoming ? detailIncoming?.parameters?.Params?.replace(/first_name=/g, '') : detail?.name}</Text>
                <Text style={{ marginLeft: 20 }}>{formatDuration(callDuration)}</Text>
            </div>

            <ImgContainer>
                <Img src={user} />
            </ImgContainer>

            <Text>{detailIncoming ? formatMobileNumber(detailIncoming?.parameters?.From) : formatMobileNumber(detail?.to)}</Text>
            <div style={{ display: 'flex', alignSelf: 'start', marginTop: 20, }}>
                <div style={{ display: 'flex', marginRight: '65px' }}>
                    <IconContainer 
                        style={{ background: mute ? palette.charcoleBlack : '#34b233' }} 
                        onClick={handleMuteToggle}
                    >
                        <Icon src={mute ? muteIcon : speaker} />
                    </IconContainer>
                    <IconContainer 
                        onClick={() => {
                            if (detailIncoming) {
                                detailIncoming.mute(true);
                            }
                        }} 
                        style={{ background: '#34b233' }}
                    >
                        <Icon src={volume} />
                    </IconContainer>
                </div>
                <Button onClick={handleEndCall}>
                    End call
                </Button>
            </div>
        </MainContainer>
    );
};

export default CallingUI;

const MainContainer = styled.div`
  width: 280px;
  height: 215px;
  position: absolute;
  display:flex;
 
  flex-direction:column;
  align-items:center;
  transition: all 0.2s ease-in-out;
  bottom: 50px;
  right: 30px;
  border-radius: 10px;
  background: #2b373f;
  box-shadow: 0 8px 16px rgba(0, 2, 4, 0.4); /* Adjust values as needed */
`;

const ImgContainer = styled.div`
background:white;
width:50px;
height:50px;
border-radius:25px;
margin-top:20px;
`
const Img = styled.img`
width:50px;
height:50px;
color:white;
`
const Text = styled.p`
font-size:14px;
color:white;
text-align:center;
margin-top:5px;
font-weight:600;
margin-bottom:5px;
`
const IconContainer = styled.div`
background:black;
width:38px;
height:38px;
display:flex;
justify-content:center;
align-items:center;
border-radius:5px;
margin-left:10px;
cursor:pointer;


`
const Icon = styled.img`
width18px;
height:18px;
color:white;
`
const Button = styled.button`
width:100px;
height:38px;
border-radius:5px;
background:red;
border:1px solid red;
color:white;
cursor:pointer;

`