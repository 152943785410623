import { KeyboardArrowDown, KeyboardArrowUp, Menu } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { FiEdit, FiEdit2 } from "react-icons/fi";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import {
  deleteCallTree,
  getCallTree,
  updateAutomation,
  updateCallTree,
} from "../../../store/Actions/SettingsAction";
import { formatMobileNumber } from "../../../utils/Commonfunction";
import { palette } from "../../../utils/Palette";
import RightModal from "../../RightSideModal";
import TopModal from "../../TopModal";
import AddCallTreeDetails from "../component/AddCallTreeDetails";
import IOSSwitchComponent from "../../../ReuseableComponets/IOSSwitch";

const Container = styled.div`
  background-color: #ffffff;
  color: #333333;
  width: 100%;

  border-radius: 8px;
  padding: 20px;
  border: 1px solid #ddd;
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  ${({ isExpanded }) =>
    isExpanded
      ? `
    max-height: auto; 
    padding: 16px;
  `
      : `
    max-height: 70px; 
    padding: 16px;
  `}
  margin: 20px 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  text-align: start;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 14px;
`;

const SubText = styled.p`
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  color: grey;
  font-weight: 400;
  text-align: start;
`;

const Details = styled.div`
  margin-top: 16px;
`;

const GreetingMessage = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const InstructionText = styled.p`
  font-size: 14px;
  margin: 8px 0;
  color: #666;
  text-align: start;
  margin-right: 10px;
  white-space: pre-line;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Select = styled.select`
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  flex: 1;
`;

const AddOptionButton = styled.button`
  background-color: #0f52ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #084d9e;
  }
`;

const SetGreetingButton = styled.button`
  background-color: #0f52ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #084d9e;
  }
`;

const ContainerMain = styled.div`
  width: 100%;
  background: #fff;
  padding-top: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 260px) and (max-width: 1300px) {
    overflow: auto;
  }
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 3px 7px;
  border-radius: 12px;
  margin-top: -2px;
  background: ${(props) => (props.isActive ? "#90EE90" : "#eee")};
`;

const StatusIndicator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "green" : "#ccc")};
  margin-right: 5px;
`;

const StatusText = styled.span`
  font-size: 12px;
  margin-top: -2px;
  color: ${(props) => (props.isActive ? "green" : "#777")};
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;

  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 30px;
    max-width: 30px;
  }
`;
const InputText = styled.textarea`
  font-family: Inter;
  height: 60px;
  font-size: 14px;
  padding: 10px;
  word-wrap: break-word;
  width: 95%;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  margin-top: 10px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const PhoneMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedMsg, setIsExpandedMsg] = useState(false);
  const [menuOptions, setMenuOptions] = useState([
    { key: "1", destination: "" },
  ]);
  const [treeData, setTreeData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [autoText, setAutoText] = useState("");
  const [autoPlay, setAutoPlay] = useState("");
  const [boolValue, setBoolValue] = useState({});
  const [AutomationValue, setAutomationValue] = useState({});
  const dispatch = useDispatch();
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const addMenuOption = () => {
    setMenuOptions([
      ...menuOptions,
      { key: menuOptions.length + 1, destination: "" },
    ]);
  };

  const init = async () => {
    const res = await dispatch(getCallTree());
    console.log(res, "sdcdkcndk");
    setTreeData(res?.data?.[0]?.callTree || {});
    setAutoText(res?.data?.[0]?.automation?.autoReplyText);
    setAutoPlay(res?.data?.[0]?.automation?.autoPlayMessage);
    setAutomationValue(res?.data?.[0]?.automation);
  };
  useEffect(() => {
    init();
  }, []);

  const handleDelete = async () => {
    const res = await dispatch(deleteCallTree(treeData?._id));
    setDeleteModalOpen(false);
    init();
  };
  const handleCHangeAutoText = async (text, type) => {
    const res = await dispatch(
      updateAutomation(
        AutomationValue?._id,
        type === "autoplay"
          ? {
              autoPlayMessage: text,
            }
          : type === "voiceMail"
          ? {
              voiceMail: text,
            }
          : type === "isRecording"
          ? {
            recordingEnabled: text,
            }
          : {
              autoReplyText: text,
            }
      )
    );
    setIsExpandedMsg(false);
    init();
  };

  return (
    <div style={{ width: "100%", overflowY: "scroll" }}>
      <h2
        style={{
          textAlign: "start",
          marginLeft: "20px",
          marginTop: "30px",
          marginBottom: "10px",
        }}
      >
        Call flow
      </h2>
      <SubText
        style={{ textAlign: "start", marginLeft: "20px", fontSize: "14px" }}
      >
        Configure your incoming call flow
      </SubText>

      <ContainerMain>
        <Container isExpanded={isExpanded} aria-expanded={isExpanded}>
          <Header onClick={toggleExpand}>
            <TitleContainer>
              <Title style={{ display: "flex" }}>
                Phone Menu{" "}
                <StatusContainer
                  isActive={treeData?.isRoot}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (treeData?.isRoot) {
                      setDeleteModalOpen(true);
                    } else {
                      setModalOpen(true);
                    }
                  }}
                >
                  <StatusIndicator isActive={treeData?.isRoot} />
                  <StatusText isActive={treeData?.isRoot}>
                    {treeData?.isRoot ? "Active" : "Inactive"}
                  </StatusText>
                </StatusContainer>
              </Title>
              <SubText>
                Direct callers to the right place based on menu options
              </SubText>
            </TitleContainer>
            <div style={{ display: "flex" }}>
              <IconButton>
                {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </Header>

          {isExpanded && (
            <Details>
              <GreetingMessage>
                <div>
                  <Title style={{ textAlign: "start" }}>Greeting message</Title>

                  <InstructionText>
                    {treeData?.greetingMessage ||
                      "Create a phone menu to enable the feature"}
                  </InstructionText>
                </div>
                <RippleButton onClick={() => setModalOpen(true)}>
                  {treeData?._id ? "Edit" : "+ Create"}
                </RippleButton>
              </GreetingMessage>

              <MenuOptions>
                <label>
                  <Title style={{ textAlign: "start" }}>Menu Options</Title>
                </label>
                <CustomTable>
                  <Table
                    style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
                    sx={{ width: "100%" }}
                    aria-label="simple table"
                  >
                    <CustomHead>
                      <TableRow>
                        <TableCell
                          className="sticky"
                          size="small"
                          style={{
                            cursor: "pointer",
                            width: "100px",
                            fontWeight: "700",
                          }}
                        >
                          Digit
                        </TableCell>

                        <TableCell
                          style={{
                            position: "relative",
                            color: palette.black,
                            fontWeight: "700",
                          }}
                          size="small"
                        >
                          Command
                        </TableCell>
                        <TableCell
                          style={{
                            width: "100px",
                            position: "relative",
                            color: palette.black,
                            fontWeight: "700",
                          }}
                          size="small"
                        >
                          Phone number
                        </TableCell>
                      </TableRow>
                    </CustomHead>
                    {treeData?.actions?.map((option, index) => (
                      <TableBody
                        key={index}
                        style={{ background: "#fff", cursor: "pointer" }}
                      >
                        <TableRow>
                          <TableCell
                            size="small"
                            style={{
                              fontWeight: "500",
                              color: palette.newBlue,
                            }}
                          >
                            {option.digit}
                          </TableCell>
                          <TableCell size="small">{option?.label}</TableCell>
                          <TableCell size="small">
                            {formatMobileNumber(option?.target)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                  {treeData?.actions?.length > 0 ? null : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="60vh"
                      sx={{ color: "gray" }}
                    >
                      <Menu sx={{ fontSize: "50px", marginBottom: 2 }} />
                      <Typography variant="h5" align="center">
                        Not enabled the Phone Menu
                      </Typography>
                      <Typography
                        variant="body2"
                        align="center"
                        color="textSecondary"
                      >
                        You can add new Phone Menu to manage your data more
                        effectively.
                      </Typography>
                    </Box>
                  )}
                </CustomTable>
              </MenuOptions>
            </Details>
          )}
        </Container>
      </ContainerMain>
      <ContainerMain style={{ paddingTop: "0px" }}>
        <Container isExpanded={isExpandedMsg} aria-expanded={isExpandedMsg}>
          <Header onClick={() => setIsExpandedMsg((prev) => !prev)}>
            <TitleContainer>
              <Title style={{ marginTop: "0px", display: "flex" }}>
                Set miss call auto reply
                <StatusContainer
                  isActive={AutomationValue?.autoReplyText}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpandedMsg((prev) => !prev);
                  }}
                >
                  <StatusIndicator isActive={AutomationValue?.autoReplyText} />
                  <StatusText isActive={AutomationValue?.autoReplyText}>
                    {AutomationValue?.autoReplyText ? "Active" : "Inactive"}
                  </StatusText>
                </StatusContainer>
              </Title>
              {
                <SubText style={{ marginBottom: "0px" }}>
                  {isExpandedMsg
                    ? ""
                    : AutomationValue?.autoReplyText ||
                      "Automatically respond to incoming messages with ease"}
                </SubText>
              }
            </TitleContainer>
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpandedMsg((prev) => !prev);
                }}
              >
                {<FiEdit style={{ fontSize: "18px" }} />}
              </IconButton>
            </div>
          </Header>
          {isExpandedMsg && (
            <div>
              <InputText
                value={autoText}
                onChange={(e) => setAutoText(e.target.value)}
                placeholder="Write your message"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <RippleButton
                  onClick={async () => {
                    await handleCHangeAutoText("");
                  }}
                  style={{
                    background: "#fff",
                    borderColor: "#fff",
                    color: "#fa3e3e",
                    textDecoration: "underline",
                  }}
                >
                  Delete
                </RippleButton>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <RippleButton
                    onClick={() => {
                      setIsExpandedMsg(false);
                      setAutoText(AutomationValue?.autoReplyText);
                    }}
                    style={{
                      background: "#fff",
                      borderColor: "#fff",
                      color: "#000",
                      marginRight: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Cancel
                  </RippleButton>
                  <RippleButton
                    onClick={async () => {
                      await handleCHangeAutoText(autoText);
                    }}
                  >
                    Save
                  </RippleButton>
                </div>
              </div>
            </div>
          )}
        </Container>
      </ContainerMain>
      <ContainerMain style={{ paddingTop: "0px" }}>
        <Container
          isExpanded={boolValue?.isGreetingMessage}
          aria-expanded={boolValue?.isGreetingMessage}
        >
          <Header
            onClick={() =>
              setBoolValue({ ...boolValue, isGreetingMessage: true })
            }
          >
            <TitleContainer>
              <Title style={{ marginTop: "0px", display: "flex" }}>
                Set incoming call greeting message autoplay
                <StatusContainer
                 isActive={AutomationValue?.autoPlayMessage}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (boolValue?.isGreetingMessage) {
                      setBoolValue({ ...boolValue, isGreetingMessage: false });
                    } else {
                      setBoolValue({ ...boolValue, isGreetingMessage: true });
                    }
                  }}
                >
                  <StatusIndicator
                    isActive={AutomationValue?.autoPlayMessage}
                  />
                  <StatusText isActive={AutomationValue?.autoPlayMessage}>
                    {AutomationValue?.autoPlayMessage ? "Active" : "Inactive"}
                  </StatusText>
                </StatusContainer>
              </Title>
              {
                <SubText style={{ marginBottom: "0px" }}>
                  {boolValue?.isGreetingMessage
                    ? ""
                    : AutomationValue?.autoPlayMessage ||
                      "Automatically respond to incoming greeting messages with autoplay functionality."}
                </SubText>
              }
            </TitleContainer>
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (boolValue?.isGreetingMessage) {
                    setBoolValue({ ...boolValue, isGreetingMessage: false });
                  } else {
                    setBoolValue({ ...boolValue, isGreetingMessage: true });
                  }
                }}
              >
                {<FiEdit style={{ fontSize: "18px" }} />}
              </IconButton>
            </div>
          </Header>
          {boolValue?.isGreetingMessage && (
            <div>
              <InputText
                value={autoPlay}
                onChange={(e) => setAutoPlay(e.target.value)}
                placeholder="Write your message"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <RippleButton
                  onClick={async () => {
                    await handleCHangeAutoText("", "autoplay");
                    setBoolValue({ ...boolValue, isGreetingMessage: false });
                  }}
                  style={{
                    background: "#fff",
                    borderColor: "#fff",
                    color: "#fa3e3e",
                    textDecoration: "underline",
                  }}
                >
                  Delete
                </RippleButton>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <RippleButton
                    onClick={() => {
                      setBoolValue({ ...boolValue, isGreetingMessage: false });
                      setAutoText(AutomationValue?.autoPlayMessage);
                    }}
                    style={{
                      background: "#fff",
                      borderColor: "#fff",
                      color: "#000",
                      marginRight: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Cancel
                  </RippleButton>
                  <RippleButton
                    onClick={async () => {
                      await handleCHangeAutoText(autoPlay, "autoplay");
                      setBoolValue({ ...boolValue, isGreetingMessage: false });
                    }}
                  >
                    Save
                  </RippleButton>
                </div>
              </div>
            </div>
          )}
        </Container>
      </ContainerMain>
      <ContainerMain style={{ paddingTop: "0px" }}>
        <Container
          isExpanded={boolValue?.isvoicemail}
          aria-expanded={boolValue?.isvoicemail}
        >
          <Header
            onClick={() => setBoolValue({ ...boolValue, isvoicemail: true })}
          >
            <TitleContainer>
              <Title style={{ marginTop: "0px", display: "flex" }}>
                Set Voicemail greeting message autoplay
                <StatusContainer
                  isActive={AutomationValue?.voiceMail}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (boolValue?.isvoicemail) {
                      setBoolValue({ ...boolValue, isvoicemail: false });
                    } else {
                      setBoolValue({ ...boolValue, isvoicemail: true });
                    }
                  }}
                >
                  <StatusIndicator isActive={AutomationValue?.voiceMail} />
                  <StatusText isActive={AutomationValue?.voiceMail}>
                    {AutomationValue?.voiceMail ? "Active" : "Inactive"}
                  </StatusText>
                </StatusContainer>
              </Title>
              {
                <SubText style={{ marginBottom: "0px" }}>
                  {boolValue?.isvoicemail
                    ? ""
                    : AutomationValue?.voiceMail ||
                      "Automatically respond to Voicemail messages with autoplay functionality."}
                </SubText>
              }
            </TitleContainer>
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (boolValue?.isvoicemail) {
                    setBoolValue({ ...boolValue, isvoicemail: false });
                  } else {
                    setBoolValue({ ...boolValue, isvoicemail: true });
                  }
                }}
              >
                {<FiEdit style={{ fontSize: "18px" }} />}
              </IconButton>
            </div>
          </Header>
          {boolValue?.isvoicemail && (
            <div>
              <InputText
                value={autoPlay}
                onChange={(e) => setAutoPlay(e.target.value)}
                placeholder="Write your message"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <RippleButton
                  onClick={async () => {
                    await handleCHangeAutoText("", "voiceMail");
                    setBoolValue({ ...boolValue, isvoicemail: false });
                  }}
                  style={{
                    background: "#fff",
                    borderColor: "#fff",
                    color: "#fa3e3e",
                    textDecoration: "underline",
                  }}
                >
                  Delete
                </RippleButton>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <RippleButton
                    onClick={() => {
                      setBoolValue({ ...boolValue, isvoicemail: false });
                      setAutoText(AutomationValue?.isvoicemail);
                    }}
                    style={{
                      background: "#fff",
                      borderColor: "#fff",
                      color: "#000",
                      marginRight: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Cancel
                  </RippleButton>
                  <RippleButton
                    onClick={async () => {
                      await handleCHangeAutoText(autoPlay, "voiceMail");
                      setBoolValue({ ...boolValue, isvoicemail: false });
                    }}
                  >
                    Save
                  </RippleButton>
                </div>
              </div>
            </div>
          )}
        </Container>
      </ContainerMain>
      <ContainerMain style={{ paddingTop: "0px" }}>
        <Container style={{ paddingTop: "0px", paddingLeft: "0px" }}>
          <FlexContainer>
          <Title style={{ marginTop: "0px", display: "flex" }}>
          Automatically Record All Incoming & Outgoing Calls
          </Title>
            <IOSSwitchComponent
              checked={AutomationValue?.recordingEnabled}
              onChange={async (val) => {
                await handleCHangeAutoText((AutomationValue?.recordingEnabled?false:true), "isRecording");
              }}
            />
          </FlexContainer>
        </Container>
      </ContainerMain>

      {modalOpen && (
        <RightModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <AddCallTreeDetails
            data={treeData}
            onSubmit={() => {
              setModalOpen(false);
              init();
            }}
            onClose={() => setModalOpen(false)}
          />
        </RightModal>
      )}
      {deleteModalOpen && (
        <TopModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
        >
          <Box p={3}>
            <Title style={{ textAlign: "start" }}>
              Are you sure you want to disable the Phone Menu?
            </Title>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <RippleButton
                onClick={() => setDeleteModalOpen(false)}
                style={{
                  backgroundColor: "#ddd",
                  borderColor: "#ddd",
                  color: "#000",
                  marginRight: "20px",
                }}
              >
                Cancel
              </RippleButton>
              <RippleButton
                onClick={() => handleDelete()}
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                Disable
              </RippleButton>
            </Box>
          </Box>
        </TopModal>
      )}
    </div>
  );
};

export default PhoneMenu;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 20px;
  align-items: center;

  font-size: 14px;
`;
