import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AccessTimeOutlined } from '@mui/icons-material';

// Styled components
const TimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  margin-left:15px;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 3px;
`;

const TimeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  color: ${(props) => (props.disabled ? '#000' : '#000')};
  width: 100%;
  min-width: 150px;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TimePicker = styled(ReactDatePicker)`
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    z-index: 25555555555555555555555555555555;
  }
  .react-datepicker__time-container {
    width: auto;
  }
  .react-datepicker__time-list {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CustomInput = ({ value, onClick, disabled, customStyle, iconStyle }) => (
  <TimeButton disabled={disabled} onClick={onClick} style={customStyle}>
    {value || 'Time'}
    <AccessTimeOutlined style={{ fontSize: '16px', ...iconStyle }} alt="time icon" />
  </TimeButton>
);

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  iconStyle: PropTypes.object
};

// TimePickerComponent using ReactDatePicker
const TimePickerComponent = ({
  label,
  selectedTime,
  onTimeChange,
  disabled,
  customStyle,
  iconStyle,
  minuteStep = 15,
  popperPlacement = 'bottom-end'
}) => {
  // Internal state to keep track of the Date object for ReactDatePicker
  const [dateValue, setDateValue] = useState(null);
  
  // Parse string time format "hh:mm A" into a Date object
  const parseTimeString = (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') return null;
    
    // Try to match the "hh:mm A" format
    const match = timeStr.match(/^(\d{1,2}):(\d{2})\s?(AM|PM|am|pm)$/);
    if (!match) return null;
    
    let [_, hours, minutes, period] = match;
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    
    if (hours > 12 || minutes > 59) return null;
    
    const date = new Date();
    date.setHours(
      period.toUpperCase() === 'PM' && hours < 12 ? hours + 12 : (period.toUpperCase() === 'AM' && hours === 12 ? 0 : hours),
      minutes,
      0,
      0
    );
    
    return date;
  };
  
  // Format Date to "hh:mm A" string format
  const formatTimeToString = (time) => {
    if (!time) return '';
    
    const timeObj = new Date(time);
    let hours = timeObj.getHours();
    const mins = timeObj.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12 || 12;
    return `${hours.toString().padStart(2, '0')}:${mins} ${period}`;
  };

  // Handle time change and format the return value
  const handleTimeChange = (time) => {
    if (!time) {
      onTimeChange('');
      setDateValue(null);
      return;
    }
    
    // Format the time as "hh:mm A" string
    const formattedTime = formatTimeToString(time);
    setDateValue(time);
    
    // Only return the formatted string
    onTimeChange(formattedTime);
  };
  
  // Initialize dateValue from selectedTime (which could be string or Date)
  useEffect(() => {
    if (!selectedTime) {
      setDateValue(null);
      return;
    }
    
    // If selectedTime is already a Date object
    if (selectedTime instanceof Date) {
      setDateValue(selectedTime);
      return;
    }
    
    // If selectedTime is a string in "hh:mm A" format, parse it
    const parsedDate = parseTimeString(selectedTime);
    if (parsedDate) {
      setDateValue(parsedDate);
    }
  }, [selectedTime]);

  return (
    <TimePickerWrapper>
      {label && <Label>{label}</Label>}
      <TimePicker
        selected={dateValue}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={minuteStep}
        timeCaption="Time"
        dateFormat="hh:mm aa"
        timeFormat="hh:mm aa"
        popperPlacement={popperPlacement}
        disabled={disabled}
        customInput={
          <CustomInput
            disabled={disabled}
            customStyle={customStyle}
            iconStyle={iconStyle}
          />
        }
      />
    </TimePickerWrapper>
  );
};

// PropTypes validation
TimePickerComponent.propTypes = {
  label: PropTypes.string,
  selectedTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  onTimeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  minuteStep: PropTypes.number,
  popperPlacement: PropTypes.string
};

TimePickerComponent.defaultProps = {
  label: '',
  selectedTime: null,
  disabled: false,
  minuteStep: 15,
  popperPlacement: 'bottom-end'
};

export default TimePickerComponent;