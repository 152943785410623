import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CancelOutlined, PaidOutlined, RequestQuoteOutlined } from '@mui/icons-material';
import { fetchAllContacts } from '../../../../store/Actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
import { palette } from '../../../../utils/Palette';
import { formatMobileNumber, putCommaInvalue } from '../../../../utils/Commonfunction';
import InputBox from '../../../../ReuseableComponets/InputBox';
import { submitBilltoContact } from '../../../../store/Actions/ContactAction';
import ToggleTabs from '../../../../ReuseableComponets/ToggleTab';
import Estimate from '../../Payments/Estimates';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BillToDetails = (props) => {
    const {estimate,invoices}=props
    const navigate=useNavigate()
    const [selected, setselected] = useState("Invoices");
  
    const dispatch = useDispatch();



    return (
        <BillingContainer>
                  <Header>
                <h4>Details</h4>
                <CancelOutlined onClick={props?.onCancel} className="close-icon" />
            </Header>

            <ToggleTabs
            labels={["Invoices","Estimates"]}
            onToggle={(val)=>setselected(val)}
            />
              <div style={{ marginTop: "15px" }}>
          <>
            {selected==='Invoices'? invoices?.length > 0 ? (
              invoices?.map((item, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    variant="body2"
                  >
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "15px",
                          textAlign: "start",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <Link
                          onClick={() =>
                            navigate(`/dashboard/invoices/${item?._id}`)
                          }
                        >
                          {`Invoice #${item?.numberOfInvoice}`}{" "}
                        </Link>
                        <span style={{ fontSize: "12px" }}>
                          <span
                            style={{
                              marginLeft: "5px",
                              color:
                                item?.invoiceStatus === "Rejected"
                                  ? "#c0113d"
                                  : item?.invoiceStatus === "Paid"
                                  ? "#29740f"
                                  : item?.invoiceStatus === "Draft"
                                  ? "#586171"
                                  : "#fa811f",
                            }}
                          >
                            {" "}
                            ●{" "}
                          </span>{" "}
                          {item?.invoiceStatus === "Paid"
                            ? item?.paymentMethod?.replace(
                                /\(In-person\)/g,
                                ""
                              ) || item?.invoiceStatus
                            : item?.invoiceStatus === "Rejected"
                            ? "Void"
                            : item?.invoiceStatus}
                        </span>
                      </h5>
                    </div>
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "20px",
                          textAlign: "end",
                        }}
                      >{`$${putCommaInvalue(
                        parseFloat(item?.totalAmount)?.toFixed(2)
                      )}`}</h5>
                      {item?.deposit && (
                        <Label>(${item?.deposit} Deposit Paid)</Label>
                      )}
                    </div>
                  </div>
                  {invoices?.length > 1 &&
                    index !== invoices?.length - 1 && (
                      <Divider style={{ color: palette.smokeWhite }} />
                    )}
                </div>
              ))
            ) : (
              <InnerContainer>
                <PaidOutlined /> No Invoices{" "}
              </InnerContainer>
            ):
            <>
            {estimate?.length > 0 ? (
              estimate?.map((item, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    variant="body2"
                  >
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "15px",
                          textAlign: "start",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          onClick={() =>
                            navigate(`/dashboard/quotes/${item?._id}`)
                          }
                        >
                          {`Estimate #${item?.numberOfQuote}`}{" "}
                        </Link>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          <span
                            style={{
                              color:
                                item?.status === "Waiting for confirmation"
                                  ? "#fa811f"
                                  : item?.status === "Draft"
                                  ? "#586171"
                                  : item?.status === "Rejected"
                                  ? "#c0113d"
                                  : "#29740f",
                            }}
                          >
                            ●{" "}
                          </span>{" "}
                          {item?.status === "Waiting for confirmation"
                            ? "Waiting"
                            : item?.status === "Rejected"
                            ? "Void"
                            : item?.status}
                        </span>
                      </h5>
                    </div>
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "20px",
                          textAlign: "start",
                        }}
                      >{`$${putCommaInvalue(
                        parseFloat(item?.totalAmount)?.toFixed(2)
                      )}`}</h5>
                    </div>
                  </div>
                  {estimate?.length > 1 &&
                    index !== estimate?.length - 1 && (
                      <Divider style={{ color: palette.smokeWhite }} />
                    )}
                </div>
              ))
            ) : (
              <InnerContainer>
                <RequestQuoteOutlined /> No Estimates{" "}
              </InnerContainer>
            )}
          </>
            
            }
          </>
        </div>
      
        </BillingContainer>
    );
};

export default BillToDetails;

/* Styled Components */
const BillingContainer = styled.div`
    border-radius: 8px;
    width: 440px;
    padding: 10px;
    position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h4 {
        font-weight: 500;
        margin: 0;
    }

    .close-icon {
        cursor: pointer;
        color: rgba(32, 32, 32, 0.85);
        &:hover {
            color: black;
        }
    }
`;

const ListContainer = styled.div`
    overflow-y: scroll;
    max-height: 350px;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom:1px solid #eee;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f7f7f7;
    }
`;

const ProfileIcon = styled.div`
    background: ${palette.newBlue};
    color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 5px;
    border-radius: 50%;
    font-weight: bold;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;
  background: #f1f3f5;
  padding: 8px 12px;
  border-radius: 6px;
  margin: 8px 0;
  font-size: 13px;
  .headerwidth {
    width: 33%;
    font-size: 13px;
    font-weight: 500;
  }
  .width {
    width: 33%;
    font-size: 13px;
    font-weight: 400;
  }
  svg {
    font-size: 20px;
  }
`;
const Link = styled.div`
  color: #0f52ba;
  font-size: 14px;
  cursor: pointer;
  margin: 6px 0;
  text-align: start;
  font-weight: 500;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 13px;
  white-space: pre-line;
  color: #344054;
  line-height: 16px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: Inter;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #0f52ba;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    z-index: 10;
`;

