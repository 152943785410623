import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Button,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '13px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  position: 'relative',
}));

const NestedMenuItem = ({ 
  parentMenuOpen,
  label, 
  icon, 
  disabled, 
  children, 
  onItemClick,
  ...props 
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isSubMenuOpen = Boolean(anchorEl);
  
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
    if (!item.preventClose) {
      handleClose();
      if (props.handleCloseParent) {
        props.handleCloseParent();
      }
    }
  };

  useEffect(() => {
    if (!parentMenuOpen) {
      handleClose();
    }
  }, [parentMenuOpen]);
  
  return (
    <>
      <StyledMenuItem onClick={handleClick} disabled={disabled}>
        {icon && icon}
        <ListItemText primary={label} />
        <ChevronRightIcon fontSize="small" sx={{ ml: 'auto' }} />
      </StyledMenuItem>
      
      <MuiMenu
        anchorEl={anchorEl}
        open={isSubMenuOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          elevation: 1,
          sx: { minWidth: 180, maxHeight: 300, overflow: 'auto', border: '1px solid #e0e0e0' }
        }}
      >
        {children.map((child, index) => (
          child.children && child.children.length > 0 ? (
            <NestedMenuItem
              key={`${child.label}-${index}`}
              parentMenuOpen={isSubMenuOpen}
              label={child.label}
              icon={child.icon}
              disabled={child.disabled}
              children={child.children}
              onItemClick={onItemClick}
              handleCloseParent={handleClose}
            />
          ) : (
            <StyledMenuItem
              key={`${child.label}-${index}`}
              onClick={() => handleItemClick(child)}
              disabled={child.disabled}
            >
              {child.icon && child.icon}
              <ListItemText primary={child.label} />
            </StyledMenuItem>
          )
        ))}
      </MuiMenu>
    </>
  );
};

const NestedMenu = ({ menuItems, anchorEl, open, onClose, onItemClick }) => (
  <MuiMenu
    anchorEl={anchorEl}
    open={Boolean(open && anchorEl)}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
      sx: { maxHeight: 300, minWidth: 200, border: '1px solid #e0e0e0', borderRadius: '4px' }
    }}
  >
    {menuItems.map((item, index) => (
      item.children && item.children.length > 0 ? (
        <NestedMenuItem
          key={`${item.label}-${index}`}
          parentMenuOpen={open}
          label={item.label}
          icon={item.icon}
          disabled={item.disabled}
          children={item.children}
          onItemClick={onItemClick}
          handleCloseParent={onClose}
        />
      ) : (
        <StyledMenuItem key={`${item.label}-${index}`} onClick={() => onItemClick(item)} disabled={item.disabled}>
          {item.icon && item.icon}
          <ListItemText primary={item.label} />
        </StyledMenuItem>
      )
    ))}
  </MuiMenu>
);

const MenuButton = ({ buttonText, buttonProps, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleItemClick = (item) => {
    console.log('Menu item clicked:', item);
    handleClose();
  };
  
  return (
    <>
      <Button onClick={handleClick} {...buttonProps}>{buttonText || 'Menu'}</Button>
      <NestedMenu menuItems={menuItems} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onItemClick={handleItemClick} />
    </>
  );
};

export { NestedMenu, MenuButton };
