import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Box, ClickAwayListener, Tab, Tabs, useTheme } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import SwipeableViews from "react-swipeable-views";
import cross from "../../assets/cross.png";
import FullScreenModal from "../../Components/FullScreenModal";
import RightModal from "../../Components/RightSideModal";
import RequestForm from "../../Components/Dashboard/RequestForm";
import TabPanel from "../../Components/TabPanel";
import Modal from "../../Components/Modal";
import FormList from "./components/FormList";
import FormDataList from "./components/FormDataList";
import FormDetails from "../../Components/Dashboard/FormDetails";
import CustomTabs from "../../ReuseableComponets/CustomTab";
import {
  DatasetOutlined,
  CallToActionOutlined,
  Add,
  ReceiptOutlined,
} from "@mui/icons-material";
import { palette } from "../../utils/Palette";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/MainHeader";
import {
  deleteonlineFormDataId,
  getOnlineBookingForm,
  resetJobForm,
} from "../../store/BookingForm/bookingFormReducer";
import { useSelector } from "react-redux";
import doneAnimation from "../../assets/done.json";
import { useToast } from "../../Components/Toaster/ToasterContext";
import CreateBooking from "../../Components/Dashboard/CreateBooking";

const OnlineBookingForm = (props) => {
  const dispatch = useDispatch();
  const { loader, allData } = useSelector((state) => state.jobForm);
  const [data, setData] = useState([]);
  const crew = useSelector((state) => state?.auth?.userData?.crewMembers);
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [formModal, setFormModal] = useState(false);
  const [formDetailModal, setFormDetailModal] = useState(false);
  const { showToast } = useToast();
  const [shareModal, setShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formNumber, setFormNumber] = useState("");
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(0);
  const handleChangeIndex1 = (index) => {
    setValue1(index);
  };
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const init = async () => {
    const res = await dispatch(getOnlineBookingForm());
    setData(res?.data);
    setCustomerData(res?.customerData);
  };
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleCopyClick = (item) => {
    navigator.clipboard.writeText(
      `https://app.getnotifi.com/onlinebooking/${item}`
    );
  };

  function a11yProps1(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function convertStringFields(obj) {
    return Object?.entries(obj)?.filter(([_, value]) => typeof value === "string")
        ?.map(([fieldName, fieldValue]) => ({ fieldName, fieldValue, fieldType: "text" }));
}

  return (
    <>
      <Body>
        <FlexboxContainer>
          <Header
            searchComponent={<input type="text" placeholder="Search..." />}
            handleShowJobs={() => console.log("Show jobs")}
            nonsearchable
          />
          <StyledContactFlex>
            <div>
              <h2>Booking Request </h2>
              <span>
                <ReceiptOutlined
                  style={{ marginRight: "5px", fontSize: "20px" }}
                />{" "}
                {allData?.customerData?.length || 0}
              </span>
            </div>
            <OptionContainer>
              <StyledTextBox
                onClick={() => {
                  navigate("/dashboard/jobform/new");
                  dispatch(resetJobForm());
                }}
              >
                <Add style={{ fontSize: "18px", marginRight: "5px" }} /> New
                template
              </StyledTextBox>
            </OptionContainer>
          </StyledContactFlex>

          {loader ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Player
                src={require("../../assets/loaders.json")}
                className="player"
                style={{ width: "200px", height: "200px" }}
                loop
                autoplay
              />
            </div>
          ) : (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <CustomTabs
                tabpanelWidth={"400px"}
                tabsData={[
                  {
                    label: "Requests",
                    icon: <DatasetOutlined style={{ fontSize: "16px" }} />,
                    content: (
                      <FormDataList
                        data={allData?.customerData}
                        setFormDetailModal={() => setFormDetailModal(true)}
                        setSelectedData={(items) => setSelectedData(items)}
                        openModal={(item) => {
                          setSelectedData(item);
                          setFormDetailModal(true);
                        }}
                      />
                    ),
                  },
                  {
                    label: "Templates",
                    icon: <CallToActionOutlined style={{ fontSize: "16px" }} />,
                    content: (
                      <FormList
                        setShareModal={() => {
                          setShareModal(true);
                        }}
                        setFormNumber={(num) => setFormNumber(num)}
                        setFormModal={() => setFormModal(true)}
                        data={allData?.arr}
                        init={() => init()}
                        openModal={(form) => {
                          setFormNumber(form?.formNo);
                          setShareModal(true);
                        }}
                        deleteForm={async (form) => {
                          await dispatch(deleteonlineFormDataId(form?.formNo));
                          init()
                        }}
                      />
                    ),
                  },
                ]}
                value={value}
              />
            </ClickAwayListener>
          )}
          {formModal && (
            <FullScreenModal
              isOpen={formModal}
              onClose={() => setFormModal(false)}
            >
              <HeaderContainer>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseButton onClick={() => setFormModal(false)}>
                    <Icons src={cross} />
                  </CloseButton>
                  <h4 style={{ color: palette.charcoleBlack }}>
                    {"Create form"}
                  </h4>
                </div>
              </HeaderContainer>
              <div
                style={{
                  height: "100%",
                  marginTop: "60px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <RequestForm
                  onCancel={() => {
                    init();
                    setFormModal(false);
                  }}
                  formNumber={formNumber}
                  onSelect={(item) => setFormNumber(item)}
                />
              </div>
            </FullScreenModal>
          )}
          {formDetailModal && (
            <FullScreenModal
              onClose={() => {
                setFormDetailModal(false);
              }}
            >
              <CreateBooking
                {...props}
                value={{
                  ...selectedData?.customerDetails,
                  phone: selectedData?.customerDetails?.to?.length > 10 ?`+${selectedData?.customerDetails?.to}`:`+1${selectedData?.customerDetails?.to}`,
                  date: selectedData?.bookingDetails?.date,
                  endDate: selectedData?.bookingDetails?.date,
                  time: selectedData?.bookingDetails?.startTime,
                  endTime: selectedData?.bookingDetails?.endTime,
                  items: selectedData?.lineItemsData || [],
                  customField:convertStringFields(selectedData?.submittedData)
                }}
                serviceRequest={false}
                newService={true}
                setCreateBookingModal={(val) => {
                  setFormDetailModal(false);
                  if (val?.number) {
                    navigate(`/dashboard/servicerequests/${val?.number}`);
                  }
                  init();
                }}
                team={crew}
                onSubmitsw={async (val, editValue) => {
                  navigate(`/dashboard/servicerequests/${editValue?.number}`);
                  init();
                  setFormDetailModal(false);
                }}
              />
            </FullScreenModal>
          )}
        </FlexboxContainer>
        {shareModal && (
          <Modal
            customStyle={{ background: "#fff" }}
            onClose={() => setShareModal(false)}
          >
            <div>
              <Box sx={{ bgcolor: "background.paper", width: 500 }}>
                <Tabs
                  value={value1}
                  onChange={handleChange1}
                  indicatorColor="secondary"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#0F52BA",
                    },
                  }}
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    sx={{ textTransform: "none" }}
                    label="Share link"
                    {...a11yProps1(0)}
                  />
                  <Tab
                    sx={{ textTransform: "none" }}
                    label="Add to website"
                    {...a11yProps1(1)}
                  />
                </Tabs>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value1}
                  onChangeIndex={handleChangeIndex1}
                >
                  <TabPanel value={value1} index={0} dir={theme.direction}>
                    <InputBoxContainer
                      style={{ marginBottom: "20px", alignItems: "center" }}
                    >
                      <div style={{ fontSize: "14px", marginBottom: "20px" }}>
                        Copy and paste your form link in a message
                      </div>
                      <Inputbox>
                        {`https://app.getnotifi.com/onlinebooking/${formNumber}`}
                        <CopyButton
                          onClick={() => {
                            handleCopyClick(formNumber);
                            showToast(
                              "Copied form successfully!",
                              doneAnimation
                            );
                          }}
                        >
                          {"Copy"}
                        </CopyButton>
                      </Inputbox>
                    </InputBoxContainer>
                  </TabPanel>
                  <TabPanel value={value1} index={1} dir={theme.direction}>
                    <h4 style={{ marginTop: 0, textAlign: "start" }}>
                      Embed code
                    </h4>
                    <InputBoxContainer style={{ marginBottom: "20px" }}>
                      <div style={{ fontSize: "14px", marginBottom: "20px" }}>
                        Copy this HTML code to add a booking button to your
                        website.
                      </div>
                      <EmbbededInput style={{ whiteSpace: "pre-wrap" }}>
                        {`<!--Start of Notifi booking button--> 
 <script src="https://app.getnotifi.com/booking-widget.js" data-booking-id=${formNumber}> </script>
 <!--End of Notifi Online booking button-->
                                    `}
                      </EmbbededInput>
                    </InputBoxContainer>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CopyButton
                        style={{ alignSelf: "flex-end", marginRight: -20 }}
                        onClick={() => {
                          navigator.clipboard.writeText(`
                                       <script 
src="https://app.getnotifi.com/booking-widget.js" 
data-booking-id=${formNumber}>
</script>
                                        `);
                          showToast(
                            "Copied html code successfully!",
                            doneAnimation
                          );
                        }}
                      >
                        {"Copy"}
                      </CopyButton>
                    </div>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </div>
          </Modal>
        )}
      </Body>
    </>
  );
};

export default OnlineBookingForm;
const Body = styled.div`
  display: flex;
  width: calc(100% - 240px);
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 240px;
`;
const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  @media (min-width: 260px) and (max-width: 721px) {
    margin-left: 0px;
  }
`;
const Inputbox = styled.div`
  align-self: center;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  font-size: 12px;

  font-weight: 400;
  color: #344054;
  background: #f3f3f3;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  padding-left: 10px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const StyledContactFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;

  h2 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 3px;
    line-spacing: 0.5px;
  }
  span {
    color: grey;
    font-size: 15px;
    text-align: start;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
    font-weight: 400;
  }
`;
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  z-index: 1000;
  padding: 0px 20px;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);

  .actionbutton {
  }
`;
const EmbbededInput = styled.div`
  width: 100%;

  border-radius: 10px;
  display: flex;
  justify-content: start;
  height: auto;
  display: flex;
  font-size: 12px;
  border-style: dotted;

  boder: 1px solid #4169e1;
  font-weight: 400;
  color: gray;
  background: #f3f3f3;
  padding: 20px;
`;
const CopyButton = styled.button`
  border: 1px solid #4169e1;
  background: #4169e1;
  color: #fff;
  width: 100px;
  height: 38px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
`;

const FlexboxContainer = styled.div`
  width: 100%;
  background: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  @media (min-width: 260px) and (max-width: 1300px) {
    flex-shrink: 0;
    overflow: auto;
  }
`;

const OptionBox = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #bbb;
  background: #0f52ba;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }
`;
const CloseButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #fff;
  margin-right: 15px;
  width: 25px;
  height: 25px;
  align-self: center;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
`;
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 0px;
`;
const Icons = styled.img`
  width: 22px;
  height: 22px;
`;
const StyledTextBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #0f52ba;
  background-color: transparent;

  &:hover {
    background-color: #dce8f5;
  }

  svg {
    font-size: 16px;
    color: #0f52ba;
    transition: color 0.3s;

    &:hover {
      color: #083f7c;
    }
  }
`;
