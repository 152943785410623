import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import cross from "../../assets/cross.png";
import Autocomplete from "react-google-autocomplete";
import { sendMessage } from "../../store/Actions/Auth.action";
import {
  colorThemes,
  formatMobileNumber,
  minutesToTime,
  TimeListAm,
  TimeListPM,
  timeToMinutes,
} from "../../utils/Commonfunction";
import Modals from "../Modal";
import Calendar from "react-calendar";
import moment from "moment";
import {
  addHasgtag,
  convertTorequest,
  CreateBookings,
  CreateBookingsV2,
  editCreateBookings,
  fetchAllContacts,
  getBookings,
  getUserDetails,
  submitBookingStatus,
  submitchecklist,
  submitEditContacts,
  UploadNotesImage,
} from "../../store/Actions/dashboard.action";
import plus from "../../assets/plus.png";
import unlock from "../../assets/unlock.png";
import pause from "../../assets/pause.png";
import fastforward from "../../assets/fast-forward.png";
import check from "../../assets/check.png";
import Autocomplete1, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Checkbox,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import LineItemComponent from "./LineItemComponent";
import AddContact from "../Settings/AddContact";
import FullScreenModal from "../FullScreenModal";
import { palette } from "../../utils/Palette";
import {
  Add,
  AddBoxOutlined,
  BookmarkAdd,
  CalendarMonth,
  Cancel,
  DeleteOutlined,
  Description,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalOffer,
  LocationOn,
  ModeEdit,
  More,
  MoreHoriz,
  People,
  Person,
  Photo,
  Schedule,
} from "@mui/icons-material";
import DatePickerComponent from "../../ReuseableComponets/DatePickerComponent";
import IOSSwitchComponent from "../../ReuseableComponets/IOSSwitch";
import InputBox from "../../ReuseableComponets/InputBox";
import CustomizedMenu from "../../ReuseableComponets/MenuComponent";
import TopModal from "../TopModal";
import RippleButton from "../../ReuseableComponets/RippleButton";
import InputPicker from "../../ReuseableComponets/InputPicker";
import FormDetails from "./FormDetails";
import ToggleTabs from "../../ReuseableComponets/ToggleTab";
import RecurringJobModal from "../../ReuseableComponets/ReoccuringjobComponent";
import { submitReoccuring } from "../../store/Actions/JobAction";
import MultiSelectWithSearch from "../Settings/component/MultiSelectSearch";
import AddCustomField from "../Settings/component/AddCustomField";
import debounce from "lodash/debounce";
import AddressSelector from "./Bookings/Component/AddressSelectorComponent";
import MapFilter from "./Contacts/Component/MapFilter";
import TimePickerComponent from "../../ReuseableComponets/TimePickerComponent";
const filter = createFilterOptions();
function CreateBooking(props) {
  const hashTags = useSelector((state) => state?.auth?.userData?.hashTags);
  const userDetails = useSelector((state) => state?.auth?.userData);
  const [addOwnerData, setAddOwnerData] = useState(props?.value?.items || []);
  const [isswitch, setisswitch] = useState(false);
  const detailContact = useSelector((state) => state?.auth?.allContacts);
  const [hastTag, setHasTag] = useState(hashTags);
  const customFieldData = useSelector(
    (state) => state?.auth?.userData?.customeFields
  );
  const checklist = useSelector((state) => state?.auth?.userData?.checkLists);
  const [customField, setDataCustomField] = useState(customFieldData);
  const [selectCustomField, setCustomField] = useState(
    props?.value?.customField || []
  );
  const [notes, setNotes] = useState(props?.value?.notes || "");
  const [selectHash, setSelectedHash] = useState(props?.value?.tags || []);
  const [scheduleLater, setScheduleLater] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isendModalopen, setIsemdModalOpen] = useState(false);
  const [isstartModalopen, setIsstartModalOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(
    props?.value?.checklistNo || []
  );
  const [isModalopen1, setIsModalOpen1] = useState(false);
  const [toogleTab, setToogleTab] = useState("One-off Job");
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState(null);
  const [selectedImage, setSelectedImage] = useState(
    props?.value?.images || []
  );
  const [postData, setpostData] = useState([]);
  const [opendAddContact, setOpenAddContact] = useState(false);
  const [team, setTeam] = React.useState(props?.team || []);
  const [selectedTeam, setSelectedTeam] = React.useState(
    props?.value?.workersAssigned || []
  );
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state?.auth?.userdata);
  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [editValue, setEditValue] = useState({
    name: props?.value?.name || props?.value?.customerName || "",
    email: props?.value?.email || "",
    address: props?.value?.address || props?.value?.customerAddress || "",
    to:
      props?.value?.number ||
      props?.value?.to ||
      props?.value?.customerNumber ||
      "",
    businessName: props?.value?.businessName || "",
    to: props?.value?.phone || props?.value?.to || userdata?.to || "",
  });
  const [addchecklist, setAddChecklist] = useState({
    title: "",
    data: [],
  });
  const [checklistItemData, setChecklistItemData] = useState([
    {
      type: "",
      value: "",
    },
  ]);
  const [address, setAddress] = React.useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    title: "",
  });
  const [contactData, setContactData] = useState(
    detailContact?.length > 0 ? detailContact : []
  );
  const [selectedContact, setSelectedContact] = useState(
    props?.additionalAddress
      ? {
          additionalAddress: props?.additionalAddress,
          address: props?.value?.address || props?.value?.customerAddress || "",
        }
      : []
  );
  const bookingNumber = useSelector(
    (state) => state?.auth?.userData?.bookingNumber
  );
  const [newCutomField, setNewCustomField] = useState({
    fieldName: "",
    fieldValue: "",
  });
  const [boolValue, setBoolValue] = useState({
    description: false,
    edit: false,
    tag: false,
    customField: false,
    isEditItem: false,
  });
  console.log(props?.value, "dccdjh");
  const [values, setValues] = useState({
    date: props?.value?.date || "",
    time: props?.value?.time
      ? moment(props?.value?.time)?.format("hh:mm A") !== "Invalid date"
        ? moment(props?.value?.time)?.format("hh:mm A")
        : props?.value?.time
      : "",
    endDate: props?.value?.endDate || "",
    endTime: props?.value?.endTime
      ? moment(props?.value?.endTime)?.format("hh:mm A") === "Invalid date"
        ? props?.value?.endTime
        : moment(props?.value?.endTime)?.format("hh:mm A")
      : "",
    name: props?.value?.name || userdata?.name || "",
    businessName: props?.value?.businessName || userdata?.businessName || "",
    email: props?.value?.email || props?.value?.email || "",
    address: props?.value?.address || props?.value?.customerAddress || "",

    notes: "",
    service: "",
    phone: props?.value?.phone || userdata?.to || "",
    workersAssigned: [],
    typeOfJob: props?.value?.typeOfJob || "",
    priorityJob: props?.value?.priorityJob || "",
    mainAddress: [],
    description: props?.value?.description || "",
    bookingNumber: bookingNumber || "",
    associatedContact: props?.value?.associatedContact || {},
    serviceAddress: props?.value?.serviceAddress || "",
  });
  const [recurringValue, setReoccuringValue] = useState({
    date: new Date(),
    time: "",
    endDate: "",
    endTime: "",
    typeDuration: "",
    duration: "",
    repeatDates: "",
    repeatType: "Weekly",
    durationMonth: "Months",
    durationUnit: 6,
  });
  const statusData = [
    { name: "Open", img: unlock, value: "Waiting for confirmation" },
    { name: "On hold", img: pause, value: "On Hold" },
    { name: "Active", img: fastforward, value: "In Progress" },
    { name: "Done", img: check, value: "Completed" },
  ];
  let fromnumber = localStorage.getItem("fromnumber");
  const onSubmit = async () => {
    if (!editValue?.to && !editValue?.email) {
      return setError(true);
    }
    var newDate = values?.time ? new Date() : values?.date ? new Date() : "";
    var newDate1 = values?.endTime
      ? new Date()
      : values?.date
      ? new Date()
      : "";
    // var newEndDate=new Date(values?.endDate)
    const selectedData = [];
    selectedTeam?.map((item) => {
      selectedData.push(item?.email);
    });
    if (values?.date) {
      if (values?.time) {
        const val = values.time.split(":");
        const val2 = val[1].split(" ");
        var hours = parseInt(val[0], 10);
        if (val2[1] === "PM" && hours < 12) {
          hours += 12;
        }
        if (val2[1] === "AM" && hours === 12) {
          hours = 0;
        }
        newDate.setHours(hours);
        newDate.setMinutes(parseInt(val2[0], 10));
      } else {
        newDate.setHours(0);
        newDate.setMinutes(0);
      }

      if (values?.endTime) {
        const val3 = values.endTime.split(":");
        const val4 = val3[1].split(" ");
        let hours1 = parseInt(val3[0], 10);
        if (val4[1] === "PM" && hours1 < 12) {
          hours1 += 12;
        }
        if (val4[1] === "AM" && hours1 === 12) {
          hours1 = 0;
        }
        newDate1.setHours(hours1);
        newDate1.setMinutes(parseInt(val4[0], 10));
      } else {
        newDate1.setHours(0);
        newDate1.setMinutes(0);
        // if(values?.date===values?.endDate){
        //    newEndDate= moment(newEndDate.setDate(newEndDate.getDate() + 1)).format('YYYY-DD-MM')
        // }
        // else{
        //     newEndDate=values?.endDate
        // }
      }
    }

    if (props?.serviceRequest) {
      const res = await dispatch(
        editCreateBookings({
          ...values,
          items: addOwnerData,
          tags: selectHash,
          customField: selectCustomField,
          notes: notes,
          images: selectedImage,
          ...editValue,
          phone: editValue?.to,
          referenceNo: props?.value?.referenceNo,
          workersAssigned: selectedData,
          time: newDate,
          endTime: newDate1,
        })
      );

      if (values?.date && values?.time) {
        if (!props?.value?.date) {
          props?.onSubmitsw(
            `Booking confirmed! It’s scheduled for ${
              moment(values?.date).format("DD MMM YYYY") !== "Invalid date"
                ? moment(values?.date).format("DD MMM YYYY")
                : values?.date
            } at ${values?.time}.`,
            editValue
          );
        }
      } else {
        props?.setCreateBookingModal();
      }
      // props?.onSubmitsw(`Your service request has been confirmed. We will be in touch shortly.`, editValue)
    } else if (props?.value?.quoteNo) {
      const res1 = await dispatch(
        convertTorequest({
          ...values,
          items: addOwnerData,
          tags: selectHash,
          customField: selectCustomField,
          notes: notes,
          images: selectedImage,
          ...editValue,
          phone: editValue?.to,
          workersAssigned: selectedData,
          time: newDate,
          endTime: newDate1,
          checklistNo: selectedChecklist,
          quoteNo: props?.value?.referenceNo,
          bookingDeposit: props?.value?.bookingDeposit,
          bookingDepositType: props?.value?.bookingDepositType,
        })
      );
      if (values?.date) {
        const formattedDate = moment(values?.date).format("DD MMM");
        const formattedNewDate = newDate
          ? moment(newDate).format("hh mm A")
          : formattedDate;
        if (localStorage.getItem("number") === "14703486001") {
          await dispatch(
            sendMessage({
              to: editValue?.to,
              from: `+${localStorage.getItem("number")}`,
              body: `Hi ${values?.name},
Thanks for booking the job with us on ${formattedDate} at ${formattedNewDate}. We appreciate your trust in our services and look forward to working with you.
Best regards,
${userDetails?.businessName}`,
            })
          );
        }
        props?.setCreateBookingModal({ ...editValue, number: res1?.number });
      } else {
        props?.setCreateBookingModal({ ...editValue, number: res1?.number });
      }
    } else {
      const res = await dispatch(
        CreateBookingsV2({
          ...values,
          items: addOwnerData,
          tags: selectHash,
          customField: selectCustomField,
          notes: notes,
          images: selectedImage,
          ...editValue,
          phone: editValue?.to,
          workersAssigned: selectedData,
          time: newDate,
          endTime: newDate1,
          checklistNo: selectedChecklist,
        })
      );
      if (values?.date) {
        const formattedDate = moment(values?.date).format("DD MMM");
        const formattedNewDate = newDate
          ? moment(newDate).format("hh mm A")
          : formattedDate;
        if (localStorage.getItem("number") === "14703486001") {
          await dispatch(
            sendMessage({
              to: editValue?.to,
              from: `+${localStorage.getItem("number")}`,
              body: `Hi ${values?.name},
Thanks for booking the job with us on ${formattedDate} at ${formattedNewDate}. We appreciate your trust in our services and look forward to working with you.
Best regards,
${userDetails?.businessName}`,
            })
          );
        }
        props?.setCreateBookingModal({ ...editValue, number: res?.number });
      } else {
        props?.setCreateBookingModal({ ...editValue, number: res?.number });
      }
    }
  };
  const roundToNearestHalfHour = (date) => {
    const momentDate = moment(date);
    const minutes = momentDate.minutes();
    const roundedMinutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 60;
    if (roundedMinutes === 60) {
      momentDate.add(1, "hour");
    }

    momentDate.minutes(roundedMinutes).seconds(0);

    return new Date(momentDate);
  };

  const onSubmitReocurring = async () => {
    if (!editValue?.to && !editValue?.email) {
      return setError(true);
    }
    var newDate = recurringValue?.time
      ? new Date()
      : roundToNearestHalfHour(new Date());
    var newDate1 = recurringValue?.endTime
      ? new Date()
      : roundToNearestHalfHour(moment(new Date()).add(2, "hour"));
    const selectedData = [];
    selectedTeam?.map((item) => {
      selectedData.push(item?.email);
    });
    if (recurringValue?.time) {
      const val = recurringValue.time.split(":");
      const val2 = val[1].split(" ");
      var hours = parseInt(val[0], 10);
      if (val2[1] === "PM" && hours < 12) {
        hours += 12;
      }
      if (val2[1] === "AM" && hours === 12) {
        hours = 0;
      }
      newDate.setHours(hours);
      newDate.setMinutes(parseInt(val2[0], 10));
    }

    if (recurringValue?.endTime) {
      const val3 = recurringValue?.endTime.split(":");
      const val4 = val3[1].split(" ");
      let hours1 = parseInt(val3[0], 10);
      if (val4[1] === "PM" && hours1 < 12) {
        hours1 += 12;
      }
      if (val4[1] === "AM" && hours1 === 12) {
        hours1 = 0;
      }
      newDate1.setHours(hours1);
      newDate1.setMinutes(parseInt(val4[0], 10));
    }

    const additionalProps = props?.value?.quoteNo
      ? {
          quoteNo: props?.value?.referenceNo,
          bookingDeposit: props?.value?.bookingDeposit,
          bookingDepositType: props?.value?.bookingDepositType,
        }
      : {};

    const res = await dispatch(
      submitReoccuring({
        ...values,
        items: addOwnerData,
        tags: selectHash,
        customField: selectCustomField,
        notes: notes,
        images: selectedImage,
        ...editValue,
        phone: editValue?.to,
        workersAssigned: selectedData,
        time: newDate,
        endTime: newDate1,
        checklistNo: selectedChecklist,
        ...additionalProps,
        date: recurringValue?.date,
        recurrence:
          recurringValue?.repeatType === "Week" ||
          recurringValue?.repeatType === "Month" ||
          recurringValue?.repeatType === "Year" ||
          recurringValue?.repeatType === "Day"
            ? {
                frequency: "custom",
                customFrequency: recurringValue?.repeatType,
                interval: recurringValue?.repeatInterval || 1,
                customDays: recurringValue?.customDaysString || [],
                duration: calculateTotalJobs(
                  recurringValue?.date,
                  recurringValue?.repeatType,
                  recurringValue?.repeatInterval,
                  `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                  recurringValue?.customDaysString
                ),
                durationString: `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                schedule: recurringValue?.label || "",
              }
            : {
                frequency:
                  recurringValue?.repeatType === "Every 2 Weeks" ||
                  recurringValue?.repeatType === "Weekly"
                    ? "Week"
                    : recurringValue?.repeatType === "Monthly"
                    ? "Month"
                    : "",
                interval:
                  recurringValue?.repeatInterval === "Every 2 Weeks"
                    ? 2
                    : recurringValue?.repeatInterval || 1,
                customDays: [],
                duration: calculateTotalJobs(
                  recurringValue?.date,
                  recurringValue?.repeatType,
                  recurringValue?.repeatInterval,
                  `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                  recurringValue?.customDaysString
                ),
                durationString: `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                schedule:
                  recurringValue?.repeatType === "Every 2 Weeks"
                    ? `Every 2 Weeks on ${moment(recurringValue?.date).format(
                        "dddd"
                      )}`
                    : recurringValue?.repeatType === "Weekly"
                    ? `Weekly on ${moment(recurringValue?.date).format("dddd")}`
                    : ` Monthly on the ${moment(recurringValue?.date).format(
                        "D"
                      )}th of the month` || "",
              },
      })
    );
    if (values?.date && values?.time) {
      props?.setCreateBookingModal({ ...editValue, number: res?.number });
    } else {
      props?.setCreateBookingModal({ ...editValue, number: res?.number });
    }
  };
  const onSubmitLineItem = async (val) => {
    var newDate = values?.time ? new Date() : "";
    var newDate1 = values?.endTime ? new Date() : "";
    const selectedData = [];

    selectedTeam?.map((item) => {
      selectedData.push(item?.email);
    });
    if (values?.time) {
      const val = values.time.split(":");
      const val2 = val[1].split(" ");
      var hours = parseInt(val[0], 10);
      if (val2[1] === "PM" && hours < 12) {
        hours += 12;
      }
      if (val2[1] === "AM" && hours === 12) {
        hours = 0;
      }
      newDate.setHours(hours);
      newDate.setMinutes(parseInt(val2[0], 10));
    }

    if (values?.endTime) {
      const val3 = values.endTime.split(":");
      const val4 = val3[1].split(" ");
      let hours1 = parseInt(val3[0], 10);
      if (val4[1] === "PM" && hours1 < 12) {
        hours1 += 12;
      }
      if (val4[1] === "AM" && hours1 === 12) {
        hours1 = 0;
      }
      newDate1.setHours(hours1);
      newDate1.setMinutes(parseInt(val4[0], 10));
    }

    if (props?.serviceRequest) {
      const res = await dispatch(
        editCreateBookings({
          ...values,
          items: val,
          tags: selectHash,
          customField: selectCustomField,
          notes: notes,
          images: selectedImage,
          ...editValue,
          phone: editValue?.to,
          referenceNo: props?.value?.referenceNo,
          workersAssigned: selectedData,
          time: newDate,
          endTime: newDate1,
        })
      );
    }
  };
  const imageHandleChange = async (e) => {
    const FileList = e.target.files;
    const imageList = [];

    Object.keys(FileList).map((item, index) => {
      const fileURL = URL.createObjectURL(FileList[item]);
      imageList.push(fileURL);
    });

    const res = await dispatch(UploadNotesImage([...FileList]));
    setpostData([...postData, ...res?.data]);
    setSelectedImage([...selectedImage, ...res?.data]);
  };
  const sendchat = async (val, data) => {
    if (val) {
      await dispatch(
        sendMessage({
          from: fromnumber,
          to: props?.value?.customerNumber,
          body: val,
        })
      );
      // setMessage('')
    }
  };

  const addNewHashTag = async (val) => {
    setSelectedHash([...selectHash, val]);
    const res = await dispatch(
      addHasgtag({
        hashTags: val,
      })
    );
    setHasTag([...hastTag, val]);
  };

  const editContact = async () => {
    await dispatch(
      submitEditContacts({
        ...editValue,
        phoneNumber: editValue?.to,
        profilePhoto: "",
        _id: props?.value?._id,
      })
    );
  };

  const init = async () => {
    setLoader(true);
    const res = await dispatch(fetchAllContacts());
    const re3 = await dispatch(getUserDetails());
    setLoader(false);
    let res1 = await dispatch(getBookings());
    if (res1.status == true) {
      setTeam(res1?.data?.team);
    }
    setContactData(res?.data);
  };
  useEffect(() => {
    setNotes(props?.value?.notes);
    init();
  }, []);
  const menuItemsc = (val) => {
    return [
      {
        label: "Switch number",
        icon: <People style={{ fontSize: "18px" }} />,
        onClick: () => console.log("Duplicate clicked"),
        disabled: props?.newService ? (val === "Contact" ? false : true) : true,
      },
      {
        divider: true,
      },
      {
        label: "Change address",
        icon: <LocationOn style={{ fontSize: "18px" }} />,
        onClick: () => console.log("Archive clicked"),
        disabled: !editValue?.to ? true : false,
      },
    ];
  };
  const parseDuration = (duration) => {
    const match = duration.match(/(\d+)\s*(days?|weeks?|months?|years?)/i);
    if (!match) return { value: 0, unit: "days" };

    return { value: parseInt(match[1], 10), unit: match[2].toLowerCase() };
  };
  const calculateEndDate = (
    startDate,
    repeatType,
    repeatInterval,
    duration,
    customDaysString
  ) => {
    const [value, unit] = duration?.split(" ");
    const startMoment = moment(startDate);
    let endMoment = startMoment
      .clone()
      .add(parseInt(value), unit)
      .subtract(1, "day");
    const timeInterval = recurringValue?.repeatInterval || 1;

    if (repeatType === "Weekly") {
      while (
        endMoment.isAfter(startMoment) &&
        endMoment.day() !== startMoment.day()
      ) {
        endMoment.subtract(1, "day");
      }
    } else if (repeatType === "Day") {
      const number = parseInt(repeatInterval?.match(/\d+/)[0], 10);
      while (
        endMoment.isAfter(startMoment) &&
        endMoment.diff(startMoment, "days") % number !== 0
      ) {
        endMoment.subtract(1, "day");
      }
    } else if (repeatType === "Year") {
      const number = parseInt(repeatInterval?.match(/\d+/)[0], 10);
      while (
        endMoment.isAfter(startMoment) &&
        endMoment.diff(startMoment, "years") % number !== 0
      ) {
        endMoment.subtract(1, "year");
      }
    } else if (repeatType === "Every 2 Weeks") {
      while (
        endMoment.isAfter(startMoment) &&
        endMoment.diff(startMoment, "days") % 14 !== 0
      ) {
        endMoment.subtract(1, "day");
      }
    } else if (repeatType === "Monthly") {
      if (endMoment.date() < startMoment.date()) {
        endMoment.subtract(1, "month");
      }
      endMoment.date(startMoment.date());
    } else if (repeatType === "Week") {
      let occurrences = [];
      let currentMoment = startMoment.clone();
      const customDays = customDaysString || [];

      if (Array.isArray(customDays) && customDays.length > 0) {
        while (
          currentMoment.isBefore(endMoment) ||
          currentMoment.isSame(endMoment, "week")
        ) {
          customDays.forEach((day) => {
            const dayInCurrentWeek = currentMoment.clone().day(day);
            if (currentMoment.isSame(startMoment, "week")) {
              if (dayInCurrentWeek.isBefore(startMoment)) {
                return;
              }
            }
            if (dayInCurrentWeek.isSameOrBefore(endMoment)) {
              occurrences.push(dayInCurrentWeek.clone());
            }
          });

          currentMoment.add(timeInterval, "weeks");
        }

        if (occurrences.length > 0) {
          endMoment = occurrences[occurrences.length - 1];
        }
      } else {
        console.error("customDays array is invalid or empty");
      }
    } else if (repeatType === "Month") {
      let occurrences = [];
      const startMoment = moment(startDate);
      const durationEndMoment = endMoment.clone();
      const exactEndMoment = endMoment.clone();
      let currentMoment = startMoment.clone();
      const customDays = customDaysString || [];
      if (Array.isArray(customDays) && customDays.length > 0) {
        while (
          currentMoment.isBefore(exactEndMoment) ||
          currentMoment.isSame(exactEndMoment, "month")
        ) {
          customDays.forEach((day) => {
            const dayInCurrentMonth = currentMoment.clone().date(day);
            const maxDaysInMonth = currentMoment.daysInMonth();
            if (day <= maxDaysInMonth) {
              if (dayInCurrentMonth.date() <= currentMoment.daysInMonth()) {
                if (currentMoment.isSame(startMoment, "month")) {
                  if (dayInCurrentMonth.isBefore(startMoment)) {
                    return;
                  }
                }
                if (dayInCurrentMonth.isSameOrBefore(exactEndMoment)) {
                  occurrences.push(dayInCurrentMonth.clone());
                }
              }
            }
          });
          currentMoment.add(timeInterval, "month");
        }

        if (occurrences.length > 0) {
          endMoment = occurrences[occurrences.length - 1]; // Get the last valid occurrence
        }
      } else {
        console.error("customDays array is invalid or empty");
      }
    }

    return endMoment.format("MMM D, YYYY");
  };
  const calculateTotalJobs = (
    startDate,
    repeatType,
    repeatInterval,
    duration,
    customDaysString
  ) => {
    const startMoment = moment(startDate);
    const [value, unit] = duration?.split(" ");
    const endMoment = startMoment
      .clone()
      .add(parseInt(value), unit)
      .subtract(1, "day");
    const timeInterval = recurringValue?.repeatInterval || 1;
    let totalJobs = 0;

    if (repeatType === "Weekly") {
      totalJobs = Math.floor(endMoment.diff(startMoment, "days") / 7) + 1;
    } else if (repeatType === "Every 2 Weeks") {
      totalJobs = Math.floor(endMoment.diff(startMoment, "days") / 14) + 1;
    } else if (repeatType === "Day") {
      const number = parseInt(repeatInterval?.match(/\d+/)[0], 10);
      totalJobs = Math.floor(endMoment.diff(startMoment, "days") / number) + 1;
    } else if (repeatType === "Monthly") {
      totalJobs = endMoment.diff(startMoment, "months") + 1;
    } else if (repeatType === "Year") {
      const number = parseInt(repeatInterval?.match(/\d+/)[0], 10);
      totalJobs = Math.floor(endMoment.diff(startMoment, "years") / number) + 1;
    } else if (repeatType === "Week") {
      const customDays = customDaysString || [];
      let currentMoment = startMoment.clone();
      while (
        currentMoment.isBefore(endMoment) ||
        currentMoment.isSame(endMoment, "week")
      ) {
        customDays?.forEach((day) => {
          const dayInCurrentWeek = currentMoment.clone().day(day);
          if (currentMoment.isSame(startMoment, "week")) {
            if (dayInCurrentWeek.isBefore(startMoment)) {
              return;
            }
          }
          if (dayInCurrentWeek.isSameOrBefore(endMoment)) {
            totalJobs++;
          }
        });
        currentMoment.add(timeInterval, "weeks");
      }
    } else if (repeatType === "Month") {
      const customDays = customDaysString || [];
      let currentMoment = startMoment.clone();
      const exactEndMoment = endMoment.clone();
      while (
        currentMoment.isBefore(exactEndMoment) ||
        currentMoment.isSame(exactEndMoment, "month")
      ) {
        customDays?.forEach((day) => {
          const dayInCurrentMonth = currentMoment.clone().date(day);
          const maxDaysInMonth = currentMoment.daysInMonth();
          if (day <= maxDaysInMonth) {
            if (dayInCurrentMonth.date() <= currentMoment.daysInMonth()) {
              if (currentMoment.isSame(startMoment, "month")) {
                if (dayInCurrentMonth.isBefore(startMoment)) {
                  return;
                }
              }
              if (dayInCurrentMonth.isSameOrBefore(exactEndMoment)) {
                totalJobs++;
              }
            }
          }
        });
        currentMoment.add(timeInterval, "month");
      }
    }

    return totalJobs;
  };

  const handleSearchContact = useMemo(
    () => (inputValue, options) => {
      const lowerInput = inputValue?.toLowerCase();
      return options?.filter((option) => {
        const nameMatch =
          option?.name?.toLowerCase().includes(lowerInput) || false;
        const numberMatch =
          option?.number?.toLowerCase().includes(lowerInput) || false;
        const businessMatch =
          option?.businessName?.toLowerCase().includes(lowerInput) || false;
        return nameMatch || numberMatch || businessMatch;
      });
    },
    []
  );

  return (
    <Main>
      <HeaderContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CloseButton onClick={() => props?.setCreateBookingModal(false)}>
            <Icons src={cross} />
          </CloseButton>
          <h3>{props?.serviceRequest ? "Job" : "Create job"}</h3>
        </div>
        <RippleButton
          style={{ marginRight: "40px" }}
          onClick={async () =>
            toogleTab === "Recurring Job"
              ? onSubmitReocurring()
              : onSubmit({ draft: false })
          }
        >
          {props?.serviceRequest ? "Save request" : "Create"}
        </RippleButton>
      </HeaderContainer>
      <div style={{ display: "flex", width: "100%" }}>
        <Flexbox style={{ width: "100%" }}>
          <div className="leftdiv">
            <StyleBox>
              <div className="keyContainer">
                <h5>Add contact</h5>
                <EditContainer
                  style={{ padding: "8px" }}
                  onClick={() => setBoolValue({ ...boolValue, edit: true })}
                >
                  <CustomizedMenu
                    buttonStyle={{
                      backgroundColor: "transparent",
                      minWidth: "auto",
                      padding: 0,
                      border: "none",
                      width: "20px",
                      height: "20px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    menuItems={menuItemsc("Contact")}
                    onClick={(lab) => {
                      if (lab === "Switch number") {
                        setValue2("");
                        setEditValue({});
                        setSelectedContact();
                      } else {
                        setisswitch(true);
                      }
                    }}
                  />
                </EditContainer>
              </div>
              {props?.newService &&
                !props?.serviceRequest &&
                !editValue?.to &&
                !editValue?.email && (
                  <div className="authoCompletewrapper">
                    <Autocomplete1
                      value={value2}
                      onChange={(event, newValue) => {
                        const searchString = "Add new contact";
                        const result = newValue?.name;
                        if (
                          newValue &&
                          newValue?.name?.includes(searchString)
                        ) {
                          setOpenAddContact(true);
                        } else if (typeof newValue?.name === "string") {
                          setValue2(result);
                          const hasBillTo = !!newValue?.billTo;
                          const primaryContact = hasBillTo
                          ? {
                                name: newValue.billTo.name,
                                address: newValue.billTo.fullAddress,
                                email: newValue.billTo.email,
                                businessName: newValue.billTo.businessName,
                                to: newValue.billTo.number,
                               serviceAddress:newValue?.address,
                               mainAddress: newValue?.mainAddress,
                            }
                          : {
                                name: newValue?.name,
                                address: newValue?.address,
                                email: newValue?.email,
                                businessName: newValue?.businessName,
                                to: newValue?.number,
                                serviceAddress:newValue?.address,
                                mainAddress: newValue?.mainAddress,
                            };
              
                      const associatedContact = hasBillTo
                          ? {
                                name: newValue?.name,
                                number: newValue?.number,
                                email: newValue?.email,
                                fullAddress: newValue?.address,
                                addressDetails: newValue?.mainAddress,
                                businessName: newValue?.businessName,
                                contactId: newValue?._id,
                            }
                          : {};
                          setSelectedContact({
                            ...newValue,
                            mainAddress: newValue?.mainAddress,
                            additionalAddress: newValue?.address
                              ? [
                                  ...newValue?.additionalAddress,
                                  { address: newValue?.address },
                                ]
                              : [...newValue?.additionalAddress],
                          });
                          setEditValue({...primaryContact});
                          setValues({...values,...primaryContact,associatedContact:associatedContact})
                        } else {
                          setValue2(result);
                          setEditValue(newValue);
                        }
                      }}
                      filterOptions={(options, { inputValue }) => {
                        return handleSearchContact(inputValue, options);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={contactData}
                      renderOption={(props, option) => (
                        <div style={{ flexDirection: "column" }} {...props}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            <Person
                              style={{ fontSize: "16px", marginRight: "5px" }}
                            />
                            <ContactLabel>{option?.name}</ContactLabel>
                          </div>
                          <ContactLabel
                            style={{
                              marginBottom: "0px",
                              fontSize: "12px",
                              color: palette.grey,
                            }}
                          >
                            {formatMobileNumber(option?.number)}
                          </ContactLabel>
                        </div>
                      )}
                      sx={{
                        width: 320,
                        "& input": {
                          height: "5px",
                          fontSize: "13px",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ccc",
                          },
                          "&:hover fieldset": {
                            borderColor: "#999",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#000",
                          },
                        },
                      }}
                      freeSolo
                      style={{ height: 30 }}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            onFocus={() => {
                              if (error) {
                                setError(false);
                              }
                            }}
                            style={{
                              fontSize: "13px",
                              border: error && "1px solid red",
                              borderRadius: "4px",
                            }}
                            {...params}
                            placeholder="Add contact"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              {editValue?.name || editValue?.to ? (
                <WrapperHeader>
                  <Value className="nametext">
                    {editValue?.name || props?.value?.name}
                  </Value>
                  {editValue?.businessName && (
                    <p className="text">
                      {editValue?.businessName || props?.value?.businessName}
                    </p>
                  )}
                  {editValue?.address && (
                    <p className="text">
                      {editValue?.address || props?.value?.address}
                    </p>
                  )}
                  {editValue?.email && (
                    <p className="text">
                      {editValue?.email || props?.value?.email}
                    </p>
                  )}
                  <p className="text">
                    {formatMobileNumber(editValue?.to || props?.value?.to)}
                  </p>
                </WrapperHeader>
              ) : null}

              {error && (
                <ContactLabel
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-5px",
                  }}
                >{`⚠ ${"Add contact"}`}</ContactLabel>
              )}
            </StyleBox>
            {/* {selectedContact?.address && <StyleBox>
                            <div style={{ position: 'relative' }} className='keyContainer'>
                                <h5>Address</h5>
                                <EditContainer style={{ padding: '8px' }} onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <CustomizedMenu
                                        buttonStyle={{
                                            backgroundColor: 'transparent',
                                            minWidth: 'auto',
                                            padding: 0,
                                            border: 'none',
                                            width: '20px',
                                            height: '20px',

                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        menuItems={menuItemsc('Address')}
                                        onClick={(lab) => {
                                            if (lab === 'Switch number') {
                                                setValue2('')
                                                setEditValue({})
                                            }
                                            else {
                                                setEditValue({ ...editValue, address: '' })
                                                setisswitch(true)
                                            }
                                        }}
                                    />
                                </EditContainer>

                            </div>
                            {(editValue?.to || props?.value?.to) ? !editValue?.address ?
                                <div className='valuewrapper'>
                                    <Autocomplete1
                                        onChange={(event, newValue) => {
                                            console.log(newValue, 'dsckjnl')
                                            if (newValue && newValue?.name?.includes("Add new address")) {
                                                setOpenAddContact(true)
                                            }
                                            else if (typeof newValue?.address === 'string') {
                                                setEditValue({ ...editValue, address: newValue?.address, mainAddress: { ...newValue } })

                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="free-solo-with-text-demo"
                                        options={selectedContact?.additionalAddress || props?.additionalAddress || []}
                                        getOptionLabel={(option) => {
                                            if (typeof option?.address === 'string') {
                                                return option.address;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option;
                                        }}
                                        renderOption={(props, option) => <li style={{ fontSize: '13px' }} {...props}>{option?.address}</li>}
                                        sx={{
                                            width: 320, '& input': {
                                                height: '5px',
                                            },
                                        }}
                                        freeSolo
                                        style={{ height: 30, }}
                                        renderInput={(params) => (
                                            <div>
                                                <TextField style={{ fontSize: '13px' }} {...params}
                                                    placeholder="Change address" />

                                            </div>
                                        )}
                                    />
                                </div> : null : null
                            }
                            {editValue?.address ?
                                <WrapperHeader>
                                    {editValue?.address && <p style={{ marginTop: '8px' }} className='text'>{editValue?.address || props?.value?.address}</p>}
                                </WrapperHeader> : null
                            }
                        </StyleBox>} */}
            <StyleBox>
              <div style={{ margin: "10px" }}>
                {" "}
                <ToggleTabs
                  labels={["One-off Job", "Recurring Job"]}
                  selected={toogleTab}
                  onToggle={(val) => {
                    setToogleTab(val);
                  }}
                />
              </div>
              {toogleTab === "Recurring Job" ? (
                <div
                  style={{ flexDirection: "column" }}
                  className="valueContainer"
                >
                  <div className="datewrapper">
                    <DatePickerComponent
                      label="Start date"
                      selectedDate={recurringValue?.date}
                      customStyle={{ minWidth: "310px", height: "40px" }}
                      onDateChange={(text) => {
                        const now = moment(new Date());
                        const minutesToNearestHalfHour =
                          Math.ceil(now.minute() / 30) * 30 - now.minute();
                        const roundedDate = now
                          .add(minutesToNearestHalfHour, "minutes")
                          .format("hh:mm A");
                        const roundedDateEnd = now
                          .add(minutesToNearestHalfHour, "minutes")
                          .add(1, "hour")
                          .format("hh:mm A");
                        setReoccuringValue({
                          ...recurringValue,
                          date: text,
                          time: roundedDate,
                          endTime: roundedDateEnd,
                        });
                      }}
                    />
                  </div>
                  <div className="datewrapper">
                    <div style={{ position: "relative" }}>
                      <Label style={{ fontWeight: "600" }}>Start time</Label>
                      <SelectTimebox
                        style={{ marginLeft: "0px" }}
                        renderValue={() => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: "13px",
                            }}
                          >
                            {recurringValue?.time || "Time"}
                            <Schedule
                              style={{
                                fontSize: "16px",
                                position: "absolute",
                                right: 10,
                              }}
                            />
                          </div>
                        )}
                        defaultValue={"REAL_ESTATE"}
                        onChange={(event) =>
                          setReoccuringValue({
                            ...recurringValue,
                            time: event.target.value,
                          })
                        }
                      >
                        {[...TimeListAm, ...TimeListPM].map((item, index) => (
                          <StyledMenuItem
                            key={item.value}
                            style={{ margin: "10px" }}
                            value={item}
                          >
                            {item}
                          </StyledMenuItem>
                        ))}
                      </SelectTimebox>
                    </div>
                    <div style={{ position: "relative" }}>
                      <Label style={{ marginLeft: "15px", fontWeight: "600" }}>
                        End time
                      </Label>
                      <SelectTimebox
                        renderValue={() => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: "13px",
                            }}
                          >
                            {recurringValue?.endTime || "Time"}
                            <Schedule
                              style={{
                                fontSize: "16px",
                                position: "absolute",
                                right: 10,
                              }}
                            />
                          </div>
                        )}
                        defaultValue={"REAL_ESTATE"}
                        onChange={(event) =>
                          setReoccuringValue({
                            ...recurringValue,
                            endTime: event.target.value,
                          })
                        }
                      >
                        {[...TimeListAm, ...TimeListPM].map((item, index) => (
                          <StyledMenuItem
                            key={item.value}
                            style={{ margin: "10px" }}
                            value={item}
                          >
                            {item}
                          </StyledMenuItem>
                        ))}
                      </SelectTimebox>
                    </div>
                  </div>

                  <DropdownContainer>
                    <Label style={{ marginTop: "10px", fontWeight: "600" }}>
                      Repeats
                    </Label>
                    <SelectDrpoDown
                      id="category-visibility"
                      value={JSON.stringify({
                        type: recurringValue?.repeatType,
                        interval: recurringValue?.repeatInterval,
                        customDaysString: recurringValue?.customDaysString,
                      })}
                      onChange={(event) => {
                        if (event.target.value === "Custom schedule") {
                          setBoolValue({ ...boolValue, custom: true });
                        } else {
                          const selectedValue = JSON.parse(event.target.value);

                          setReoccuringValue({
                            ...recurringValue,
                            repeatType: selectedValue.type,
                            repeatInterval: selectedValue.interval,
                            customDaysString: selectedValue?.customDaysString,
                          });
                        }
                      }}
                    >
                      <Option
                        value={JSON.stringify({ type: "Weekly", interval: 1 })}
                      >
                        {` Weekly on ${moment(recurringValue?.date).format(
                          "dddd"
                        )}`}
                      </Option>
                      <Option
                        value={JSON.stringify({
                          type: "Every 2 Weeks",
                          interval: 2,
                        })}
                      >
                        {`Every 2 Weeks on ${moment(
                          recurringValue?.date
                        ).format("dddd")}`}
                      </Option>
                      <Option
                        value={JSON.stringify({ type: "Monthly", interval: 1 })}
                      >
                        {` Monthly on the ${moment(recurringValue?.date).format(
                          "D"
                        )}th of the month`}
                      </Option>
                      {recurringValue?.label && (
                        <Option value={JSON.stringify(recurringValue?.value)}>
                          {recurringValue?.label}
                        </Option>
                      )}

                      <Option disabled>or</Option>
                      <Option value={"Custom schedule"}>
                        Custom schedule...
                      </Option>
                    </SelectDrpoDown>
                  </DropdownContainer>

                  <Label
                    style={{
                      marginTop: "10px",
                      marginLeft: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Duration
                  </Label>

                  <DropdownContainer
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="number"
                      min="1"
                      value={6}
                      className="input"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                      value={recurringValue?.durationUnit}
                      onChange={(e) =>
                        setReoccuringValue({
                          ...recurringValue,
                          durationUnit: e.target.value,
                        })
                      }
                      style={{ width: "20%", alignItems: "center" }}
                    />
                    <SelectDrpoDown
                      id="category-visibility"
                      value={recurringValue?.durationMonth}
                      style={{
                        width: "90%",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                      onChange={(event) => {
                        // setInvoiceData({ ...invoicedata, categoryVisiblity: event.target.value })
                        setReoccuringValue({
                          ...recurringValue,
                          durationMonth: event.target.value,
                        });
                      }}
                    >
                      <Option value="Days">Days</Option>
                      <Option value="Weeks">Weeks</Option>
                      <Option value="Months">Months</Option>

                      <Option value="Years">Years</Option>
                    </SelectDrpoDown>
                  </DropdownContainer>

                  <DropdownContainer>
                    <Label
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Visits
                    </Label>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{ borderRight: "1px solid #ddd", width: "35%" }}
                      >
                        <p>First</p>
                        <p>
                          {moment(recurringValue?.date).format("MMM DD, YYYY")}
                        </p>
                      </div>
                      <div
                        style={{ borderRight: "1px solid #ddd", width: "35%" }}
                      >
                        <p>Last</p>
                        <p>
                          {calculateEndDate(
                            recurringValue?.date,
                            recurringValue?.repeatType,
                            recurringValue?.repeatInterval,
                            `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                            recurringValue?.customDaysString
                          )}
                        </p>
                      </div>
                      <div>
                        <p>Total</p>
                        <p>
                          {calculateTotalJobs(
                            recurringValue?.date,
                            recurringValue?.repeatType,
                            recurringValue?.repeatInterval,
                            `${recurringValue?.durationUnit} ${recurringValue?.durationMonth}`,
                            recurringValue?.customDaysString
                          )}
                        </p>
                      </div>
                    </div>
                  </DropdownContainer>
                </div>
              ) : (
                <div
                  style={{ flexDirection: "column" }}
                  className="valueContainer"
                >
                  <div className="datewrapper">
                    <DatePickerComponent
                      label="Start date"
                      selectedDate={values?.date}
                      onDateChange={(text) => {
                        setValues({ ...values, date: text, endDate: text });
                      }}
                      disabled={scheduleLater ? true : false}
                    />
                    <div style={{ position: "relative" }}>
                      <Label style={{ marginLeft: "15px", fontWeight: "600" }}>
                        Start time
                      </Label>
                      <TimePickerComponent
                        selectedTime={values?.time}
                        onTimeChange={(text) => {
                          setValues({ ...values, time: text });
                        }}
                      />
                      {/* <SelectTimebox
                                                disabled={scheduleLater ? true : false}
                                                renderValue={() =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                        {values?.time || 'Time'}
                                                        <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                    </div>
                                                } defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, time: event.target.value })}>
                                                {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                    <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                )}
                                            </SelectTimebox> */}
                    </div>
                  </div>
                  <div className="datewrapper">
                    <DatePickerComponent
                      label="End date"
                      selectedDate={values?.endDate}
                      onDateChange={(text) => {
                        if (new Date(text) < new Date(values?.date)) {
                          setValues({ ...values, endDate: text, date: text });
                        } else {
                          setValues({ ...values, endDate: text });
                        }
                      }}
                      disabled={scheduleLater ? true : false}
                    />
                    <div style={{ position: "relative" }}>
                      <Label style={{ marginLeft: "15px", fontWeight: "600" }}>
                        End time
                      </Label>
                      <TimePickerComponent
                        selectedTime={values?.endTime}
                        onTimeChange={(text) => {
                          setValues((prev) => {
                            const newValues = { ...prev, endTime: text };
                            const startMinutes = timeToMinutes(newValues.time);
                            const endMinutes = timeToMinutes(text);

                            if (endMinutes < startMinutes && newValues.time) {
                              return {
                                ...prev,
                                endTime: text,
                                time: minutesToTime(endMinutes - 30),
                              };
                            }
                            return newValues;
                          });
                        }}
                      />
                      {/* <SelectTimebox
                                                disabled={scheduleLater ? true : false}
                                                renderValue={() =>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                        {values?.endTime || 'Time'}
                                                        <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                                    </div>
                                                }
                                                defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, endTime: event.target.value })}>
                                                {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                                    <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                                )}
                                            </SelectTimebox> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                  >
                    <p>Schedule later</p>
                    <IOSSwitchComponent
                      value={scheduleLater}
                      onChange={(val) => {
                        setScheduleLater(val.target.checked);
                        if (val.target.checked) {
                          setValues({
                            ...values,
                            date: "",
                            time: "",
                            endDate: "",
                            endTime: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </StyleBox>
            <StyleBox>
              <div className="keyContainer">
                <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>Crew</h5>
                <EditContainer
                  onClick={() => setBoolValue({ ...boolValue, edit: true })}
                >
                  <People style={{ fontSize: "16px" }} />
                </EditContainer>
              </div>
              {/* {!boolValue?.crew ? selectedTeam?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', marginTop: '10px', marginLeft: '12px' }}>
                            {selectedTeam?.map((item, index) =>
                                <div className='valueContainer' style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginBottom: '8px' }}>{item?.firstName}</div>
                            )}
                        </div> : 
                        <div style={{ alignItems: 'start' }} className='valueContainer'>Add Crew</div> : */}
              <div
                className="valueContainer"
                style={{ flexDirection: "column", marginTop: "10px" }}
              >
                <Autocomplete1
                  value={value3}
                  onChange={(event, newValue) => {
                    const searchString = "Add new contact";

                    if (
                      newValue &&
                      newValue?.firstName?.includes(searchString)
                    ) {
                      setOpenAddContact(true);
                    } else if (typeof newValue?.firstName === "string") {
                      //setValue2(result);
                      setSelectedTeam([...selectedTeam, newValue]);

                      // setSelectedHash([...selectHash, result])
                    } else {
                      //setValue2(result)
                      setSelectedTeam([...selectedTeam, newValue]);
                      //  setHasTag([...selectHash,result])
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option?.firstName
                    );

                    // filtered.push({ name: `Add new contact` });

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={team}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option?.firstName === "string") {
                      return option.firstName;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(props, option) => (
                    <li style={{ fontSize: "13px" }} {...props}>
                      {option?.firstName}
                    </li>
                  )}
                  sx={{
                    width: 315,
                    "& input": {
                      height: "5px",
                      fontSize: "13px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#000",
                      },
                    },
                  }}
                  freeSolo
                  style={{
                    height: 30,
                    marginLeft: "10px",
                    marginBottom: "20px",
                    fontSize: "13px",
                  }}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        style={{ fontSize: "13px" }}
                        {...params}
                        placeholder="Add crew member"
                      />
                    </div>
                  )}
                />
                {selectedTeam?.length > 0 ? (
                  <WrapperField style={{ marginTop: "0px" }}>
                    {selectedTeam?.map((item, index) => (
                      <WrapperContainer
                        style={{
                          background:
                            colorThemes[index % colorThemes?.length]
                              ?.background,
                          color: colorThemes[index % colorThemes?.length]?.text,
                          border: `1px solid ${
                            colorThemes[index % colorThemes?.length]?.outline
                          }`,
                        }}
                      >
                        {item?.firstName}{" "}
                        <Cancel
                          style={{
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const arr = selectedTeam?.filter(
                              (obj) => obj?.email !== item?.email
                            );
                            setSelectedTeam(arr);
                          }}
                          src={cross}
                        />
                      </WrapperContainer>
                    ))}
                  </WrapperField>
                ) : null}
                {/* <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                }}>Cancel</SendButton1>
                                <SendButton1 style={{ marginRight: -15 }} onClick={() => {

                                    setBoolValue({ ...boolValue, crew: false })
                                    // setValue({ ...value, ...editValue })
                                    // setEditDetail(false)
                                }}>Save</SendButton1>
                            </div> */}
              </div>
            </StyleBox>

            <StyleBox>
              <div className="keyContainer">
                <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>Tags</h5>
                <EditContainer
                  onClick={() => setBoolValue({ ...boolValue, edit: true })}
                >
                  <LocalOffer style={{ fontSize: "16px" }} />
                </EditContainer>
              </div>
              {/* {!boolValue?.tag ? selectHash?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px', marginTop: '10px', marginLeft: '12px' }}>
                            {selectHash?.map((item, index) =>
                                <div className='valueContainer' style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}`, display: 'flex', alignItems: 'center', padding: '5px 10px 5px 10px', borderRadius: '5px', marginRight: '10px', marginBottom: '8px' }}>{item}</div>
                            )}
                        </div> : 
                        <div style={{ alignItems: 'start' }} className='valueContainer'>Add Tags</div> : */}
              <div
                className="valueContainer"
                style={{ flexDirection: "column", marginTop: "10px" }}
              >
                <Autocomplete1
                  value={value}
                  onChange={(event, newValue) => {
                    const searchString = "Create ";
                    const replacement = "";
                    const result = newValue
                      ?.replace(searchString, replacement)
                      ?.replace(/"/g, "");

                    if (newValue && newValue?.includes(searchString)) {
                      addNewHashTag(result);
                      setValue("");
                    } else if (typeof newValue === "string") {
                      setValue("");
                      setSelectedHash([...selectHash, result]);
                      // setSelectedHash([...selectHash, result])
                    } else {
                      setValue("");
                      //  setHasTag([...selectHash,result])
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Create "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={hastTag}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(props, option) => (
                    <li style={{ fontSize: "13px" }} {...props}>
                      {option}
                    </li>
                  )}
                  sx={{
                    width: 315,
                    marginLeft: "12px",
                    "& input": {
                      height: "5px",
                      fontSize: "13px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#000",
                      },
                    },
                  }}
                  freeSolo
                  style={{
                    height: 30,
                    marginLeft: "10px",
                    marginBottom: "20px",
                    fontSize: "13px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: "13px" }}
                      {...params}
                      placeholder="Search or create a tag"
                    />
                  )}
                />
                {selectHash?.length > 0 ? (
                  <WrapperField style={{ marginTop: "0px" }}>
                    {selectHash?.map((item, index) => (
                      <WrapperContainer
                        style={{
                          background:
                            colorThemes[index % colorThemes?.length]
                              ?.background,
                          color: colorThemes[index % colorThemes?.length]?.text,
                          border: `1px solid ${
                            colorThemes[index % colorThemes?.length]?.outline
                          }`,
                        }}
                      >
                        {item}{" "}
                        <Cancel
                          style={{
                            fontSize: "14px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const arr = selectHash?.filter(
                              (obj) => obj !== item
                            );
                            setSelectedHash(arr);
                          }}
                        />
                      </WrapperContainer>
                    ))}
                  </WrapperField>
                ) : null}
                {/* <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                    <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {

                                        setBoolValue({ ...boolValue, tag: false })
                                    }}>Cancel</SendButton1>
                                    <SendButton1 style={{ marginRight: -15 }} onClick={() => {

                                        setBoolValue({ ...boolValue, tag: false })
                                        // setValue({ ...value, ...editValue })
                                        // setEditDetail(false)
                                    }}>Save</SendButton1>
                                </div> */}
              </div>
            </StyleBox>
            <StyleBox>
              <div className="keyContainer">
                <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Checklist
                </h5>
                <EditContainer
                  onClick={() => {
                    checklist?.length > 0
                      ? setBoolValue({ ...boolValue, isChecklist: true })
                      : setBoolValue({ ...boolValue, addchecklist: true });
                  }}
                >
                  <Add style={{ fontSize: "16px" }} />
                </EditContainer>
              </div>
              {selectedChecklist?.length > 0 && (
                <div
                  className="valueContainer"
                  style={{
                    flexDirection: "column",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  {selectedChecklist?.map((item) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        margin: "5px",
                      }}
                    >
                      <div>
                        <Label>{item?.title}</Label>
                        <Label style={{ color: palette.grey }}>
                          {item?.data?.length} checklist items
                        </Label>
                      </div>
                      <DeleteOutlined
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          const data = selectedChecklist.filter(
                            (items) => items?.checklistNo !== item?.checklistNo
                          );
                          setSelectedChecklist(data);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </StyleBox>
            <StyleBox>
              <div className="keyContainer">
                <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Custom field
                </h5>
                <EditContainer
                  onClick={() => setBoolValue({ ...boolValue, edit: true })}
                >
                  <BookmarkAdd style={{ fontSize: "16px" }} />
                </EditContainer>
              </div>
              {/* {!boolValue?.customField ? selectCustomField?.length > 0 ? <>

                            {selectCustomField?.slice(0, itemsToShow).map((item, index) => (
                                <FieldContainer key={index} >
                                    <p className='fieldName'>{item?.fieldName}</p>
                                    <p className='fieldValue'>{item?.fieldValue}</p>

                                </FieldContainer>


                            ))}
                            {selectCustomField.length > 3 && (
                                <OptionBox style={{ alignSelf: 'end', margin: '15px' }} onClick={toggleShowMore}>{showMore ? 'Show Less' : 'Show More'}</OptionBox>

                            )}
                        </>
                            : <div style={{ alignItems: 'start' }} className='valueContainer'>Add Custom fields</div>

                            :  */}
              <div
                className="valueContainer"
                style={{
                  flexDirection: "column",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Autocomplete1
                  value={value1}
                  onChange={(event, newValue) => {
                    const searchString = "Create ";
                    const replacement = "";
                    const result = newValue?.fieldName
                      ?.replace(searchString, replacement)
                      ?.replace(/"/g, "");

                    if (typeof newValue === "string") {
                      setValue1("");
                      setCustomField([
                        ...selectCustomField,
                        { fieldName: "cdsf" },
                      ]);
                    } else if (newValue && newValue.fieldName) {
                      setCustomField([
                        ...selectCustomField,
                        {
                          fieldName: newValue?.fieldName,
                          fieldValue: newValue?.fieldValue,
                          fieldType: newValue?.fieldType,
                          options: newValue?.options,
                        },
                      ]);
                      setValue1("");
                    } else {
                      setValue1("");
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={customField?.filter((item) => item?.type === "job")}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option?.fieldName) {
                      return option.fieldName;
                    }
                    return "";
                  }}
                  renderOption={(props, option) => (
                    <li style={{ fontSize: "13px" }} {...props}>
                      {option?.fieldName}
                    </li>
                  )}
                  sx={{
                    width: 315,
                    marginLeft: "12px",
                    "& input": {
                      height: "5px",
                      fontSize: "13px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#999",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#000",
                      },
                    },
                  }}
                  freeSolo
                  style={{
                    height: 30,
                    marginLeft: "10px",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: "13px" }}
                      {...params}
                      placeholder="Search custom field"
                    />
                  )}
                />
                <div
                  style={{
                    marginTop: "20px",
                    fontWeight: "500",
                    color: "#4169e1",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsModalOpen1(true)}
                >
                  Create new custom field
                </div>
                {selectCustomField?.length > 0 ? (
                  <CustomBox style={{ width: "100%" }}>
                    {selectCustomField?.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "310px",
                        }}
                      >
                        {item?.fieldType === "dropdownSingle" ? (
                          <div style={{ width: "100%", marginBottom: "10px" }}>
                            <Label style={{ fontWeight: "550" }}>
                              {item?.fieldName}
                            </Label>

                            <SelectDrpoDown
                              id="category-visibility"
                              value={item?.fieldValue}
                              style={{ width: "110%" }}
                              defaultValue=""
                              onChange={(e) => {
                                const data = [...selectCustomField];
                                data[index].fieldValue = e.target.value;
                                setCustomField(data);
                              }}
                            >
                              <Option value="" disabled>
                                Choose value
                              </Option>

                              {item?.options?.map((vars, index) => (
                                <Option key={index} value={vars}>
                                  {vars}
                                </Option>
                              ))}
                            </SelectDrpoDown>
                          </div>
                        ) : item?.fieldType === "dropdownMultiple" ? (
                          <div style={{ width: "84%", marginBottom: "10px" }}>
                            <Label style={{ fontWeight: "550" }}>
                              {item?.fieldName}
                            </Label>
                            <MultiSelectWithSearch
                              options={item?.options}
                              containerStyle={{ width: "97%" }}
                              dropDownStyle={{ width: "96%" }}
                              onSelectionChange={(value) => {
                                const data = [...selectCustomField];
                                data[index].fieldValue = JSON.stringify(value);
                                setCustomField(data);
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ width: "100%", marginBottom: "10px" }}>
                            <Label style={{ fontWeight: "550" }}>
                              {item?.fieldName}
                            </Label>
                            <InputBox
                              placeholder="Value"
                              type="text"
                              value={item?.fieldValue}
                              style={{ height: "15px", width: "100%" }}
                              onChange={(e) => {
                                const data = [...selectCustomField];
                                data[index].fieldValue = e.target.value;
                                setCustomField(data);
                              }}
                            />
                          </div>
                        )}

                        <DeleteOutlined
                          style={{
                            marginLeft: "30px",
                            cursor: "pointer",
                            fontSize: "20px",
                            marginTop: "10px",
                          }}
                          onClick={() => {
                            const data = [...selectCustomField];
                            data.splice(index, 1);
                            setCustomField(data);
                          }}
                        />
                      </div>
                    ))}
                  </CustomBox>
                ) : null}
                {/* <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                    <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                        setBoolValue({ ...boolValue, customField: false })
                                    }}>Cancel</SendButton1>
                                    <SendButton1 style={{ marginRight: -15 }} onClick={() => {
                                        setBoolValue({ ...boolValue, customField: false })
                                    }}>Save</SendButton1>
                                </div> */}
              </div>
            </StyleBox>
            {/* <StyleBox>
                            <div className='keyContainer'>
                                <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                    Notes
                                </h5>
                                <EditContainer onClick={() => setBoolValue({ ...boolValue, edit: true })}>
                                    <Description style={{ fontSize: '16px' }} />
                                </EditContainer>
                            </div>
                         
                            <>
                                <div className='valueContainer' style={{ flexDirection: 'column', justifyContent: 'center' }}>
                                  
                                    <InputText
                                        placeholder="Note"
                                        type="text"
                                        value={notes}
                                        // value={editValue?.email}

                                        onChange={(e) => {
                                            setNotes(
                                                e.target.value
                                            )
                                        }}

                                    />
                                </div>
                            
                            </>

                        </StyleBox> */}
            <StyleBox>
              <div className="keyContainer">
                <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Photos / Attachments
                </h5>
                <EditContainer
                  onClick={() => setBoolValue({ ...boolValue, edit: true })}
                >
                  <Photo style={{ fontSize: "16px" }} />
                </EditContainer>
              </div>
              <>
                <div
                  className="valueContainer"
                  style={{ flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "10px",
                    }}
                  >
                    {selectedImage?.map((item) => (
                      <div
                        style={{
                          flexWrap: "wrap",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <PhotosStyle src={item} />
                        <div
                          onClick={() => {
                            const arr = selectedImage?.filter(
                              (obj) => obj !== item
                            );
                            setSelectedImage(arr);
                          }}
                          style={{
                            position: "absolute",
                            display: "flex",
                            top: 0,
                            right: 5,
                            background: "white",
                            borderRadius: 10,
                          }}
                        >
                          <MoreIcon style={{ width: "15px" }} src={cross} />
                        </div>
                      </div>
                    ))}

                    <FileInputButton htmlFor="fileInput">
                      <MoreIcon src={plus} />
                    </FileInputButton>
                    <FileInput
                      id="fileInput"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={imageHandleChange}
                    />
                  </div>
                  {/* 
                                <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '30px', justifyContent: 'flex-end', }}>
                                    <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                        // setSelectedImage(tempUserDetails?.picUrl)
                                        setBoolValue({ ...boolValue, photo: false })
                                    }}>Cancel</SendButton1>
                                    <SendButton1 style={{ marginRight: -15 }} onClick={() => {
                                        addPhotos()
                                        setBoolValue({ ...boolValue, photo: false })
                                        // setValue({ ...value, ...editValue })
                                        // setEditDetail(false)
                                    }}>Save</SendButton1>
                                </div> */}
                </div>
              </>
            </StyleBox>
          </div>
          <StyleRight>
            <RightLineItemMain
              style={{
                marginBottom: "20px",
                paddingTop: "10px",
                paddingBottom: !boolValue?.activityExpend ? "0px" : "15px",
              }}
            >
              <Header>
                {"Calendar"}
                <IconButton
                  style={{
                    marginTop: "-5px",
                    width: "28px",
                    height: "28px",
                    background: "#f3f3f3",
                  }}
                >
                  {boolValue?.activityExpend ? (
                    <KeyboardArrowUp
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setBoolValue({ ...boolValue, activityExpend: false });
                      }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setBoolValue({ ...boolValue, activityExpend: true });
                      }}
                    />
                  )}
                </IconButton>
              </Header>
              {boolValue?.activityExpend && <MapFilter />}
            </RightLineItemMain>
            <RightLineItemMain
              style={{ paddingLeft: "40px", paddingRight: "40px" }}
            >
              <JobDetails>
                <div className="left-column">
                  <div className="flex-row margin-bottom">
                    <h5 className="label">Job number:</h5>
                    <Inputbox
                      type="text"
                      value={values?.bookingNumber}
                      placeholder="Job number"
                      className="input-box"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          bookingNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex-row">
                    <h5 className="label">Job name:</h5>
                    <Inputbox
                      type="text"
                      value={values?.bookingName}
                      placeholder="Job name"
                      className="input-box"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          bookingName: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="right-column">
                  <div className="flex-row margin-bottom justify-end">
                    <h5 className="label">Job type:</h5>
                    <InputPicker
                      value={values?.typeOfJob}
                      data={userDetails?.jobType?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      label="Job type"
                      onChange={(val) =>
                        setValues({ ...values, typeOfJob: val })
                      }
                    />
                  </div>
                  <div className="flex-row justify-end">
                    <h5 className="label">Job priority:</h5>
                    <InputPicker
                      value={values?.priorityJob}
                      data={userDetails?.jobPriority?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      label="Priority"
                      onChange={(val) =>
                        setValues({ ...values, priorityJob: val })
                      }
                    />
                  </div>
                </div>
              </JobDetails>

              <h4 style={{ marginBottom: "0px", textAlign: "start" }}>
                {"Add line items"}
              </h4>

              {!loader && (
                <LineItemComponent
                  edit={boolValue}
                  value={(val) => {
                    if (props?.serviceRequest) {
                      setAddOwnerData(val);
                      onSubmitLineItem(val);
                    } else {
                      setAddOwnerData(val);
                    }
                  }}
                  items={[...addOwnerData]}
                  serviceRequest={props?.serviceRequest}
                />
              )}
            </RightLineItemMain>

            <RightLineItemMain
              style={{
                paddingLeft: "40px",
                marginTop: "20px",
                paddingRight: "40px",
              }}
            >
              <h4
                style={{
                  marginBottom: "0px",
                  marginTop: "0px",
                  textAlign: "start",
                }}
              >
                {"Add field note"}
              </h4>
              <InputText
                placeholder="Field note"
                type="text"
                value={values?.description}
                // value={editValue?.email}
                style={{ width: "97%", marginLeft: "0px" }}
                onChange={(e) => {
                  setValues({
                    ...values,
                    description: e.target.value,
                  });
                }}
              />
            </RightLineItemMain>
          </StyleRight>
        </Flexbox>
        {props?.form && (
          <div
            style={{
              width: "40%",
              paddingTop: "80px",
              position: "sticky",
              top: "0",
              overflowY: "auto",
              height: "100vh",
            }}
          >
            <div
              style={{
                textAlign: "start",
                fontSize: "16px",
                marginLeft: "20px",
                fontWeight: "600",
              }}
            >
              Form details
            </div>
            <FormDetails formDetail={props?.formDetail} type={"Job"} />
          </div>
        )}
      </div>

      {isendModalopen && (
        <Modals
          onClose={() => setIsemdModalOpen(false)}
          customStyle={{ background: "#fff" }}
        >
          <div style={{ marginLeft: "-10px" }}>
            <HeaderContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CloseButton onClick={() => setIsemdModalOpen(false)}>
                  <Icons src={cross} />
                </CloseButton>
                <h4>Select end date & time</h4>
              </div>
            </HeaderContainer>
            <CalendarStyle
              style={{ zIndex: 10000 }}
              className="p-3 d-flex justify-content-center"
            >
              <div
                style={{
                  zIndex: 10000,
                  borderRight: "1px solid #f3f3f3",
                  marginRight: "15px",
                  width: "350px",
                  paddingRight: "-25px",
                }}
              >
                <Calendar
                  className="react-calendar"
                  value={values?.endDate}
                  onChange={(val) => setValues({ ...values, endDate: val })}
                  minDate={new Date()}
                />
              </div>
              <Flexbox className="justify-content-between">
                <div
                  style={{
                    display: "inline",
                    marginTop: "10px",
                    marginRight: 10,
                  }}
                >
                  {TimeListAm.map((item) => {
                    return (
                      <div
                        onClick={() => setValues({ ...values, endTime: item })}
                        style={{
                          padding: "5px",
                          background:
                            values?.endTime === item ? "#398AFF" : "#fff",
                          cursor: "pointer",
                          color: values?.endTime !== item ? "#000" : "#fff",
                          fontSize: "14px",
                          border:
                            values?.endTime === item
                              ? "1px solid #d4e6ff"
                              : "1px solid #DDDDDD",
                          width: "80px",
                          marginBottom: "7px",
                          borderRadius: "5px",
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: "inline", marginTop: "10px" }}>
                  {TimeListPM.map((item) => {
                    return (
                      <div
                        onClick={() => setValues({ ...values, endTime: item })}
                        style={{
                          padding: "5px",
                          background:
                            values?.endTime === item ? "#398AFF" : "#fff",
                          cursor: "pointer",
                          color: values?.endTime !== item ? "#000" : "#fff",
                          border:
                            values?.endTime === item
                              ? "1px solid #d4e6ff"
                              : "1px solid #DDDDDD",
                          fontSize: "14px",
                          width: "80px",
                          marginBottom: "7px",
                          borderRadius: "5px",
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              </Flexbox>
            </CalendarStyle>
            <ButtonBox>
              <SendButton1
                style={{
                  background: "#F3F3F3",
                  color: "#000",
                  marginRight: "15px",
                }}
                onClick={() => setIsemdModalOpen(false)}
              >
                Cancel
              </SendButton1>
              <SendButton1
                style={{ marginRight: -15 }}
                onClick={async () => {
                  setIsemdModalOpen(false);
                }}
              >
                Save
              </SendButton1>
            </ButtonBox>
          </div>
        </Modals>
      )}
      {isstartModalopen && (
        <Modals
          onClose={() => setIsstartModalOpen(false)}
          customStyle={{ background: "#fff" }}
        >
          <div style={{ marginLeft: "-10px" }}>
            <HeaderContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CloseButton onClick={() => setIsstartModalOpen(false)}>
                  <Icons src={cross} />
                </CloseButton>
                <h4>Select start date & time</h4>
              </div>
            </HeaderContainer>
            <CalendarStyle
              style={{ zIndex: 10000 }}
              className="p-3 d-flex justify-content-center"
            >
              <div
                style={{
                  zIndex: 10000,
                  borderRight: "1px solid #f3f3f3",
                  marginRight: "15px",
                  width: "350px",
                  paddingRight: "-25px",
                }}
              >
                <Calendar
                  className="react-calendar"
                  value={values?.date}
                  onChange={(val) => setValues({ ...values, date: val })}
                  minDate={new Date()}
                />
              </div>
              <Flexbox className="justify-content-between">
                <div
                  style={{
                    display: "inline",
                    marginTop: "10px",
                    marginRight: 10,
                  }}
                >
                  {TimeListAm.map((item) => {
                    return (
                      <div
                        onClick={() => setValues({ ...values, time: item })}
                        style={{
                          padding: "5px",
                          background:
                            values?.time === item ? "#398AFF" : "#fff",
                          cursor: "pointer",
                          color: values?.time !== item ? "#000" : "#fff",
                          fontSize: "14px",
                          border:
                            values?.time === item
                              ? "1px solid #d4e6ff"
                              : "1px solid #DDDDDD",
                          width: "80px",
                          marginBottom: "7px",
                          borderRadius: "5px",
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: "inline", marginTop: "10px" }}>
                  {TimeListPM.map((item) => {
                    return (
                      <div
                        onClick={() => setValues({ ...values, time: item })}
                        style={{
                          padding: "5px",
                          background:
                            values?.time === item ? "#398AFF" : "#fff",
                          cursor: "pointer",
                          color: values?.time !== item ? "#000" : "#fff",
                          border:
                            values?.time === item
                              ? "1px solid #d4e6ff"
                              : "1px solid #DDDDDD",
                          fontSize: "14px",
                          width: "80px",
                          marginBottom: "7px",
                          borderRadius: "5px",
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              </Flexbox>
            </CalendarStyle>
            <ButtonBox>
              <SendButton1
                style={{
                  background: "#F3F3F3",
                  color: "#000",
                  marginRight: "15px",
                }}
                onClick={() => setIsstartModalOpen(false)}
              >
                Cancel
              </SendButton1>
              <SendButton1
                style={{ marginRight: -15 }}
                onClick={async () => {
                  setIsstartModalOpen(false);
                  // const res = await dispatch(createNewCustomField({
                  //     "fields": [
                  //         newCutomField
                  //     ]
                  // }))
                  // setDataCustomField([...customField, newCutomField])
                  // setIsModalOpen1(false)
                }}
              >
                Save
              </SendButton1>
            </ButtonBox>
          </div>
        </Modals>
      )}

      <AddCustomField
        selectedField={{}}
        isVisible={isModalopen1}
        type={"job"}
        onClose={() => {
          setIsModalOpen1(false);
        }}
        onSubmit={(value) => {
          setDataCustomField([...customField, { ...value, type: "job" }]);
          setCustomField([
            ...selectCustomField,
            {
              fieldName: value?.fieldName,
              fieldType: value?.fieldType,
              options: value?.options,
              type: "job",
            },
          ]);
          setIsModalOpen1(false);
        }}
      />
      {opendAddContact && (
        <FullScreenModal
          isOpen={opendAddContact}
          onClose={() => setOpenAddContact(false)}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <AddContact
              onSubmit={() => {
                init();
                setOpenAddContact(false);
              }}
              onCancel={() => {
                setOpenAddContact(false);
              }}
            />
          </div>
        </FullScreenModal>
      )}

      {edit && (
        <TopModal
          overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
          onClose={() => {
            setEdit(false);
            // setEditValue({
            //     "name": tempUserDetails?.name || "",
            //     "email": tempUserDetails?.email || "",
            //     "address": tempUserDetails?.address || "",
            //     "businessName": tempUserDetails?.businessName || '',
            //     "to": tempUserDetails?.number || '',
            // })
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>{"Edit contact"}</h4>
            <Cancel
              onClick={() => {
                setEdit(false);

                // setEditValue({
                //     "name": tempUserDetails?.name || "",
                //     "email": tempUserDetails?.email || "",
                //     "address": tempUserDetails?.address || "",
                //     "businessName": tempUserDetails?.businessName || '',
                //     "to": tempUserDetails?.number || '',
                // })
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{ paddingLeft: "15px", paddingRight: "20px", width: "90%" }}
          >
            <InputBox
              header={"Name"}
              placeholder="Name"
              type="text"
              value={editValue?.name}
              onChange={(e) => {
                setEditValue({
                  ...editValue,
                  name: e.target.value,
                });
              }}
            />
            <InputBox
              header={"Business name"}
              placeholder="Business name"
              type="text"
              value={editValue?.businessName}
              onChange={(e) => {
                setEditValue({
                  ...editValue,
                  businessName: e.target.value,
                });
              }}
            />

            <InputBox
              header={"Email"}
              placeholder="Email"
              type="text"
              value={editValue?.email}
              onChange={(e) => {
                setEditValue({
                  ...editValue,
                  email: e.target.value,
                });
              }}
            />

            <Label
              style={{ marginTop: "20px", marginLeft: "3px" }}
              htmlFor={"Address"}
            >
              Street 1
            </Label>

            <Place
              apiKey="AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA"
              types={["address"]}
              options={{
                types: ["address"],
              }}
              defaultValue={address?.street}
              style={{
                width: "100%",
                borderRadius: "5px",
                fontSize: "14px",
                height: "20px",
                fontWeight: "400",
                fontFamily: "Inter, sans-serif",
                color: "#344054",
                alignSelf: "start",
                background: "#ffffff",
                border: "0.5px solid #d0d5dd",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                padding: "10px 14px",
              }}
              placeholder="street 1"
              //onChange={onPlaceSelected}
              //onClick={onPlaceSelected}

              onPlaceSelected={(details) => {
                const street = `${
                  details?.address_components.find((addressComponent) =>
                    addressComponent.types.includes("street_number")
                  )?.short_name || ""
                } ${
                  details?.address_components.find((addressComponent) =>
                    addressComponent.types.includes("route")
                  )?.short_name || ""
                }`;

                const zipCode = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("postal_code")
                )?.short_name;
                const region = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes(
                      "administrative_area_level_1"
                    )
                )?.long_name;
                const city = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("locality")
                )?.long_name;
                const country = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("country")
                )?.long_name;
                setAddress({
                  ...address,
                  postalCode: zipCode,
                  city: city,
                  state: region,
                  street: street,
                  country: country,
                  address: details?.formatted_address,
                });
              }}
            />
            <InputBox
              header={"Street 2"}
              value={address?.street2}
              style={{ display: "flex", alignSelf: "center" }}
              id="outlined-basic"
              size="small"
              placeholder="Street 2"
              variant="outlined"
              onChange={(e) =>
                setAddress({ ...address, street2: e.target.value })
              }
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <InputBox
                  header={"City"}
                  value={address?.city}
                  style={{ display: "flex", alignSelf: "center" }}
                  id="outlined-basic"
                  size="small"
                  placeholder="City"
                  variant="outlined"
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                />
              </div>
              <div style={{ width: "45%" }}>
                <InputBox
                  header={"Province"}
                  value={address?.state}
                  id="outlined-basic"
                  size="small"
                  placeholder="Province"
                  variant="outlined"
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingBottom: "25px",
              }}
            >
              <div style={{ width: "45%" }}>
                <InputBox
                  header={"Postal code"}
                  value={address?.postalCode}
                  style={{ display: "flex", alignSelf: "center" }}
                  id="outlined-basic"
                  size="small"
                  placeholder="Postal code"
                  variant="outlined"
                  onChange={(e) =>
                    setAddress({ ...address, postalCode: e.target.value })
                  }
                />
              </div>
              <div style={{ width: "45%" }}>
                <InputBox
                  header={"Country"}
                  value={address?.country}
                  id="outlined-basic"
                  size="small"
                  placeholder="Country"
                  variant="outlined"
                  onChange={(e) =>
                    setAddress({ ...address, country: e.target.value })
                  }
                />
              </div>
            </div>

            <div
              style={{
                alignSelf: "flex-end",
                display: "flex",
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "-20px",
                justifyContent: "flex-end",
              }}
            >
              <RippleButton
                style={{
                  background: "#fff",
                  color: "#414552",
                  border: "0.5px solid #414552",
                  marginRight: "15px",
                }}
                onClick={() => {
                  setEdit(false);
                  // setEditValue({
                  //     "name": tempUserDetails?.name || "",
                  //     "email": tempUserDetails?.email || "",
                  //     "address": tempUserDetails?.address || "",
                  //     "businessName": tempUserDetails?.businessName || '',
                  //     "to": tempUserDetails?.number || '',
                  // })
                }}
              >
                Cancel
              </RippleButton>
              <RippleButton
                onClick={() => {
                  editContact();
                  setEdit(false);
                }}
              >
                Save
              </RippleButton>
            </div>
          </div>
        </TopModal>
      )}

      {boolValue?.addchecklist && (
        <TopModal
          onClose={() => setBoolValue({ ...boolValue, addchecklist: false })}
          overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>{"Add checklist"}</h4>
            <Cancel
              onClick={() => {
                setBoolValue({ ...boolValue, addchecklist: false });
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <InputBox
            header={"Checklist name"}
            style={{ width: "95%" }}
            placeholder="Checklist name"
            value={addchecklist?.title}
            onChange={(e) =>
              setAddChecklist({ ...addchecklist, title: e.target.value })
            }
          />
          <h5
            style={{
              textAlign: "start",
              margin: "15px 5px 0px 5px",
              fontWeight: "500",
            }}
          >
            Checklist item
          </h5>
          {checklistItemData?.map((item, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <InputPicker
                value={item?.type}
                label={"Type"}
                style={{ height: "40px", background: "white" }}
                data={[
                  { label: "Checkbox", value: "Checkbox" },
                  { label: "Text", value: "Text" },
                  { label: "Link", value: "Link" },
                ]}
                onChange={(val) => {
                  const data = [...checklistItemData];
                  data[index].type = val;
                  setChecklistItemData([...data]);
                }}
              />
              <InputBox
                style={{ width: "85%" }}
                placeholder="Item"
                value={item?.value}
                onChange={(e) => {
                  const data = [...checklistItemData];
                  data[index].value = e.target.value;
                  setChecklistItemData([...data]);
                }}
              />
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const data = [...checklistItemData];
                  data.splice(index, 1);
                  setChecklistItemData(data);
                }}
              />
            </div>
          ))}
          <h4
            onClick={() => {
              setChecklistItemData([
                ...checklistItemData,
                {
                  type: "",
                  value: "",
                },
              ]);
            }}
            style={{
              textAlign: "start",
              color: palette.newBlue,
              cursor: "pointer",
            }}
          >
            + Checklist item
          </h4>
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
              justifyContent: "flex-end",
            }}
          >
            <RippleButton
              style={{
                background: "#fff",
                color: "#414552",
                border: "0.5px solid #414552",
                marginRight: "15px",
              }}
              onClick={() => {
                setBoolValue({ ...boolValue, addchecklist: false });
              }}
            >
              Cancel
            </RippleButton>
            <RippleButton
              onClick={async () => {
                const res1 = await dispatch(
                  submitchecklist({ ...addchecklist, data: checklistItemData })
                );
                setSelectedChecklist([
                  ...selectedChecklist,
                  {
                    ...addchecklist,
                    data: checklistItemData,
                    checklistNo: res1?.data?.checklistNo,
                  },
                ]);
                setBoolValue({ ...boolValue, addchecklist: false });
              }}
            >
              Save
            </RippleButton>
          </div>
        </TopModal>
      )}

      {boolValue?.isChecklist && (
        <TopModal
          onClose={() => setBoolValue({ ...boolValue, isChecklist: false })}
          overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>{"Checklist"}</h4>
            <Cancel
              onClick={() => {
                setEdit(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          {checklist?.map((item, index) => (
            <div
              onClick={() => {
                const isItemSelected = selectedChecklist?.some(
                  (items) => items?.checklistNo === item?.checklistNo
                );
                if (isItemSelected) {
                  const data = selectedChecklist.filter(
                    (items) => items?.checklistNo !== item?.checklistNo
                  );
                  setSelectedChecklist(data);
                } else {
                  const data = [...selectedChecklist, item];
                  setSelectedChecklist(data);
                }
              }}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "15px",
                cursor: "pointer",
              }}
            >
              <Checkbox
                style={{ width: "25px", marginTop: "-2px", marginRight: "5px" }}
                checked={selectedChecklist?.some(
                  (items) => items?.checklistNo === item?.checklistNo
                )}
              />
              <div>
                <Label>{item?.title}</Label>
                <Label style={{ color: palette.grey }}>
                  {item?.data?.length} checklist items
                </Label>
              </div>
            </div>
          ))}
          <div
            style={{
              alignSelf: "flex-end",
              display: "flex",
              marginTop: "20px",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "flex-end",
            }}
          >
            <RippleButton
              style={{
                background: "#fff",
                color: "#414552",
                border: "0.5px solid #414552",
                marginRight: "15px",
              }}
              onClick={() => {
                setBoolValue({ ...boolValue, isChecklist: false });
              }}
            >
              Cancel
            </RippleButton>
            <RippleButton
              onClick={() => {
                setBoolValue({ ...boolValue, isChecklist: false });
              }}
            >
              Save
            </RippleButton>
          </div>
        </TopModal>
      )}

      {isswitch && (
        <TopModal
          onClose={() => setisswitch(false)}
          overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>{"Select address"}</h4>
            <Cancel
              onClick={() => {
                setisswitch(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          {boolValue?.newAddress ? (
            <Place
              apiKey="AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA"
              types={["address"]}
              options={{
                types: ["address"],
              }}
              style={{
                width: "95%",
                borderRadius: "5px",
                fontSize: "14px",
                height: "25px",
                fontWeight: "400",
                fontFamily: "Inter, sans-serif",
                color: "#344054",
                alignSelf: "start",
                margin: "10px 0px",
                background: "#ffffff",
                border: "0.5px solid #d0d5dd",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                padding: "10px 14px",
              }}
              placeholder="Search address"
              autoFocus
              onChange={(e) => {
                console.log(e.target.value, "eee");
                setEditValue({ ...editValue, address: e.target.value });
              }}
              onPlaceSelected={(details) => {
                const street = `${
                  details?.address_components.find((addressComponent) =>
                    addressComponent.types.includes("street_number")
                  )?.short_name || ""
                } ${
                  details?.address_components.find((addressComponent) =>
                    addressComponent.types.includes("route")
                  )?.short_name || ""
                }`;

                const zipCode = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("postal_code")
                )?.short_name;
                const region = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes(
                      "administrative_area_level_1"
                    )
                )?.long_name;
                const city = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("locality")
                )?.long_name;
                const country = details?.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.includes("country")
                )?.long_name;
                let address = {
                  postalCode: zipCode,
                  city: city,
                  state: region,
                  street: street,
                  country: country,
                  address: details?.formatted_address,
                  title: details?.formatted_address,
                };

                setEditValue({
                  ...editValue,
                  address: address?.address,
                  title: address?.address,
                  mainAddress: { ...address },
                });
                setSelectedContact({
                  ...selectedContact,
                  additionalAddress: [
                    ...selectedContact?.additionalAddress,
                    address,
                  ],
                });
                // setisswitch(false)
              }}
            />
          ) : (
            <div style={{ marginBottom: "15px" }}>
              <AddressSelector
                selectedContact={selectedContact?.additionalAddress}
                nonHeader={true}
                value={{
                  title: editValue?.title,
                  address: editValue?.address,
                }}
                onClick={(item) => {
                  setEditValue({
                    ...editValue,
                    address: item?.address,
                    title: item?.title,
                    mainAddress: { ...item },
                  });
                }}
                onClose={() => {}}
              />
            </div>
          )}
          {boolValue?.newAddress ? (
            <div style={{display:'flex',gap:'15px'}}>
              <AddressButton
              style={{background:'white',color:'#ddd',border:'1px solid #ddd'}}
                onClick={() =>
                 { setBoolValue({ ...boolValue, newAddress: false })
                 setEditValue({
                    ...editValue,
                    address: values?.address,
                    title: values?.title,
                    mainAddress: { ...values?.mainAddress },
                  });
                }
                  
                }
              >
                <h4 style={{color:'grey'}}> Cancel</h4>
              </AddressButton>
              <AddressButton
                onClick={() => {
                  setBoolValue({ ...boolValue, newAddress: false });
                  setisswitch(false);
                }}
              >
                <h4> Save</h4>
              </AddressButton>
            </div>
          ) : (
            <AddressButton
              onClick={() => {
                setBoolValue({ ...boolValue, newAddress: true });
              }}
            >
              <AddBoxOutlined
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <h4> Add address</h4>
            </AddressButton>
          )}
        </TopModal>
      )}
      <RecurringJobModal
        isOpen={boolValue?.custom}
        onClose={() => setBoolValue({ ...boolValue, custom: false })}
        onSave={(val) => {
          setReoccuringValue({
            ...recurringValue,
            repeatType: val.repeatUnit,
            repeatInterval: val?.dayOfYear || 1,
            customDaysString: val.repeatInterval,
            label: val?.label,
            value: val?.value,
          });
        }}
      />
    </Main>
  );
}

export default CreateBooking;

const Main = styled.div`
  width: 100%;
  height: 99vh;
  overflow-y: scroll;
`;
const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -40px;
  border: 1px solid #ddd;
  align-items: center;
  width: 100px;
  padding-top: 15px;
  height: 70px;
  margin-left: -15px;
  border-radius: 15px;
  cursor: pointer;
`;
const IconProcess = styled.img`
  width: 25px;
  height: 25px;
  margin-bottom: -10px;
`;

const Wrapper = styled.div`
  display: block;
  width: 90%;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
  box-size: border-box;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  .inputwrapper {
    width: 41%;
    @media (min-width: 260px) and (max-width: 721px) {
      width: 38.5%;
    }
  }
  .button {
    margin-left: 230px;
    width: 300px;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const AddressButton = styled.div`
  background: #0f52ba;
  cursor: pointer;
  color: white;
  padding: 10px 3px;
  border-radius: 8px;
  width: 120px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: center;

  h4 {
    text-align: start;
    font-size: 13px;
    margin: 0;
    color: white;
  }
`;

const EditContainer = styled.div`
  border-left: 1px solid ${palette.smokeWhite};
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`;
const Link = styled.div`
  color: #0f52ba;
  font-size: 14px;
  cursor: pointer;
  margin: 6px 0;
  text-align: start;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const DateButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  height: 35px;
  width: 125px;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f3f3f3;
  font-sizze: 14px;
  justify-content: space-between;
`;

const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid gray;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PhotosStyle = styled.img`
  display: flex;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;
const ButtonBox = styled.div`
  align-self: flex-end;
  display: flex;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  justify-content: flex-end;
`;
const MoreIcon = styled.img`
  width: 15px;
  cursor: pointer;
  color: black;
`;
const CalendarStyle = styled.div`
  display: flex;
  box-sizing: border-box;
  background: #ffffff;

  height: auto;
  border-radius: 5px;
  padding: 15px;
  margin-left: 15px;
`;
const SendButton = styled.button`
  border: 1px solid #4169e1;
  background: #4169e1;
  borderradius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid white;
  color: white;

  cursor: pointer;
`;
const CustomBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 12px;
  width: 285px;
`;
const JobDetails = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;

@media (max-width: 968px) {
  flex-direction: column;
}

.left-column {
    justify-content: flex-start;
    width: 40%;
  
    @media (max-width: 968px) {
      width: 100%;
    }
  }
  
  .right-column {
    justify-content: flex-start;
    width: 40%;
  
    @media (max-width: 968px) {
      width: 100%;
    }
  }
  
  .flex-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px; /* Adjusted for spacing in responsive view */
  
    @media (max-width: 868px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .margin-bottom {
    margin-bottom: 10px;
  }
  
  .justify-end {
    justify-content: flex-end;
  
    @media (max-width: 868px) {
      justify-content: flex-start;
    }
  }
  
  .label {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 20px;
    width: 40%;
    text-align: start;
  
    @media (max-width: 868px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  
  .input-box {
    width: 60%;
    padding-left: 10px;
    box-sizing: border-box;
    @media (max-width: 868px) {
        width: 100%;
    }
  
  
`;
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;

  height: auto;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  z-index: 1000;
  padding: 0px 20px;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
`;
const Place = styled(Autocomplete)`
  height: 30px;
  width: 296px;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-weight: 400;
  padding-left: 12px;
  @media (min-width: 260px) and (max-width: 721px) {
    width: 85%;
  }
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
  }
  &:focus {
    outline: none;
    border: 1px solid lightgray;
  }
`;
const SendButton1 = styled.button`
  border: 1px solid #4169e1;
  background: #4169e1;
  color: #fff;
  margin-top: 15px;
  width: 100px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid white;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Icons = styled.img`
  width: 15px;
  height: 15px;
`;

const ContactLabel = styled.p`
  color: ${palette.black};
  font-size: 13px;
  text-align: start;
  line-height: 16px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 4px;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .nameButtton {
    font-weight: 600;
    color: ${palette.charcoleBlack};
  }
`;

const StyleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid ${palette.smokeWhite};
  background: white;
  width: 350px;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
  h5 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  p {
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 16px;
  }
  .keyContainer {
    padding: 0px 0px 0px 10px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: ${palette.black};
    background: #f9f9f9;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${palette.smokeWhite};
    align-items: center;
  }
  .valueContainer {
    padding: 7px;

    background: #fff;
    font-size: 14px;

    display: flex;
  }
  .authoCompletewrapper {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .valuewrapper {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .datewrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;
const StyleRight = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;

  width: 90%;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 20px;
  height: auto;
  border-radius: 8px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-size: 15px;
  font-weight: 600;
  padding: 15px;
  padding-top: 5px;
`;
const WrapperField = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  margin-top: 10px;
  margin-left: 10px;
`;
const WrapperContainer = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
`;
const RightLineItemMain = styled.div`
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
`;
const Inputbox = styled.input`
  height: 34px;
  font-size: 13px;
  padding-left: 10px;
  margin-right: 15px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  color: ${palette.charcoleBlack};
  border-radius: 4px;
  align-self: center;
  ::placeholder {
    font-size: 13px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1.5px solid ${palette.charcoleBlack};
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const Input = styled.input`
  height: 25px;
  font-size: 14px;
  padding-left: 10px;
  word-wrap: break-word;
  width: 100%;
  display: block;
  border-radius: 5px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: 1px solid #000;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 13px;
  white-space: pre-line;
  color: #344054;
  line-height: 16px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: Inter;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`;
const CustomButton2 = styled.button`
  border: 1px solid #4169e1;
  background: #4169e1;
  borderradius: 4px;
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
  width: 96.5%;
  height: 54px;
  border-radius: 8px;
  border: 1px solid white;
  align-self: flex-end;
  color: white;
  font-size: 16px;
  margin-top: 35px;
  margin-bottom: 35px;
  cursor: pointer;
`;
const Value = styled.p`
  color: #000;
  font-size: 14px;
  text-align: start;

  width: 70%;
  margin-bottom: 8px;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ValueInvoice = styled.p`
  color: #000;
  font-size: 14px;
  text-align: start;
  padding: 6px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 8px;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const LeftHeader = styled.p`
  color: #000;
  font-size: 16px;
  text-align: start;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 70%;
  font-weight: 600;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Texts2 = styled.div`
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const CloseButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #ddd;
  margin-right: 30px;
  width: 25px;
  height: 25px;
  align-self: center;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
`;
const OptionBox = styled.div`
  width: 100px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #bbb;

  display: flex;

  font-size: 14px;

  align-items: center;
  justify-content: center;
  margin-left: 30px;

  font-weight: 500;
  cursor: pointer;
`;

const SendIcon = styled.img`
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-top: 20px;
`;
const ItemIcon = styled.img`
  width: 15px;
  height: 15px;
`;
const Flexbox = styled.div`
  display: flex;
  height: auto;
  margin-top: 60px;
  background: #fff;

  .leftdiv {
    width: 450px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
`;
const Profile = styled.div`
  width: 20px;
  padding: 3px;
  background: #4169e1;
  text-align: center;
  border-radius: 5px;
  color: white;
  margin-left: 20px;
  height: 20px;
  margin-top: 4px;
  font-weight: 600;
  font-size: 14px;
  padding-top: 4px;
`;
// const Line1=styled.p`
// color:#d4d4d4;
// margin-left:20px;
// margin-right:10px;
// margin-top:8px;
// `
const Line2 = styled.p`
  color: #d4d4d4;
  margin-left: 10px;
  margin-right: 13px;
  margin-top: 8px;
`;
// const ReviewStar=styled.img`
// margin-top:7px;
// `
const Hr = styled.hr`
  border: 1px solid #e2e8f0;
  margin-top: -3px;
`;
const LocateGps = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-top: -3px;
  &:hover {
    background-color: #f3f3f3;
  }
`;
const InputText = styled.textarea`
  font-family: "Inter", sans-serif;
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color: ${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.renderdiv{
    display:flex;
    justify-content:space-between; 
    align-items:center; 
    font-size:13px; 
}
.select-timebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-size: 13px;
    position: relative;
}

.schedule-icon {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`;
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  align-self: start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 5px;
  .text {
    font-size: 13px;
    text-align: start;
    margin-top: 0px;
    margin-bottom: 5px;
    line-height: 16px;
  }
  .nametext {
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
  }
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  margin-right: 13px;

  p {
    text-align: start;
    display: flex;
    justify-content: start;
    align-self: start;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .input {
    padding: 10.5px;
    font-size: 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    width: 20%;
    align-items: center;

    color: #344054;

    &:focus {
      outline: none;
      border-color: #000;
      box-shadow: none;
    }
  }
`;

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff
    url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E")
    no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
