import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Download } from '@mui/icons-material';

const CarouselOverlay = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.visible ? '1' : '0')};
`;

const CarouselContainer = styled.div`
  width: 85%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
  position: relative;
  background: transparent;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
`;

const ImageContainer = styled.div`
  display: flex;
  transition: transform ${(props) => props.transitionSpeed || '0.5s'} cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: ${(props) => `translateX(-${props.translate}%)`};
  height: 100%;
`;

const ImageWrapper = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
`;

const ZoomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PannableContainer = styled.div`
  position: relative;
  transform: translate(${(props) => props.x}px, ${(props) => props.y}px);
  cursor: ${(props) => (props.isDragging ? 'grabbing' : props.scale > 1 ? 'grab' : 'default')};
  transition: ${(props) => (props.isDragging ? 'none' : 'transform 0.1s ease')};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(${(props) => props.scale});
  transform-origin: center;
  transition: transform 0.3s ease;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  ${(props) => (props.left ? 'left: 20px;' : 'right: 20px;')}
  
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    transform: translateY(-50%) scale(1.1);
  }
  
  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s ease;
  z-index: 1100;
  
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    transform: scale(1.1);
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  padding: 10px 0;
`;

const Dot = styled.button`
  background: ${(props) => (props.active ? 'white' : 'rgba(255, 255, 255, 0.5)')};
  border: none;
  border-radius: 50%;
  width: ${(props) => (props.active ? '12px' : '10px')};
  height: ${(props) => (props.active ? '12px' : '10px')};
  margin: 0 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: white;
    transform: scale(1.2);
  }
  
  &:focus {
    outline: none;
  }
`;

const Caption = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease;
  transform: translateY(${(props) => (props.visible ? '0' : '20px')});
  font-size: 16px;
`;

const Counter = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }
`;

const ControlsToolbar = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 15px;
  border-radius: 20px;
  z-index: 1050;
`;

const ToolbarButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  &:focus {
    outline: none;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ZoomPercentage = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 14px;
`;

const FullscreenButton = styled(ToolbarButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const PremiumImageCarousel = ({ 
  images, 
  captions = [], 
  visible = false, 
  onClose, 
  initialIndex = 0,
  transitionSpeed = '0.5s',
  showCaptions = true,
  showCounter = true,
  autoPlay = false,
  autoPlayInterval = 5000,
  downloadEnabled = true,
  zoomEnabled = true,
  maxZoom = 3
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isLoading, setIsLoading] = useState(true);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const zoomContainerRef = useRef(null);
  
  const totalImages = images?.length || 0;
  
  // Reset current index and zoom when visibility changes
  useEffect(() => {
    if (visible) {
      setCurrentIndex(initialIndex);
    }
  }, [visible, initialIndex]);
  
  // Reset zoom and position when image changes
  useEffect(() => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
    setIsLoading(true);
  }, [currentIndex]);
  
  // Auto play functionality
  useEffect(() => {
    let interval;
    if (visible && autoPlay && totalImages > 1 && scale === 1) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
      }, autoPlayInterval);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [visible, autoPlay, autoPlayInterval, totalImages, scale]);
  
  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!visible) return;
      
      switch (e.key) {
        case 'ArrowLeft':
          if (scale === 1) prevSlide();
          break;
        case 'ArrowRight':
          if (scale === 1) nextSlide();
          break;
        case 'Escape':
          if (scale > 1) {
            resetZoom();
          } else {
            onClose();
          }
          break;
        case '+':
          if (zoomEnabled) zoomIn();
          break;
        case '-':
          if (zoomEnabled) zoomOut();
          break;
        case 'f':
          toggleFullscreen();
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [visible, totalImages, scale, zoomEnabled]);
  
  // Fullscreen API
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);
  
  const nextSlide = useCallback(() => {
    if (totalImages <= 1 || scale > 1) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  }, [totalImages, scale]);
  
  const prevSlide = useCallback(() => {
    if (totalImages <= 1 || scale > 1) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  }, [totalImages, scale]);
  
  const goToSlide = (index) => {
    if (scale > 1) return;
    setCurrentIndex(index);
  };
  
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  
  // Calculate boundaries for panning based on image and container dimensions
  const calculateBoundaries = () => {
    if (!imageRef.current || !zoomContainerRef.current || scale <= 1) {
      return { maxX: 0, minX: 0, maxY: 0, minY: 0 };
    }
    
    const zoomContainer = zoomContainerRef.current.getBoundingClientRect();
    const image = imageRef.current.getBoundingClientRect();
    
    // Calculate the scaled dimensions
    const scaledWidth = image.width * scale;
    const scaledHeight = image.height * scale;
    
    // Calculate maximum allowed movement in each direction
    const maxX = Math.max(0, (scaledWidth - zoomContainer.width) / 2);
    const maxY = Math.max(0, (scaledHeight - zoomContainer.height) / 2);
    
    return { maxX, minX: -maxX, maxY, minY: -maxY };
  };
  
  // Constrain position within boundaries
  const constrainPosition = (pos) => {
    const { maxX, minX, maxY, minY } = calculateBoundaries();
    
    return {
      x: Math.min(maxX, Math.max(minX, pos.x)),
      y: Math.min(maxY, Math.max(minY, pos.y))
    };
  };
  
  // Zoom functions
  const zoomIn = () => {
    if (!zoomEnabled) return;
    setScale(prevScale => Math.min(prevScale + 0.5, maxZoom));
  };
  
  const zoomOut = () => {
    if (!zoomEnabled) return;
    setScale(prevScale => {
      const newScale = Math.max(prevScale - 0.5, 1);
      if (newScale === 1) {
        setPosition({ x: 0, y: 0 });
      }
      return newScale;
    });
  };
  
  const resetZoom = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };
  
  // Download functionality
  const downloadImage = () => {
    if (!downloadEnabled || !images[currentIndex]) return;
    
    const link = document.createElement('a');
    link.href = images[currentIndex];
    link.download = `image-${currentIndex + 1}.jpg`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Mouse drag handling
  const handleMouseDown = (e) => {
    if (scale <= 1) return;
    
    e.preventDefault();
    setIsDragging(true);
    setDragStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };
  
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    const newPosition = {
      x: e.clientX - dragStartPos.x,
      y: e.clientY - dragStartPos.y
    };
    
    setPosition(constrainPosition(newPosition));
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  
  // Touch handlers for swipe and pinch zoom
  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      // Single touch - track for swipe or drag
      setTouchStart(e.targetTouches[0].clientX);
      
      if (scale > 1) {
        setIsDragging(true);
        setDragStartPos({
          x: e.touches[0].clientX - position.x,
          y: e.touches[0].clientY - position.y
        });
      }
    }
  };
  
  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      setTouchEnd(e.targetTouches[0].clientX);
      
      if (isDragging && scale > 1) {
        const newPosition = {
          x: e.touches[0].clientX - dragStartPos.x,
          y: e.touches[0].clientY - dragStartPos.y
        };
        
        setPosition(constrainPosition(newPosition));
        e.preventDefault(); // Prevent scrolling when dragging
      }
    }
  };
  
  const handleTouchEnd = () => {
    setIsDragging(false);
    
    if (scale <= 1 && touchStart && touchEnd) {
      if (touchStart - touchEnd > 100) {
        // Swipe left - next slide
        nextSlide();
      }
      
      if (touchEnd - touchStart > 100) {
        // Swipe right - previous slide
        prevSlide();
      }
    }
  };
  
  // Double tap/click to zoom
  const handleDoubleClick = (e) => {
    if (!zoomEnabled) return;
    
    if (scale > 1) {
      resetZoom();
    } else {
      setScale(2);
      
      // Center zoom on click point
      if (zoomContainerRef.current) {
        const rect = zoomContainerRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        
        // Calculate center position
        const centerX = (offsetX - rect.width / 2);
        const centerY = (offsetY - rect.height / 2);
        
        setPosition(constrainPosition({ 
          x: centerX, 
          y: centerY 
        }));
      }
    }
  };
  
  // Mouse wheel zoom
  const handleWheel = (e) => {
    if (!zoomEnabled) return;
    e.preventDefault();
    
    if (e.deltaY < 0) {
      zoomIn();
    } else {
      zoomOut();
    }
  };
  
  // Fullscreen toggle
  const toggleFullscreen = () => {
    if (!containerRef.current) return;
    
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };
  
  if (!images || images.length === 0) {
    return null;
  }
  
  return (
    <CarouselOverlay 
      visible={visible} 
      onClick={(e) => {
        // Only allow closing when clicking outside the container and not zoomed
        if (e.target === e.currentTarget && scale === 1) {
          onClose();
        }
      }}
    >
      <CloseButton onClick={onClose}>×</CloseButton>
      
      {showCounter && totalImages > 1 && (
        <Counter>{currentIndex + 1} / {totalImages}</Counter>
      )}
      
      <ControlsToolbar>
        {zoomEnabled && (
          <>
            <ToolbarButton 
              onClick={zoomOut} 
              disabled={scale === 1}
              aria-label="Zoom out"
              title="Zoom out"
            >
              −
            </ToolbarButton>
            <ZoomPercentage>{Math.round(scale * 100)}%</ZoomPercentage>
            <ToolbarButton 
              onClick={zoomIn} 
              disabled={scale >= maxZoom}
              aria-label="Zoom in"
              title="Zoom in"
            >
              +
            </ToolbarButton>
            {/* <ToolbarButton 
              onClick={resetZoom} 
              disabled={scale === 1}
              aria-label="Reset zoom"
              title="Reset zoom"
            >
              ↺
            </ToolbarButton> */}
          </>
        )}
        
        {downloadEnabled && (
          <ToolbarButton 
            onClick={downloadImage}
            aria-label="Download image"
            title="Download image"
          >
            <Download style={{fontSize:'16px'}}/>
          </ToolbarButton>
        )}
      </ControlsToolbar>
      
      <FullscreenButton 
        onClick={toggleFullscreen}
        aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
      >
        {isFullscreen ? "⤓" : "⤢"}
      </FullscreenButton>
      
      <CarouselContainer 
        onClick={(e) => e.stopPropagation()}
        ref={containerRef}
      >
        <ImageContainer 
          translate={currentIndex * 100}
          transitionSpeed={scale > 1 ? '0s' : transitionSpeed}
        >
          {images.map((image, index) => (
            <ImageWrapper key={index}>
              {isLoading && currentIndex === index && <LoadingSpinner />}
              
              <ZoomContainer
                ref={index === currentIndex ? zoomContainerRef : null}
                onDoubleClick={handleDoubleClick}
                onWheel={handleWheel}
                onTouchStart={index === currentIndex ? handleTouchStart : undefined}
                onTouchMove={index === currentIndex ? handleTouchMove : undefined}
                onTouchEnd={index === currentIndex ? handleTouchEnd : undefined}
              >
                {index === currentIndex && (
                  <PannableContainer
                    x={position.x}
                    y={position.y}
                    scale={scale}
                    isDragging={isDragging}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                  >
                    <Image 
                      ref={imageRef}
                      src={image} 
                      alt={captions[index] || `Slide ${index + 1}`} 
                      onLoad={handleImageLoad}
                      loading="lazy"
                      scale={scale}
                    />
                  </PannableContainer>
                )}
                
                {index !== currentIndex && (
                  <Image 
                    src={image} 
                    alt={captions[index] || `Slide ${index + 1}`} 
                    loading="lazy"
                    scale={1}
                  />
                )}
              </ZoomContainer>
            </ImageWrapper>
          ))}
        </ImageContainer>
        
        {totalImages > 1 && scale === 1 && (
          <>
            <NavButton left onClick={prevSlide}>❮</NavButton>
            <NavButton onClick={nextSlide}>❯</NavButton>
            
            <DotsContainer>
              {Array.from({ length: totalImages }).map((_, index) => (
                <Dot 
                  key={index} 
                  active={index === currentIndex} 
                  onClick={() => goToSlide(index)} 
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </DotsContainer>
          </>
        )}
        
        {showCaptions && captions[currentIndex] && (
          <Caption visible={!isLoading && scale === 1}>
            {captions[currentIndex]}
          </Caption>
        )}
      </CarouselContainer>
    </CarouselOverlay>
  );
};

PremiumImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  captions: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  initialIndex: PropTypes.number,
  transitionSpeed: PropTypes.string,
  showCaptions: PropTypes.bool,
  showCounter: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
  downloadEnabled: PropTypes.bool,
  zoomEnabled: PropTypes.bool,
  maxZoom: PropTypes.number
};

export default PremiumImageCarousel;