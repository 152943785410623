import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import GPS from '../../assets/gps.png'
import io from 'socket.io-client';
import payments from '../../assets/paymentsIconss.png'
import payment from '../../assets/payment.png'
import reviews from '../../assets/reviews.png'
import Sendicon from '../../assets/message1.png'
import calendasr from '../../assets/contactBookInactive.png'
import cross from '../../assets/cross.png'
import quote from '../../assets/quote.png'
import invoice from '../../assets/invoice.png'
import google from '../../assets/Google.png'
import iconlocation from '../../assets/iconlocation.png'
import edit from '../../assets/edit.png'
import calltel from '../../assets/viber.png'
import Openarrow from '../../assets/down.png'
import Closearrow from '../../assets/up.png'

import { getAllinchats, getAlloutchats, getUserData, sendMessage } from '../../store/Actions/Auth.action'
import { formatMobileNumber, TimeListAm, TimeListPM } from '../../utils/Commonfunction';
import Modals from '../Modal';
import Calendar from "react-calendar";
import UserDetails from './UserDetails';
import CreateInvoice from './CreateInvoice'
import CreateBooking from './CreateBooking'
import CreateQuote from './CreateQuote'
import FullScreenModal from '../FullScreenModal'
import { Player } from '@lottiefiles/react-lottie-player'
import { palette } from '../../utils/Palette'
import VideoJS from '../../ReuseableComponets/VideoJS'
import moment from 'moment'
import EmojiPicker from 'emoji-picker-react'
import { FiPhoneCall, FiSend } from 'react-icons/fi'
import { IconButton, InputAdornment, Menu, TextField } from '@mui/material'
import { EmojiEmotionsOutlined, MoreHoriz } from '@mui/icons-material'
const socket = io('https://apis.getnotifi.com',{autoConnect:false});

function RightSideConversations(props) {
  const globename = useSelector(state => state?.auth?.globename)
  const globenumber = useSelector(state => state?.auth?.globenum)
  const token = useSelector(state => state?.auth?.token)
  const globecolor = useSelector(state => state?.auth?.globecolor)
  const [message, setMessage] = useState(null)
  const [body, setBody] = useState(null)
  const [num, setNum] = useState(null)
  const [isModalopen, setModalOpen] = useState(false);
  const [isSlectModal, setIsSelectModal] = useState(false);
  const [isModalopenInvoice, setModalOpenInvoice] = useState(false);
  const [isModalopenQuote, setModalOpenQuote] = useState(false);
  const [loader, setLoader] = useState(false)
  const [show, setisShow] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openEmoji = Boolean(anchorEl);

  const [alldata, setAlldata] = useState([])
  // const [details,setDetails]=useState(null)
  let fromnumber = localStorage.getItem('fromnumber')

  const detail = useSelector(state => state?.auth?.convo)
  //const firstnumber = detail ? detail?.[0]?.name ? detail?.[0]?.name : detail?.[0]?.to :''
  const frstnum = detail ? detail?.[0]?.to : ''
  let dispatch = useDispatch()

  const init = async () => {
      setLoader(true)
    var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
    const res = await dispatch(getAllinchats(`${cleaned2}`));
    const Dates = [...res?.data]
    Dates.sort((a, b) => new Date(b?.date) - new Date(a?.date))
    setAlldata(Dates)
    setLoader(false)
    // dispatch(getAlloutchats(globenumber?globenumber:frstnum,fromnumber));
    dispatch(getUserData())
  }
  useEffect(() => {

   // setAlldata(props?.value?.conversationIds?.sort((a, b) => new Date(b?.date) - new Date(a?.date)))
    init()
  }, [globenumber, frstnum, fromnumber])
  const outchats = useSelector(state => state?.auth?.outchats)
  const inchats = useSelector(state => state?.auth?.inchats)
  const userdata = useSelector(state => state?.auth?.userData)



  useEffect(() => {
    var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
    // socket.emit('join_room','hello worldddd')
    socket.connect()
    socket.on('conversation', (msg) => {
      console.log(msg, cleaned2, localStorage.getItem('number'))
      if (msg[0].from === `+${cleaned2}` && msg[0].to === `+${localStorage.getItem('number')}`) {
        init()
      }
      // setBody(msg?.[1])
      // setNum(msg?.[0].from)
      socket.close()
      socket.connect()
    })
    return () => {
      socket.off('conversation');
    };
  }, []);
  //  const handledate=(item)=>{
  //     const dateString = item;
  //     const date = new Date(dateString);
  //     const hours = date.getUTCHours();
  //     const minutes = date.getUTCMinutes();
  //     let hours12 = hours % 12;
  //     if (hours12 === 0) {
  //     hours12 = 12;
  //     }
  //    let customminutes=minutes<10 ?'0'+minutes:minutes
  //    const meridiem = hours < 12 ? "AM" : "PM";
  //    const formattedTime = `${hours12}:${customminutes} ${meridiem}`;
  //    return formattedTime
  //  }

  const handleClickEmoji = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorEl(null);
  };

  const handlesend = (e) => {
    setMessage(e.target.value)
  }
  const sendchat = async (val) => {
    setMessage('')
    if (message || val) {
      await dispatch(sendMessage(
        {
          "from": fromnumber,
          "to": props?.value?.number,
          "body": val ? val : message
        }
      ))

      var cleaned2 = ("" + props?.value?.number).replace(/\D/g, "");
      const res = await dispatch(getAllinchats(`+${cleaned2}`));
      const Dates = [...res?.data]
      Dates.sort((a, b) => new Date(b?.date) - new Date(a?.date))
      setAlldata(Dates)

    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendchat();
    }
  };
  function formatChatTimestamp(dateString, userTimeZone) {
    const currentDate = new Date();
    const chatDate = new Date(dateString + 'T00:00:00'); // Assume midnight for simplicity

    // Adjust the chatDate to the user's time zone
    chatDate.toLocaleString('en-US', { timeZone: userTimeZone });

    const timeDifference = Math.abs(currentDate - chatDate);
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (hoursDifference < 24) {
      // Recent chat within 24 hours
      return `Today`;
    } else if (hoursDifference < 48) {
      // Recent chat within 24 hours
      return `Yesterday`;
    } else {
      // Older chat, display the date
      const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
      return `${chatDate.toLocaleDateString('en-US', dateOptions)}`
    }
  }

  const combinedArray = outchats && inchats ? outchats?.concat(inchats) : ''
  // let alldata= combinedArray?combinedArray?.sort((a, b) => {
  //   return  new Date(b.createdAt) - new Date(a.createdAt);
  // })
  // :''
  useEffect(() => {
    if (body) {
      if (globenumber === num) {
        combinedArray.push(body)
      }
    }
  }, [num, body, globenumber])

  const extractMoneyfromUrl = (url) => {
    const urlString = url;
    const priceRegex = /\$\d+\.\d{2}/;
    const match = urlString?.match(priceRegex);

    if (match && match.length > 0) {
      const price = match[0];

      return price; // Returning the actual price without converting it to a string
    }
    else {
      return message?.price
    }
  }

  function checkInvoiceQuote(bookingMessage) {
    const normalizedMessage = bookingMessage?.replace(/\n/g, '').toLowerCase();

    const baseResponse = {
        body: bookingMessage,
        type: "outbound-reply",
        createdAt: new Date()
    };

    if (normalizedMessage) {
      if (normalizedMessage.includes("https://app.getnotifi.com/invoice/") ||
      normalizedMessage.includes("viewed invoice")) {
        return { ...baseResponse, invoice: true, price: extractMoneyfromUrl() };
      } else if (normalizedMessage.includes("https://app.getnotifi.com/quote/")||
      normalizedMessage.includes("viewed quote") ||
      normalizedMessage.includes("approved quote")) {
        return { ...baseResponse, quote: true, price: extractMoneyfromUrl() };
      
      }  else if (normalizedMessage.includes('quick review')) {
            return { ...baseResponse, google: true };
        } else if (normalizedMessage.includes('track us using')) {
            return { ...baseResponse, track: true };
        }
    }

    return baseResponse;
}
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsSelectModal(false); // Close the modal when clicking outside
    }
  };
  const onEmojiClick = (emojiData) => {
    setMessage((prev) => (prev || "") + emojiData.emoji);
  };


  return (
    <Main >
     <FlexHeader>
        <div style={{ display: 'flex' }}>
          <Profile>
            <span>{props?.value?.name?.toUpperCase().slice(0, 1)}</span>
          </Profile>
          <div>
            <Name>{props?.value?.name || formatMobileNumber(props?.value?.number)}</Name>
            <Value>{formatMobileNumber(props?.value?.number)}</Value>
          </div>
        </div>
        {/* <Line1>|</Line1>
<div>
<ReviewStar src='https://app.podium.com/static/media/review.9e8d7fce974894c5320fbeb93e8d4acb.svg' alt='review'/>
</div> */}
        <div style={{ display: 'flex' }}>
          <StyledIconBox style={{ marginRight: '10px' }} onClick={() => {
            const call = token.connect({
              params: {
                to: props?.value?.number,
                from: `+${localStorage.getItem('number')}`
              }
            })

            props?.setConnection(call)
            dispatch({ type: "CONNECTION", payload: call })
            dispatch({
              type: "CALLDETAIL", payload: {
                name: props?.value?.name,
                to: props?.value?.number
              }
            })
            dispatch({ type: "CALLSHOW", payload: true })
          }}>
            <FiPhoneCall />
          </StyledIconBox>
          <StyledIconBox onClick={() => {
           
          }}>
            <MoreHoriz />
          </StyledIconBox>
        </div>

      </FlexHeader>

      <Chats >


        {props?.value ?
          alldata
            ?.map((x, index) => {
              return (
                <div key={index}>
                  <Name style={{ fontSize: 12, fontWeight: '400', color: 'gray', textAlign: 'center', marginTop: '15px', marginBottom: '25px', marginLeft: -10 }}>{formatChatTimestamp(x?.date, 'America/Toronto')}</Name>
                  {x?.messages?.map((x, index) => {
                    return (
                      x?.type === 'outbound' || !x.type ?
                        <OutText  >
                          <span className="tooltip">{moment(x?.createdAt).format('hh:mm A')}</span>
                          <OutChat>
                            {x?.invoice || checkInvoiceQuote(x?.message)?.invoice || checkInvoiceQuote(x?.message)?.quote || x?.type !== "inbound" && x?.quote || checkInvoiceQuote(x?.message)?.google || x?.google || x?.type !== "inbound" && checkInvoiceQuote(x?.message)?.track || x?.track ?
                              checkInvoiceQuote(x?.message)?.google || x?.google ? <GoogleContainer><ChatIcons style={{ width: '15px', height: '15px', padding: 5 }} src={google} /></GoogleContainer> :
                                <ChatIcons src={checkInvoiceQuote(x?.message)?.invoice || x?.invoice ? invoice : checkInvoiceQuote(x?.message)?.track || x?.track ? iconlocation : quote} /> : null}
                            <Messagetext>{checkInvoiceQuote(x?.message)?.invoice || x?.invoice ? `Payment request of ${extractMoneyfromUrl(x?.message) || x?.price}` : checkInvoiceQuote(x?.message)?.google || x?.google ? 'Leave us a review!' : checkInvoiceQuote(x?.message)?.track || x?.track ? "We're on our way." : checkInvoiceQuote(x?.message)?.quote || x?.quote ? `Quote sent for ${extractMoneyfromUrl(x?.message) || x?.price}` : x?.message}</Messagetext>
                            {/* <p className='outtime'>{handledate(x.createdAt)}</p> */}
                          </OutChat>


                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: palette.newBlue,
                              //backgroundColor:"red",
                              width: 20,
                              height: 15,
                              bottom: -2,
                              borderBottomLeftRadius: 25,
                              right: -10
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "#ffffff",

                              //backgroundColor:"green",
                              width: 20,
                              height: 35,
                              bottom: -6,
                              borderBottomLeftRadius: 18,
                              right: -20

                            }}
                          />
                        </OutText>
                        : x?.type === 'inbound' ?
                          <>
                            <InText>
                              {/* <ProfileImage src={profile} alt='profileimg' className={globecolor}/> */}
                              <InChat>
                                {x?.responseType === 'MMS' ? x?.contentType === 'video/mp4' ? <VideoJS options={{
                                  autoplay: false,
                                  controls: true,
                                  responsive: false,
                                  fluid: true,
                                  sources: [
                                    {
                                      src: x.message,
                                      type: 'video/mp4'
                                    },

                                  ]
                                }} onReady={(player) => {

                                }} /> : <img onClick={() => {
                                 
                                }} style={{ width: '250px', marginTop: '15px', marginBottom: '15px' }} src={x?.message} /> : <div style={{display:'flex',alignItems:'center'}}>
                                 {(checkInvoiceQuote(x?.message)?.invoice||checkInvoiceQuote(x?.message)?.quote)? <ChatIcons src={checkInvoiceQuote(x?.message)?.invoice  ? invoice : quote} />:null}
                                  <Messagetext>{x?.message}</Messagetext>
                                </div>

                                }
                                {/* <div style={{alignSelf:'flex-end',fontSize:'12px'}}>{moment(x?.createdAt).format('hh:mm A')}</div> */}
                              </InChat>
                              <span className="tooltip">{moment(x?.createdAt).format('hh:mm A')}</span>
                              {/* <div style={{alignSelf:'flex-end',fontSize:'10px',color:palette.grey}}>{moment(x?.createdAt).format('hh:mm A')}</div> */}
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: '#F3F3F3',
                                  //backgroundColor:"red",
                                  width: 20,
                                  height: 15,
                                  bottom: -2,
                                  borderBottomRightRadius: 20,
                                  left: -10
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "#ffffff",
                                  //backgroundColor:"green",
                                  width: 20,
                                  height: 35,
                                  bottom: -6,
                                  borderBottomRightRadius: 18,
                                  left: -20

                                }}
                              />

                            </InText>

                          </>
                          : ''
                    )
                  })}
                </div>
              )
            })
          : ''}

      </Chats>

      <InputContainer>
        {/* <Flexbox style={{ width: '150%' }}>
            <Flexbox onClick={() => setModalOpen(true)}>
              <ItemIcon src={calendasr} />
              <Element>&nbsp;Create booking&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setModalOpenQuote(true)}>
              <ItemIcon src={payment} />
              <Element>&nbsp;Quote&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setModalOpenInvoice(true)} >
              <ItemIcon src={payments} />
              <Element>&nbsp;Invoice&nbsp;</Element>
            </Flexbox>
            <Flexbox onClick={() => setMessage(`Please leave us a quick review by clicking the link below:https://app.getnotifi.com/review/${new Date().getDate()}/${userdata?.placeId}`)}>
              <ItemIcon src={reviews} />
              <Element>&nbsp;Ask for review&nbsp;</Element>
            </Flexbox>
          </Flexbox> */}


        <div style={{ position: 'relative' }}>
          {/* {isSlectModal ?
            <div onClick={handleOverlayClick} style={{
              zIndex: 1500,
              position: 'fixed',
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",

              margin: 0,
              padding: 0,
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignContent: "stretch",
              alignItems: "center",
            }}>
              <div style={{
                backgroundColor: '#FAFAFA',
                boxShadow: "0 12px 48px rgba(32,40,77,.08),0 32px 80px rgba(32,40,77,.1)",
                opacity: 1.0,
                borderRadius: '8px',
                width: "250px",
                background: 'white',
                position: 'absolute', 
                top: 'calc(100% - 320px)', 
                left: "560px",

                zIndex: 1000,
              }
              } >
                <Flexbox style={{ display: 'inline', }}>
                  <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                    setIsSelectModal(false)
                    setModalOpen(true)
                  }}>
                    <ItemIcon src={calendasr} />
                    <Element>&nbsp;Job&nbsp;</Element>
                  </Flexbox>
                  <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                    setIsSelectModal(false)
                    setModalOpenQuote(true)
                  }}>
                    <ItemIcon src={payment} />
                    <Element>&nbsp;Quote&nbsp;</Element>
                  </Flexbox>
                  <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                    setIsSelectModal(false)
                    setModalOpenInvoice(true)
                  }} >
                    <ItemIcon src={payments} />
                    <Element>&nbsp;Invoice&nbsp;</Element>
                  </Flexbox>
                  <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => {
                    setIsSelectModal(false)
                    setModalOpenTemplate(true)
                  }} >
                    <ItemIcon src={web} />
                    <Element>&nbsp;Templates&nbsp;</Element>
                  </Flexbox>
                  <Flexbox style={{ margin: '5px', padding: '8px' }} onClick={() => setMessage(`Please leave ${userdata?.businessName} a quick review by clicking the link below:https://app.getnotifi.com/review/${new Date().getDate()}/${userdata?.placeId}`)}>
                    <ItemIcon src={reviews} />
                    <Element>&nbsp;Ask for review&nbsp;</Element>
                  </Flexbox>
                </Flexbox>

              </div>
            </div>
            : null} */}



        </div>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openEmoji}
          onClose={handleCloseEmoji}

          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              minWidth: '220px',

              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.16))',
              mt: -2,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >



          <EmojiPicker searchDisabled style={{ border: '1px solid #fff', height: '350px', fontSize: '14px' }} onEmojiClick={onEmojiClick} previewConfig={{ showPreview: false }} />






        </Menu>

        <Elements style={{ marginBottom: '30px' }}>
          {/* <StyledIconBox  style={{borderRadius:'20px',width:'45px',height:'40px',background:'#f3f3f3'}}>
          <Add style={{fontSize:'20px'}} color='black'/>
          </StyledIconBox> */}
          <InputBox
            InputProps={{
              style: {

                width: '100%',
                borderRadius: '10px',
                fontSize: '15px',
                minHeight: '30px',
                background: '#fff',
                marginTop: '10px',
                marginBottom: '10px',

              },
              startAdornment: (
                <InputAdornment position="start">
                
                  <IconButton style={{ width: '30px', height: '30px' }} onClick={handleClickEmoji}>
                    <EmojiEmotionsOutlined />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton style={{ marginRight: '-8px' }} onClick={() => {
                    sendchat()
                  }}>
                    <FiSend style={{ color: message ? 'black' : 'grey' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            multiline

            type="text" placeholder="Send a message" onKeyPress={handleKeyPress} value={message} onChange={(e) => {

              handlesend(e)
            }} />
          {/* <SendIcon src={Sendicon} onClick={() => sendchat()} /> */}
          {/* <CustomButton style={{ border: '1px solid #4169e1', background: '#4169e1', borderRadius: '4px', color: '#fff', marginBottom: '15px', height: '35px', width: '120px', fontSize: 14 }} onClick={() => sendchat()}>
            Send
          </CustomButton> */}
        </Elements>

      </InputContainer>
    </Main>
  )
}

export default RightSideConversations

const Main = styled.div`
flex-grow: 1;
overflow-x:hidden;
overflow-y: hidden;
margin-top:-10px;
padding-top:25px;
scrollbar-width: thin;
scrollbar-color: #888 transparent;
padding-bottom:20px;
display: flex;
flex-direction: column;
height: 100vh;
margin-left:-15px;
margin-right:-15px;


`
const Name = styled.p`
text-align:start;
padding-left:10px;
font-size: 14px;
color: rgb(45, 47, 49);
font-weight: 500;
margin-top:3px;
margin-bottom:0px;
`
const Value = styled.p`
text-align:start;
padding-left:10px;
font-size: 11px;
color: grey;
font-weight: 400;
margin-top:3px;
margin-bottom:0px;
`


const ChatIcons = styled.img`
width: 35px;
height: 35px;
`
const Element = styled.div`

font-size:14px;
font-weight:500;
margin-right:12px;
cursor:pointer;
`
const Elements = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:flex-start;
align-self:start;

margin-top:-10px;

`

const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc; 
  }

  svg {
    font-size: 17px;
    color: rgba(32, 32, 32, 0.6); 
    transition: color 0.3s;

    &:hover {
      color: black; 
    }
  }
`;


const Flexbox = styled.div`
display:flex;
&:hover {
  background:#f3f3f3;
}

`

const FlexHeader = styled.div`
display:flex;
border-bottom:1px solid #ebeef1;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
padding-bottom:11.4px;
`
const Profile = styled.div`
width:25px;
padding:3px;
background:${palette.newBlue};
text-align:center;
border-radius:5px;
display:flex;
align-items:center;
justify-content:center;
color:white;
height:25px;
margin-top:4px;
font-weight:600;
font-size:14px;
padding-top:4px;
`
// const Line1=styled.p`
// color:#d4d4d4;
// margin-left:20px;
// margin-right:10px;
// margin-top:8px;
// `


const Chats = styled.div`
flex: 1;
overflow-y: auto;
overflow-x:hidden;
display: flex;
flex-direction: column-reverse;
padding-left:20px;
padding-right:10px;
padding-top:10px;
flex-direction: column-reverse;
&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`
const GoogleContainer = styled.div`
border-radius:20px;
background:#fff;
display:flex;
justify-content:center;
align-items:center;
margin-right:5px;

.img{

}

`
const InputBox = styled(TextField)`
width: 100%;
border-radius: 20px;
&& {
  width: 100%;
}


&:focus {
  outline: none;
}
.MuiOutlinedInput-root {
  &.Mui-focused {
    fieldset {
      border-color: black; 
    }
  }
}


`;

const InputContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
  margin-left: 15px;
  align-items:center;
  justify-content:center;
  margin-right: 15px;
  padding-left: 10px;
  margin-top: 15px;
  padding-right: 10px;
  @media (min-width: 260px) and (max-width: 820px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;


// const Button1 = styled.button`
// color:rgb(70, 110, 255);
// border:1px solid white;
// background:white;
// margin-right:10px;
// border-radius:6px;
// width:100px;
// font-weight:600;
// cursor:pointer;
// `
// const Button2 = styled.button`
// color:white;
// background:rgb(70, 110, 255);
// font-weight:600;
// border:1px solid white;
// border-radius:6px;
// padding:6px;
// width:60px;
// cursor:pointer;
// `
const OutText = styled.div`
display:flex;
justify-content:flex-end;
margin-right:15px;
margin-bottom:15px;
position: relative;
.tooltip {
  visibility: hidden;
  width: auto;
  color: rgba(32, 32, 32, 0.6);
  text-align: center;
  display:flex;
  align-items:center;
  border-radius: 5px;
  padding: 5px;
  font-size:10px;
  margin-right: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

&:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

    `
const OutChat = styled.div`
background:${palette.newBlue};
color:white;
border-radius:10px 10px 10px 10px;
padding:15px;
padding-bottom:3px;
word-wrap: break-word; 
  overflow-wrap: break-word; 
 
padding-top:0px;
text-align:start;
max-width:280px;
word-wrap: break-word;
word-break: break-word; 
overflow-wrap: anywhere; 
display: inline-block;
display:flex;
align-items:center;
margin-right:0px;
.outtime{
font-size:12px;
padding:0px;
margin-top:-8px;
}
`
const Messagetext = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;
`
const InChat = styled.div`
background:#F3F3F3;
border-radius:10px 10px 10px 10px;
padding:12px;
padding-top:0px;
padding-bottom:3px;
text-align:start;
max-width:250px;
word-break: break-word; 
overflow-wrap: anywhere; 
display: inline-block;
align-items:center;
margin-left:0px;
.intime{
color:rgb(70, 110, 255);
font-size:12px;
padding:0px;
margin-top:-8px;
}
`

const InText = styled.div`
display:flex;
justify-content:flex-start;
margin-bottom:15px;
margin-left:5px;
position:relative;
.pink{
    background:pink;
    }
    .orange{
    background: #ff9900;
    }
    .violet{
    background:#974EDE;
    }
    .tooltip {
      visibility: hidden;
      width: auto;
      color: rgba(32, 32, 32, 0.6);
      text-align: center;
      display:flex;
      align-items:center;
      border-radius: 5px;
      padding: 5px;
      font-size:10px;
      margin-left: 10px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  
    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }
`