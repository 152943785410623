import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import {
  createTimeChange,
  getAllCrew,
  getCardData,
  getWeekCardData,
  submitCreatejobChange,
  submitEditjobChange,
  submitTimeChange,
} from "./TimesheetAction";
const initialState = {
  loader: false,
  formLoader: false,
  buttonLoader: false,
  preview: false,
  value: {},
  error: "",
  uniqueId: "",
  team: [],
  selectedCrew: "",
  allBooking: [],
  fields: [],
  weekData: [],
};

const TimesheetSlice = createSlice({
  name: "jobForm",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setselectedcrew: (state, action) => {
      state.selectedCrew = action.payload;
    },
    updatefields: (state, action) => {
      state.fields = action.payload;
    },
    updateSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    },
    toggleDay: (state, action) => {
      const day = action.payload;
      state.selectedDays[day].enabled = !state.selectedDays[day].enabled;
    },
    updateTime: (state, action) => {
      const { day, type, value } = action.payload;
      state.selectedDays[day][type] = value;
    },
    setHours: (state, action) => {
      state.hours = action.payload;
    },
    setMinutes: (state, action) => {
      state.minutes = action.payload;
    },
    resetJobForm: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCrew.pending, (state) => {
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(getAllCrew.fulfilled, (state, action) => {
        console.log("jelc cdnj");
        if (action.payload?.status === true) {
          state.loader = false;
          const formattedData = action.payload?.data?.map((user) => ({
            ...user,
            label: `${user.firstName.trim()} ${user.lastName.trim()}`,
            value: user.roles[0]._id,
          }));
          state.team = formattedData;
          state.buttonLoader = false;
        } else {
          state.error = action.payload?.error || "Something went wrong";
          state.buttonLoader = false;
        }
      })
      .addCase(getCardData.pending, (state) => {
        state.error = "";
        state.loader = true;
        state.error = null;
      })
      .addCase(getCardData.fulfilled, (state, action) => {
        console.log(action.payload, "scdc");
        state.loader = false;
        (state.fields = action.payload?.historyData || []),
          (state.allBooking = action.payload?.referenceNumbers),
          (state.buttonLoader = false);
      })
      .addCase(getWeekCardData.pending, (state) => {
        state.error = "";
        state.loader = true;
        state.error = null;
      })
      .addCase(getWeekCardData.fulfilled, (state, action) => {
        state.loader = false;
        (state.weekData = action.payload?.weekData || []),
          (state.buttonLoader = false);
      })
      .addCase(submitTimeChange.pending, (state) => {
        state.error = "";
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(submitTimeChange.fulfilled, (state, action) => {
        console.log(action, "scdc");
        state.loader = false;
        if (!action?.payload?.status) {
          state.error = action.payload?.message;
        } else {
          state.error = "";
          state.fields = action.payload?.historyData || [];
        }
        state.buttonLoader = false;
      })
      .addCase(createTimeChange.pending, (state) => {
        state.error = "";
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(createTimeChange.fulfilled, (state, action) => {
        console.log(action, "scdc");
        state.loader = false;
        if (!action?.payload?.status) {
          state.error = action.payload?.message;
        } else {
          state.error = "";
          state.fields = action.payload?.historyData || [];
        }
        state.buttonLoader = false;
      })
      .addCase(submitEditjobChange.pending, (state) => {
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(submitEditjobChange.fulfilled, (state, action) => {
        console.log(action, "scdc");
        state.loader = false;
        state.buttonLoader = false;
      })
      .addCase(submitCreatejobChange.pending, (state) => {
        state.buttonLoader = true;
        state.error = null;
      })
      .addCase(submitCreatejobChange.fulfilled, (state, action) => {
        state.loader = false;
        state.buttonLoader = false;
      });
  },
});

export const {
  setLoader,
  setselectedcrew,
  updatefields,
  updateSelectedDays,
  toggleDay,
  updateTime,
  setHours,
  setMinutes,
  resetJobForm,
} = TimesheetSlice.actions;

export default TimesheetSlice.reducer;
