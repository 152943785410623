import { Avatar, Card, CardContent, Divider, IconButton, InputAdornment, Menu, MenuItem, Select, StepLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import cross from '../../../assets/cross.png'
import Autocomplete from 'react-google-autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import invoiceimg from '../../../assets/invoice.png'
import quoteimg from '../../../assets/quote.png'
import booking1 from '../../../assets/booking1.png'
import allactivites from '../../../assets/add.png'
import trash from '../../../assets/trash.png'
import check from '../../../assets/check.png'
import TextField from '@mui/material/TextField';
import Autocomplete1, { createFilterOptions } from '@mui/material/Autocomplete';
import Calendar from "react-calendar";
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Modal';
import { addContactPhotos, addDetaisNote, addHasgtag, deleteAdditionalAddress, deleteAdditionalContact, deleteAdditionalNotes, deleteAddTask, DoneAddTask, getContactDetailV2, getUserDetails, saveAdditionalAddress, saveAdditionalContact, saveAdditionalNotes, submitAddTask, submitassignCustomField, submitassignTag, submitchangeStatus, submitEditContacts, UploadNotesImage } from '../../../store/Actions/dashboard.action';
import moment from 'moment';
import RightModal from '../../RightSideModal';
import RightSideConversations from '../RightSideConversation';
import { calendarTeamColor, cardBrandImages, colorThemes, dayViewColor, formatMobileNumber, putCommaInvalue, TimeListAm, TimeListPM } from '../../../utils/Commonfunction';
import { Player } from '@lottiefiles/react-lottie-player';
import CreateInvoice from '../CreateInvoice';
import CreateQuote from '../CreateQuote';
import FullScreenModal from '../../FullScreenModal';
import { sendMessage } from '../../../store/Actions/Auth.action';
import plus from '../../../assets/plus.png'
import CreateBooking from '../CreateBooking';
import { DateRange } from 'react-date-range'
import { addDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { palette } from '../../../utils/Palette';
import { flag, InvoiceColor, jobColor, pdf, plusIcon, quoteIconColor } from '../../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, AddOutlined, ArrowBackIos, Call, Cancel, Check, Construction, Edit, Handyman, Inbox, KeyboardArrowDown, LibraryBooks, LocalAtm, MessageOutlined, MyLocation, Paid, PaidRounded, PermPhoneMsg, Person, RequestQuote, RequestQuoteOutlined, RequestQuoteRounded, Schedule, SearchOutlined, TaskAlt } from '@mui/icons-material';
import AutocompleteComponent from '../../../ReuseableComponets/CustomAutoComplete';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import TimelineContent from '@mui/lab/TimelineContent';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import CustomizedMenu from '../../../ReuseableComponets/MenuComponent';
import InputBox from '../../../ReuseableComponets/InputBox';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CustomTabs from '../../../ReuseableComponets/CustomTab';
import TopModal from '../../TopModal';
import DeleteModal from './DeleteModal';
import MultiSelectWithSearch from '../../Settings/component/MultiSelectSearch';
import AddCustomField from '../../Settings/component/AddCustomField';
import AddTask from '../Bookings/AddTask';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Component/Card';
import { Elements } from '@stripe/react-stripe-js';

import ServicePlanForm from './Component/AddPlans';
import { FiEdit, FiMapPin, FiTrash2 } from 'react-icons/fi';
import { deleteAttachmentnotes } from '../../../store/Actions/JobAction';
import Checkbox from '../../../ReuseableComponets/CheckBox';
import AddContactTemplate from './Component/AddContactTemplate';
import BillToComponent from './Component/BillToComponent';
import AnimatedModal from '../../ModalComponent/AnimatedModal';
import { submitBillToDelete } from '../../../store/Actions/ContactAction';
import BillToDetails from './Component/BillToDetails';
import { cn } from '../../../lib/utils';
const filter = createFilterOptions();
const ContactDetails = (props) => {
    const navigate = useNavigate()
    let { number } = useParams()
    const ProgressDataColor = [
        '#007BFF', '#FFC107', '#B02A37', '#DC3545', '#28A745', '#FF851B',
        '#6C757D', '#6610F2', '#20C997', '#FD7E14', '#17A2B8', '#E83E8C',
        '#6F42C1', '#A52A2A'
    ];
    const stripePromise = loadStripe('pk_live_51P9N8zACcdvY3p8RYrzyirkdBENuYjon6a8IpYCsbdpGxIhHGsg6e8zwkpN1MHliCjhhXlm5eGzC4P1qo8IMifHJ00RCjlogPp', {
        stripeAccount: 'acct_1PVFmjPBobLNwj8z'
    });
    const [addresstitle, setAddressTitle] = useState("")
    const [boolValue, setBoolValue] = useState({
        description: false,
        edit: false,
        tag: false,
        customField: false,
        photo: false,
        task: false
    })
    const [address, setAddress] = React.useState({

        "street": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "title": ''

    })
    const [editValue, setEditValue] = useState({
        "name": "",
        "email": "",
        "address": "",
        "to": "",
        "businessName": ''

    })

    const [isModalopen1, setIsModalOpen1] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [value, setValue] = React.useState(null);
    const [loader, setLoader] = React.useState(false);
    const [value1, setValue1] = React.useState(null);
    const [isDelete, setDelete] = useState({})
    const [additionalNotes, setAdditionalNotes] = useState('')
    const [additionalcallNotes, setAdditionalcallNotes] = useState('')
    const [isstartModalopen, setIsstartModalOpen] = useState(false)
    const [isBookingModalopen, setIsBookingModalOpen] = useState(false)
    const userData = useSelector(state => state?.auth?.userData)
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
    const customFieldData = useSelector(state => state?.auth?.userData?.customeFields)
    const contactDetail = useSelector(state => state?.auth?.contactDetail)
    const [customField, setDataCustomField] = useState(customFieldData)
    const [hastTag, setHasTag] = useState(hashTags)
    const [notes, setNotes] = useState('')
    const [selectHash, setSelectedHash] = useState([])
    const [selectCustomField, setCustomField] = useState([])
    const token = useSelector(state => state?.auth?.token)
    const [userDetails, setUserDetails] = useState({})
    const [status, setStatus] = useState('Status')
    const [searchData, setSearchData] = useState([])
    const [tempUserDetails, setTempUserDetails] = useState({})
    const [showMore, setShowMore] = useState(false);
    const itemsToShow = showMore ? selectCustomField.length : 3;
    const [selectedNumber, setSelectedNumber] = useState({})
    const [isModalopenInvoice, setModalOpenInvoice] = useState(false);
    const [isModalopenQuote, setModalOpenQuote] = useState(false);
    const [communication, setCommunication] = useState([])
    const [type, setType] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectedImage, setSelectedImage] = useState([]);
    const [postData, setpostData] = useState([]);
    const [taskData, setTaskData] = useState([])
    const [open, setOpen] = useState(false)
    const refOne = useRef(null)
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [previewURL, setpreviewURL] = useState('')
    const [search, setSearch] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [expanded, setExpanded] = React.useState('panel1');
    const [digitalContract, setDigitalContact] = React.useState([]);
    const [additioncalContact, setAdditionalContact] = useState({
        "contactId": contactDetail?._id,
        "name": "",
        "email": "",
        "phoneNumber": ""
    })

    console.log('contactDetail', contactDetail)
    const handleChange = (panel) => {
        if (panel === expanded) {
            setExpanded(null)
        }
        else {
            setExpanded(panel);
        }
    };

    const open1 = Boolean(anchorEl);
    const openAssign = Boolean(anchorEl1);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClickAssign = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleCloseAssign = () => {
        setAnchorEl1(null);
    };

    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const [values, setValues] = useState({
        date: '',
        time: '',
        taskDescription: '',
        "number": contactDetail?.number,
    })
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const dispatch = useDispatch()

    const init = async () => {
        setLoader(true)
        const res1 = await dispatch(getContactDetailV2(number))
        const res2 = await dispatch(getUserDetails())

        setCommunication(res1?.data[0]?.communication)
        setHasTag(res2?.data?.hashTags)
        setDataCustomField(res2?.data?.customeFields)
        setTempUserDetails(res1?.data[0])
        setUserDetails(res1?.data[0]?.invoiceData)
        setSearchData(res1?.data[0]?.additionalAddress)
        setSelectedHash(res1?.data[0]?.hashTags?.length > 0 ? res1?.data[0]?.hashTags : [])
        setCustomField(res1?.data[0]?.userFields?.length > 0 ? res1?.data[0]?.userFields : [])
        setNotes(res1?.data[0]?.notes)
        setStatus(res1?.data[0]?.status || 'Status')
        setTaskData(res1?.data[0]?.taskIds)
        setEditValue({
            "name": res1?.data[0]?.name || "",
            "email": res1?.data[0]?.email || "",
            "address": res1?.data[0]?.address || "",
            "businessName": res1?.data[0]?.businessName || '',
            "to": res1?.data[0]?.number || '',
            "mainAddress": res1?.data[0]?.mainAddress || '',
            displayName: res1?.data[0]?.displayName || '',
            allowSMSFollowUp: res1?.data[0]?.allowSMSFollowUp || '',
            allowEmailFollowUp: res1?.data[0]?.allowEmailFollowUp || '',
        })

        setLoader(false)

    }
    const formatFullAddress = (addressComponents) => {
        const { street, city,street2, state, postalCode, country } = addressComponents;
        const addressParts = [
          street,
          street2,
          city,
          state && postalCode ? `${state} ${postalCode}` : state || postalCode,
          country
        ].filter(Boolean); 

        return addressParts.join(', ');
      };
    

    const filterData = [
        {
            name: 'All activities',
            image: allactivites
        },
        {
            name: 'Invoices',
            image: invoiceimg
        },
        {
            name: 'Quotes',
            image: quoteimg
        },
        {
            name: 'Bookings',
            image: booking1
        }
    ]

    useEffect(() => {

        init()
    }, []);

    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }

    const changeStatus = async (value) => {
        setStatus(value?.value)

        const res = await dispatch(submitchangeStatus({
            "numberId": contactDetail?._id,
            "status": value
        }))
        init()


    }
    const assignTag = async () => {


        const res = await dispatch(submitassignTag({
            "numberId": contactDetail?._id,
            "hashTags": selectHash
        }))
        init()


    }
    const editContact = async () => {
        await dispatch(submitEditContacts({
            ...editValue,
            "phoneNumber": editValue?.to || "",
            "profilePhoto": "",
            "address": (address?.address)?(address?.address || editValue?.address):formatFullAddress(address),
            "mainAddress": address,
            displayName: `${editValue?.displayName}`,
            "_id": number,

        }))
        init()
    }
    const assignCustomFiled = async () => {

        const res = await dispatch(submitassignCustomField({
            "numberId": contactDetail?._id,
            "customFields": selectCustomField
        }))
        init()


    }
    const addNotes = async () => {

        const res = await dispatch(addDetaisNote({
            "numberId": contactDetail?._id,
            "notes": notes
        }))
        init()


    }
    const onPressAttachPhoto = async () => {
        const detail = {
            "attachment": [
                {

                    "note": values?.attachmentDesc || '',
                    "attachment": selectedImage
                }
            ],
            "_id": contactDetail?._id,
        }

        const res = await dispatch(submitEditContacts(detail));
        init()
    }
    const submitTask = async () => {
        console.log(values, "dfjbfjfbjhb")
        const res = await dispatch(submitAddTask({ ...values, number: editValue?.to }))
        init()


    }

    const isImageUrl = (url) => {

        const hasImageExtension = /\.(jpg|jpeg|png|gif|bmp|webp|tiff)$/i.test(url);
        const commonImagePatterns = /(logo|image|img|picture)/i.test(url);
        return hasImageExtension || commonImagePatterns;
    };
    const addNewHashTag = async (val) => {
        setSelectedHash([...selectHash, val])
        const res = await dispatch(addHasgtag({
            "hashTags": val
        }))
        setHasTag([...hastTag, val])



    }
    const sendchat = async (val) => {

        if (val) {
            await dispatch(sendMessage(
                {
                    "from": `+${localStorage.getItem('number')}`,
                    "to": editValue?.to,
                    "body": val
                }
            ))

        }
        init()
    }
    const editAdditionalContact = async () => {

        const res = await dispatch(saveAdditionalContact({ ...additioncalContact, "contactId": number }))
        console.log(res, "cdddjhb")
        if (res.status === true) {
            init()
            setBoolValue({ ...boolValue, edit: false, error: '' })
        }
        else {
            return setBoolValue({ ...boolValue, error: res?.message })
        }
    }
    const addPhotos = async () => {
        await dispatch(addContactPhotos(number, postData))
        init()
    }

    // const imageHandleChange = (e) => {
    //     const FileList = e.target.files
    //     const imageList = []

    //     Object.keys(FileList).map((item, index) => {
    //         const fileURL = URL.createObjectURL(FileList[item])
    //         imageList.push(fileURL)

    //     })
    //     setpostData([...postData, ...FileList])
    //     setSelectedImage([...selectedImage, ...imageList])
    // }
    const imageHandleChange = async (e) => {
        const FileList = e.target.files
        const imageList = []

        Object.keys(FileList).map((item, index) => {
            const fileURL = URL.createObjectURL(FileList[item])
            imageList.push(fileURL)

        })

        const res = await dispatch(UploadNotesImage([...FileList]));
        setpostData([...postData, ...res?.data])
        setSelectedImage([...selectedImage, ...res?.data])
    }

    const menuItemsc = [
        {
            label: 'Edit',
            icon: <FiEdit style={{ fontSize: '16px', marginRight: '15px', marginTop: '-3px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Delete',
            icon: <FiTrash2 style={{ fontSize: '16px', marginRight: '15px', marginTop: '-3px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'Waiting for confirmation': return '#fa811f';
            case 'Draft': return '#586171';
            case 'Rejected': return '#c0113d';
            default: return '#29740f';
        }
    };
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const searchFilterFunction = (text) => {
        if (text) {
            const newData = tempUserDetails?.additionalAddress?.filter((item) => {
                const nameData = item?.address ? item.address.toUpperCase() : ''.toUpperCase();
                const numberData = item?.title ? item.title.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();

                return (
                    nameData.includes(searchText) ||
                    numberData.includes(searchText)
                );
            });
            setSearchData(newData);
            setSearch(text);
        } else {
            setSearchData(tempUserDetails?.additionalAddress);
            setSearch(text);
        }
    };

    const BookingValue=()=>{
        const hasBillTo = !!tempUserDetails?.billTo;

        const primaryContact = hasBillTo
            ? {
                  name: tempUserDetails?.name,
                  address: tempUserDetails?.address,
                  email: tempUserDetails?.email,
                  businessName: tempUserDetails?.businessName,
                  to: tempUserDetails?.number,
                  serviceAddress:tempUserDetails?.address,
                //   name: tempUserDetails.billTo.name,
                //   address: tempUserDetails.billTo.fullAddress,
                //   email: tempUserDetails.billTo.email,
                //   businessName: tempUserDetails.billTo.businessName,
                //   to: tempUserDetails.billTo.number,
                //  serviceAddress:tempUserDetails?.address,
              }
            : {
                  name: tempUserDetails?.name,
                  address: tempUserDetails?.address,
                  email: tempUserDetails?.email,
                  businessName: tempUserDetails?.businessName,
                  to: tempUserDetails?.number,
                  serviceAddress:tempUserDetails?.address,
              };

        const associatedContact = hasBillTo
            ? {
                            name: tempUserDetails.billTo.name,
                  fullAddress: tempUserDetails.billTo.fullAddress,
                  email: tempUserDetails.billTo.email,
                  businessName: tempUserDetails.billTo.businessName,
                  number: tempUserDetails.billTo.number,
                 serviceAddress:tempUserDetails?.address,
              }
            : {};


            return {...primaryContact,associatedContact:associatedContact}
    }

    return (
        <>
            <Body>
                {loader ? <div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </div> : <Main>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => navigate(-1)}>
                                <ArrowBackIos style={{ fontSize: '16px' }} />
                            </CloseButton>

                        </div>
                    </HeaderContainer>
                    <Felxbox>
                        <div style={{ width: '450px', display: 'flex', flexDirection: 'column', }}>
                            <WrapperHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <ValueName style={{ fontWeight: '500', fontSize: '16px', color: palette.black }}>{tempUserDetails?.displayName ? tempUserDetails?.businessName : editValue?.name}</ValueName>
                                    <CustomizedMenu
                                        buttonStyle={{
                                            backgroundColor: 'transparent',
                                            minWidth: 'auto',
                                            padding: '2px',
                                            border: 'none',
                                        }}
                                        menuItems={menuItemsc}
                                        onClick={(lab) => {
                                            if (lab === 'Delete') {
                                                setDelete({
                                                    delete: true,
                                                    id: number,
                                                    type: 'Delete'

                                                })
                                            }
                                            else {
                                                setBoolValue({ ...boolValue, editcontact: true })
                                                setAddress({ ...tempUserDetails?.mainAddress, address: tempUserDetails?.address })
                                            }
                                        }}
                                    />
                                </div>
                                {editValue?.businessName && <p className='text'>{!tempUserDetails?.displayName ? tempUserDetails?.businessName : editValue?.name}</p>}
                                {editValue?.address && <p className='text'>{editValue?.address}</p>}
                                {editValue?.email && <p className='text'>{editValue?.email}</p>}
                                {editValue?.to && <p className='text'>{formatMobileNumber(editValue?.to)}</p>}

                            </WrapperHeader>
                            <Selectbox
                                style={{ borderRadius: '20px', marginTop: '0px', }}
                                placeholder='select' value={'Select an option'} displayEmpty

                                renderValue={() =>
                                    <div style={{ fontSize: '14px' }} className='keyContainer'>{status !== 'Status' && <a style={{ fontSize: '18px', color: tempUserDetails?.statusColor ? tempUserDetails?.statusColor : status === 'Potential' ? ProgressDataColor[0] : status === 'In-Progress' ? ProgressDataColor[1] : status === 'Qualified' ? ProgressDataColor[2] : status === 'Bad fit' ? ProgressDataColor[3] : status === 'Customer' ? ProgressDataColor[4] : ProgressDataColor[5] }}> {'●  '}</a>}

                                        {status}
                                    </div>} onChange={(e) => {
                                        console.log(e.target)
                                        changeStatus(e.target.value)

                                    }}
                                sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,

                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,

                                    },
                                }}

                                MenuProps={{ // Add MenuProps to customize menu styling
                                    PaperProps: {
                                        style: {
                                            marginTop: '8px', // Adjust this value for the desired gap
                                        },
                                    },
                                }}
                            >
                                {userData?.statusOptions?.map((item, index) =>
                                    <StyledMenuItem key={index} value={item}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', height: '32px', alignItems: 'center', width: '100%' }}>

                                            <p style={{ fontSize: '14px', textAlign: 'start' }}><a style={{ fontSize: '18px', color: item?.color || ProgressDataColor[index] }}>{'●  '}</a> {item?.value}</p>



                                        </div>
                                    </StyledMenuItem>
                                )}
                            </Selectbox>

                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Add contact
                                    </h5>
                                    <EditContainer onClick={() => {
                                        setBoolValue({ ...boolValue, edit: true })
                                        setAdditionalContact({})
                                    }}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.edit ?
                                    contactDetail?.additionalContact?.map((item) =>
                                        <WrapperHeader style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '5px', paddingRight: '15px' }}>
                                                <div>
                                                    <ValueName style={{ fontWeight: '600', fontSize: '13px', marginBottom: '5px', color: palette.black }}>{item?.name}</ValueName>
                                                    {item?.number && <p className='text'>{formatMobileNumber(item?.number)}</p>}
                                                    {item?.companyTitle && <p className='text'>{item?.companyTitle}</p>}
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <Call onClick={() => {
                                                        const call = token.connect({
                                                            params: {
                                                                to: item?.number,
                                                                from: `+${localStorage.getItem('number')}`
                                                            }
                                                        })

                                                        dispatch({ type: "CONNECTION", payload: call })
                                                        dispatch({
                                                            type: "CALLDETAIL", payload: {
                                                                name: item?.name,
                                                                to: item?.number,
                                                            }
                                                        })
                                                        dispatch({ type: "CALLSHOW", payload: true })
                                                    }} style={{ fontSize: '16px', marginRight: '15px', cursor: 'pointer' }} />
                                                    <MessageOutlined onClick={() => {
                                                        setSelectedNumber(item)
                                                        setModalOpen(true)
                                                    }} style={{ fontSize: '16px', marginRight: '15px', cursor: 'pointer' }} />
                                                    <FiTrash2 style={{ cursor: 'pointer' }} onClick={async () => {
                                                        await dispatch(deleteAdditionalContact({
                                                            id: number,
                                                            contactId: item?._id
                                                        }))
                                                        init()
                                                    }} />
                                                </div>
                                            </div>
                                            {/* <MoreIcon style={{ width: '20px' }} src={notebook} /> */}
                                        </WrapperHeader>
                                    )

                                    :
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', width: '280px' }}>


                                        <InputBox
                                            header={"Name"}
                                            placeholder="Name"
                                            type="text"
                                            value={additioncalContact?.name}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, name: e.target.value
                                                })
                                            }}

                                        />

                                        <InputBox
                                            header={"Email"}
                                            placeholder="Email"
                                            type="text"
                                            value={additioncalContact?.email}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, email: e.target.value
                                                })
                                            }}

                                        />
                                        <Label style={{ marginTop: '15px' }}>Phone number</Label>
                                        <PhoneFiled

                                            placeholder="Enter phone number"
                                            value={additioncalContact?.phoneNumber}
                                            onChange={(val) => {

                                                setAdditionalContact({
                                                    ...additioncalContact, phoneNumber: val
                                                })

                                            }}

                                            defaultCountry="US"
                                            countryCallingCodeEditable={false}
                                            style={{
                                                width: '308px', borderRadius: '5px', '&.PhoneInputInput': {
                                                    width: '100%'
                                                }
                                            }}

                                            international
                                            focusInputOnCountrySelection='true'
                                            tabIndex="0"
                                        />
                                        {/* <InputBox
                                            header={"Phone number"}
                                            placeholder="Phone number"
                                            type="text"
                                            value={additioncalContact?.phoneNumber}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, phoneNumber: e.target.value
                                                })
                                            }}

                                        /> */}
                                        <InputBox
                                            header={"Title"}
                                            placeholder="Sales & Marketing"
                                            type="text"
                                            value={additioncalContact?.title}
                                            style={{ height: '10px' }}
                                            onChange={(e) => {
                                                setAdditionalContact({
                                                    ...additioncalContact, title: e.target.value
                                                })
                                            }}

                                        />
                                        {boolValue?.error && <Label style={{ color: 'red' }}>{`⚠ ${boolValue?.error}`}</Label>}

                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '-20px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, edit: false, error: '' })}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                // setEditValue({ ...editValue, ...editValue })
                                                editAdditionalContact()

                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5>Bill to</h5>
                                    <AnimatedModal
                                        buttonStyles={{
                                            customStyles: { margin: 0, padding: 0 },
                                        }}

                                        buttonContent={
                                            tempUserDetails?.billTo?<Link>View details</Link>:<EditContainer style={{ background: '#f8f8f8' }} onClick={() => setBoolValue({ ...boolValue, billTo: true })}>
                                                { <AddOutlined style={{ fontSize: '16px' }} />}
                                            </EditContainer>
                                        }
                                        modalContent={(close) => {
                                            return (
                                                tempUserDetails?.billTo?<BillToDetails
                                                invoices={tempUserDetails?.billToInvoices}
                                                estimate={tempUserDetails?.billToQuotes}
                                                onCancel={()=> close()}
                                                />:  <BillToComponent
                                                    onSubmit={() => {
                                                        init()
                                                        close()
                                                    }}
                                                    onCancel={()=> close()}
                                                    contactId={number}
                                                />
                                            );
                                        }}
                                    />

                                </div>
                              {tempUserDetails?.billTo&&  <WrapperHeader style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '5px', paddingRight: '15px' }}>
                                        <div>
                                            <ValueName style={{ fontWeight: '600', fontSize: '13px', marginBottom: '5px', color: palette.black }}>{tempUserDetails?.billTo?.name}</ValueName>
                                            {tempUserDetails?.billTo?.businessName && <p className='text'>{tempUserDetails?.billTo?.businessName}</p>}
                                            {tempUserDetails?.billTo?.fullAddress && <p className='text'>{tempUserDetails?.billTo?.fullAddress}</p>}
                                            {tempUserDetails?.billTo?.email && <p className='text'>{tempUserDetails?.billTo?.email}</p>}
                                            {tempUserDetails?.billTo?.number && <p className='text'>{formatMobileNumber(tempUserDetails?.billTo?.number)}</p>}
                                           
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                         
                                            <FiTrash2 style={{ cursor: 'pointer' }} onClick={async () => {
                                             await dispatch(submitBillToDelete(number))
                                             init()
                                            }} />
                                        </div>
                                    </div>
                                    {/* <MoreIcon style={{ width: '20px' }} src={notebook} /> */}
                                </WrapperHeader>}
                            </StyleBox>

                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5>Tags</h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, tag: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.tag ?
                                    selectHash?.length > 0 ?
                                        <Wrapper>
                                            {selectHash?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>
                                                    {item}
                                                </WrapperContainer>
                                            )}
                                        </Wrapper> : null :
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>
                                        <AutocompleteComponent
                                            value={value}
                                            onChange={(result) => {
                                                setValue('');
                                                setSelectedHash([...selectHash, result])
                                            }}
                                            onNewChange={async (result) => {
                                                setValue('');
                                                addNewHashTag(result)
                                            }}
                                            options={hastTag}
                                            placeholder="Search or create a tag"
                                            width={'98%'}
                                            marginLeft="0px"
                                            inputHeight="25px"
                                            inputFontSize="13px"

                                        />

                                        {selectHash?.length > 0 ? <Wrapper style={{ marginTop: '25px' }}>
                                            {selectHash?.map((item, index) =>
                                                <WrapperContainer style={{ background: colorThemes[index % colorThemes?.length]?.background, color: colorThemes[index % colorThemes?.length]?.text, border: `1px solid ${colorThemes[index % colorThemes?.length]?.outline}` }}>{item} <Cancel style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                    const arr = selectHash?.filter(obj => obj !== item)
                                                    setSelectedHash(arr)
                                                }} src={cross} /></WrapperContainer>
                                            )}
                                        </Wrapper> : null}
                                        <Divider style={{ marginTop: selectHash?.length > 0 ? '0px' : '30px' }} />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '10px', paddingRight: '15px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setSelectedHash(tempUserDetails?.hashTags)
                                                setBoolValue({ ...boolValue, tag: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                assignTag()
                                                setBoolValue({ ...boolValue, tag: false })
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>

                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Tasks
                                    </h5>
                                    <EditContainer onClick={() => {
                                        setValues({})
                                        setBoolValue({ ...boolValue, isTask: true, taskData: null })
                                    }}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {taskData?.length > 0 ? <Wrapper>
                                    {taskData?.map((item, index) =>
                                        <div className='valueContainer' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div style={{ width: '90%' }}>
                                                <Label style={{ cursor: 'pointer' }} onClick={() => {

                                                    setBoolValue({ ...boolValue, isTask: true, taskData: item })
                                                }}>{item?.task}</Label>
                                                <Label style={{ marginTop: '5px', fontSize: '12px', color: palette.grey }}> {`${moment(item?.date)?.format('DD MMM YYYY')} at ${moment(item?.time)?.format('hh:mm A') === 'Invalid date' ? item?.time : moment(item?.time)?.format('hh:mm A')}`}</Label>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <Check
                                                    onClick={async () => {
                                                        await dispatch(DoneAddTask(item?._id))
                                                        init()
                                                    }}
                                                    style={{ fontSize: '20px', color: '#000', marginLeft: '15px' }} src={check} />

                                                <FiTrash2 style={{ color: '#000', width: '18px', marginLeft: '20px' }} onClick={async () => {
                                                    await dispatch(deleteAddTask(item?._id))
                                                    init()
                                                }} src={trash} />
                                            </div>
                                        </div>
                                    )}
                                </Wrapper> : null
                                    // <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                    //     <InputText
                                    //         placeholder="Description"
                                    //         type="text"
                                    //         value={values?.taskDescription}
                                    //         onChange={(e) => { setValues({ ...values, taskDescription: e.target.value }) }}
                                    //     />

                                    //     <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center' }}>

                                    //         <DatePickerComponent
                                    //             label="Date"
                                    //             selectedDate={values?.date}
                                    //             onDateChange={(text) => setValues({ ...values, date: text })}
                                    //         />
                                    //         <div style={{ position: 'relative' }}>
                                    //             <Label style={{ marginLeft: '15px', fontWeight: '600' }}>Time</Label>
                                    //             <SelectTimebox
                                    //                 renderValue={() =>
                                    //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                    //                         {values?.time || 'Select time'}
                                    //                         <Schedule style={{ fontSize: '16px', position: 'absolute', right: 10 }} />
                                    //                     </div>
                                    //                 }
                                    //                 defaultValue={"REAL_ESTATE"} onChange={(event) => setValues({ ...values, time: event.target.value })}>
                                    //                 {[...TimeListAm, ...TimeListPM].map((item, index) =>
                                    //                     <StyledMenuItem key={item.value} style={{ margin: '10px' }} value={item}>{item}</StyledMenuItem>
                                    //                 )}
                                    //             </SelectTimebox>

                                    //         </div>
                                    //     </div>


                                    //     <div style={{ alignSelf: 'flex-end', display: 'flex', paddingTop: '20px', marginRight: '30px', justifyContent: 'flex-end', }}>
                                    //         <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                    //             setSelectedHash(tempUserDetails?.hashTags)
                                    //             setBoolValue({ ...boolValue, task: false })
                                    //         }}>Cancel</RippleButton>
                                    //         <RippleButton style={{ marginRight: -15 }} onClick={() => {
                                    //             submitTask()
                                    //             setBoolValue({ ...boolValue, task: false })

                                    //         }}>Save</RippleButton>
                                    //     </div>
                                    // </div>

                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Custom fields
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, customField: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.customField ? selectCustomField?.length > 0 ? <>
                                    {selectCustomField?.slice(0, itemsToShow).map((item, index) => (
                                        <CustomFieldWrapper>
                                            <Tooltip arrow placement='top' title={item?.fieldName}>
                                                <p className='fieldName'>{item?.fieldName}</p>
                                            </Tooltip>
                                            <p className='fieldValue'>{item?.fieldValue?.replace(/[\[\]"]/g, '').replace(/,/g, ', ')}</p>
                                        </CustomFieldWrapper>
                                    ))}
                                    {selectCustomField.length > 3 && (
                                        <OptionBox style={{ alignSelf: 'end', margin: '15px' }} onClick={toggleShowMore}>{showMore ? 'Show Less' : 'Show More'}</OptionBox>
                                    )}
                                </>
                                    : null
                                    : <div className='valueContainer' style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '10px' }}>

                                        <Autocomplete1
                                            value={value1}
                                            onChange={(event, newValue) => {

                                                const searchString = 'Create ';
                                                const replacement = '';
                                                const result = newValue?.fieldName?.replace(searchString, replacement)?.replace(/"/g, '');

                                                if (typeof newValue === 'string') {
                                                    setValue1('');
                                                    setCustomField([...selectCustomField, { fieldName: 'cdsf' }])
                                                } else if (newValue && newValue.fieldName) {
                                                    setCustomField([...selectCustomField, { fieldName: newValue?.fieldName, fieldValue: newValue?.fieldValue, fieldType: newValue?.fieldType, options: newValue?.options }])
                                                    setValue1('');
                                                } else {
                                                    setValue1('');

                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="free-solo-with-text-demo"
                                            options={customField?.filter((item => item?.type !== 'job'))}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                if (option?.fieldName) {
                                                    return option.fieldName;
                                                }
                                                return '';
                                            }}
                                            renderOption={(props, option) => <li style={{ fontSize: '13px' }}  {...props}>{option?.fieldName}</li>}
                                            sx={{
                                                width: 315, marginLeft: '12px', '& input': {
                                                    height: '5px', fontSize: '13px',


                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#ccc',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#999',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#000',
                                                    },
                                                }

                                            }}
                                            freeSolo
                                            style={{ height: 30, marginLeft: '10px', marginBottom: '0px', fontSize: '13px' }}
                                            renderInput={(params) => (
                                                <TextField style={{ fontSize: '13px' }} {...params} placeholder="Search custom field" />
                                            )}
                                        />
                                        <div style={{ marginTop: '20px', marginBottom: '10px', color: '#4169e1', cursor: 'pointer' }} onClick={() => setIsModalOpen1(true)}>Create new custom field</div>
                                        {selectCustomField?.length > 0 ? <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px', paddingBottom: '20px', marginLeft: '12px', width: '325px' }}>

                                            {selectCustomField?.map((item, index) =>
                                                <div style={{ display: 'flex', alignItems: 'center', width: '310px', }}>
                                                    {item?.fieldType === "dropdownSingle" ?
                                                        <div style={{ width: '100%', marginBottom: '10px' }}>
                                                            <Label style={{ fontWeight: '550' }}>{item?.fieldName}</Label>

                                                            <SelectDrpoDown
                                                                id="category-visibility"
                                                                value={item?.fieldValue}
                                                                style={{ width: '110%' }}
                                                                defaultValue=''
                                                                onChange={(e) => {
                                                                    const data = [...selectCustomField]
                                                                    data[index].fieldValue = e.target.value
                                                                    data[index].fieldType = item?.fieldType
                                                                    setCustomField(data)

                                                                }}
                                                            >
                                                                <Option value="" disabled>
                                                                    Choose value
                                                                </Option>

                                                                {
                                                                    item?.options?.map((vars, index) => (
                                                                        <Option key={index} value={vars}>
                                                                            {vars}
                                                                        </Option>
                                                                    ))
                                                                }
                                                            </SelectDrpoDown>

                                                        </div> : item?.fieldType === "dropdownMultiple" ?
                                                            <div style={{ width: '84%', marginBottom: '10px' }}>
                                                                <Label style={{ fontWeight: '550' }}>{item?.fieldName}</Label>
                                                                <MultiSelectWithSearch
                                                                    options={item?.options}
                                                                    containerStyle={{ width: '97%' }}
                                                                    dropDownStyle={{ width: '96%' }}
                                                                    onSelectionChange={(value) => {
                                                                        const data = [...selectCustomField]
                                                                        data[index].fieldValue = JSON.stringify(value)
                                                                        data[index].fieldType = item?.fieldType
                                                                        setCustomField(data)
                                                                    }}

                                                                />


                                                            </div> : <div style={{ width: '100%', marginBottom: '10px' }}>
                                                                <Label>{item?.fieldName}</Label>
                                                                <InputBox

                                                                    placeholder="Value"
                                                                    type="text"
                                                                    value={item?.fieldValue}
                                                                    style={{ height: '15px', width: '100%' }}
                                                                    onChange={(e) => {
                                                                        const data = [...selectCustomField]
                                                                        data[index].fieldValue = e.target.value
                                                                        data[index].fieldType = item?.fieldType
                                                                        setCustomField(data)
                                                                    }}


                                                                />
                                                            </div>}
                                                    <FiTrash2 style={{ marginLeft: '30px', fontSize: '20px', cursor: 'pointer', marginTop: '10px' }} onClick={() => {
                                                        const data = [...selectCustomField]
                                                        data.splice(index, 1)
                                                        setCustomField(data)
                                                    }} /></div>
                                            )}
                                        </div> : null}
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setCustomField(tempUserDetails?.userFields)
                                                setBoolValue({ ...boolValue, customField: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                assignCustomFiled()
                                                setBoolValue({ ...boolValue, customField: false })
                                                // setValue({ ...value, ...editValue })
                                                // setEditDetail(false)
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                }
                            </StyleBox>
                            {(localStorage.getItem('number') === '16043326202' || localStorage.getItem('number') === '12086688040' || localStorage.getItem('number') === '12085029077' || localStorage.getItem('number') === '19372000612' || localStorage.getItem('number') === '19074166661') && <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Payment Methods
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, addPayment: true })}>
                                        {tempUserDetails?.stripePaymentMethods?.length > 0 ? <Edit style={{ fontSize: '16px' }} /> : <AddOutlined style={{ fontSize: '16px' }} />}
                                    </EditContainer>
                                </div>
                                {tempUserDetails?.stripePaymentMethods?.length > 0 ?
                                    <div >
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                            </div>
                                            <CustomFieldWrapper style={{ width: '100%' }}>
                                                <Tooltip arrow placement='top' title={"Accountholder name"}>
                                                    <p className='fieldName'>{"Cardholder Name"}</p>
                                                </Tooltip>
                                                <p className='fieldValue'>{tempUserDetails?.stripePaymentMethods?.[0]?.name}</p>
                                            </CustomFieldWrapper>
                                            <CustomFieldWrapper style={{ width: '100%', alignItems: 'center' }}>
                                                <Tooltip arrow placement='top' title={"Card number"}>
                                                    <p className='fieldName'>{"Card number"}</p>
                                                </Tooltip>
                                                <p className='fieldValue'>{` ************ `}
                                                    {tempUserDetails?.stripePaymentMethods?.[0]?.last4} {<img style={{width:'20px',height:'20px',marginRight:'5px'}} src={cardBrandImages[`${tempUserDetails?.stripePaymentMethods?.[0]?.brand}`]}/>}
                                                </p>
                                            </CustomFieldWrapper>
                                            {/* <CustomFieldWrapper style={{width:'100%'}}>
                                            <Tooltip arrow placement='top' title={"Card type"}>
                                                <p className='fieldName'>{"Card type"}</p>
                                            </Tooltip>
                                            <p className='fieldValue'>{tempUserDetails?.stripePaymentMethods?.[2]?.brand}</p>
                                        </CustomFieldWrapper> */}
                                        </div>

                                    </div> : <p style={{ color: 'gray' }} className='valueContainer' >{"There's no payment method saved on file for this client."}</p>}
                            </StyleBox>}
                            {(localStorage.getItem('number') === '16043326202' || localStorage.getItem('number') === '12086688040' || localStorage.getItem('number') === '12085029077' || localStorage.getItem('number') === '19372000612' || localStorage.getItem('number') === '19074166661') &&
                                <StyleBox>
                                    <div className='keyContainer'>
                                        <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                            Service Plans
                                        </h5>
                                        <EditContainer onClick={() => setBoolValue({ ...boolValue, addplans: true })}>
                                            {tempUserDetails?.subscription?.[0]?.priceId ? <Edit style={{ fontSize: '16px' }} /> : <AddOutlined style={{ fontSize: '16px' }} />}
                                        </EditContainer>
                                    </div>
                                    {tempUserDetails?.subscription?.[0]?.priceId ?
                                        <div style={{ padding: '10px 20px' }} >

                                            <Label className='fieldName'>{tempUserDetails?.subscription[0]?.name}</Label>
                                            <Label style={{ color: 'gray', fontWeight: '400' }} className='fieldName'>${putCommaInvalue(parseFloat(tempUserDetails?.subscription[0]?.amount)?.toFixed(2))} per {tempUserDetails?.subscription[0]?.interval?.intervalCount > 1 ? `${tempUserDetails?.subscription[0]?.interval?.intervalCount} ${tempUserDetails?.subscription[0]?.interval?.interval}s` : tempUserDetails?.subscription[0]?.interval?.interval} ({tempUserDetails?.subscription[0]?.discount}% discount)</Label>

                                        </div> : <p style={{ color: 'gray' }} className='valueContainer' >{"No service plans are currently available. Add a new plan to get started."}</p>}
                                </StyleBox>
                            }
                            {/* <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Digital contract
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, addContract: true })}>
                                        {tempUserDetails?.subscription?.[0]?.priceId ? <Edit style={{ fontSize: '16px' }} /> : <AddOutlined style={{ fontSize: '16px' }} />}
                                    </EditContainer>
                                </div>
                             
                                {tempUserDetails?.subscription?.[0]?.priceId ?
                                    <div style={{ padding: '10px 20px' }} >

                                        <Label className='fieldName'>{tempUserDetails?.subscription[0]?.name}</Label>
                                        <Label style={{ color: 'gray', fontWeight: '400' }} className='fieldName'>${putCommaInvalue(parseFloat(tempUserDetails?.subscription[0]?.amount)?.toFixed(2))} per {tempUserDetails?.subscription[0]?.interval?.intervalCount > 1 ? `${tempUserDetails?.subscription[0]?.interval?.intervalCount} ${tempUserDetails?.subscription[0]?.interval?.interval}s` : tempUserDetails?.subscription[0]?.interval?.interval} ({tempUserDetails?.subscription[0]?.discount}% discount)</Label>

                                    </div> : null}
                            </StyleBox> */}
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Account Notes
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, description: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {!boolValue?.description ? notes ? <p className='valueContainer' >{notes}</p> : null :
                                    <>
                                        <div className='valueContainer' style={{ flexDirection: 'column', alignItems: 'center', marginBottom: '10px', }}>

                                            <InputText
                                                placeholder="Note"
                                                type="text"
                                                value={notes}
                                                onChange={(e) => {
                                                    setNotes(
                                                        e.target.value
                                                    )
                                                }}

                                            />
                                        </div>
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', marginTop: '10px', marginBottom: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setNotes(tempUserDetails?.notes)
                                                setBoolValue({ ...boolValue, description: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                addNotes()
                                                setBoolValue({ ...boolValue, description: false })

                                            }}>Save</RippleButton>
                                        </div>
                                    </>
                                }
                            </StyleBox>
                            <StyleBox>
                                <div className='keyContainer'>
                                    <h5 style={{ marginTop: '0px', marginBottom: '0px' }}>
                                        Photos / Attachments
                                    </h5>
                                    <EditContainer onClick={() => setBoolValue({ ...boolValue, photo: true })}>
                                        <AddOutlined style={{ fontSize: '16px' }} />
                                    </EditContainer>
                                </div>
                                {boolValue?.photo ? <>
                                    <div className='valueContainer' style={{ flexDirection: 'column', marginBottom: '10px' }}>
                                        <InputText
                                            placeholder="Note"
                                            type="text"
                                            style={{ width: '90%', marginLeft: '0px', marginTop: '20px' }}
                                            // value={editValue?.email}

                                            onChange={(e) => {
                                                setValues({ ...values, attachmentDesc: e.target.value }

                                                )
                                            }}

                                        />
                                        <h5 style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'start', marginLeft: '10px' }}>Attach files</h5>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px', paddingRight: '10px' }}>
                                            {selectedImage?.map((item) =>
                                                <div style={{ flexWrap: 'wrap', position: 'relative', overflow: 'hidden' }}>
                                                    <PhotosStyle src={isImageUrl(item) ? item : pdf} />
                                                    <div
                                                        onClick={() => {
                                                            const arr = selectedImage?.filter(obj => obj !== item)
                                                            setSelectedImage(arr)
                                                        }}
                                                        style={{ position: 'absolute', display: 'flex', top: 0, right: 5, background: 'white', borderRadius: 10 }}>
                                                        <MoreIcon style={{ width: '15px' }} src={cross} />
                                                    </div>
                                                </div>
                                            )}

                                            <FileInputButton htmlFor="fileInput">
                                                <MoreIcon src={plus} />
                                            </FileInputButton>
                                            <FileInput
                                                id="fileInput"
                                                type="file"
                                                accept="image/*,.pdf"
                                                multiple
                                                onChange={imageHandleChange}
                                            />
                                        </div>
                                        <Divider />
                                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginRight: '15px', paddingTop: '10px', justifyContent: 'flex-end', }}>
                                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                setSelectedImage([])
                                                setBoolValue({ ...boolValue, photo: false })
                                            }}>Cancel</RippleButton>
                                            <RippleButton onClick={() => {
                                                onPressAttachPhoto()
                                                setBoolValue({ ...boolValue, photo: false })

                                                // setValue({ ...value, ...editValue })
                                                // setEditDetail(false)
                                            }}>Save</RippleButton>
                                        </div>
                                    </div>
                                </> : tempUserDetails?.attachment?.length > 0 ? <div className='valueContainer' style={{ flexDirection: 'column', }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 0px', }}>
                                        {tempUserDetails?.attachment?.length > 0 && [...tempUserDetails?.attachment]?.map((item, index) =>
                                            <>
                                                <div style={{ width: '100%', display: 'flex', marginBottom: '15px', marginTop: '10px', alignItems: 'flex-start', }}>
                                                    <div style={{ width: '90%', }}>
                                                        <p style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px' }} >{item?.note || `Note #${index + 1}`}</p>
                                                        {item?.createdAt && <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '11px', textAlign: 'start' }} >{moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}</p>}
                                                    </div>
                                                    <IconButton onClick={async () => {
                                                        await dispatch(deleteAttachmentnotes({
                                                            _id: number,
                                                            attachmentId: item?._id
                                                        }))
                                                        init()
                                                    }}>
                                                        <FiTrash2 style={{ fontSize: '16px' }} />
                                                    </IconButton>
                                                </div>
                                                {item?.attachment?.map((items) =>
                                                    <PhotosStyle onClick={() => {
                                                        window.open(items)
                                                    }} src={isImageUrl(items) ? items : pdf} />

                                                )}

                                            </>)}

                                    </div>
                                </div> : null}
                            </StyleBox>
                        </div>
                        {

                            <StyleRight>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flex: "1", marginBottom: '10px', marginTop: '20px' }}>
                                    <div style={{ display: 'flex' }}>

                                        <OptionBox onClick={() => {
                                            const call = token.connect({
                                                params: {
                                                    to: editValue?.to,
                                                    from: `+${localStorage.getItem('number')}`
                                                }
                                            })

                                            dispatch({ type: "CONNECTION", payload: call })
                                            dispatch({
                                                type: "CALLDETAIL", payload: {
                                                    name: editValue?.name,
                                                    to: editValue?.to,
                                                }
                                            })
                                            dispatch({ type: "CALLSHOW", payload: true })
                                        }}>
                                            <Call style={{ marginRight: '10px', fontSize: '16px' }} />
                                            Call
                                        </OptionBox>
                                        <OptionBox onClick={() => {
                                            setSelectedNumber(editValue)
                                            setModalOpen(true)

                                        }}>
                                            <MessageOutlined style={{ marginRight: '10px', fontSize: '16px' }} />
                                            SMS
                                        </OptionBox>
                                        <OptionBox onClick={handleClickAssign}>
                                            <Person style={{ marginRight: '10px', fontSize: '16px' }} />
                                            {tempUserDetails?.assignedUser || "All user"}
                                        </OptionBox>
                                        <Menu
                                            anchorEl={anchorEl1}
                                            id="account-menu"
                                            open={openAssign}
                                            onClose={handleCloseAssign}
                                            onClick={handleCloseAssign}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    minWidth: '180px',

                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <div style={{
                                                maxHeight: '300px',
                                                overflowY: 'scroll',
                                            }}>
                                                {
                                                    userData?.crewMembers?.map((item, index) => <MenuItem style={{ color: palette.black, fontSize: '14px' }} onClick={async () => {
                                                        await dispatch(submitEditContacts({
                                                            "assignedUser": item?._id,
                                                            "_id": number,
                                                        }))
                                                        init()
                                                    }}>
                                                        <Avatar style={{ height: '22px', width: '22px', background: calendarTeamColor[index]?.light }} color={dayViewColor[index]}>
                                                            <Typography style={{ fontSize: '10px', fontWeight: '600', color: calendarTeamColor[index]?.original }}>{getName(`${item?.firstName} ${item?.lastName || ''}`)}</Typography>
                                                        </Avatar>
                                                        <Typography style={{ fontSize: '13px', color: (`${item?.firstName} ${item?.lastName}`) === tempUserDetails?.assignedUser ? 'black' : 'grey' }}>{item?.firstName} {item?.lastName || ''}</Typography>
                                                    </MenuItem>)
                                                }
                                            </div>




                                        </Menu>
                                    </div>
                                    <div>
                                        <Tooltip arrow title='Add' placement="top">
                                            <IconButton style={{
                                                width: '30px',
                                                height: '30px', marginRight: '12px'
                                            }} onClick={handleClick} >
                                                <Add style={{ fontSize: '20px' }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open1}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            {<MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                // setType(false)
                                                // setModalData({})
                                                // setModalOpenQuote(true)
                                                // setAnchorEl(null)
                                                navigate(`/dashboard/quotes/new?contactId=${tempUserDetails?._id}`)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={quoteIconColor} />
                                                Create estimate
                                            </MenuItem>}
                                            <MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                setType(false)
                                                setIsBookingModalOpen(true)
                                                setAnchorEl(null)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={jobColor} />
                                                Create job
                                            </MenuItem>
                                            <MenuItem style={{ color: palette.newBlue, fontSize: '14px' }} onClick={() => {
                                                navigate(`/dashboard/invoices/new?contactId=${tempUserDetails?._id}`)
                                                // setType(false)
                                                // setModalOpenInvoice(true)
                                                // setAnchorEl(null)
                                            }}>
                                                <Icons style={{ width: '15px', height: '15px', marginRight: '12px' }} src={InvoiceColor} />
                                                Create invoice
                                            </MenuItem>



                                        </Menu>

                                        {/* <OptionBox onClick={() => {
                                    setType(false)
                                    setModalOpenQuote(true)
                                }}>
                                    <OptionIcon style={{ marginRight: '10px' }} src={payment} />
                                    Quote
                                </OptionBox>
                                <OptionBox onClick={() => {
                                    setType(false)
                                    setIsBookingModalOpen(true)
                                }}>
                                    <OptionIcon style={{ marginRight: '10px', }} src={payment} />
                                    Job
                                </OptionBox> */}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>

                                    <div style={{ display: 'flex' }}>
                                        {/* <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '25px', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MoreIcon style={{ width: '15px', marginRight: '8px' }} src={flag} />
                                                    {filters}
                                                </div>
                                            )} onChange={(e) => {
                                                console.log(e.target)

                                            }}
                                            sx={{
                                                boxShadow: "none",
                                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,

                                                },
                                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,

                                                },
                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {filterData?.map((item, index) =>
                                                <StyledMenuItem key={index} value={index}>
                                                    <ContainerBox
                                                        onClick={() => {
                                                            setFilters(item?.name)
                                                            if (item?.name === 'All activities') {
                                                                setSearchData(userDetails)
                                                            }
                                                            else if (item?.name === 'Invoices') {
                                                                const data = userDetails?.filter(item => item?.invoiceStatus)
                                                                setSearchData(data)
                                                            }
                                                            else if (item?.name === 'Quotes') {
                                                                const data = userDetails?.filter(item => item?.status)
                                                                setSearchData(data)
                                                            }
                                                            else {
                                                                const data = userDetails?.filter(item => item?.bookingStatus)
                                                                setSearchData(data)
                                                            }

                                                        }}
                                                        style={{ color: filters === item?.name ? '#4169e1' : '#000' }}>

                                                        <FilerIcon src={item?.image} />
                                                        {item?.name}</ContainerBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox> */}

                                        {/* <StyledDiv>
                                            <CalendarMonthOutlined style={{ fontSize:'16px', marginRight: '10px' }} src={calenda} />
                                            <CustomUrl
                                                value={range1[0]?.startDate ? `${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}` : 'Select date'}
                                                readOnly
                                                style={{ width: !range1[0]?.startDate && '70px' }}

                                                onClick={() => setOpen(open => !open)}
                                            />
                                        </StyledDiv> */}



                                        {open &&
                                            <>
                                                <StyleBox style={{ position: 'absolute', marginTop: '30px', background: 'white' }} ref={refOne}>
                                                    <DateRange
                                                        style={{ height: '220px' }}
                                                        onChange={item => setRange([item.selection])}
                                                        editableDateInputs={true}
                                                        moveRangeOnFirstSelection={false}

                                                        ranges={range}
                                                        months={1}
                                                        direction="horizontal"
                                                        className="calendarElement"
                                                    />
                                                    <ButtonBox>
                                                        <SendButton1 style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: '',
                                                                endDate: ''
                                                            }])
                                                            setRange([
                                                                {
                                                                    startDate: new Date(),
                                                                    endDate: addDays(new Date(), 3),
                                                                    key: 'selection'
                                                                }
                                                            ])
                                                            setSearchData(userDetails)
                                                        }}>Clear</SendButton1>
                                                        <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: range?.[0]?.startDate,
                                                                endDate: range?.[0]?.endDate
                                                            }])
                                                            const filteredData = userDetails.filter(item => {
                                                                const itemDate = moment(item.date).format('YYYY-DD-MM');
                                                                return itemDate >= moment(range?.[0]?.startDate)?.format('YYYY-DD-MM') && itemDate <= moment(range?.[0]?.endDate)?.format('YYYY-DD-MM');
                                                            });
                                                            setSearchData(filteredData)
                                                            // const res = await dispatch(createNewCustomField({
                                                            //     "fields": [
                                                            //         newCutomField
                                                            //     ]
                                                            // }))
                                                            // setDataCustomField([...customField, newCutomField])
                                                            // setIsModalOpen1(false)
                                                        }}>Apply</SendButton1>
                                                    </ButtonBox>
                                                </StyleBox>
                                            </>
                                        }

                                    </div>


                                </div>
                                <CustomTabs
                                    tabpanelWidth={'750px'}
                                    tabsData={[
                                        {
                                            label: 'Communication',
                                            icon: <Inbox style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OptionBox onClick={() => {
                                                        if (communication?.some(item => item.type === 'note' && item.new === true)) {
                                                            // Do nothing if the object is already in the array
                                                        }
                                                        else {
                                                            setCommunication([{ type: 'note', new: true }, ...communication])
                                                            setAdditionalNotes('')
                                                        }
                                                    }}><AddOutlined style={{ fontSize: '16px', marginRight: '10px' }} /> Note</OptionBox>
                                                    <OptionBox onClick={() => {
                                                        if (communication?.some(item => item.type === 'note' && item.newCall === true)) {
                                                            // Do nothing if the object is already in the array
                                                        }
                                                        else {
                                                            setCommunication([{ type: 'note', newCall: true }, ...communication])
                                                            setAdditionalcallNotes('')
                                                        }
                                                    }}><AddOutlined style={{ fontSize: '16px', marginRight: '10px' }} /> Call log</OptionBox>
                                                </div>

                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        communication?.map((item, index) =>
                                                            item?.new || item?.newCall ? <TimelineItem key={index} sx={{
                                                                width: 'calc(100% + 60px)',
                                                                marginLeft: '-30px',
                                                                marginRight: '-30px',
                                                            }}>
                                                                <TimelineSeparator>

                                                                    <TimelineDot style={{ width: '18px', height: '18px', background: item?.title === 'call Log' ? item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? 'red' : palette.green : palette.green : '' }}>
                                                                        {
                                                                            item?.newCall ? <PermPhoneMsg style={{ fontSize: '18px', }} /> : <LibraryBooks style={{ fontSize: '18px', }} />
                                                                        }
                                                                    </TimelineDot>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card onClick={() => {
                                                                        // if (item?.bookingStatus) {
                                                                        //     setModalData(item)
                                                                        //     setType(true)
                                                                        //     setIsBookingModalOpen(true)
                                                                        // }

                                                                    }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <CommunicationInputText
                                                                                    placeholder={"Add a note..."}
                                                                                    value={item?.newCall ? additionalcallNotes : additionalNotes}
                                                                                    onChange={(e) => item?.newCall ? setAdditionalcallNotes(e.target.value) : setAdditionalNotes(e.target.value)}
                                                                                    style={{ border: 'none', width: '90%', boxShadow: 'none', '.&onfocus': 'none' }}
                                                                                />
                                                                                <Actions onClick={() => {
                                                                                    const newArray = [...communication]; // Make a copy to avoid mutating the original array
                                                                                    newArray.splice(index, 1);
                                                                                    setCommunication(newArray)

                                                                                }}>
                                                                                    <FiTrash2 />
                                                                                </Actions>
                                                                            </Header>
                                                                            <Divider />
                                                                            <ButtonBox style={{ paddingBottom: '3px', marginBottom: '0px' }}>

                                                                                <RippleButton style={{ marginRight: '-10px' }} onClick={async () => {
                                                                                    await dispatch(saveAdditionalNotes({
                                                                                        "contactId": number,
                                                                                        "type": item?.newCall ? "call Log" : 'note',
                                                                                        "note": item?.newCall ? additionalcallNotes : additionalNotes,
                                                                                        "createdAt": new Date()
                                                                                    }))
                                                                                    init()

                                                                                }}>Save</RippleButton>
                                                                            </ButtonBox>

                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem> :

                                                                <TimelineItem key={index} sx={{
                                                                    width: 'calc(100% + 60px)',
                                                                    marginLeft: '-30px',
                                                                    marginRight: '-30px',
                                                                }}>
                                                                    <TimelineSeparator>

                                                                        <TimelineDot style={{ width: '18px', height: '18px', background: item?.type === 'call Log' ? palette.green : item?.status === 'Done' ? palette.orange : item?.type === 'note' ? palette.pup : item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? 'red' : palette.green : palette.green }}>
                                                                            {
                                                                                item?.type === 'call Log' ? <PermPhoneMsg style={{ fontSize: '18px', }} /> : item?.type === 'note' ? <LibraryBooks style={{ fontSize: '18px', }} /> : item?.status === 'Done' ? <TaskAlt style={{ fontSize: '18px', }} /> : <Call style={{ fontSize: '18px', }} />
                                                                            }
                                                                        </TimelineDot>
                                                                        <TimelineConnector sx={{ width: '1px' }} />
                                                                    </TimelineSeparator>
                                                                    <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                        <Card onClick={() => {
                                                                            // if (item?.bookingStatus) {
                                                                            //     setModalData(item)
                                                                            //     setType(true)
                                                                            //     setIsBookingModalOpen(true)
                                                                            // }

                                                                        }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                            <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                                <Header>
                                                                                    {item?.type === 'call Log' || item?.type === 'note' ? <Label style={{ marginBottom: '0px', marginTop: '4px' }} >{item?.note}</Label  > : item?.status === 'Done' ? <Label style={{ marginBottom: '2px', marginTop: '2px' }} ><span style={{ color: palette.grey }}>{`Task completed: `}</span> {item?.task}</Label> : <Status color={item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f'}>
                                                                                        {item?.direction === 'outbound-dial' ? item?.status === 'canceled' || item?.status === 'no-answer' ? `Missed call from ${item?.contactName}` : `Outgoing call to ${item?.contactName}` : `Incoming call from ${item?.contactName}`}
                                                                                    </Status>}
                                                                                    {item?.type === 'call Log' || item?.type === 'note' ? <Actions>

                                                                                        <FiTrash2 style={{ cursor: 'pointer' }} onClick={async () => {
                                                                                            await dispatch(deleteAdditionalNotes({
                                                                                                id: number,
                                                                                                addressId: item?._id
                                                                                            }))
                                                                                            init()
                                                                                        }} />
                                                                                    </Actions> : null}
                                                                                </Header>

                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }} variant="body2">
                                                                                    <div>

                                                                                        <Label style={{ color: palette.grey, marginBottom: '0px', fontSize: '12px' }}>{moment(item?.createdAt).format('DD MMM, hh:mm A')}</Label>
                                                                                    </div>

                                                                                </div>

                                                                                {item?.items?.length > 0 && <>
                                                                                    <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                        {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                        <h5>Line items</h5>
                                                                                    </LineItemsToggle>
                                                                                    {expanded === index && (
                                                                                        <LineItemsContainer>
                                                                                            {item?.items?.map((item, idx) => (
                                                                                                <LineItem key={idx}>
                                                                                                    <div>
                                                                                                        <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                        <p className="description">{item?.description}</p>
                                                                                                    </div>
                                                                                                    <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                                </LineItem>
                                                                                            ))}
                                                                                        </LineItemsContainer>
                                                                                    )}
                                                                                </>}
                                                                            </CardContent>
                                                                        </Card>
                                                                    </TimelineContent>
                                                                </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {
                                            label: 'Addresses',
                                            icon: <LocationOnIcon style={{ fontSize: '16px' }} />,
                                            content: <>
                                                <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', marginBottom: '20px', border: '1px solid #f3f3f3', paddingBottom: '0px', paddingTop: '0px', }}  >
                                                    <CardContent style={{ padding: '8px' }} >
                                                        {!boolValue?.address ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CustomTextField
                                                                placeholder="Search"
                                                                size="small"
                                                                value={search}
                                                                onChange={(e) => searchFilterFunction(e.target.value)}
                                                                InputProps={{
                                                                    style: {
                                                                        paddingLeft: '10px',
                                                                        borderRadius: '8px',
                                                                        fontSize: '14px',
                                                                        maxWidth: '100%',

                                                                        background: '#f3f3f3',

                                                                    },
                                                                    startAdornment: (
                                                                        <CustomInputAdornment position="start">
                                                                            <CustomIconButton>
                                                                                <SearchOutlined style={{ fontSize: '16px' }} />
                                                                            </CustomIconButton>
                                                                        </CustomInputAdornment>
                                                                    ),


                                                                }}
                                                            />
                                                            <Tooltip arrow placement='top' title={'Add Address'}>
                                                                <CustomIconButton onClick={() => setBoolValue({ ...boolValue, address: true })}>
                                                                    <AddOutlined style={{ fontSize: '18px' }} />
                                                                </CustomIconButton>
                                                            </Tooltip>
                                                            {/* <HeaderButton onClick={() => setBoolValue({ ...boolValue, address: true })} style={{ justifyContent: 'center', cursor: 'pointer', alignItems: 'center', }}>
                                                                <AddOutlined style={{ fontSize: '18px', marginRight: '5px' }} />
                                                                new address
                                                            </HeaderButton> */}
                                                        </div> :
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5%' }}>
                                                                <InputBox
                                                                    header={"Name"}
                                                                    value={addresstitle}

                                                                    style={{ display: 'flex', width: '90%', alignSelf: 'center', }}
                                                                    id="outlined-basic"
                                                                    size="small"
                                                                    placeholder="Name"
                                                                    variant="outlined"
                                                                    onChange={(e) => setAddressTitle(e.target.value)} />
                                                                <Label style={{ marginTop: '20px' }} htmlFor={'Address'}>Address</Label>

                                                                <Place
                                                                    apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                                                                    types={['address']}
                                                                    options={{
                                                                        types: ["address"],
                                                                    }}
                                                                    style={{
                                                                        width: '90%',
                                                                        borderRadius: '5px',
                                                                        fontSize: '14px',
                                                                        height: '20px',
                                                                        fontWeight: '400',
                                                                        fontFamily: 'Inter, sans-serif',
                                                                        color: '#344054',
                                                                        alignSelf: 'start',
                                                                        background: '#ffffff',
                                                                        border: '0.5px solid #d0d5dd',
                                                                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                                                        padding: '10px 14px',
                                                                    }}
                                                                    placeholder='Address'
                                                                    //onChange={onPlaceSelected}
                                                                    //onClick={onPlaceSelected}
                                                                    // onChange={(e) => {
                                                                    //     console.log(e.target.value, "eee")
                                                                    //     setAddress({ ...address, street: e.target.value,address:'' })
                                                                    // }}

                                                                    onPlaceSelected={(details) => {
                                                                        const street = `${details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('street_number')
                                                                        )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('route'),
                                                                        )?.short_name || ''}`;

                                                                        const zipCode = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('postal_code'),
                                                                        )?.short_name;
                                                                        const region = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('administrative_area_level_1'),
                                                                        )?.long_name;
                                                                        const city = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('locality'),
                                                                        )?.long_name;
                                                                        const country = details?.address_components.find((addressComponent) =>
                                                                            addressComponent.types.includes('country'),
                                                                        )?.long_name;
                                                                        setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address })
                                                                        console.log(details, zipCode, city, street, "details")
                                                                        // setAddressValue(details?.formatted_address)
                                                                        // setValues({ ...values, address: details?.formatted_address })
                                                                    }}

                                                                />

                                                                {/* <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"City"}
                                                                        value={address?.city}

                                                                        style={{ display: 'flex', alignSelf: 'center', }}
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        placeholder="City"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                                                                </div>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Province"}
                                                                        value={address?.state}


                                                                        id="outlined-basic" size="small"
                                                                        placeholder="Province"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Postal code"}
                                                                        value={address?.postalCode}

                                                                        style={{ display: 'flex', alignSelf: 'center', }}
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        placeholder="Postal code"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
                                                                </div>
                                                                <div style={{ width: '45%' }}>

                                                                    <InputBox
                                                                        header={"Country"}
                                                                        value={address?.country}


                                                                        id="outlined-basic" size="small"
                                                                        placeholder="Country"
                                                                        variant="outlined"
                                                                        onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                                                                </div>

                                                            </div> */}
                                                                <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '5%', justifyContent: 'flex-end', }}>
                                                                    <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setBoolValue({ ...boolValue, address: false })}>Cancel</RippleButton>
                                                                    <RippleButton onClick={async () => {
                                                                        await dispatch(saveAdditionalAddress({ ...address, "contactId": number, "title": addresstitle }))
                                                                        setAddressTitle('')
                                                                        init()
                                                                        setBoolValue({ ...boolValue, address: false })
                                                                    }}>Save</RippleButton>
                                                                </div>
                                                            </div>}
                                                    </CardContent>
                                                </Card>
                                                {searchData?.map((item, index) => <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', paddingBottom: '0px', marginBottom: '20px', paddingTop: '0px', }}  >
                                                    <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                        <Header>
                                                            <Status >
                                                                <FiMapPin style={{ marginBottom: '-2px' }} /> {item?.title || `Address ${index + 1}`}
                                                            </Status>
                                                            <Actions>
                                                                <FiTrash2 style={{ cursor: 'pointer' }} onClick={async () => {
                                                                    await dispatch(deleteAdditionalAddress({
                                                                        id: number,
                                                                        addressId: item?._id
                                                                    }))
                                                                    init()
                                                                }} />
                                                            </Actions>
                                                        </Header>
                                                        <Label style={{ fontSize: '13px', fontWeight: '400', marginTop: '8px', marginBottom: '0px', color: palette.grey }}>{item?.address}</Label>
                                                    </CardContent>
                                                </Card>)}
                                            </>
                                        },
                                        {
                                            label: 'Estimates',
                                            icon: <RequestQuote style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.quotes?.map((item, index) =>
                                                            item?.status &&
                                                            <TimelineItem key={index} sx={{
                                                                width: 'calc(100% + 60px)',
                                                                marginLeft: '-30px',
                                                                marginRight: '-30px',
                                                            }}>
                                                                <TimelineSeparator>
                                                                    <Tooltip arrow placement='top' title={item?.status === 'Waiting for confirmation' ? 'Waiting' : item?.status === 'Rejected' ? 'Void' : item?.status}>
                                                                        <TimelineDot style={{ width: '18px', height: '18px', background: item?.status === 'Waiting for confirmation' ? '#fa811f' : item?.status === 'Draft' ? '#586171' : item?.status === "Rejected" ? '#c0113d' : '#29740f' }}>
                                                                            <RequestQuoteOutlined style={{ fontSize: '18px', }} />
                                                                        </TimelineDot>
                                                                    </Tooltip>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', paddingBottom: '0px', paddingTop: '0px' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={getStatusColor(item?.status)}>
                                                                                    {item?.name}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <FiEdit style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        navigate(`/dashboard/quotes/${item?._id}`)
                                                                                        // setModalData(item)
                                                                                        // setType(true)
                                                                                        // setModalOpenQuote(true)
                                                                                    }} />
                                                                                    {item?.status !== 'Rejected' && <FiTrash2 style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setDelete({
                                                                                            delete: true,
                                                                                            val: 'Rejected',
                                                                                            item: item,
                                                                                            type: 'Quote',
                                                                                            number: item?.numberOfQuote
                                                                                        })
                                                                                    }} />}


                                                                                </Actions>

                                                                            </Header>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '10px', fontWeight: '500', textAlign: 'start' }}>{`Estimate #${item?.numberOfQuote}`}</h5>
                                                                                    <Label style={{ color: palette.grey, fontSize: '12px' }}>{moment(item?.date)?.format('DD MMM, hh:mm A')}</Label>
                                                                                </div>
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '10px', fontWeight: '500', textAlign: 'start' }}>{`$${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}`}</h5>

                                                                                </div>
                                                                            </div>
                                                                            <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                <h5>Line items</h5>
                                                                            </LineItemsToggle>
                                                                            {expanded === index && (
                                                                                <LineItemsContainer>
                                                                                    {item?.items?.map((item, idx) => (
                                                                                        <LineItem key={idx}>
                                                                                            <div>
                                                                                                <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                {item?.description && <p className="description">{item?.description}</p>}
                                                                                            </div>
                                                                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                        </LineItem>
                                                                                    ))}
                                                                                </LineItemsContainer>
                                                                            )}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.createdBy)}</Avatar>
                                                                                    <Label className='label' >{item?.createdBy}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {

                                            label: 'Jobs',
                                            icon: <Construction style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.bookings?.map((item, index) =>

                                                            item?.bookingStatus &&
                                                            <TimelineItem key={index} sx={{
                                                                width: 'calc(100% + 60px)',
                                                                marginLeft: '-30px',
                                                                marginRight: '-30px',
                                                            }}>
                                                                <TimelineSeparator>
                                                                    <Tooltip arrow placement='top' title={item?.bookingStatus === 'Waiting for confirmation' ? 'Open' : item?.bookingStatus}>
                                                                        <TimelineDot style={{ width: '18px', height: '18px', background: item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f', }}>
                                                                            <Handyman style={{ fontSize: '18px', }} />
                                                                        </TimelineDot>
                                                                    </Tooltip>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card onClick={() => {


                                                                    }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f'}>
                                                                                    {item?.name}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <FiEdit style={{ cursor: 'pointer' }} onClick={() => {

                                                                                        navigate(`/dashboard/servicerequests/${item?.referenceNo}`)

                                                                                    }} />
                                                                                    <FiTrash2 style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        setDelete({
                                                                                            delete: true,
                                                                                            val: 'Rejected',
                                                                                            item: item,
                                                                                            type: 'Job',

                                                                                        })
                                                                                    }} />
                                                                                </Actions>
                                                                            </Header>

                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '10px', fontWeight: '500', textAlign: 'start' }}>{`${item?.customerAddress}`}</h5>
                                                                                    <Label style={{ color: palette.grey, fontSize: '12px' }}>{item?.date ? `${moment(item?.date)?.format('DD MMM') || ''} at ${moment(item?.time)?.format('hh:mm A') || ''}` : 'Unscheduled job'}</Label>
                                                                                </div>

                                                                            </div>

                                                                            {item?.items?.length > 0 && <>
                                                                                <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                    {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                    <h5>Line items</h5>
                                                                                </LineItemsToggle>
                                                                                {expanded === index && (
                                                                                    <LineItemsContainer>
                                                                                        {item?.items?.map((item, idx) => (
                                                                                            <LineItem key={idx}>
                                                                                                <div>
                                                                                                    <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                    <p className="description">{item?.description}</p>
                                                                                                </div>
                                                                                                <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                            </LineItem>
                                                                                        ))}
                                                                                    </LineItemsContainer>
                                                                                )}
                                                                            </>}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.createdBy)}</Avatar>
                                                                                    <Label className='label' >{item?.createdBy}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        },
                                        {

                                            label: 'Invoices',
                                            icon: <Paid style={{ fontSize: '16px' }} />,
                                            content: <div style={{ height: '90vh' }}>
                                                <Timeline position='right'
                                                    sx={{
                                                        [`& .${timelineItemClasses.root}:before`]: {
                                                            flex: 0,
                                                            padding: 0,
                                                        },
                                                    }}

                                                    orientation="vertical"
                                                >
                                                    {
                                                        contactDetail?.invoices?.map((item, index) =>
                                                            item?.invoiceStatus &&
                                                            <TimelineItem key={index} sx={{
                                                                width: 'calc(100% + 60px)',
                                                                marginLeft: '-30px',
                                                                marginRight: '-30px',
                                                            }}>
                                                                <TimelineSeparator>
                                                                    <Tooltip arrow placement='top' title={item?.invoiceStatus === 'Rejected' ? 'Void' : item?.invoiceStatus}>
                                                                        <TimelineDot style={{ width: '18px', height: '18px', background: item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f' }}>
                                                                            <LocalAtm style={{ fontSize: '18px', }} />
                                                                        </TimelineDot>
                                                                    </Tooltip>
                                                                    <TimelineConnector sx={{ width: '1px' }} />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ marginBottom: '30px' }}>
                                                                    <Card
                                                                        onClick={() => {
                                                                            // if (item?.invoiceStatus) {
                                                                            //     setModalData(item)
                                                                            //     setType(true)
                                                                            //     setModalOpenInvoice(true)
                                                                            // }

                                                                        }} style={{ boxShadow: '0px 4px 16px rgba(30, 30, 30, 0.05)', border: '1px solid #f3f3f3', cursor: 'pointer' }}  >
                                                                        <CardContent style={{ paddingTop: '5px', paddingBottom: '8px' }} >
                                                                            <Header>
                                                                                <Status color={item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f'}>
                                                                                    {item?.name}
                                                                                </Status>
                                                                                <Actions>
                                                                                    <FiEdit style={{ cursor: 'pointer' }} onClick={() => {
                                                                                        navigate(`/dashboard/invoices/${item?._id}`)
                                                                                        // setModalData(item)
                                                                                        // setType(true)
                                                                                        // setModalOpenInvoice(true)
                                                                                    }} />
                                                                                    {item?.invoiceStatus !== 'Rejected' && <FiTrash2
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            setDelete({
                                                                                                delete: true,
                                                                                                val: 'Void',
                                                                                                item: item,
                                                                                                type: 'Invoice',
                                                                                                number: item?.numberOfInvoice
                                                                                            })
                                                                                        }}
                                                                                    />}
                                                                                </Actions>
                                                                            </Header>

                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} variant="body2">
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '10px', fontWeight: '500', textAlign: 'start' }}>{`Invoice #${item?.numberOfInvoice}`}</h5>
                                                                                    <Label style={{ color: palette.grey, fontSize: '12px' }}>{moment(item?.date)?.format('DD MMM, hh:mm A')}</Label>
                                                                                </div>
                                                                                <div>
                                                                                    <h5 style={{ marginBottom: '0px', marginTop: '10px', fontWeight: '500', textAlign: 'start' }}>{`$${parseFloat(item?.totalAmount)?.toFixed(2)}`}</h5>

                                                                                </div>
                                                                            </div>
                                                                            <LineItemsToggle onClick={() => handleChange(index)}>
                                                                                {expanded === index ? <KeyboardArrowDown /> : <ArrowForwardIosSharpIcon style={{ fontSize: '14px' }} />}
                                                                                <h5>Line items</h5>
                                                                            </LineItemsToggle>
                                                                            {expanded === index && (
                                                                                <LineItemsContainer>
                                                                                    {item?.items?.map((item, idx) => (
                                                                                        <LineItem key={idx}>
                                                                                            <div>
                                                                                                <p className="name">{item?.name} × {item?.quantity || '1'}</p>
                                                                                                <p className="description">{item?.description}</p>
                                                                                            </div>
                                                                                            <p className="price">${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                                                                        </LineItem>
                                                                                    ))}
                                                                                </LineItemsContainer>
                                                                            )}
                                                                            <ProfileContainer>
                                                                                <div className='inner-container' variant="body2">
                                                                                    <Avatar className='avatar'>{getName(item.createdBy)}</Avatar>
                                                                                    <Label className='label' >{item?.createdBy}</Label>
                                                                                </div>
                                                                            </ProfileContainer>
                                                                        </CardContent>
                                                                    </Card>
                                                                </TimelineContent>
                                                            </TimelineItem>

                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                        }
                                    ]}
                                    value={value}

                                />


                            </StyleRight>

                        }
                    </Felxbox>

                </Main>}

            </Body>
            <AddCustomField
                isVisible={isModalopen1}
                selectedField={{}}
                type={'contact'}
                onClose={() => {
                    setIsModalOpen1(false)

                }}
                onSubmit={(value) => {
                    setDataCustomField([...customField, value])
                    setCustomField([...selectCustomField, { fieldName: value?.fieldName, fieldType: value?.fieldType, options: value?.options }])
                    setIsModalOpen1(false)
                }}
            />

            {modalOpen && <RightModal
                customStyle={{ background: '#fff' }}
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <div style={{ height: '100%', display: 'flex' }}>
                    <RightSideConversations setConnection={(call) => {
                        // setConnection(call)
                    }} value={{ ...selectedNumber, number: selectedNumber?.to || selectedNumber?.number }} />
                </div>
            </RightModal>}

            {
                boolValue?.isTask && <TopModal
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    customStyle={{ width: '370px', maxHeight: '90%', background: '#fff' }}

                    onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                >
                    <AddTask
                        selectedSlot={boolValue?.taskData?._id ? boolValue?.taskData : { name: tempUserDetails?.name, number: tempUserDetails?.number }}
                        team={userData?.crewMembers}
                        header={"Add Task"}
                        onClose={() => setBoolValue({ ...boolValue, isTask: false })}
                        onSubmit={() => {
                            init()
                            setBoolValue({ ...boolValue, isTask: false })
                        }}
                    />
                </TopModal>
            }

            {isModalopenInvoice && (
                <FullScreenModal onClose={() => setModalOpenInvoice(false)}>

                    <CreateInvoice
                        {...props}
                        type={type}
                        value={type ? { ...modalData, edit: true } : {
                            ...editValue, address: tempUserDetails?.address, email: tempUserDetails?.email,
                            ...(tempUserDetails?.subscription[0]?.discount
                                ? {
                                    discount: tempUserDetails?.subscription[0]?.discount,
                                    discountType: '%'
                                }
                                : {}),
                            tempUserDetails: {
                                ...tempUserDetails, additionalAddress: [{ address: editValue?.address }, ...tempUserDetails?.additionalAddress], additionalContact: [{
                                    "name": tempUserDetails?.name,
                                    "number": tempUserDetails?.number,
                                    email: tempUserDetails?.email
                                },

                                ...tempUserDetails?.additionalContact]
                            }
                        }}
                        setModalOpenInvoice={() => setModalOpenInvoice(false)}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenInvoice(false)


                        }
                        }
                    />


                </FullScreenModal>
            )}
            {isModalopenQuote && (
                <FullScreenModal onClose={() => setModalOpenQuote(false)}>

                    <CreateQuote
                        {...props}
                        value={type ? { ...modalData, edit: true } : {
                            ...editValue, address: tempUserDetails?.address, email: tempUserDetails?.email || '',
                            ...(tempUserDetails?.subscription[0]?.discount
                                ? {
                                    discount: tempUserDetails?.subscription[0]?.discount,
                                    discountType: '%'
                                }
                                : {}),
                            tempUserDetails: {
                                ...tempUserDetails, additionalAddress: [{ address: editValue?.address }, ...tempUserDetails?.additionalAddress], email: tempUserDetails?.email || '', additionalContact: [{
                                    "name": tempUserDetails?.name,
                                    "number": tempUserDetails?.number,
                                    email: tempUserDetails?.email
                                }, ...tempUserDetails?.additionalContact]
                            }
                        }}

                        setModalOpenInvoice={() => setModalOpenQuote(false)}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setModalOpenQuote(false)


                        }
                        }
                    />


                </FullScreenModal>


            )}

            {isBookingModalopen && (
                <FullScreenModal onClose={() => setIsBookingModalOpen(false)}>

                    <CreateBooking
                        {...props}
                        serviceRequest={type}
                        newService={true}
                        value={type ? { ...modalData, ...BookingValue()} : editValue?.to ? {...editValue,...BookingValue()} : {}}
                        additionalAddress={[{ address: editValue?.address }, ...tempUserDetails?.additionalAddress]}
                        setCreateBookingModal={(val) => {
                            setIsBookingModalOpen(false)
                            if (val?.number) {
                                navigate(`/dashboard/servicerequests/${val?.number}`)
                            }
                        }}
                        onSubmitsw={(val) => {

                            sendchat(val)
                            setIsBookingModalOpen(false)
                            if (val?.number) {
                                navigate(`/dashboard/servicerequests/${val?.number}`)
                            }

                        }
                        }
                    />


                </FullScreenModal>
            )}
            {isstartModalopen && <Modal

                onClose={() => setIsstartModalOpen(false)}
                customStyle={{ background: '#fff' }}
            >
                <div style={{ marginLeft: '-10px' }}>
                    <HeaderContainer>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CloseButton onClick={() => setIsstartModalOpen(false)}>
                                <Icons src={cross} />
                            </CloseButton>

                        </div>
                    </HeaderContainer>
                    <CalendarStyle style={{ zIndex: 10000, }} className='p-3 d-flex justify-content-center'>
                        <div style={{ zIndex: 10000, borderRight: '1px solid #f3f3f3', marginRight: '15px', width: '350px', paddingRight: '-25px' }} >
                            <Calendar className='react-calendar'
                                value={values?.date}
                                onChange={(val) => setValues({ ...values, date: val })}
                                minDate={new Date()}
                            />

                        </div>
                        <Felxbox className='justify-content-between'>
                            <div style={{ display: 'inline', marginTop: '10px', marginRight: 10, }}>
                                {TimeListAm.map((item) => {
                                    return (
                                        <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', fontSize: '14px', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={{ display: 'inline', marginTop: '10px' }}>
                                {TimeListPM.map((item) => {
                                    return (
                                        <div onClick={() => setValues({ ...values, time: item })} style={{ padding: '5px', background: values?.time === item ? '#398AFF' : '#fff', cursor: 'pointer', color: values?.time !== item ? '#000' : '#fff', border: values?.time === item ? '1px solid #d4e6ff' : '1px solid #DDDDDD', fontSize: '14px', width: '80px', marginBottom: '7px', borderRadius: '5px' }}>

                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </Felxbox>
                    </CalendarStyle>
                    <ButtonBox>
                        <SendButton1 style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => setIsstartModalOpen(false)}>Cancel</SendButton1>
                        <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                            setIsstartModalOpen(false)
                            // const res = await dispatch(createNewCustomField({
                            //     "fields": [
                            //         newCutomField
                            //     ]
                            // }))
                            // setDataCustomField([...customField, newCutomField])
                            // setIsModalOpen1(false)
                        }}>Save</SendButton1>
                    </ButtonBox>
                </div>
            </Modal>}
            {imagemodalOpen && (
                <Modal
                    isOpen={imagemodalOpen}
                    onClose={() => setImageModalOpen(false)}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <CloseButton onClick={() => setImageModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>

                    </div>

                    <Image onClick={() => {
                        window.open(previewURL)
                    }} src={previewURL} />


                </Modal>
            )}
            {
                isDelete?.delete && <TopModal
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    onClose={() => setDelete({})}
                >
                    <DeleteModal
                        value={isDelete}
                        onCancel={() => setDelete({})}
                        onSubmit={(val) => {
                            if (val === 'delete') {
                                setDelete({})
                                navigate(-1)
                            }
                            else {
                                setDelete({})
                                init()
                            }
                        }}
                    />
                </TopModal>
            }
            {
                boolValue?.editcontact && <TopModal
                    overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                    onClose={() => {
                        setBoolValue({ ...boolValue, editcontact: false })
                        setEditValue({
                            "name": tempUserDetails?.name || "",
                            "email": tempUserDetails?.email || "",
                            "address": tempUserDetails?.address || "",
                            "businessName": tempUserDetails?.businessName || '',
                            "to": tempUserDetails?.number || '',
                        })
                    }}
                    customStyle={{ maxHeight: '90%' }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>
                            {"Edit contact"}
                        </h4>
                        <Cancel onClick={() => {
                            setBoolValue({ ...boolValue, editcontact: false })
                            setEditValue({
                                "name": tempUserDetails?.name || "",
                                "email": tempUserDetails?.email || "",
                                "address": tempUserDetails?.address || "",
                                "businessName": tempUserDetails?.businessName || '',
                                "to": tempUserDetails?.number || '',
                            })
                        }} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ paddingLeft: '15px', paddingRight: '20px', width: '90%' }}>


                        <InputBox
                            header={"Name"}
                            placeholder="Name"
                            type="text"
                            value={editValue?.name}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, name: e.target.value
                                })
                            }}

                        />
                        <InputBox
                            header={"Business name"}
                            placeholder="Business name"
                            type="text"
                            value={editValue?.businessName}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, businessName: e.target.value
                                })
                            }}

                        />
                        <Checkbox

                            checked={editValue?.displayName}
                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, displayName: e.target.checked
                                })
                            }}
                            label='Use company name as the primary name'
                        />
                        <InputBox
                            header={"Email"}
                            placeholder="Email"
                            type="text"
                            value={editValue?.email}
                            headerStyle={{ marginTop: '10px' }}

                            onChange={(e) => {
                                setEditValue({
                                    ...editValue, email: e.target.value
                                })
                            }}

                        />
                         <Label style={{ marginTop: '20px', marginLeft: '3px' }} htmlFor={'Address'}>Phone number</Label>
                         <div className='phoneInput'>

<PhoneInputFiled
   
    placeholder="Enter phone number"
    value={editValue?.to}
    onChange={(val) => {
        setEditValue({
            ...editValue, to:val
        })
    }}
    defaultCountry="US"
    countryCallingCodeEditable={false}
    style={{
        width: '100%', borderRadius: '5px', '&.PhoneInputInput': {
          
        }
    }}
    numberInputProps={{
        className: cn(
          "flex  w-full rounded-md border px-3 py-2 text-base",
          "ring-offset-background file:border-0 file:bg-transparent file:text-sm",
          "file:font-medium file:text-foreground placeholder:text-muted-foreground",
          "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          "disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
        "border-input"
        )
      }}

    international
    focusInputOnCountrySelection='true'
    tabIndex="0"
/>
</div>

                        <Label style={{ marginTop: '20px', marginLeft: '3px' }} htmlFor={'Address'}>Street 1</Label>

                        <Place
                            apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                            types={['address']}
                            options={{
                                types: ["address"],
                            }}
                            defaultValue={address?.street}
                            style={{
                                width: '100%',
                                borderRadius: '5px',
                                fontSize: '14px',
                                height: '20px',
                                fontWeight: '400',
                                fontFamily: 'Inter, sans-serif',
                                color: '#344054',
                                alignSelf: 'start',
                                background: '#ffffff',
                                border: '0.5px solid #d0d5dd',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                padding: '10px 14px',
                            }}
                            placeholder='street 1'
                            //onChange={onPlaceSelected}
                            //onClick={onPlaceSelected}
                            onChange={(e) => {
                                console.log(e.target.value, "eee")
                                setAddress({ ...address, street: e.target.value,address:'' })
                            }}
                            onPlaceSelected={(details) => {
                                const street = `${details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('street_number')
                                )?.short_name || ''} ${details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('route'),
                                )?.short_name || ''}`;

                                const zipCode = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('postal_code'),
                                )?.short_name;
                                const region = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('administrative_area_level_1'),
                                )?.long_name;
                                const city = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('locality'),
                                )?.long_name;
                                const country = details?.address_components.find((addressComponent) =>
                                    addressComponent.types.includes('country'),
                                )?.long_name;
                                setAddress({ ...address, postalCode: zipCode, city: city, state: region, street: street, country: country, address: details?.formatted_address })
                                console.log(details, zipCode, city, street, "details")
                                // setAddressValue(details?.formatted_address)
                                // setValues({ ...values, address: details?.formatted_address })
                            }}

                        />
                        <InputBox
                            header={"Street 2"}
                            value={address?.street2}

                            style={{ display: 'flex', alignSelf: 'center', }}
                            id="outlined-basic"
                            size="small"
                            placeholder="Street 2"
                            variant="outlined"
                            onChange={(e) => setAddress({ ...address, street2: e.target.value })} />
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"City"}
                                    value={address?.city}

                                    style={{ display: 'flex', alignSelf: 'center', }}
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="City"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, city: e.target.value })} />
                            </div>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Province"}
                                    value={address?.state}


                                    id="outlined-basic" size="small"
                                    placeholder="Province"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, state: e.target.value })} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '25px' }}>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Postal code"}
                                    value={address?.postalCode}

                                    style={{ display: 'flex', alignSelf: 'center', }}
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Postal code"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
                            </div>
                            <div style={{ width: '45%' }}>

                                <InputBox
                                    header={"Country"}
                                    value={address?.country}


                                    id="outlined-basic" size="small"
                                    placeholder="Country"
                                    variant="outlined"
                                    onChange={(e) => setAddress({ ...address, country: e.target.value })} />
                            </div>

                        </div>
                        <Checkbox
                            checked={editValue?.allowEmailFollowUp}
                            customStyle={{ marginTop: '-10px' }}
                            onChange={(e) => setEditValue({
                                ...editValue, allowEmailFollowUp: e.target.checked
                            })}
                            label={'Enable Email Notifications for Follow-Ups'}
                        />
                        <Checkbox
                            checked={editValue?.allowSMSFollowUp}
                            customStyle={{ marginTop: '-10px' }}
                            onChange={(e) => setEditValue({
                                ...editValue, allowSMSFollowUp: e.target.checked
                            })}
                            label={'Enable SMS Notifications for Follow-Ups'}
                        />

                        <div style={{ alignSelf: 'flex-end', display: 'flex', marginTop: '20px', marginBottom: '20px', marginRight: '-20px', justifyContent: 'flex-end', }}>
                            <RippleButton style={{ background: '#fff', color: '#414552', border: '0.5px solid #414552', marginRight: '15px' }} onClick={() => {
                                setBoolValue({ ...boolValue, editcontact: false })
                                setEditValue({
                                    "name": tempUserDetails?.name || "",
                                    "email": tempUserDetails?.email || "",
                                    "address": tempUserDetails?.address || "",
                                    "businessName": tempUserDetails?.businessName || '',
                                    "to": tempUserDetails?.number || '',
                                })
                            }}>Cancel</RippleButton>
                            <RippleButton onClick={async() => {
                               await  editContact()
                                setBoolValue({ ...boolValue, editcontact: false })
                            }}>Save</RippleButton>
                        </div>
                    </div>
                </TopModal>
            }

            {boolValue?.addPayment && <TopModal
                overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                customStyle={{ width: '35%' }}
                onClose={() => {
                    setBoolValue({ ...boolValue, addPayment: false })
                }}           >
                <Elements stripe={stripePromise} >
                    <CheckoutForm
                        value={{
                            "name": tempUserDetails?.name || "",
                            "email": tempUserDetails?.email || "",
                            "address": tempUserDetails?.address || "",
                            "businessName": tempUserDetails?.businessName || '',
                            "to": tempUserDetails?.number || '',
                            "contactId": tempUserDetails?._id
                        }}
                        onClose={() => {
                            setBoolValue({ ...boolValue, addPayment: false })
                        }}
                    />
                </Elements>
            </TopModal>}
            {boolValue?.addplans && <TopModal
                overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                customStyle={{ width: '45%' }}
                onClose={() => {
                    setBoolValue({ ...boolValue, addplans: false })
                }}           >
                <ServicePlanForm
                    id={tempUserDetails?._id}
                    onClose={() => {
                        setBoolValue({ ...boolValue, addplans: false })
                    }}
                    onSubmit={() => {
                        setBoolValue({ ...boolValue, addplans: false })
                        init()
                    }}
                />
            </TopModal>}
            {boolValue?.addContract && <TopModal
                overlayStyle={{ alignItems: "center", paddingTop: '0px' }}
                customStyle={{ width: '75%', padding: '0px', maxHeight: '80%' }}
                onClose={() => {
                    setBoolValue({ ...boolValue, addContract: false })
                }}           >
                <AddContactTemplate
                    id={tempUserDetails?._id}
                    contactData={tempUserDetails}
                    onClose={() => {
                        setBoolValue({ ...boolValue, addContract: false })
                    }}
                    onSubmit={() => {
                        setBoolValue({ ...boolValue, addContract: false })
                        init()
                    }}
                />
            </TopModal>}


        </>

    )
}
export default ContactDetails;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const CustomUrl = styled.input`
background: none;
border-radius: 8px;
cursor:pointer;
border:none;
height: 30px;
font-size:14px;

&:focus {
    outline: none;
    border: none; 
  }
`
const Main = styled.div`
padding-left:25px;
padding-right:25px;
width:100%;
height:99vh;
overflow-y:scroll;


`
const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
`;
const WrapperHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:column;
    margin-top:20px;
    margin-bottom:20px;
    .text{
        font-size:13px;
        text-align:start;
        margin-top:0px;
        margin-bottom:5px;
        line-height: 16px;

    }
`;
const Image = styled.img`
width:350px;
height:350px;
margin-top:20px;
margin-bottom:20px;

`
const Felxbox = styled.div`
display:flex;
align-items:start;
height:auto;
`
const CardContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  font-size: 13px;
  font-weight: 500;
  border-bottom:1px solid #eee;
  padding-bottom:5px;
`;
const HeaderButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-item:center;
  align-items: start;
  font-size: 14px;
  font-weight: 500;
`;
const Status = styled.h2`
  margin: 0;
  text-align: start;
  font-size:13px;
  font-weight:500;
  margin-top:0px;
  span {
    color: ${props => props.color};
    background: #fff;
    font-size: 18px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items:center;
  margin-top: 3px;
  svg {
    font-size: 16px; 
    margin-right: 15px;
    color: rgba(32, 32, 32, 0.75);
    &:last-child {
        margin-right: 0;
        font-size: 18px;
      }
  }
  &:hover {
    
    svg {
        color:#000;
    }
  }

`;


const HeaderContainer = styled.div`
display:flex;

height:auto;
justify-content:space-between;
align-items:center; 
`
const CloseButton = styled.button`

display:flex;
align-items:center;
justify-content:center;
padding-left:10px;
border: none;
background:#ddd;
margin-top:15px;
width:30px;
height:30px;
align-self:center;
border-radius:25px;
font-size: 14px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const ValueName = styled.p`
color:${palette.charcoleBlack};

font-size: 14px;
text-align:start;
margin-top:0px;
margin-bottom:12px;
width:100%;

flex-wrap:wrap;
word-break:break-all;
white-space:nowrap;
overflow:hidden;
text-overflow: ellipsis;

`
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-left:10px;
margin-bottom:10px;
width: 60px;
height: 60px;

`
const OptionIcon = styled.img`
width: 13px;
height: 13px;
`
const PhoneFiled = styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;

.PhoneInputCountry{
    border: 0.5px solid #d0d5dd;
    height:21px;
    border-radius:4px;
    padding:8px;
    
}
.PhoneInputInput{
    height:35px;
    border-radius:4px;
    border: ${(props) => props.error ? '1px' : '0.5px'} solid ${(props) => props.error ? 'red' : '#d0d5dd'};
    &:focus {
        outline: none;
        border: 1.5px solid ${palette.charcoleBlack};
      
      }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const Label = styled.label`
    font-weight: 500;
    font-size: 13px;
    white-space:pre-line;
    color: #344054;
    line-height:16px;
    display: flex;
    margin-top:0px;
    margin-bottom: 5px;
    align-self: flex-start;
    font-family:Inter;
    flex-wrap:wrap;
    word-wrap: break-word; 
    overflow-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden; 
    width:100%;
    text-align:start; 
`
const WrapperContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
`;
const StyledStepLabel = styled(StepLabel)`

    && .MuiStepLabel-labelContainer {
       display:none;
    }
`;
const Selectbox = styled(Select)`
width:auto;
border-radius:8px;
text-align:start;
paddin-left:5px;
padding-right:5px;
margin-top:10px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #ddd;
background:white;
height:30px;
font-size:13px;
margin-bottom:10px;
:hover{
    background: #dddddd;
    border-radius: 4px;
}

.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const SelectTimebox = styled(Select)`
width:100%;
border-radius:4px;
text-align:start;

border:none;
height:34px;
margin-top:-1px;
width:150px;
margin-left:10px;
.MuiSelect-select {
    padding-right: 0px;
    width:100px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd;
  }

  .MuiSelect-icon {
    display: none;
    width:0px;
    height:0px;
  }

  .MuiMenuItem-root {
    border: 1px solid #ddd;
   
  }

  .MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 5px;
  }
}

`
const StyleRight = styled.div`
display:inline;
justify-content:center;
align-self:start;
flex-direction:column;
overflow-y:scroll;
width:95%;

margin-left:30px;
height:auto;
overflow-x:hidden;


`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid ${palette.smokeWhite};
background:white;
width:350px;
margin-top:20px;
border-radius:4px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.05);
h5{
    margin-top:0px;
    margin-bottom:0px;
}
p{
    font-size: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
    margin-left:10px;
    margin-right:10px;
    line-height:16px;
}
.keyContainer{
    padding: 0px 0px 0px 10px;
    font-size:16px;
    font-weight:500;
    color:${palette.black};
    background:#f9f9f9;
display:flex;
justify-content:space-between;
border-bottom:1px solid ${palette.smokeWhite};
align-items:center;
}
.valueContainer{
    padding:7px;
    
    background:#fff;
    font-size:14px;
  
display:flex;
}
`
const EditContainer = styled.div`

border-left:1px solid  ${palette.smokeWhite};
display:flex;
align-items:center;
padding:10px;
cursor:pointer;

&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`

const LineItemsToggle = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
  cursor: pointer;
  h5 {
    text-align: start;
    margin: 0;
    font-weight:500;
  }
  svg {
    margin-right: 5px;
  }
`;

const LineItemsContainer = styled.div`
  padding: 0 10px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-bottom:0px;
  
    }
    &.description {
      color: gray;
      margin-top:0px;
      margin-bottom:5px;
      line-height:16px;
      white-space:pre-line;
    }
    &.price {
      font-weight: 550;
    }
  }
`;

const MoreIcon = styled.img`


width:22px;
cursor:pointer;
color:black
`

const InputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height: 50px;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  margin-top:10px;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const CommunicationInputText = styled.textarea`
  font-family: 'Inter', sans-serif; 
  min-height:150px;
  max-height:80vh%;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5;
  word-wrap: break-word;
  width: 86%;
  display: block;
  border-radius: 8px;
  font-weight: 400;
  color:${palette.charcoleBlack};
  background: #ffffff;
  border: 1px solid #ddd;
  resize: none;
  padding:0px;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline:none;
    border: none;
  
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;

const SendButton1 = styled.button`
border:1px solid #4169e1;

 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`

const CustomTextField = styled(TextField)`
&& {
    border-radius: 8px;
    border: none; 
    padding: 5px;
    width:94%;

    .MuiOutlinedInput-root {
      & fieldset {
        border: none; 
      }
      &:hover fieldset {
        border: none; 
      }
      &.Mui-focused fieldset {
        border: 1px solid black; 
      }
    }
  }

`;
const Link = styled.div`
  color: #0f52ba;
  font-size: 12px;
  cursor: pointer;
  margin: 8px 5px;
  text-align: start;
  font-weight: 500;
  margin-right: 5px;
  background:#f8f8f8;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;
const Place = styled(Autocomplete)`
display:flex;
margin-right:0px;
margin-left:0px;
border-radius:5px;
font-size: 16px;
height:44px;
border-radius:4px;
border:1px solid  ${palette.charcoleBlack};
font-weight:400;
padding-left:12px;

&::placeholder {
  font-size:14px;
  font-weight:300;
}
&:focus {
    
    outline: 1.5px solid ${palette.charcoleBlack};
  
  }
`
const ContainerBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;

align-items:center;

font-weight:500;
cursor:pointer;

`
const CustomFieldWrapper = styled.div`
  display: flex;
  align-items: start;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 5px;
  .fieldName{
    width: 42%;
    font-weight: 600;
    font-size: 13px;
    text-align: start;
    line-height:16px;
    flex-wrap:wrap;
    word-break:break-all;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  .fieldValue{
    width: 56%;
    font-size: 13px;
    line-height:16px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: start;
    white-space: pre-wrap;
    overflow: hidden;
  }
`;
const OptionBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px 10px; 
border-radius: 15px;
margin-right:12px;
cursor: pointer;
transition: background-color 0.3s ease, box-shadow 0.3s ease;
font-size: 13px;
color: rgba(32, 32, 32, 0.85);
font-weight:500;
background-color: transparent; 
svg {
  font-size: 16px; 
  color: rgba(32, 32, 32, 0.85);
}
&:hover {
  background-color: #eeeeee;
  border-radius:15px;
  color:#000;
  svg {
      color:#000;
  }
}
`
const ViewContainer = styled.div`
border:1px solid gray;
display:flex;
justify-content:center;
color:#000;
font-size:12px;
width:100px;
border-radius: 5px;
align-self:end;
cursor:pointer;
background:#fff;
padding:3px 10px 3px 10px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const FileInputButton = styled.label`
  display: inline-block;

  background-color: white; /* Professional Blue */
  color: #000; 
  font-size:14px;
  margin-bottom:10px;
  border-radius: 8px;
  border:1px solid gray;
  width:60px;
  height:60px;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const CalendarStyle = styled.div`
display:flex;
box-sizing: border-box;
background: #FFFFFF;

height:auto;
border-radius: 5px;
padding:15px;
margin-left:15px;
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size:13px;
  background-color: #fff; 
  margin-bottom:15px;
  width:auto;
  :hover{
    background: #dddddd;

}

 `
const ProfileContainer = styled.div`
display: flex;
justify-content: flex-end;
align-content: flex-end;
align-items: center;
margin-top:5px;
.inner-container {
    display: flex;
  }
  
  .avatar {
    width: 20px;
    height: 20px;
    background:${palette.newBlue}; /* Replace with your palette.newBlue */
    font-size: 10px;
    margin-right: 5px;
  }
  
  .label {
    font-weight: 500;
    margin-top: 2px;
    font-size:14px;
  }
  
`;
const PhoneInputFiled = styled(PhoneInput)`

--PhoneInputCountryFlag-borderColor:#fff;
--PhoneInputCountryFlag-borderColor--focus:#fff;
--PhoneInputCountrySelectArrow-marginLeft:15px;
--PhoneInputCountrySelectArrow-color--focus:#000;
--PhoneInputCountrySelectArrow-borderWidth: 1.5px;

.PhoneInputCountry{
    border: 0.5px solid #d0d5dd;
    height:41px;
}
.PhoneInputInput{
    height:25px;
    border: ${(props) => props.error ?'1px solid red':`1px solid ${palette.smokeWhite}`};
    &:focus {
        outline: none;
        border: 1px solid ${palette.smokeWhite};
      
      }
      ${cn(
        "flex w-full rounded-md border px-3 py-2 text-base",
        "ring-offset-background file:border-0 file:bg-transparent file:text-sm",
        "file:font-medium file:text-foreground placeholder:text-muted-foreground",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        "disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
      
      )}
}

`