import React from "react";
import styled from "styled-components";
import { palette } from "../../../../utils/Palette";
import { FaExclamationCircle } from "react-icons/fa";
import { formatMobileNumber } from "../../../../utils/Commonfunction";
import { Divider, Tooltip, Typography } from "@mui/material";
import EventDetails from "./EventCard";
import {
  deleteAddEvent,
  deleteAddTask,
} from "../../../../store/Actions/dashboard.action";
import moment from "moment";
import RippleButton from "../../../../ReuseableComponets/RippleButton";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;

const Card = styled.div`
  background-color: #e1ecfd;
  border: 1px solid #d4e3c6;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
`;

const Title = styled.span`
  color: #2c3e50;
  font-size: 14px;
  text-align: start;
`;

const Number = styled.span`
  background-color: ${palette.newBlue};
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

const Address = styled.div`
  color: #2c3e50;
  font-size: 13px;
  text-align: start;
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7f8c8d;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

const MapCard = ({ data, onclick, type, isRoute, selectedLocations,onClickMarker }) => {
  const navigate=useNavigate()
  if (!data || data.length === 0) {
    return (
      <Container>
        <NoDataMessage>
          <IconWrapper>
            <FaExclamationCircle />
          </IconWrapper>
          No jobs
        </NoDataMessage>
      </Container>
    );
  }

  return (
    <Container>
      {data.map((item, index) => (
        <TooltipStyled
          disableTouchListener
          disableInteractive={false}
          placement={"right-start"}
          PopperProps={{
            disableInteractive: true,
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#fff",
                color: "#000",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
                fontSize: "14px",
                maxHeight: "550px",

                paddingBottom:
                  item?.data?.type == "task" || item?.data?.type == "event"
                    ? "0px"
                    : "10px",
                width: "350px",

                overflowY: "scroll",
              },
            },
            arrow: {
              sx: {
                color: "#fff",
              },
            },
          }}
          title={
            type ? null : (
              <div
                key={index}
                style={{
                  width: "280px",
                  height: "100%",
                  maxHeight: "380px",
                  paddingRight: "10px",
                  overflowY: "scroll",
                  padding:'10px',
                  paddingBottom:'-10px'
                }}
              >
                <EventBox>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{
                      color: palette.newBlue,
                      textAlign: "start",
                      fontSize: "12px",
                    }}
                  >
                    {`${ item?.data?.timezone
                        ? moment.tz(item?.data?.date, item?.data?.timezone).format("DD MMM YYYY"):moment(item?.data?.date).format("DD MMM YYYY")}, ${
                      item?.data?.timezone
                        ? moment(item?.data?.time)
                            .tz(item?.data?.timezone)
                            .format("hh:mm A")
                        : moment(item?.data?.time).format("hh:mm A")
                    }`}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div>
                      <EventTitle>{item?.data?.name}</EventTitle>
                      {item?.data?.email && (
                        <StyledTypography
                          style={{ marginBottom: "6px" }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {item?.data?.email}
                        </StyledTypography>
                      )}
                      <StyledTypography variant="body2" color="textSecondary">
                        {formatMobileNumber(item?.data?.customerNumber)}
                      </StyledTypography>
                    </div>
                    <BookingStatus status={item?.data?.bookingStatus}>
                      <span style={{ marginRight: "5px" }}>●</span>
                      {item?.data?.bookingStatus === "Waiting for confirmation"
                        ? "Open"
                        : item?.data?.bookingStatus}
                    </BookingStatus>
                  </div>
                  <Divider />
                  <LocationTitle>Location</LocationTitle>
                  <StyledTypography>
                    {item?.data?.customerAddress}
                  </StyledTypography>
                  {/* {item?.data?.team?.length > 0 && (
                   <>
                     <Divider />
                     <LocationTitle>Crew</LocationTitle>
                     {item?.data?.team?.map((item) => (
                       <StyledTypography
                         variant="body2"
                         color="textSecondary"
                         key={item.id}
                       >
                         {item?.firstName} {item?.lastName}
                       </StyledTypography>
                     ))}
                   </>
                 )}
                 {item?.data?.items?.length > 0 && (
                   <>
                     <Divider />
                     <LocationTitle>Line items</LocationTitle>
                     {item?.data?.items?.map((item) => (
                       <StyledTypography
                         variant="body2"
                         color="textSecondary"
                         key={item.id}
                       >
                         {item?.name} x {item?.quantity}
                       </StyledTypography>
                     ))}
                   </>
                 )} */}
                </EventBox>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap:'10px',
                    background: "#fff",
                  }}
                >
                  <RippleButton
                    onClick={() =>{
 navigate(`/dashboard/calander/${item?.data?.referenceNo}`)
                    }
                     
                    }
                    variant="contained"
                    color="primary"
                    style={{
                      width: "150px",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                  >
                    View details
                  </RippleButton>
                  <RippleButton
                    onClick={() => {
                      onClickMarker(item,index)
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      width: "150px",
                      fontWeight: "500",
                      fontSize: "13px",
                      background: "#f3f3f3",
                      color: "black",
                      border: "1px solid #ddd",
                    }}
                  >
                    {selectedLocations.some(
                      (loc) => loc.referenceId === item.data?.referenceNo
                    )
                      ? "Remove"
                      : isRoute
                      ? "Add Routing"
                      : "Start Routing"}
                  </RippleButton>
                </div>
              </div>
            )
          }
        >
          <Card key={item.id} onClick={() => onclick(item)}>
            <CardHeader>
              <Title>
                {item?.data?.name ||
                  item?.data?.customerNumber ||
                  item?.item?.name}
              </Title>
              {item?.index && <Number>{item?.index}</Number>}
            </CardHeader>
            <Address>
            <p style={{ marginTop: "6px", marginBottom: "5px" }}>
                {item?.data?.customerAddress || item?.item?.customerAddress}
              </p>
              <p style={{ marginTop: "6px", marginBottom: "5px" }}>
                {formatMobileNumber(
                  item?.data?.customerNumber || item?.item?.customerNumber
                )}
              </p>
             
            </Address>
          </Card>
        </TooltipStyled>
      ))}
    </Container>
  );
};

export default MapCard;

const EventBox = styled.div`
  padding: 4px;
  overflow-y: scroll;
`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
  text-align: start;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
    status === "Waiting for confirmation"
      ? "#045ad0"
      : status === "In Progress"
      ? "#29740f"
      : status === "Completed"
      ? "#004D4D"
      : "#fa811f"};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
  text-align: start;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(32, 32, 32, 0.75);
  text-align: start;
`;
