import { Cancel } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import DatePickerComponent from "../../../../ReuseableComponets/DatePickerComponent";
import DatePickerWithShortcuts from "../../../../ReuseableComponets/MultiDatePicker";
import RippleButton from "../../../../ReuseableComponets/RippleButton";
import { getAllServicePlan } from "../../../../store/Actions/JobAction";
import { createSubscription } from "../../../../store/Actions/SettingsAction";
import { palette } from "../../../../utils/Palette";
const ServicePlanForm = (props) => {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [chargeType, setChargeType] = useState("charge_automatically");
  const [manualDays, setManualDays] = useState(0);
  const [loader, setLoader] = useState(false)
  const [servicePlan, setServicePlan] = useState([])
  const [date, setDate] = useState(moment(new Date())?.format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(new Date()).add(10, "years").startOf("month").format('YYYY-MM-DD'))
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const init = async () => {
    setLoader(true)
    let res = await dispatch(getAllServicePlan())
    if (res.status === true) {
      setLoader(false)
      setServicePlan(res?.data)
    }
    setLoader(false)
  }

  useEffect(() => {
    init()
  }, [])
  const handleFormSubmit = async () => {
    const data = {
      "collectionMethod": chargeType,
      "daysDue": manualDays,
      "customBillingCycle": date,
      "customEndDate":endDate,
    }

    const res = await dispatch(createSubscription(selectedPlan, props?.id, data))

    if (res.status === true) {
      props?.onSubmit()
    }
    else {
      setError(res?.message)
    }


  };



  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', alignItems: 'center' }}>
        <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>
          {"Add Plan"}
        </h4>
        <Cancel onClick={() => {
          props?.onClose()
        }} style={{ cursor: 'pointer' }} />
      </div>
      {loader ? <Spinner /> : <>
        <FormGroup>
          <Label htmlFor="servicePlan">Select Service Plan</Label>
          <Select
            id="servicePlan"
            value={selectedPlan}
            onChange={(e) => setSelectedPlan(e.target.value)}
            required
          >
            <option value="" disabled>
              Choose a plan
            </option>
            {
              servicePlan?.map((item) =>
                <option value={item?.priceId}>{item?.name}</option>
              )
            }


          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Charge Type</Label>
          <RadioGroup style={{ marginBottom: '10px' }}>

            <RadioInput
              type="radio"
              id="charge_automatically"
              name="chargeType"
              value="charge_automatically"
              checked={chargeType === "charge_automatically"}
              onChange={(e) => setChargeType(e.target.value)}
            />
            <Label style={{ marginBottom: '0px' }} htmlFor="charge_automatically">Automatically charge a payment method file</Label>
          </RadioGroup>
          <RadioGroup>
            <RadioInput
              type="radio"
              id="send_invoice"
              name="chargeType"
              value="send_invoice"
              checked={chargeType === "send_invoice"}
              onChange={(e) => setChargeType(e.target.value)}
            />
            <Label style={{ marginBottom: '0px' }} htmlFor="send_invoice">Email invoice to the customer to pay manually</Label>
          </RadioGroup>
        </FormGroup>
        {chargeType === "send_invoice" && (
          <FormGroup>
            <Label htmlFor="manualDays">Payment due</Label>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
              <Input
                type="number"
                id="manualDays"
                value={manualDays}
                onChange={(e) => setManualDays(e.target.value)}
                placeholder="number"
                style={{ width: '15%', alignItems: 'center' }}
                required
              />
              <Label style={{ marginTop: '10px', marginLeft: '10px', color: palette.grey }}>days after invoice is sent</Label>
            </div>
          </FormGroup>
        )}
        <div style={{ display: 'flex', paddingBottom: '38px',paddingTop:'10px', gap: '15px' }}>
          <div>
            <Label>Start date</Label>
          <DatePickerComponent selectedDate={date} onDateChange={(date) => {
            setDate(date)
          }}/>
          </div>
          <div>
            <Label>End date</Label>
          <DatePickerWithShortcuts selectedDate={endDate} startDate={date} onDateChange={(date) => {
            setEndDate(date)
          }} />
          </div>
        </div>

        {
          error ? <div>
            <br />
            <p style={{ fontSize: '14px', color: '#D81159', textAlign: 'start', marginTop: '-20px' }}>{error}</p>
          </div> : null
        }
        <RippleButton onClick={() => {
          handleFormSubmit()
        }} style={{ width: '100%', height: '40px' }} type="submit">Submit</RippleButton>
      </>}

    </Container>
  );
};

export default ServicePlanForm;

const Container = styled.div`

  padding: 20px 0px;
  border-radius: 8px;
  background-color: #fff;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  margin-top:35px;

`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align:start;
  font-size:14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 13px;
  height:35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;

  color: #344054;
  background: #fff url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const RadioInput = styled.input`
  margin-right: 0px;
`;

const Input = styled.input`
  width: 96%;
  padding: 0.5rem;

  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;

const Button = styled.button`
  background-color: #0f52ba;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: #084a9c;
  }
`;
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #0f52ba;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  margin: 20px auto;
`;

