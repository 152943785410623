import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import DatePickerComponent from "../../../ReuseableComponets/DatePickerComponent";
import { getCommisiopReport, getTipReport } from "../../../store/Actions/PaymentAction";

const TipsReportPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  
  const dispatch = useDispatch();

  const handleGetTipReport = async() => {
    const res= await dispatch(getTipReport())
    if(res?.status===true){
        window.open(res?.downloadUrl)
    }
  }
  
  const handleGetReport = async () => {
    // Validate dates
    if (!startDate || !endDate) {
      setError("Please select both start and end dates");
      return;
    }
    
    if (new Date(startDate) > new Date(endDate)) {
      setError("Start date cannot be later than end date");
      return;
    }
    
    setError("");
    setIsLoading(true);
    
    try {
      const res = await dispatch(getCommisiopReport(startDate, endDate ));
      
      if (res?.success === true) {
        window.open(res?.reportUrl);
      } else {
        setError("Failed to generate report. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while generating the report");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <PageHeader>
        <Header> Reports</Header>
        <SubHeader>Generate and download detailed tip collection reports</SubHeader>
      </PageHeader>
      <ReportCard>
      <CardTitle>Generate Tip Report</CardTitle>
        <CardDescription>
          Generate detailed reports about tips collected. Click the button below to get your report.
        </CardDescription>
       
        <Button onClick={handleGetTipReport}>Generate Report</Button>
      </ReportCard>
      <ReportCard>
        <CardHeader>
          <CardTitle>Generate Commission Report</CardTitle>
          <CardDescription>
            Select a date range to generate a detailed report of Commission collected during that period
          </CardDescription>
        </CardHeader>
        
        <FormContainer>
          <div style={{display:'flex',gap:'20px'}}>
          <FormGroup>
            <Label htmlFor="startDate">Start Date</Label>
            <DatePickerComponent
              id="startDate"
              type="date"
              selectedDate={startDate}
              onDateChange={(e) =>{ 
                setError('')
                setStartDate(e)}}
              max={endDate || undefined}
            />
          </FormGroup>
          
          <FormGroup>
            <Label htmlFor="endDate">End Date</Label>
            <DatePickerComponent
              id="endDate"
              type="date"
              selectedDate={endDate}
              onDateChange={(e) =>{ 
                setError('')
                setEndDate(e)}}
              min={startDate || undefined}
            />
          </FormGroup>
          </div>
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
          
          <Button 
            onClick={handleGetReport} 
            disabled={isLoading}
          >
            {isLoading ? "Generating..." : "Generate & Download Report"}
          </Button>
        </FormContainer>
      </ReportCard>
      
      <InfoBox>
        <InfoIcon>ℹ️</InfoIcon>
        <InfoText>
          Reports are generated in CSV format and include detailed information about tips,
          including transaction name, dates, and amounts.
        </InfoText>
      </InfoBox>
    </Container>
  );
};

export default TipsReportPage;

const Container = styled.div`
  width: 100%;
  background: #f8f9fa;
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align:start;
  
  @media (min-width: 260px) and (max-width: 768px) {
    padding: 20px 15px;
  }
`;

const PageHeader = styled.div`
  margin-bottom: 30px;
`;

const Header = styled.h1`
  font-size: 28px;
  color: #2d3748;
  margin-bottom: 8px;
  font-weight: 600;
`;

const SubHeader = styled.p`
  font-size: 16px;
  color: #718096;
  margin: 0;
`;

const ReportCard = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 24px;
  max-width: 600px;
  width: 100%;
  
  @media (min-width: 260px) and (max-width: 768px) {
    padding: 20px;
  }
`;

const CardHeader = styled.div`
  margin-bottom: 24px;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 8px;
  margin-top:0px;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #718096;
  margin: 0;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 30px;
`;
const Button = styled.button`
  background-color: #0f52ba;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 14px;
  border: none;
  border-radius: 6px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  width: 100%;
  opacity: ${props => props.disabled ? 0.7 : 1};
  margin-top: 10px;

  &:hover {
    background-color: ${props => props.disabled ? '#0f52ba' : '#084a9b'};
    transform: ${props => props.disabled ? 'none' : 'translateY(-1px)'};
  }

  &:active {
    background-color: #063d82;
    transform: translateY(1px);
  }
`;

const ErrorMessage = styled.p`
  color: #e53e3e;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #ebf8ff;
  border-left: 4px solid #3182ce;
  padding: 16px;
  border-radius: 6px;
  max-width: 620px;
`;

const InfoIcon = styled.span`
  margin-right: 12px;
  font-size: 18px;
`;

const InfoText = styled.p`
  color: #2c5282;
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
`;

const Content = styled.div`
display: flex;
flex-direction: column;
text-align:start;
align-items: center;
justify-content: center;
background: #f9f9f9;
border: 1px solid #ddd;
border-radius: 10px;
padding: 20px;
margin-top:20px;
width: 300px;
cusrsor:pointer;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
transition: transform 0.2s, box-shadow 0.2s;

&:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
`;