import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "@fullcalendar/core";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import styled from "styled-components";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { getCalendarDataV2 } from "../../../store/Actions/dashboard.action";
import { palette } from "../../../utils/Palette";
import CustomToolbar from "./Component/CustomToolBar";
import TabComponent from "./Component/CustomTab";
import DispatchboardMap from "./Component/DispatchBoardMap";
import RightSideModal from "../../RightSideModal";
import JobDetails from "./Component/JobDetails";

const CalendarContainer = styled.div`
  width: 100%;
  max-height: 45%;
  height: 100%;
  overflow: visible;
  background-color: #fff;
  border-color: #eee;

  .calendar-wrapper {
    border-radius: 8px;
    height: 100%;
    position: relative;
    max-height: 100%;
  }

  .controls {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    padding: 0 20px;
  }

  button {
    padding: 8px 16px;
    background-color: ${palette.newBlue};
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;

    &:hover {
      background-color: #3367d6;
    }
  }

  .fc-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #fff;
  }
  .fc-timeline-slot {
    color: gray !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    font-size: 14px;
    border-color: #f3f3f3;
  }

  .fc-datagrid-header {
    background: #eee;
    font-weight: 500 !important;
    font-size: 14px;
    border-color: #f3f3f3;
    color: grey;
  }
  .fc-datagrid-body {
    font-weight: 400 !important;
    font-size: 14px;
    color: #000;
  }
  .fc-cell-shaded {
    border: none;
  }
  .fc-timeline-header-row {
    background: #eee;
    font-weight: 500 !important;
    font-size: 14px;
    border-color: #f3f3f3;
  }
  .fc-timeline-slot-major {
    color: #ddd;
    font-weight: 500 !important;
    text-transform: uppercase !important;

    border-color: #eee;
  }

  .fc-slot-past {
    color: gray !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    border-color: #f3f3f3;
  }

  .fc-slot-today {
    color: grey !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    font-size: 14px;
    border-color: #f3f3f3;
  }

  .fc-view-harness {
    background-color: #fff;
    border-color: #f3f3f3;
  }

  .fc-daygrid-day {
    background-color: #fff;
    border-color: #f3f3f3;
  }

  .fc-daygrid-day-top {
    text-align: center;
    font-size: 12px;
    color: rgba(32, 32, 32, 0.55);
  }
  .fc-datagrid-cell-frame {
    display: flex;
    align-items: start;
    padding-top: 4px;
  }

  .fc-daygrid-day-number {
    background: #eee;
    padding: 3px;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
  }

  .fc-daygrid-day-events {
    overflow: visible;
  }

  .fc-daygrid-more-link {
    color: grey;
    border: none;
    border-radius: 5px;
    padding: 4px;
    font-size: 12px;
    cursor: pointer;
    background: #fff;
    text-decoration: underline;
  }

  .fc-datagrid-cell {
    display: flex;
    align-items: start;
    gap: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .fc-resource {
    display: flex;
    min-height: 42px;
    border-top: none;
    border-bottom: 1px solid #eee;
    border-left: none;
    border-right: none;
  }

  .fc-daygrid-more-link:hover {
    background: #eee;
  }

  .fc-timegrid {
    border-color: #fff;
    border: 0.5px solid #eee;
  }

  .fc-timegrid-slot {
    border-top: 1px dotted #eee;
  }

  .fc-event {
    cursor: pointer;
    transition: all 0.2s;
    padding: 6px;
    margin: 2px 0px;

    &:hover {
      filter: brightness(99%);
    }
  }
  .fc-event-title-container {
    display: flex;
    align-items: center;
  }

  .fc-event-main {
    font-size: 12px;
  }

  .fc-resource-timeline .fc-resource-cell {
    font-weight: 600;
  }

  .fc-timegrid-slot-label {
    border-color: #eee;
    text-align: center;
  }

  .fc-button {
    background: white !important;
    border: 1px solid #d1d5db !important;
    color: black !important;
    padding: 5px 12px;
    border-radius: 8px;
  }

  .fc-button-active {
    // background: #0f52ba !important;
    // color: white !important;
  }

  .fc-filterButton-button {
    background: #6b46c1 !important;
    color: white !important;
    border-radius: 20px;
    padding: 6px 14px;
    position: relative;
  }

  .fc-filterButton-button::after {
    content: "1";
    position: absolute;
    top: 3px;
    right: 6px;
    background: white;
    color: #6b46c1;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 50%;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    div {
      padding: 15px 25px;
      background: white;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      font-weight: 500;
    }
  }

  .fc-timegrid-event {
    border-left: 4px solid red;
  }

  .fc-timegrid-slots {
    border-color: #eee;
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 240px);
  overflow-x: scroll;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 240px;
`;

const ResourceTimelineCalendar = () => {
  const calendarRef = useRef(null);
  const calendarInstanceRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [currentView, setCurrentView] = useState("resourceTimelineWeek");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState(new Date());
  const dispatch = useDispatch();

  const startDate = (date) => {
    const start = moment(date).startOf("day").format("YYYY-MM-DD");
    const end = moment(date).endOf("day").add(1, "days").format("YYYY-MM-DD");
    return { start, end };
  };

  const initCalendarData = async () => {
    setIsLoading(true);
    try {
      const { start, end } = startDate(new Date());
      const res1 = await dispatch(getCalendarDataV2(start, end));

      let eventArr = [];
      let teamArr = [];

      const dataarr = [...(res1?.data?.detailData || [])];
      dataarr?.forEach((item, index) => {
        const newDate = item?.timezone
          ? moment.tz(item?.date, item?.timezone).format("DD-MMM-YYYY")
          : moment(item?.date).format("DD-MMM-YYYY");

        const newEndDate = item?.timezone
          ? moment.tz(item?.endDate, item?.timezone).format("DD-MMM-YYYY")
          : moment(item?.endDate).format("DD-MMM-YYYY");

        const newTime = item?.timezone
          ? moment.tz(item?.time, item?.timezone).format("HH:mm")
          : moment(item?.time).format("HH:mm");

        const newEndTime = item?.timezone
          ? moment.tz(item?.endTime, item?.timezone).format("HH:mm")
          : moment(item?.endTime).format("HH:mm");

        let startdat = new Date(
          moment(newDate, "DD-MMM-YYYY").year(),
          moment(newDate, "DD-MMM-YYYY").month(),
          moment(newDate, "DD-MMM-YYYY").date(),
          moment(newTime, "HH:mm").hours(),
          moment(newTime, "HH:mm").minutes()
        );

        if (!isNaN(startdat)) {
          const enddat =
            item?.endDate && item?.endTime
              ? new Date(
                  moment(newEndDate, "DD-MMM-YYYY").year(),
                  moment(newEndDate, "DD-MMM-YYYY").month(),
                  moment(newEndDate, "DD-MMM-YYYY").date(),
                  moment(newEndTime, "HH:mm").hours(),
                  moment(newEndTime, "HH:mm").minutes()
                )
              : new Date(
                  moment(newDate, "DD-MMM-YYYY").year(),
                  moment(newDate, "DD-MMM-YYYY").month(),
                  moment(newDate, "DD-MMM-YYYY").date(),
                  moment(newTime, "HH:mm").hours() + 1,
                  moment(newTime, "HH:mm").minutes()
                );

          const teamMembers =
            item?.team?.length > 0
              ? item.team
              : [{ firstName: "Unassigned jobs" }];

          teamMembers.forEach((member, idx) => {
            eventArr.push({
              id: `event-${index}-${member?.firstName || "Unassigned"}`,
              resourceId: member?.firstName || "Unassigned jobs",
              title: item?.name || item?.to || item?.title,
              display: idx === 0 ? true : false,
              start: startdat,
              end: enddat,
              color: "#daf0ff",
              extendedProps: {
                description:
                  item?.customerAddress || item?.task || item?.description,
                status: item?.bookingStatus,
                type: item?.type,
                data: item,
                travelDetails: (() => {
                  if (item?.tripInfo) {
                    if (item.tripInfo[member.userId]) {
                      return item.tripInfo[member.userId];
                    }
                  }
                  return null;
                })(),
              },
            });
          });
        }
      });

      const calendarTeamColor = [
        { light: "#D6E3F6", original: "#1976D2" },
        { light: "#D6F0E0", original: "#2E7D32" },
        { light: "#F9E9D6", original: "#ED6C02" },
        { light: "#F5D6EB", original: "#9C27B0" },
        { light: "#FFD6D6", original: "#D32F2F" },
        { light: "#D6F5F5", original: "#0097A7" },
      ];

      res1?.data?.team?.forEach((item, index) => {
        const colorIndex = index % calendarTeamColor.length;
        teamArr.push({
          id: item.firstName,
          title: item.firstName,
          eventColor: calendarTeamColor[colorIndex]?.light,
          eventBorderColor: calendarTeamColor[colorIndex]?.original,
        });
      });

      teamArr.unshift({
        id: "Unassigned jobs",
        title: "Unassigned jobs",
        eventColor: "#D6E3F6",
        eventBorderColor: "#1976D2",
      });

      const formattedEvents = eventArr.map((event) => ({
        ...event,
        start: event.start.toISOString(),
        end: event.end.toISOString(),
      }));

      setEvents(formattedEvents);
      setResources([...teamArr]);

      initializeCalendar();
      if (calendarInstanceRef.current) {
        calendarInstanceRef.current.setOption("resources", []);
        calendarInstanceRef.current.setOption("resources", teamArr);
        calendarInstanceRef.current.removeAllEvents();
        formattedEvents.forEach((event) => {
          calendarInstanceRef.current.addEvent(event);
        });
      }
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeRender = async (date) => {
    setSelected(date)
    setIsLoading(true);
    try {
      const { start, end } = startDate(date);
      const res1 = await dispatch(getCalendarDataV2(start, end));

      let eventArr = [];
      let teamArr = [];

      const dataarr = [...(res1?.data?.detailData || [])];
      dataarr?.forEach((item, index) => {
        const newDate = item?.timezone
          ? moment.tz(item?.date, item?.timezone).format("DD-MMM-YYYY")
          : moment(item?.date).format("DD-MMM-YYYY");

        const newEndDate = item?.timezone
          ? moment.tz(item?.endDate, item?.timezone).format("DD-MMM-YYYY")
          : moment(item?.endDate).format("DD-MMM-YYYY");

        const newTime = item?.timezone
          ? moment.tz(item?.time, item?.timezone).format("HH:mm")
          : moment(item?.time).format("HH:mm");

        const newEndTime = item?.timezone
          ? moment.tz(item?.endTime, item?.timezone).format("HH:mm")
          : moment(item?.endTime).format("HH:mm");

        let startdat = new Date(
          moment(newDate, "DD-MMM-YYYY").year(),
          moment(newDate, "DD-MMM-YYYY").month(),
          moment(newDate, "DD-MMM-YYYY").date(),
          moment(newTime, "HH:mm").hours(),
          moment(newTime, "HH:mm").minutes()
        );

        if (!isNaN(startdat)) {
          const enddat =
            item?.endDate && item?.endTime
              ? new Date(
                  moment(newEndDate, "DD-MMM-YYYY").year(),
                  moment(newEndDate, "DD-MMM-YYYY").month(),
                  moment(newEndDate, "DD-MMM-YYYY").date(),
                  moment(newEndTime, "HH:mm").hours(),
                  moment(newEndTime, "HH:mm").minutes()
                )
              : new Date(
                  moment(newDate, "DD-MMM-YYYY").year(),
                  moment(newDate, "DD-MMM-YYYY").month(),
                  moment(newDate, "DD-MMM-YYYY").date(),
                  moment(newTime, "HH:mm").hours() + 1,
                  moment(newTime, "HH:mm").minutes()
                );

          const teamMembers =
            item?.team?.length > 0
              ? item.team
              : [{ firstName: "Unassigned jobs" }];

          teamMembers.forEach((member, idx) => {
            eventArr.push({
              id: `event-${index}-${member?.firstName || "Unassigned"}`,
              resourceId: member?.firstName || "Unassigned jobs",
              title: item?.name || item?.to || item?.title,
              start: startdat,
              display: idx === 0 ? true : false,
              end: enddat,
              color: "#daf0ff",
              extendedProps: {
                description:
                  item?.customerAddress || item?.task || item?.description,
                status: item?.bookingStatus,
                type: item?.type,
                data: item,
                travelDetails: (() => {
                  if (item?.tripInfo) {
                    if (item.tripInfo[member.userId]) {
                      return item.tripInfo[member.userId];
                    }
                  }
                  return null;
                })(),
              },
            });
          });
        }
      });
      const calendarTeamColor = [
        { light: "#D6E3F6", original: "#1976D2" },
        { light: "#D6F0E0", original: "#2E7D32" },
        { light: "#F9E9D6", original: "#ED6C02" },
        { light: "#F5D6EB", original: "#9C27B0" },
        { light: "#FFD6D6", original: "#D32F2F" },
        { light: "#D6F5F5", original: "#0097A7" },
      ];

      res1?.data?.team?.forEach((item, index) => {
        const colorIndex = index % calendarTeamColor.length;
        teamArr.push({
          id: item.firstName,
          title: item.firstName,

          eventColor: calendarTeamColor[colorIndex]?.light,
          eventBorderColor: calendarTeamColor[colorIndex]?.original,
        });
      });

      teamArr.unshift({
        id: "Unassigned jobs",
        title: "Unassigned jobs",
        // businessHours: { startTime: "08:00", endTime: "18:00" },
        eventColor: "#D6E3F6",
        eventBorderColor: "#1976D2",
      });

      const formattedEvents = eventArr.map((event) => ({
        ...event,
        start: event.start.toISOString(),
        end: event.end.toISOString(),
      }));

      setEvents(formattedEvents);
      setResources([...teamArr]);
      console.log(teamArr, "scd");
      calendarInstanceRef.current.setOption("events", formattedEvents);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await initCalendarData();
    };

    fetchData();
  }, []);

  const initializeCalendar = () => {
    if (!calendarRef.current) return;
    calendarInstanceRef.current = new Calendar(calendarRef.current, {
      plugins: [resourceTimelinePlugin],
      initialView: "resourceTimelineDay",
      schedulerLicenseKey: "0310982610-fcs-1740640118",
      headerToolbar: false,
      customButtons: {
        timeDropdown: {
          text: "Daily",
          click: function () {
            alert("Open dropdown");
          },
        },
        datePicker: {
          text: moment(new Date())?.format("ddd, MMM DD YYYY"),
          click: function () {
            alert("Open date picker");
          },
        },
        filterButton: {
          text: "🔍 1",
          click: function () {
            alert("Filter clicked");
          },
        },
      },

      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      nowIndicator: true,
      height: "100%",
      resources: resources,
      resourceLabelText: "Team Members",
      resourceAreaWidth: "20%",
      resourceAreaHeaderContent: "Team Members",
      events: events,
      eventClick: handleEventClick,
      eventMouseEnter: handleEventMouseEnter,
      eventMouseLeave: handleEventMouseLeave,
      dateClick: handleDateClick,
      select: handleDateSelect,
      eventDrop: handleEventDrop,
      eventResize: handleEventResize,
      eventContent: (arg) => renderEventContent(arg),
      slotDuration: "00:30:00",
      slotLabelInterval: "01:00:00",
      slotMinWidth: 100,
      businessHours: false,

      eventDidMount: (info) => {
        const event = info.event;
        const el = info.el;
        let backgroundColor =
          event.extendedProps.type === "google"
            ? "#FFF8E1"
            : event.extendedProps.type === "task"
            ? "#FFEBEE"
            : event.extendedProps.type === "event"
            ? "#E8F5E9"
            : event.extendedProps.type === "multivist"
            ? "#eee"
            : "#E3F2FD";

        let color =
          event.extendedProps.type === "google"
            ? "#FED000"
            : event.extendedProps.type === "task"
            ? "red"
            : event.extendedProps.type === "event"
            ? "green"
            : event.extendedProps.type === "multivist"
            ? "#6C757D"
            : palette.newBlue;

        el.style.backgroundColor = backgroundColor;
        el.style.borderRadius = "5px";
        el.style.opacity = "1";
        el.style.border = `1.5px solid ${color}`;
        el.style.fontWeight = "500";
        if (info.event.extendedProps?.travelDetails?.travelTime) {
          el.style.height = "40px";
        }

        const titleEl = el.querySelector(".fc-event-title");
        const timeEl = el.querySelector(".fc-event-time");

        if (titleEl) titleEl.style.color = "black";
        if (timeEl) timeEl.style.color = "black";
        el.querySelectorAll("*").forEach((child) => {
          child.style.color = "black";
        });
        el.style.fontSize = "12px";
        el.style.marginLeft = "3px";
        el.style.display = "block";
      },

      resourceLabelDidMount: (info) => {
        const resource = info.resource;
        const nameParts = resource.title.split(" ");
        const initials =
          nameParts.length > 1
            ? nameParts[0][0] + nameParts[1][0]
            : nameParts[0][0];

        info.el.innerHTML = `


            <div style="
              width: 25px; 
              height: 25px; 
              border-radius: 50%; 
              background: linear-gradient(135deg, #959aa6 0%, #2c3e50 100%); 
              margin-left:10px;
             margin-top:8px;
              color: #fff; 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              font-size: 11px; 
              font-weight: 500;
            ">
              ${initials.toUpperCase()}
            </div>
        
        `;
      },
    });
    calendarInstanceRef.current.render();
  };

  const renderEventContent = (arg) => {
    const status = arg.event.extendedProps.status;
    const type = arg.event.extendedProps.type;

    const mainFrame = document.createElement("div");
    mainFrame.className = "fc-event-main-frame";
    mainFrame.style.display = "flex";
    mainFrame.style.alignItems = "center";

    const titleContainer = document.createElement("div");
    titleContainer.className = "fc-event-title-container";
    titleContainer.style.display = "flex";
    titleContainer.style.alignItems = "center";
    titleContainer.style.gap = "4px";
    titleContainer.style.width = "100%";
    titleContainer.style.overflow = "hidden";
    mainFrame.appendChild(titleContainer);

    console.log(arg.event?.extendedProps);

    const typeIcon = document.createElement("span");
    typeIcon.className = "material-icons";
    typeIcon.style.fontSize = "16px";
    typeIcon.style.color = "#555";
    typeIcon.style.flexShrink = "0";

    if (type === "google") {
      typeIcon.textContent = "calendar_today";
    } else if (type === "task") {
      typeIcon.textContent = "task_alt";
    } else if (type === "event") {
      typeIcon.textContent = "event";
    } else if (type === "multivist") {
      typeIcon.textContent = "sync";
    } else {
      if (status === "Completed") {
        typeIcon.textContent = "check_circle";
      } else {
        typeIcon.textContent = "schedule";
      }
    }

    const statusPill = document.createElement("span");
    statusPill.className = "fc-status-pill";
    if (status === "Waiting for confirmation") {
      statusPill.style.backgroundColor = "#045ad0";
    } else if (status === "In Progress") {
      statusPill.style.backgroundColor = "#29740f";
    } else if (status === "Completed") {
      statusPill.style.backgroundColor = "#004D4D";
    } else {
      statusPill.style.backgroundColor = "#fa811f";
    }

    if (type === "task" && arg.event.extendedProps?.data?.status) {
      statusPill.textContent = arg.event.extendedProps.data.status;
    } else if (status === "Waiting for confirmation") {
      statusPill.textContent = "Waiting";
    } else {
      statusPill.textContent = status || "";
    }

    const style = document.createElement("style");
    style.textContent = `
      .fc-status-pill {
        color: #ffffff !important;
        border-radius: 2px;
        padding: 2px 5px;
        font-size: 11px;
        margin-right: 5px;
        flex-shrink: 0;
        font-weight: 300;
        font-size:10px;
      }
    `;
    document.head.appendChild(style);
    const titleElement = document.createElement("div");
    titleElement.className = "fc-event-title";
    titleElement.textContent = arg.event.title;
    titleElement.style.overflow = "hidden";
    titleElement.style.textOverflow = "ellipsis";
    titleElement.style.whiteSpace = "nowrap";

    const travelTimeBar = document.createElement("div");
    travelTimeBar.style.position = "absolute";
    travelTimeBar.style.bottom = "-10px";
    travelTimeBar.style.left = `-${
      Math.floor(arg.event.extendedProps?.travelDetails?.travelTime / 60000) *
      3.53
    }px`;
    travelTimeBar.style.height = "2px";
    travelTimeBar.style.border = "1px dashed grey";
    travelTimeBar.style.width = `${
      Math.floor(arg.event.extendedProps?.travelDetails?.travelTime / 60000) *
      3.63
    }px`;
    travelTimeBar.style.cursor = "ew-resize";

    const createTooltip = (text) => {
      const tooltip = document.createElement("div");
      tooltip.style.position = "absolute";
      tooltip.style.padding = "8px 8px";
      tooltip.textContent = text;
      tooltip.style.background = "#ccc";
      tooltip.style.color = "#fff";
      tooltip.style.fontSize = "12px";
      tooltip.style.borderRadius = "4px";
      tooltip.style.visibility = "hidden";
      tooltip.style.whiteSpace = "nowrap";
      tooltip.style.display = "inline-block";
      tooltip.style.whiteSpace = "nowrap";
      tooltip.style.transition = "opacity 0.2s, transform 0.2s";
      tooltip.style.transform = "translateX(-50%)";
      tooltip.style.marginTop = "-10px";
      tooltip.style.height = "30px";
      tooltip.style.left = "50%";

      const arrow = document.createElement("div");
      arrow.style.position = "absolute";
      arrow.style.bottom = "-5px";
      arrow.style.left = "50%";
      arrow.style.transform = "translateX(-50%)";
      arrow.style.width = "0";
      arrow.style.height = "0";
      arrow.style.borderLeft = "5px solid transparent";
      arrow.style.borderRight = "5px solid transparent";
      arrow.style.borderTop = "5px solid #ccc";

      tooltip.appendChild(arrow);
      return tooltip;
    };

    const startTooltip = createTooltip("Dispatched");
    const endTooltip = createTooltip("Start job");

    const startDot = document.createElement("div");
    startDot.style.position = "absolute";
    startDot.style.left = "0";
    startDot.style.top = "50%";
    startDot.style.transform = "translate(-50%, -50%)";
    startDot.style.width = "8px";
    startDot.style.height = "8px";
    startDot.style.backgroundColor = "grey";
    startDot.style.borderRadius = "50%";
    startDot.style.cursor = "pointer";
    startDot.appendChild(startTooltip);

    startDot.addEventListener("mouseenter", () => {
      startTooltip.style.visibility = "visible";
      startTooltip.style.top = "-20px";
    });
    startDot.addEventListener("mouseleave", () => {
      startTooltip.style.visibility = "hidden";
    });

    const endDot = document.createElement("div");
    endDot.style.position = "absolute";
    endDot.style.right = "0";
    endDot.style.top = "50%";
    endDot.style.transform = "translate(50%, -50%)";
    endDot.style.width = "8px";
    endDot.style.height = "8px";
    endDot.style.backgroundColor = "blue";
    endDot.style.borderRadius = "50%";
    endDot.style.cursor = "pointer";
    endDot.appendChild(endTooltip);
    endDot.addEventListener("mouseenter", () => {
      endTooltip.style.visibility = "visible";
      endTooltip.style.top = "-20px";
    });
    endDot.addEventListener("mouseleave", () => {
      endTooltip.style.visibility = "hidden";
    });
    travelTimeBar.appendChild(startDot);
    travelTimeBar.appendChild(endDot);

    // Optional: Add description if available
    if (arg.event?.extendedProps?.description) {
      titleElement.textContent += ` | ${arg.event.extendedProps.description}`;
    }
    const handleResize = () => {
      const eventWidth = mainFrame.offsetWidth;
      if (eventWidth < 100) {
        statusPill.style.display = "none";
        if (eventWidth < 50) {
          // For very small events, just show an ellipsis
          titleElement.textContent = "...";
        }
      } else {
        statusPill.style.display = "inline-block";
      }
    };
    setTimeout(handleResize, 0);
    if (typeof ResizeObserver !== "undefined") {
      const ro = new ResizeObserver(handleResize);
      ro.observe(mainFrame);
    }

    titleContainer.appendChild(typeIcon);
    titleContainer.appendChild(statusPill);
    titleContainer.appendChild(titleElement);

    if (arg.event.extendedProps?.travelDetails?.travelTime) {
      titleContainer.appendChild(travelTimeBar);
    }

    return { domNodes: [mainFrame] };
  };

  const handleEventClick = (info) => {

    const eventData = {
        id: info.event.id,
        title: info.event.title,
        start: info.event.start,
        end: info.event.end,  
        allDay: info.event.allDay,
        extendedProps: info.event.extendedProps,
    };
    if(info?.event?.extendedProps?.type==='event'||info?.event?.extendedProps?.type==='task'){

    }
    else{
    setIsModalOpen(true);
    setSelectedEvent(eventData);
    }

  };

  /**
   * Format a date for display
   */
  const formatDateTime = (date) => {
    if (!date) return "N/A";
    return new Date(date).toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const handleEventMouseEnter = (info) => {
    setTooltipInfo({
      event: info.event,
      position: {
        x: info.jsEvent.pageX,
        y: info.jsEvent.pageY,
      },
    });
  };

  const handleEventMouseLeave = () => {
    setTooltipInfo(null);
  };

  const handleDateClick = (info) => {
    console.log("Date clicked:", info.dateStr);
  };

  const handleDateSelect = (info) => {
    if (
      window.confirm(
        `Would you like to create an event from ${formatDateTime(
          info.start
        )} to ${formatDateTime(info.end)}?`
      )
    ) {
      const title =
        window.prompt("Please enter a title for your event") ||
        "Untitled Event";

      calendarInstanceRef.current.addEvent({
        id: `event-${Date.now()}`,
        title,
        start: info.startStr,
        end: info.endStr,
        resourceId: info.resource ? info.resource.id : "Unassigned jobs",
        allDay: info.allDay,
        color: getRandomColor(),
      });
    }

    calendarInstanceRef.current.unselect();
  };

  const getRandomColor = () => {
    const colors = [
      "#daf0ff",
      "#D6F0E0",
      "#F9E9D6",
      "#F5D6EB",
      "#FFD6D6",
      "#D6F5F5",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handleEventDrop = (info) => {
    console.log(
      `Event "${info.event.title}" was moved to ${formatDateTime(
        info.event.start
      )}`
    );

    simulateApiCall(
      () => {
        console.log("Event moved successfully");
      },
      () => {
        info.revert();
        console.error("Failed to move event, changes reverted");
      }
    );
  };

  const handleEventResize = (info) => {
    console.log(
      `Event "${info.event.title}" was resized to ${formatDateTime(
        info.event.start
      )} - ${formatDateTime(info.event.end)}`
    );

    simulateApiCall(
      () => {
        console.log("Event resized successfully");
      },
      () => {
        info.revert();
        console.error("Failed to resize event, changes reverted");
      }
    );
  };

  const simulateApiCall = (
    onSuccess,
    onError = null,
    delay = 500,
    shouldFail = false
  ) => {
    setTimeout(() => {
      if (shouldFail && onError) {
        onError();
      } else {
        onSuccess();
      }
    }, delay);
  };

  const refreshCalendarData = () => {
    initCalendarData();
  };

  const handleViewChange = (viewName) => {
    setCurrentView(viewName);
  };

  return (
    <AppContainer>
      <div style={{ height: "100vh", overflow: "scroll" }}>
        <CustomToolbar
          calendarInstance={calendarInstanceRef.current}
          currentView={currentView}
          onViewChange={handleViewChange}
          onChangeDate={(date) => onChangeRender(date)}
        />

        <CalendarContainer>
          {/* <div className="controls">
          <button onClick={refreshCalendarData}>Refresh Calendar Data</button>
        </div> */}

          <div className="calendar-wrapper">
            {isLoading && (
              <div className="loading-overlay">
                <div> Refreshing data...</div>
              </div>
            )}
            <div ref={calendarRef}></div>
          </div>
        </CalendarContainer>
        <TabComponent events={events} />
        <DispatchboardMap />
      </div>
      {ismodalOpen && (
        <RightSideModal isOpen={ismodalOpen} onClose={()=>{
            setIsModalOpen(false)
            onChangeRender(selected)
        }}>
          <JobDetails 
          data={selectedEvent}
          
          />
        </RightSideModal>
      )}
    </AppContainer>
  );
};

export default ResourceTimelineCalendar;
