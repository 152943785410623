import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

const TabButton = styled.button`
  flex: 1;
  padding: 8px 15px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: ${({ isLeft }) => (isLeft ? "8px" : "8px")};
  background: ${({ isActive, activeTabBackgroundColor }) =>
    isActive ? activeTabBackgroundColor : "#eee"};
  color: ${({ isActive, activeTabColor }) =>
    isActive ? activeTabColor : "rgba(32, 32, 32, 0.85)"};
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: ${({ isActive, activeTabBackgroundColor }) =>
      isActive ? activeTabBackgroundColor : "#ddd"};
  }

  svg {
    font-size: 12px;
      color: ${({ isActive, activeTabColor }) =>
    isActive ? activeTabColor : "rgba(32, 32, 32, 0.55)"};
  }
`;

const ToggleTabsWithIcon = ({
  tabs,
  onToggle,
  activeTabColor,
  activeTabBackgroundColor,
  customStyle,
  selected,
}) => {
  const [activeTab, setActiveTab] = useState(
    selected ? selected : tabs[0].label
  );

  const handleClick = (tab) => {
    setActiveTab(tab.label);
    if (onToggle) {
      onToggle(tab);
    }
  };

  return (
    <div
      style={{
        background: "#eee",
        padding: "3.5px",
        borderRadius: "8px",
        ...customStyle,
      }}
    >
      <TabContainer style={customStyle}>
        {tabs.map((tab, index) => (
          <TabButton
            key={tab.label}
            isLeft={index === 0}
            isActive={activeTab === tab.label}
            activeTabBackgroundColor={activeTabBackgroundColor}
            activeTabColor={activeTabColor}
            onClick={() => handleClick(tab)}
          >
            {tab.icon && tab.icon}
            {tab.label}
            {`  (${tab?.number||0})`}
          </TabButton>
        ))}
      </TabContainer>
    </div>
  );
};

ToggleTabsWithIcon.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node, 
    })
  ).isRequired,
  onToggle: PropTypes.func,
  activeTabColor: PropTypes.string,
  activeTabBackgroundColor: PropTypes.string,
  selected: PropTypes.string,
};

ToggleTabsWithIcon.defaultProps = {
  onToggle: null,
  activeTabColor: "white",
  activeTabBackgroundColor:'linear-gradient(135deg, #4c87c9 0%, #1a5bb0 100%)'
};

export default ToggleTabsWithIcon;
