import * as React from "react";
import { useState } from "react";
import ToggleTabsWithIcon from "../../../../ReuseableComponets/ToggleTabWithIcon";
import {
  FaUsers,
  FaClipboardList,
  FaTasks,
  FaRegCalendarAlt,
  FaClock,
  FaPause,
  FaCheckCircle,
  FaForward,
} from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { palette } from "../../../../utils/Palette";
import { TableHead } from "@mui/material";
import { formatMobileNumber } from "../../../../utils/Commonfunction";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const tabs = [
  { label: "Unassigned", icon: <FaUsers />, filterKey: "Unassigned jobs" },
  { label: "Visits", icon: <FaClipboardList />, filterKey: "multivist" },
  { label: "Tasks", icon: <FaTasks />, filterKey: "task" },
  { label: "Events", icon: <FaRegCalendarAlt />, filterKey: "event" },
  {
    label: "Waiting",
    icon: <FaClock />,
    filterKey: "Waiting for confirmation",
  },
  { label: "On Hold", icon: <FaPause />, filterKey: "On Hold" },
  { label: "In Progress", icon: <FaForward />, filterKey: "In Progress" },
  { label: "Completed", icon: <FaCheckCircle />, filterKey: "Completed" },
];

export default function TabComponent({ events }) {
  console.log(events, "scds");
  const [selectedTab, setSelectedTab] = useState(tabs[0].filterKey);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const navigate=useNavigate()

  React.useEffect(() => {
    if (!events) return;

    setFilteredEvents(
      events.filter(
        (item) =>
          item.resourceId === selectedTab ||
          item.extendedProps?.type === selectedTab ||
          item.extendedProps?.status === selectedTab
      )
    );
  }, [events, selectedTab]);

  const handleTabChange = (tab) => {
    console.log(tab, "cdds");
    setSelectedTab(tab.filterKey);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredEvents.length)
      : 0;

  return (
    <div>
      <ToggleTabsWithIcon
        tabs={tabs.map((tab) => ({
          ...tab,
          label: tab.label,
          icon: tab.icon,
          number: events.filter(
            (item) =>
              item?.display&&(item.resourceId === tab.filterKey ||
              item.extendedProps?.type === tab.filterKey ||
              item.extendedProps?.status === tab.filterKey)
          ).length,
        }))}
        onToggle={handleTabChange}
        customStyle={{ borderRadius: "0px"}}
      />

      <CustomTable>
        <Table sx={{ minWidth: 500,marginTop:'15px' }} aria-label="events table">
          <CustomHead>
            <TableRow>
              <TableCell size="small">
                Customer name
             
              </TableCell>
              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Start
              </TableCell>
              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                End
              </TableCell>
              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
                Address
              </TableCell>

              <TableCell
                style={{
                  position: "relative",
                  color: palette.black,
                  fontWeight: "700",
                }}
                size="small"
              >
               Phone number
              </TableCell>

            
            </TableRow>
          </CustomHead>
          <TableBody >
            {filteredEvents
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event, index) => (event?.display&&
                <TableRow style={{cursor:'pointer'}} key={index} onClick={() => {
                    if(event?.extendedProps?.type==='event'||event?.extendedProps?.type==='task'){

                    }
                    else{
                    navigate(`/dashboard/servicerequests/${event.extendedProps?.data?.referenceNo}`)
                    }
                }}>
                  <TableCell onClick={(e)=>{
                    e.stopPropagation()
                    console.log(                          event.extendedProps.data)
                    navigate(`/dashboard/contacts/${event.extendedProps.data.customerId}`)
                    
                  }} size="small">{event.title}</TableCell>
                  <TableCell size="small">
                    {" "}
                    <span
                      style={{
                        color:
                          event.extendedProps?.status ===
                          "Waiting for confirmation"
                            ? "#045ad0"
                            : event.extendedProps?.status === "In Progress"
                            ? "#29740f"
                            : event.extendedProps?.status === "Completed"
                            ? "#004D4D"
                            : "#fa811f",
                        marginRight: "5px",
                      }}
                    >
                      ●
                    </span>{" "}
                    {event.extendedProps?.type === "task"
                      ? event.extendedProps?.data?.status
                      : event.extendedProps?.status ===
                        "Waiting for confirmation"
                      ? "Waiting"
                      : event.extendedProps?.status}
                  </TableCell>
                  <TableCell size="small">
                    {event.extendedProps?.type
                      ? event.extendedProps.type.charAt(0).toUpperCase() +
                        event.extendedProps.type.slice(1)
                      : ""}
                  </TableCell>
                  <TableCell size="small">
                    {moment(event?.start)?.format('DD MMM, hh:mm A')}
                  </TableCell>
                  <TableCell size="small">
                  {moment(event?.end)?.format('DD MMM, hh:mm A')}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: "180px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    size="small"
                  >
                    {event.extendedProps?.description || "-"}
                  </TableCell>
                  <TableCell size="small">
                    {formatMobileNumber(
                      event.extendedProps?.data?.phone ||
                        event.extendedProps?.data?.number ||
                        event.extendedProps?.data?.customerNumber
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 43 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <CustomTablePagination
                rowsPerPageOptions={[4, 10]}
                colSpan={7}
                count={filteredEvents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ height: "20px" }}
                style={{ height: "20px" }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CustomTable>
    </div>
  );
}

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none;
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 400;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left: 1px solid #f3f3f3;
    font-weight: 700;
    color: ${palette.newBlue};
    z-index: 1;
    min-width: 150px;
  }
  .MuiTablePagination-root {
    height: 30px !important;
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background: #f8f8f8;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;
  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 150px;
  }
`;
const CustomTablePagination = styled(TablePagination)`
  && {
    height: 40px;
    min-height: 40px;

    .MuiTablePagination-toolbar {
      height: 40px;
      min-height: 40px;
      padding: 0 10px;
    }

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-select,
    .MuiTablePagination-selectIcon,
    .MuiTablePagination-displayedRows,
    .MuiTablePagination-actions {
      color: ${palette.black};
      font-family: Inter;
      font-size: 12px;
    }

    .MuiIconButton-root {
      color: ${palette.black};

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
`;
