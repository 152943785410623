import React from 'react';

const CustomSVGMarker = ({ 
  text = 'A', 
  backgroundColor = '#4A90E2', 
  textColor = '#0F52BA',
  width = 52,
  height = 62,
  textSize = 14,
  borderWidth = 1.5 
}) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width} 
      height={height} 
      viewBox="-2 -2 54 64"
    >
      <defs>
        <filter id="shadow" x="-5%" y="-5%" width="140%" height="140%">
          <feDropShadow dx="0" dy="3" stdDeviation="4" floodColor="rgba(0,0,0,0.3)"/>
        </filter>
      </defs>
      <path 
        d="M25 0 C40 0, 50 15, 50 30 C50 45, 30 55, 25 60 C20 55, 0 45, 0 30 C0 15, 10 0, 25 0 Z" 
        fill={backgroundColor}
        stroke={textColor}
        strokeWidth={borderWidth}
        filter="url(#shadow)"
      />
      <circle 
        cx="25" 
        cy="27" 
        r="15" 
        fill="white"
        stroke="rgba(0,0,0,0.1)" 
        strokeWidth="2"
      />

      <text 
        x="25" 
        y="30" 
        textAnchor="middle" 
        dominantBaseline="middle"
        fill={textColor} 
        fontFamily="Inter" 
        fontSize={textSize}
        fontWeight="bold"
      >
        {text.toString().toUpperCase().slice(0,2)}
      </text>
    </svg>
  );
};

export default CustomSVGMarker;