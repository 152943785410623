import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import moment from "moment";
export const getAllCrew = createAsyncThunk(
  "timesheet/team",
  async (value, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const userData = state.auth.userData;
      const response = await NetworkOps.get(ServiceEnum.getCrew);
      const userObject = {
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
        userId: userData.userId,
        firstName: userData.firstName,
        lastName: userData.lastName,
      };
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);
export const getCardData = createAsyncThunk(
    "timesheet/cardData",
    async (value, { getState, rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.getCardData,value);
        console.log(res?.data,"fvd")
        if (res?.data) {
            const formattedDate = value?.startDate;
            const dayData = res?.data?.[formattedDate];
            const tempData = dayData?.checkInDetails?.time
            ? [
                { 
                    ...dayData?.checkInDetails,
                    key: "checkIn",
                    start: moment(dayData?.checkInDetails?.time).format("HH:mm"),
                    checkIn: true ,
                    editiable:true,
                    _id:'dccdd'
                },
                ...(dayData?.pauseDetails?.map(pause => ({
                    ...pause,
                    key: "pause",
                    start: moment(pause.pauseTime).format("HH:mm"),
                    end:  moment(pause.resumeTime).format("HH:mm"),
                    editiable:true
                })) || [])
            ]
            : (dayData?.pauseDetails?.map(pause => ({
                ...pause,
                key: "pause",
                start: moment(pause.pauseTime).format("HH:mm"),
                end:  moment(pause.resumeTime).format("HH:mm"),
                editiable:true,
                _id:'dccdd'
            })) || []);
        
        if (dayData?.checkOutDetails?.time) {
            tempData.push({ 
                ...dayData?.checkOutDetails, 
                key: "checkOut",
                start: moment(dayData?.checkOutDetails?.time).format("HH:mm"),
                checkOut: true ,
                editiable:true
            });
        }
        
        if (dayData?.jobDetails?.length > 0) {
            const combinedData = [
                ...tempData,
                ...dayData?.jobDetails.map(job => ({
                    ...job,
                    key: job.referenceNo,
                    start: moment(job.startTime).format("HH:mm"),
                    end:  moment(job.stopTime).format("HH:mm"),
                    editiable:true,
                    type:'job'
                }))
            ];
        
            combinedData.sort((a, b) => {
                const getTime = (item) => 
                    item.time || item.pauseTime || item.startTime || 0;
        
                const timeA = getTime(a);
                const timeB = getTime(b);
        
                return moment(timeA).diff(moment(timeB));
            });
        
            return {
                historyData: combinedData,
                resData: dayData,
                referenceNumbers: res?.data?.categories
            };
        }
        
        return {
            historyData: tempData,
            resData: dayData,
            referenceNumbers: res?.data?.categories
        };
        
        } 
       
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );
  export const createTimeChange = createAsyncThunk(
    "timesheet/createtime",
    async (value, { getState, rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.createTimeAdmin,value);
        console.log(res?.data,"fvd")
        if (res?.status===true) {
            const dayData = res;
            const tempData = dayData?.checkInDetails?.time
            ? [
                { 
                    ...dayData?.checkInDetails,
                    key: "checkIn",
                    start: moment(dayData?.checkInDetails?.time).format("HH:mm"),
                    checkIn: true ,
                    editiable:true,
                    _id:'dccdd'
                },
                ...(dayData?.pauseDetails?.map(pause => ({
                    ...pause,
                    key: "pause",
                    start: moment(pause.pauseTime).format("HH:mm"),
                    end:  moment(pause.resumeTime).format("HH:mm"),
                    editiable:true
                })) || [])
            ]
            : (dayData?.pauseDetails?.map(pause => ({
                ...pause,
                key: "pause",
                start: moment(pause.pauseTime).format("HH:mm"),
                end:  moment(pause.resumeTime).format("HH:mm"),
                editiable:true,
                _id:'dccdd'
            })) || []);
        
        if (dayData?.checkOutDetails?.time) {
            tempData.push({ 
                ...dayData?.checkOutDetails, 
                key: "checkOut",
                start: moment(dayData?.checkOutDetails?.time).format("HH:mm"),
                checkOut: true ,
                editiable:true
            });
        }
        
        if (dayData?.jobDetails?.length > 0) {
            const combinedData = [
                ...tempData,
                ...dayData?.jobDetails.map(job => ({
                    ...job,
                    key: job.referenceNo,
                    start: moment(job.startTime).format("HH:mm"),
                    end:  moment(job.stopTime).format("HH:mm"),
                    editiable:true,
                     type:'job'
                }))
            ];
        
            combinedData.sort((a, b) => {
                const getTime = (item) => 
                    item.time || item.pauseTime || item.startTime || 0;
        
                const timeA = getTime(a);
                const timeB = getTime(b);
        
                return moment(timeA).diff(moment(timeB));
            });
        
            return {
                historyData: combinedData,
                resData: dayData,
                referenceNumbers: res?.data?.categories
            };
        }
        
        return {
            historyData: tempData,
            resData: dayData,
            referenceNumbers: res?.data?.categories
        };
        
        } 
        else{
            return {
                ...res,
                status:false
            }
        }
       
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );

  export const submitTimeChange = createAsyncThunk(
    "timesheet/submitData",
    async (value, { getState, rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.adminEditTime,value);
        console.log(res?.data,"fvd")
        if (res?.status===true) {
            const dayData = res;
            const tempData = dayData?.checkInDetails?.time
            ? [
                { 
                    ...dayData?.checkInDetails,
                    key: "checkIn",
                    start: moment(dayData?.checkInDetails?.time).format("HH:mm"),
                    checkIn: true ,
                    editiable:true,
                    _id:'dccdd'
                },
                ...(dayData?.pauseDetails?.map(pause => ({
                    ...pause,
                    key: "pause",
                    start: moment(pause.pauseTime).format("HH:mm"),
                    end:  moment(pause.resumeTime).format("HH:mm"),
                    editiable:true
                })) || [])
            ]
            : (dayData?.pauseDetails?.map(pause => ({
                ...pause,
                key: "pause",
                start: moment(pause.pauseTime).format("HH:mm"),
                end:  moment(pause.resumeTime).format("HH:mm"),
                editiable:true,
                _id:'dccdd'
            })) || []);
        
        if (dayData?.checkOutDetails?.time) {
            tempData.push({ 
                ...dayData?.checkOutDetails, 
                key: "checkOut",
                start: moment(dayData?.checkOutDetails?.time).format("HH:mm"),
                checkOut: true ,
                editiable:true
            });
        }
        
        if (dayData?.jobDetails?.length > 0) {
            const combinedData = [
                ...tempData,
                ...dayData?.jobDetails.map(job => ({
                    ...job,
                    key: job.referenceNo,
                    start: moment(job.startTime).format("HH:mm"),
                    end:  moment(job.stopTime).format("HH:mm"),
                    editiable:true,
                     type:'job'
                }))
            ];
        
            combinedData.sort((a, b) => {
                const getTime = (item) => 
                    item.time || item.pauseTime || item.startTime || 0;
        
                const timeA = getTime(a);
                const timeB = getTime(b);
        
                return moment(timeA).diff(moment(timeB));
            });
        
            return {
                historyData: combinedData,
                resData: dayData,
                referenceNumbers: res?.data?.categories
            };
        }
        
        return {
            historyData: tempData,
            resData: dayData,
            referenceNumbers: res?.data?.categories
        };
        
        } 
        else{
            return {
                ...res,
                status:false
            }
        }
       
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );

  export const submitEditjobChange = createAsyncThunk(
    "timesheet/editJob",
    async (value, { getState, rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.editAdminJob,value);
        console.log(res?.data,"fvd")
        if (res?.status===true) {
            const dayData = res;
            const tempData = dayData?.checkInDetails?.time
            ? [
                { 
                    ...dayData?.checkInDetails,
                    key: "checkIn",
                    start: moment(dayData?.checkInDetails?.time).format("HH:mm"),
                    checkIn: true ,
                    editiable:true,
                    _id:'dccdd'
                },
                ...(dayData?.pauseDetails?.map(pause => ({
                    ...pause,
                    key: "pause",
                    start: moment(pause.pauseTime).format("HH:mm"),
                    end:  moment(pause.resumeTime).format("HH:mm"),
                    editiable:true
                })) || [])
            ]
            : (dayData?.pauseDetails?.map(pause => ({
                ...pause,
                key: "pause",
                start: moment(pause.pauseTime).format("HH:mm"),
                end:  moment(pause.resumeTime).format("HH:mm"),
                editiable:true,
                _id:'dccdd'
            })) || []);
        
        if (dayData?.checkOutDetails?.time) {
            tempData.push({ 
                ...dayData?.checkOutDetails, 
                key: "checkOut",
                start: moment(dayData?.checkOutDetails?.time).format("HH:mm"),
                checkOut: true ,
                editiable:true
            });
        }
        
        if (dayData?.jobDetails?.length > 0) {
            const combinedData = [
                ...tempData,
                ...dayData?.jobDetails.map(job => ({
                    ...job,
                    key: job.referenceNo,
                    start: moment(job.startTime).format("HH:mm"),
                    end:  moment(job.stopTime).format("HH:mm"),
                    editiable:true,
                     type:'job'
                }))
            ];
        
            combinedData.sort((a, b) => {
                const getTime = (item) => 
                    item.time || item.pauseTime || item.startTime || 0;
        
                const timeA = getTime(a);
                const timeB = getTime(b);
        
                return moment(timeA).diff(moment(timeB));
            });
        
            return {
                historyData: combinedData,
                resData: dayData,
                referenceNumbers: res?.data?.categories
            };
        }
        
        return {
            historyData: tempData,
            resData: dayData,
            referenceNumbers: res?.data?.categories
        };
        
        } 
        else{
            return {
                ...res,
                status:false
            }
        }
       
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );
  export const submitCreatejobChange = createAsyncThunk(
    "timesheet/createjob",
    async (value, { getState, rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.createAdminJob,value);
        console.log(res?.data,"fvd")
        if (res?.status===true) {
            const dayData = res;
            const tempData = dayData?.checkInDetails?.time
            ? [
                { 
                    ...dayData?.checkInDetails,
                    key: "checkIn",
                    start: moment(dayData?.checkInDetails?.time).format("HH:mm"),
                    checkIn: true ,
                    editiable:true,
                    _id:'dccdd'
                },
                ...(dayData?.pauseDetails?.map(pause => ({
                    ...pause,
                    key: "pause",
                    start: moment(pause.pauseTime).format("HH:mm"),
                    end:  moment(pause.resumeTime).format("HH:mm"),
                    editiable:true
                })) || [])
            ]
            : (dayData?.pauseDetails?.map(pause => ({
                ...pause,
                key: "pause",
                start: moment(pause.pauseTime).format("HH:mm"),
                end:  moment(pause.resumeTime).format("HH:mm"),
                editiable:true,
                _id:'dccdd'
            })) || []);
        
        if (dayData?.checkOutDetails?.time) {
            tempData.push({ 
                ...dayData?.checkOutDetails, 
                key: "checkOut",
                start: moment(dayData?.checkOutDetails?.time).format("HH:mm"),
                checkOut: true ,
                editiable:true
            });
        }
        
        if (dayData?.jobDetails?.length > 0) {
            const combinedData = [
                ...tempData,
                ...dayData?.jobDetails.map(job => ({
                    ...job,
                    key: job.referenceNo,
                    start: moment(job.startTime).format("HH:mm"),
                    end:  moment(job.stopTime).format("HH:mm"),
                    editiable:true,
                     type:'job'
                }))
            ];
        
            combinedData.sort((a, b) => {
                const getTime = (item) => 
                    item.time || item.pauseTime || item.startTime || 0;
        
                const timeA = getTime(a);
                const timeB = getTime(b);
        
                return moment(timeA).diff(moment(timeB));
            });
        
            return {
                historyData: combinedData,
                resData: dayData,
                referenceNumbers: res?.data?.categories
            };
        }
        
        return {
            historyData: tempData,
            resData: dayData,
            referenceNumbers: res?.data?.categories
        };
        
        } 
        else{
            return res;
        }
       
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );

  export const getWeekCardData = createAsyncThunk(
    "timesheet/weekCardData",
    async (value, { rejectWithValue }) => {
      try {
        const res = await NetworkOps.post(ServiceEnum.getCardData, value);
        console.log(res?.data, "Fetched Week Data");
        const { startDate, endDate } = value;
        const dateRange = getDatesInRange(startDate, endDate);
        const formattedData = dateRange.map((date) => {
          const dayData = res?.data?.[date] || {};
          return {
            date,
            historyData: formatDayData(dayData),
            totalHoursWorked:dayData?.totalHoursWorked||0,
            resData: dayData || {},
          };
        });
        console.log(formattedData,"sdvsvfsv")
  
        return {
          weekData: formattedData,
          referenceNumbers: res?.data?.categories || [],
        };
      } catch (error) {
        return rejectWithValue(error.response?.data || "Something went wrong");
      }
    }
  );

  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate, "day")) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }
    return dates;
  };

  const formatDayData = (dayData) => {
    if (!dayData || Object.keys(dayData).length === 0) return [];
  
    let tempData = [];
  
    if (dayData?.checkInDetails?.time) {
      tempData.push({
        ...dayData.checkInDetails,
        key: "checkIn",
        start: dayData?.checkInDetails?.time,
        checkIn: true,
        editiable: true,
        _id: "checkInId",
      });
    }
  
    if (dayData?.pauseDetails?.length) {
      tempData.push(
        ...dayData.pauseDetails.map((pause) => ({
          ...pause,
          key: "pause",
          start: pause?.pauseTime,
          end: pause?.resumeTime,
          editiable: true,
          _id: pause._id,
        }))
      );
    }
  
    if (dayData?.checkOutDetails?.time) {
      tempData.push({
        ...dayData.checkOutDetails,
        key: "checkOut",
        start: dayData?.checkOutDetails?.time,
        checkOut: true,
        editiable: true,
        _id: "checkOutId",
      });
    }
  
    if (dayData?.jobDetails?.length) {
      tempData.push(
        ...dayData.jobDetails.map((job) => ({
          ...job,
          key: job.referenceNo,
          start: job.startTime,
          end: job.stopTime,
          editiable: true,
          type: "job",
        }))
      );
    }
  
    return tempData.sort((a, b) => moment(a.start).diff(moment(b.start)));
  };