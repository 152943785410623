import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaHome,
  FaMapMarkerAlt,
  FaChevronDown,
  FaPencilAlt,
  FaCheck,
} from "react-icons/fa";
import Autocomplete from "react-google-autocomplete";
import { palette } from "../../utils/Palette";
import RippleButton from "../../ReuseableComponets/RippleButton";
import AddressSearch from "../CustomerSearch/AddressSearch";
import { ArrowDropDown } from "@mui/icons-material";
import { FiX } from "react-icons/fi";
import { IconButton } from "@mui/material";

const PageContainer = styled.div`
  max-width: 640px;
  text-align: start;
  color: #333;
  padding: 10px;
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const MainHeading = styled.h1`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  letter-spacing: 0.01em;
  font-family: Inter;
  color: #333;
`;

const SubHeading = styled.p`
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  color: rgb(45, 47, 49);
  margin-top: -15px;
  font-weight: 400;
`;

const SectionHeading = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0 0 0.75rem 0;
  line-height: 1.4;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Section = styled.div`
  margin-top: 1.75rem;
`;

const InputBase = styled.input`
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const InputWithIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height:44px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const InputWithIcon = styled(InputBase)`
  padding-left: 2.5rem;
`;

const SelectWrapper = styled.div`
  position: relative;

`;

const Select = styled.select`
  display: block;
  width: 100%;
  height:44px;

  padding: 0 2.5rem 0 2.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;

  &:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    outline: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const SelectIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Place = styled(Autocomplete)`
  width: 100%;
  display: flex;
  border-radius: 5px;
  font-size: 16px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${palette.lightGrey};
  font-weight: 400;
  padding-left: 40px;

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
  }

  &:focus {
    outline: 1.5px solid ${palette.charcoleBlack};
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #2563eb;
  font-size: 14px;
  cursor: pointer;
  margin-left: auto;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: rgba(37, 99, 235, 0.1);
  }

  svg {
    margin-right: 0.25rem;
  }
`;

const SavedSection = styled.div`
  padding: 0.75rem;
  background-color: #f8fafc;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  margin-bottom: 1rem;
`;

const SavedInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  span {
    font-weight: 500;
    color: #333;
  }
`;

const SavedValue = styled.div`
  font-size: 14px;
  color: #64748b;
  margin-top: 0.25rem;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  color: #16a34a;
  font-size: 14px;
  margin-top: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const DepotSetupPage = (props) => {
    console.log(props?.baseData?.endAddress?.type)
  const [formData, setFormData] = useState(props?.baseData?{...props?.baseData, endLocationType:props?.baseData?.endAddress?.type||"return"}:{
    startAddress: "",
    endLocationType:props?.baseData?.endAddress?.type||"return",
    endAddress: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isserviceAddress, setIsserviceAddress] = React.useState(false);
  const [boolValue,setBoolValue]=useState({})
  const shouldShowEndLocation = formData.endLocationType === "other";

  const handleStartLocationSelect = (place) => {
    console.log(place,"dcsdc")
    setFormData({
      ...formData,
      startAddress:{
        fullAddress: place.formatted_address || place.name,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),

      },
    });
  };

  const handleEndLocationSelect = (place) => {
    setFormData({
      ...formData,
      endAddress:{
        fullAddress: place.formatted_address || place.name,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),

      },
    });
  };

  const handleEndLocationTypeChange = (e) => {
    setFormData({
      ...formData,
      endLocationType: e.target.value,
      endAddress: e.target.value === "return" ? {} : formData.endLocation,
    });
  };

  const handleSave = async() => {
    if (!formData.startAddress) {
      alert("Please provide a start location");
      return;
    }

    if (formData.endLocationType === "other" && !formData.endAddress) {
      alert("Please provide an end location");
      return;
    }

    // setIsSaved(true);
    // setIsEditing(false);
    // setShowSuccessMessage(true);

    // setTimeout(() => {
    //   setShowSuccessMessage(false);
    // }, 3000);

   await  props?.onsubmit(formData)
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const endLocationTypeDisplay = {
    return: "Return to start location",
    other: "End at other address",
    none: "Don't use the end location",
  };

  return (
    <PageContainer>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <MainHeading>Location</MainHeading>
      <IconButton onClick={props?.onclose}>
      <FiX size={22}/>
      </IconButton>
      </div>
      {/* <SubHeading>Set the main location for all technicians. You can also edit all this information later.</SubHeading> */}

      { !isEditing ? (
        <>
          <Section>
            <SectionHeading>Starting your routes</SectionHeading>
            <SavedSection>
              <SavedInfo>
                <span>Start location</span>
                <EditButton onClick={handleEdit}>
                  <FaPencilAlt size={14} />
                  Edit
                </EditButton>
              </SavedInfo>
              <SavedValue>{formData.startAddress?.fullAddress}</SavedValue>
            </SavedSection>
          </Section>

          <Section>
            <SectionHeading>Finishing your routes</SectionHeading>
            <SavedSection>
              <SavedInfo>
                <span>End location</span>
              </SavedInfo>
              <SavedValue>
                {endLocationTypeDisplay[formData.endLocationType]}
                { (
                  <div style={{ marginTop: "0.5rem" }}>
                    {formData.endAddress?.fullAddress}
                  </div>
                )}
              </SavedValue>
            </SavedSection>
          </Section>

        </>
      ) : (
        <>
          <Section>
            <SectionHeading>Starting your routes</SectionHeading>

            <FormGroup>
              <SectionHeading>Start location</SectionHeading>
            {!boolValue?.newStart?  <StyledSelect
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !isserviceAddress && setIsserviceAddress(true);
                }}
              >
                {
                formData.startAddress?.fullAddress||"Choose start address"}
                <ArrowDropDown />
                {isserviceAddress && (
                  <AddressSearch
                    customers={[]}
                    onSelect={(val) => {
                    
                    }}
                    isNew={true}
                    onCreateNew={() => {
                        setBoolValue({...boolValue,newStart:true})
                    }}
                    onclose={() => setIsserviceAddress(false)}
                  />
                )}
              </StyledSelect>:
              <InputWithIconWrapper>
                <IconWrapper>
                  <FaHome size={16} />
                </IconWrapper>
                <Place
                  apiKey='AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA'
                  types={['address']}
                  options={{
                    types: ["address"],
                  }}
                  autoFocus
                  defaultValue={formData.startAddress?.fullAddress}
                  onPlaceSelected={handleStartLocationSelect}
                  placeholder='Enter start location'
                />
              </InputWithIconWrapper>}
            </FormGroup>
          </Section>

          <Section>
            <SectionHeading>Finishing your routes</SectionHeading>

            <FormGroup>
              <SectionHeading>End location</SectionHeading>
              <SelectWrapper>
                <IconWrapper>
                  <FaMapMarkerAlt size={16} />
                </IconWrapper>
                <Select
                  value={formData.endLocationType}
                  onChange={handleEndLocationTypeChange}
                >
                  <option value="return">Return to start location</option>
                  <option value="other">End at other address</option>
                  {/* <option value="none">Don't use the end location</option> */}
                </Select>
                <SelectIconWrapper>
                  <FaChevronDown size={12} />
                </SelectIconWrapper>
              </SelectWrapper>
            </FormGroup>

            {formData.endLocationType==='other' && (
              <FormGroup  style={{marginBottom:'130px'}}>
                 {!boolValue?.newEnd?  <StyledSelect
                
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !boolValue?.isEndAddress && setBoolValue({...boolValue,isEndAddress:true});
                }}
              >
                {
                 formData.endAddress?.fullAddress|| "Choose end address"}
                <ArrowDropDown />
                {boolValue?.isEndAddress && (
                  <AddressSearch
                    customers={[]}
                    onSelect={(val) => {
                    
                    }}
                    isNew={true}
                    onCreateNew={() => {
                        setBoolValue({...boolValue,newEnd:true})
                    }}
                    onclose={() =>  setBoolValue({...boolValue,isEndAddress:false})}
                  />
                )}
              </StyledSelect>:
                 <InputWithIconWrapper>
                 <IconWrapper>
                   <FaMapMarkerAlt size={16} />
                 </IconWrapper>
                 <Place
                   apiKey="AIzaSyDC8F29YJAnHp6qxyBf7YWFGPzj-c04rRA"
                   types={["address"]}
                   autoFocus
                   options={{
                     types: ["address"],
                   }}
                   defaultValue={formData.endAddress?.fullAddress}
                   onPlaceSelected={handleEndLocationSelect}
                   placeholder="Enter end location"
                 />
               </InputWithIconWrapper>}
            
              </FormGroup>
            )}
          </Section>
      
        </>
      )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "15px",
              marginTop: "60px",
            }}
          >
            <RippleButton style={{ marginTop: "10px",width:'100%' }} onClick={handleSave}>
              Continue
            </RippleButton>
          </div>
    </PageContainer>
  );
};

export default DepotSetupPage;

const StyledSelect = styled.button`
  width: 100%;
 height:40px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  appearance: none;
  cursor: pointer;
  padding-left:10px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
`;