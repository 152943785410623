import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from 'styled-components';
import IOSSwitchComponent from "../../ReuseableComponets/IOSSwitch";
import { autoBookingEstimate, autopayementInvoice } from "../../store/Actions/dashboard.action";

const Permissions = (props) => {
    const convertQuoteToBooking = useSelector(state => state?.auth?.userData?.convertQuoteToBooking)
    const acceptPaymentOnline = useSelector(state => state?.auth?.userData?.acceptPaymentOnline)
    const [value, setValue] = useState(convertQuoteToBooking || false)
    const [allowpayment, setAllowPayment] = useState(acceptPaymentOnline || false)
    const dispatch = useDispatch()

    useEffect(() => {
        setValue(convertQuoteToBooking)
        setAllowPayment(acceptPaymentOnline)
    }, [convertQuoteToBooking,acceptPaymentOnline])

    return (
        <>
            <div style={{ width: '100%', overflowY: 'scroll' }}>

                <Heading>Automation</Heading>
                <SubText>Manage app permissions to ensure proper access control</SubText>
                <Section>
                    <div className="title">Estimates</div>
                    <FlexContainer>

                        <IOSSwitchComponent
                            checked={value}
                            onChange={async (val) => {
                                setValue(value ? false : true)
                                dispatch(
                                    autoBookingEstimate(value ? false : true)
                                )
                            }
                            }
                        />
                        Auto-convert approved estimates to jobs
                    </FlexContainer>
                    <div className="title" style={{ marginTop: '20px' }}>Invoices</div>
                    <FlexContainer>

                        <IOSSwitchComponent
                            checked={allowpayment}
                            onChange={async (val) => {
                                setAllowPayment(allowpayment ? false : true)
                                dispatch(
                                    autopayementInvoice(allowpayment ? false : true)
                                )
                            }
                            }
                        />
                        Allow online payment in invoice
                    </FlexContainer>
                </Section>
            </div>
        </>
    )
}
export default Permissions;


const Heading = styled.h2`
  text-align: start;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const SubText = styled.p`
  text-align: start;
  margin-left: 20px;
  font-size: 14px;
`;
const FlexContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 20px 0px 0px 20px;
    align-items: center;
    gap: 20px;
    font-size: 14px;
  

`
const Section = styled.div`
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 10px;
  margin:20px;
  gap: 20px;
  box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.07);
  border: 1px solid #f3f3f3;
  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: start;
    margin-left:20px;
  }
  .switchcontainer {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0px 0px 20px;
    align-items: center;
    gap: 20px;
    font-size: 14px;
  }
`;