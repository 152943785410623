import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
  } from "react";
  import {
    GoogleMap,
    MarkerF,
    OverlayView,
    useLoadScript,
  } from "@react-google-maps/api";

  import { useDispatch } from "react-redux";

  import { initializeApp } from "@firebase/app";
  import { getDatabase, ref, onValue } from "@firebase/database";
  import styled from "styled-components";
  import { FaInfo, FaInvision, FaRegEye } from "react-icons/fa";
  import { IconButton } from "@mui/material";
  import { FiInfo } from "react-icons/fi";
import mapStyles from "../../Components/Tracking/mapStyles";
import { getAllCrew } from "../../store/Actions/SettingsAction";
import { getName } from "../../utils/Commonfunction";
import Header from "../../Components/Header/MainHeader";

  
  const firebaseConfig = {
    apiKey: "AIzaSyBE6sK4tiAaZ8H15kFhfzITz6kqS4VPIvI",
    authDomain: "swiftbel.firebaseapp.com",
    databaseURL: "https://swiftbel-default-rtdb.firebaseio.com",
    projectId: "swiftbel",
    storageBucket: "swiftbel.appspot.com",
    messagingSenderId: "542308296429",
    appId: "1:542308296429:web:fb9eaf64609f96faf94ee4",
    measurementId: "G-HKM42XCE1B",
  };
  const InitialMarker = React.memo(
    ({ position, initial, isActive, onClick, size = 40, fontSize = 14 }) => {
      if (!position) return null;
  
      return (
        <OverlayView
          position={position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -(size / 2),
            y: -(size / 2),
          })}
        >
          <CircleButton
            onClick={onClick}
            
          >
            {initial}
          </CircleButton>
        </OverlayView>
      );
    }
  );
  
  const DispatchboardMap = () => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [tabData, setTabData] = useState([]);
    const [team, setTeam] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [zoom, setZoom] = useState(4);
    const [showMap, setShowMap] = useState(false);
    const mapRef = useRef(null);
    const markersRef = useRef({});
    const isInitialRender = useRef(true);
    const lastZoomOperation = useRef(Date.now());
    const pageRef=useRef(null)
    const dispatch = useDispatch();
    const getInitials = (name) => {
      if (!name) return "?";
      const names = name.split(" ");
      if (names.length === 1) return names[0].charAt(0).toUpperCase();
      return (
        names[0].charAt(0) + names[names.length - 1].charAt(0)
      ).toUpperCase();
    };
    const updateTabData = useCallback(() => {
      const currentTime = Date.now();
      if (currentTime - lastZoomOperation.current > 300) {
        setTabData(Object.values(markersRef.current));
      }
    }, []);
  
    const init = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => {
            setCurrentLocation({ lat: 38.7946, lng: -106.5348 });
          }
        );
      } else {
        setCurrentLocation({ lat: 38.7946, lng: -106.5348 });
      }
  
      const res = await dispatch(getAllCrew());
      setTeam(res?.data);
  
      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);
      let unsubscribes = [];
      const initialMarkers = {};
      res?.data?.forEach((item) => {
        initialMarkers[item.userId] = {
          ...item,
          position: null,
          initials: getInitials(item?.name || item?.fullName || ""),
        };
      });
      markersRef.current = initialMarkers;
  
      let updateTimeout = null;
  
      res?.data?.forEach((item) => {
        const locationRef = ref(db, item?.userId);
        const unsubscribe = onValue(
          locationRef,
          (snapshot) => {
            const data = snapshot.val();
            if (data?.location?.latitude && data?.location?.longitude) {
              markersRef.current[item.userId] = {
                ...item,
                position: {
                  lat: data.location.latitude,
                  lng: data.location.longitude,
                },
                initials: getInitials(item?.name || item?.fullName || ""),
              };
              clearTimeout(updateTimeout);
              updateTimeout = setTimeout(updateTabData, 300);
            }
          },
          (error) => console.error("Firebase error:", error)
        );
  
        unsubscribes.push(unsubscribe);
      });
  
      return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe && unsubscribe());
        clearTimeout(updateTimeout);
      };
    };
  
    useEffect(() => {
      const cleanup = init();
      return () => {
        cleanup.then((fn) => fn && fn());
      };
    }, []);
  
    const fitBoundsToMarkers = (map) => {
      if (!map || !mapRef.current) return;
  
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidMarkers = false;
  
      if (tabData.length > 0) {
        tabData.forEach(({ position }) => {
          if (position) {
            bounds.extend(
              new window.google.maps.LatLng(position.lat, position.lng)
            );
            hasValidMarkers = true;
          }
        });
      }
  
      if (!hasValidMarkers && currentLocation) {
        bounds.extend(
          new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng)
        );
      }
  
      if (bounds.isEmpty()) return;
      if (isInitialRender.current) {
        map.fitBounds(bounds);
        isInitialRender.current = false;
  
        const listener = map.addListener("bounds_changed", () => {
          if (map.getZoom() > 8) map.setZoom(8);
          window.google.maps.event.removeListener(listener);
        });
      }
    };
  
    const handleOnLoad = (map) => {
      mapRef.current = map;
      setTimeout(() => fitBoundsToMarkers(map), 500);
      map.addListener("zoom_changed", () => {
        lastZoomOperation.current = Date.now();
        setZoom(map.getZoom());
      });
  
      map.addListener("dragstart", () => {
        lastZoomOperation.current = Date.now();
      });
  
      map.addListener("idle", () => {
        setTimeout(() => {
          updateTabData();
        }, 200);
      });
    };
  
    const getMarkerSize = () => {
      if (zoom <= 3) return 24;
      if (zoom <= 5) return 30;
      if (zoom <= 7) return 36;
      if (zoom <= 10) return 40;
      return 44;
    };
  
    const getFontSize = () => {
      if (zoom <= 3) return 10;
      if (zoom <= 5) return 12;
      if (zoom <= 7) return 14;
      if (zoom <= 10) return 16;
      return 18;
    };
  
    const createMarkerIcon = useCallback(
      (initial, isActive) => {
        const size = getMarkerSize();
        const color = isActive ? "#4285F4" : "#8c8c8c";
  
        return {
          url: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}"><circle cx="${
            size / 2
          }" cy="${size / 2}" r="${
            size / 2 - 2
          }" fill="${color}" stroke="white" stroke-width="2"/><text x="50%" y="50%" font-size="${
            size / 2
          }" font-weight="bold" fill="white" text-anchor="middle" dominant-baseline="middle">${initial}</text></svg>`,
          scaledSize: new window.google.maps.Size(size, size),
          anchor: new window.google.maps.Point(size / 2, size / 2),
        };
      },
      [zoom]
    );
  
    const validMarkers = useMemo(() => {
      return tabData.filter((item) => item.position);
    }, [tabData]);
  
    if (!currentLocation) return <div>Loading Map...</div>;
  
    const markerSize = getMarkerSize();
    const fontSize = getFontSize();
  
    const scrollToMap = () => {
      setShowMap((prev) => {
          if(prev){
              return false;
          }
          else{
              
              setTimeout(() => {
                  if (pageRef.current) {
                      pageRef.current.scrollIntoView({ behavior: "smooth", block: 'start' });
                  }
              }, 500);
              return true;
          }
      })
  
    
    };
  
    return (
        <Body>
         
      <div ref={pageRef} style={{ height: "auto", width: "100%" }}>
      <Header nonsearchable={true} />
          
        <div>
      
        </div>
        { (
          <div style={{ display: "flex", height: "auto", width: "100%", height: "100%"  }}>
              <div style={{padding:'15px',border:'1px solid #ddd',margin:'0px 10px',width: "100%",borderRadius:'10px'}}>
            <GoogleMap
              onLoad={handleOnLoad}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "100vh" }}
              options={{
                styles: mapStyles,
                maxZoom: 18,
                gestureHandling: "greedy",
              }}
              center={currentLocation}
              zoom={4}
            >
              {zoom <= 1
                ? validMarkers.map((item, index) => (
                    <MarkerF
                      key={item.userId}
                      position={item.position}
                      onClick={() => setActiveMarker(item.userId)}
                      icon={createMarkerIcon(
                        item.initials || "?",
                        activeMarker === item.userId
                      )}
                      zIndex={activeMarker === item.userId ? 1000 : 1}
                      options={{
                        optimized: true,
                        clickable: true,
                      }}
                    />
                  ))
                : validMarkers.map((item) => (
                    <InitialMarker
                      key={item.userId}
                      position={item.position}
                      initial={
                        getName(`${item.firstName} ${item?.lastName}`) ||
                        item.initials ||
                        "?"
                      }
                      isActive={activeMarker === item.userId}
                      onClick={() => setActiveMarker(item.userId)}
                      size={markerSize}
                      fontSize={fontSize}
                    />
                  ))}
            </GoogleMap>
            </div>
            <Sidebar>
              <SidebarHeader>
                <h3 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Team Members
                </h3>
              </SidebarHeader>
              {team.map((member) => (
                <TeamMember
                  key={member.userId}
                  active={activeMarker === member.userId}
                  onClick={() => {
                      const selectedMarker = validMarkers.find(item => item.userId === member.userId);
                      if (selectedMarker && mapRef.current) {
                        setActiveMarker(member.userId);
                        
                        const bounds = new window.google.maps.LatLngBounds();
                        bounds.extend(selectedMarker.position);
                        
                        mapRef.current.fitBounds(bounds); 
                      }
                    }}
                >
                  <CircleButton active={activeMarker === member.userId}>
                    {getName(`${member.firstName} ${member?.lastName}`)}
                  </CircleButton>
                  <div className="detailContainer">
                  {`${member.firstName} ${member?.lastName}`}
                  <p className="subtext" active={activeMarker === member.userId}>{member?.email}</p>
                  </div>
                </TeamMember>
              ))}
            </Sidebar>
          </div>
        )}
      </div>
      </Body>
    );
  };
  
  export default React.memo(DispatchboardMap);

  const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`;
  
  const Sidebar = styled.div`
    width: 500px;
    height: auto;
    background: #f4f6f9;
    padding: 10px;
    overflow-y: auto;
    border-radius:10px;
    border: 1px solid #ddd;
  
  `;
  
  const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  `;
  
  const ShowMapButton = styled.button`
    background: linear-gradient(135deg, #000000, #2C2C2C, #4A4A4A);
    color: white;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: ${(props) => (props.small ? "8px 12px" : "12px 24px")};
    font-size: ${(props) => (props.small ? "14px" : "16px")};
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
  
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  
    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  `;
  
  const TeamMember = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom:10px;
  
    transition: all 0.2s ease;
    background: ${({ active }) => (active ? "#0F52BA" : "transparent")};
    color: ${({ active }) => (active ? "white" : "#333")};
  
    .detailContainer{
    text-align:start;
    margin-left:10px;
      font-size:14px;
    }
    .subtext{
    margin-top:4px;
    margin-bottom:0px;
    font-size:13px;
    color: ${({ active }) => (active ? "white" : "grey")};
    }
  
    &:hover {
      background: ${({ active }) => (active ? "#0F52BA" : "#ddd")};
    }
  `;
  
  const InitialCircle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${({ active }) => (active ? "#4c87c9" : "#bbb")};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    margin-right: 10px;
    font-size: 18px;
  `;
  
  const StyledTextBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  
    font-size: 13px;
    color: #000;
    font-weight: 500;
    background-color: transparent;
  
    &:hover {
      background-color: #dce8f5;
    }
  
    svg {
      font-size: 16px;
      color: #000;
      transition: color 0.3s;
  
      &:hover {
        color: #083f7c;
      }
    }
  `;
  
  const CircleButton = styled.button`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: ${({ isActive }) =>
      isActive
        ? "linear-gradient(135deg, #000000, #2C2C2C, #4A4A4A)"
        : "linear-gradient(135deg, #959aa6 0%, #2c3e50 100%)"};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    transform: ${({ isActive }) => (isActive ? "scale(1.1)" : "scale(1)")};
    user-select: none;
  `;
  