import React, { useState } from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import styled from "styled-components";

const TagSelector = (props) => {
    const hashTags = useSelector(state => state?.auth?.userData?.hashTags)
  const [tags, setTags] = useState(hashTags|| []);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState(props?.selected||[]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleAddTag = () => {
    if (searchTerm.trim() && !tags.includes(searchTerm.trim())) {
      setTags([...tags, searchTerm.trim()]);
      setSearchTerm(""); // Clear input after adding
      setDropdownVisible(false);
    }
  };

  const handleSave = () => {
    console.log("Selected Tags:", selectedTags);
    props?.handleSave(selectedTags)
  };

  const filteredTags = tags.filter((tag) =>
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateNewTag = () => {
    if (searchTerm.trim()) {
      handleAddTag();
    }
  };

  return (
    <Container>
      <Header>
        <div style={{ display: "flex" }}>
          <CloseButton style={{ marginRight: "10px" }} onClick={()=>props?.close()}>
            <FiX />
          </CloseButton>
          <HeaderText>Add tag</HeaderText>
        </div>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </Header>

      <SearchInputContainer>
        <SearchInput
          type="text"
          placeholder="Search or add tags..."
          value={searchTerm}
          onChange={handleSearch}
          onKeyDown={(e) => e.key === "Enter" && handleCreateNewTag()}
        />
      </SearchInputContainer>

      {dropdownVisible && searchTerm.trim() && !tags.includes(searchTerm.trim()) && (
        <Dropdown>
          <DropdownItem onClick={handleAddTag}>
            Create new "{searchTerm}"
          </DropdownItem>
        </Dropdown>
      )}

      <TagList>
        {filteredTags.map((tag) => (
          <TagItem
            key={tag}
            onClick={() => handleTagClick(tag)}
            isSelected={selectedTags.includes(tag)}
          >
            <TagName>{tag}</TagName>
            {selectedTags.includes(tag) && <FaCheckCircle color="green" />}
          </TagItem>
        ))}
      </TagList>
    </Container>
  );
};

export default TagSelector;

const Container = styled.div`
  width: 350px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  position:relative;

`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const CloseButton = styled.div`
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: #e74c3c;
  }
`;

const HeaderText = styled.h3`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #333;
`;

const SaveButton = styled.div`
  font-size: 15px;
  color: #0f52ba;
  cursor: pointer;

  &:hover {
    color: #084c99;
    text-decoration: underline;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #0f52ba;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 80px;
  left: 10px;
  right:0;
  width: 93.8%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const TagList = styled.div`
  display: flex;
  flex-direction: column;
  max-height:300px;
  overflow-y:scroll;
`;

const TagItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const TagName = styled.span`
  font-size: 14px;
  color: #333;
`;
