import { CircularProgress, Divider, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaMoneyBill, FaUsers } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components";
import {
  colorThemes,
  formatMobileNumber,
  putCommaInvalue,
} from "../../../../utils/Commonfunction";
import { PaidOutlined, RequestQuoteOutlined } from "@mui/icons-material";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  fetchbookingDetails,
  updatejobData,
} from "../../../../store/Actions/dashboard.action";
import { useNavigate } from "react-router-dom";
import AnimatedModal from "../../../ModalComponent/AnimatedModal";
import TagSelector from "../../../../Pages/Estimates/Component/TagSelector";
import AddNote from "./AddNote";

const JobDetails = ({ data }) => {
  console.log(data, "sdd");
  const [invoiceData, setInvoiceData] = useState(
    data?.extendedProps?.invoiceData || []
  );
  const [estimateData, setEstimateData] = useState(
    data?.extendedProps?.quoteData || []
  );
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState(data?.extendedProps?.data || {});
  const [description, setdescription] = useState("");
  const [note, setNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const init = async () => {
    setLoading(true);
    const res1 = await dispatch(
      fetchbookingDetails(data?.extendedProps?.data?.referenceNo)
    );
    setValue(res1?.data);
    setTags(res1?.data?.hashTags);
    setInvoiceData(res1?.data?.invoiceData);
    setEstimateData(res1?.data?.quoteData || []);
    setdescription(res1?.data?.notes);
    setLoading(false)
  };

  useEffect(() => {
    init();
  }, []);

  const updateData = async (value) => {
    const res = await dispatch(
      updatejobData({ tags: value }, data?.extendedProps?.data?.referenceNo)
    );
  };
  const updatefield = async (val) => {
    const res = await dispatch(
      updatejobData(
        { description: val },
        data?.extendedProps?.data?.referenceNo
      )
    );
  };
  return (
    loading ? (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    ): <Container>
      <Header>
        <h2 className="text-lg font-semibold">
          Job #{value?.["job number"] || value?.["bookingNumber"]}
          {data?.extendedProps?.type === "multivist"
            ? ` - ${data?.title}`
            : null}
        </h2>
        <Status status={data?.extendedProps?.data?.bookingStatus}>
          {" "}
          {data?.extendedProps?.data?.type === "task"
            ? "Task"
            : data?.extendedProps?.data?.type === "event"
            ? "Event"
            : data?.extendedProps?.data?.type === "google"
            ? "Google"
            : data?.extendedProps?.data?.bookingStatus ===
              "Waiting for confirmation"
            ? "Open"
            : data?.extendedProps?.data?.bookingStatus}
        </Status>
      </Header>
      <Section>
        <div className="flex">
          <div>
            <h3 className="header">{value?.name}</h3>
            <p style={{ fontSize: "13px" }} className="subheader">
              {value?.customerAddress}
            </p>
            <p style={{ fontSize: "13px" }} className="subheader">
              {formatMobileNumber(
                value?.customerNumber ||
                  value?.number ||
                  value?.customerPhoneNumber
              )}
            </p>
          </div>
          <Link style={{width:'80px',justifyContent:'end',display:'flex', marginTop:'0px'}} onClick={() =>
              navigate(
                `/dashboard/servicerequests/${data?.extendedProps?.data?.referenceNo}`
              )
            }>
            View job
          </Link>
        
        </div>
      </Section>
      <Section>
        <JobCard status={value?.bookingStatus}>
          <div>
            <div
              style={{ textAlign: "start", fontSize: "12px", color: "gray" }}
            >
              #{value?.["job number"] || value?.["bookingNumber"]}
              {data?.extendedProps?.type === "multivist"
                ? ` - ${data?.title}`
                : null}{" "}
              |{" "}
              {value?.type === "task"
                ? "Task"
                : value?.type === "event"
                ? "Event"
                : value?.type === "google"
                ? "Google"
                : value?.bookingStatus === "Waiting for confirmation"
                ? "Open"
                : value?.bookingStatus}
            </div>
            <div className="flex">
              <div>
                <p className="text" style={{fontSize:'14px'}}>Start</p>
                <span className="subtext">
                  {moment(data?.start)?.format("DD MMM, hh:mm A")}
                </span>
              </div>
              <div>
                <p className="text" style={{fontSize:'14px'}}>End</p>
                <span className="subtext">
                  {moment(data?.end)?.format("DD MMM, hh:mm A")}
                </span>
              </div>
              <div>
                <p className="text" style={{fontSize:'14px'}}>Duration</p>
                <div className="subtext">
                  {moment
                    .duration(moment(data?.end).diff(moment(data?.start)))
                    .humanize()}
                </div>
              </div>
            </div>
            <StyledContainer>
              <FaUsers style={{ fontSize: "15px" }} />
              {value?.team?.map((item) => (
                <WrapperContainer>{item?.firstName}</WrapperContainer>
              ))}
              {value?.team?.length <= 0 && (
                <WrapperContainer>{"Unassigned"}</WrapperContainer>
              )}
            </StyledContainer>
          </div>
        </JobCard>
      </Section>
      <Section>
        <h3 className="header" style={{ paddingBottom: "10px" }}>
          Job overview
        </h3>
        <Divider />
        <div className="flex" style={{ marginTop: "15px" }}>
          <div>
            <p className="text" style={{ textAlign: "start" }}>
              Tags
            </p>
            <StyledContainer>
              {tags?.map((item, index) => (
                <WrapperContainer
                  style={{
                    background:
                      colorThemes[index % colorThemes?.length]?.background,
                    color: colorThemes[index % colorThemes?.length]?.text,
                    border: `1px solid ${
                      colorThemes[index % colorThemes?.length]?.outline
                    }`,
                  }}
                >
                  {item}
                </WrapperContainer>
              ))}
              {tags?.length <= 0 && (
                <p
                  className="subheader"
                  style={{ textAlign: "start", marginTop: "-10px" }}
                >
                  No tags
                </p>
              )}
            </StyledContainer>
          </div>
          <AnimatedModal
            buttonContent={
              <IconButton>
                <FiEdit style={{ fontSize: "18px" }} />
              </IconButton>
            }
            modalContent={(close) => {
              return (
                <div style={{ width: "100%" }}>
                  <TagSelector
                    selected={tags}
                    close={close}
                    handleSave={async (val) => {
                      setTags(val);
                      updateData(val);
                      close();
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex" style={{ marginTop: "15px" }}>
          <div>
            <p className="text" style={{ textAlign: "start" }}>
              Field notes
            </p>
            <p className="subheader" style={{ textAlign: "start" }}>
              {description || "No notes"}
            </p>
          </div>
          <IconButton onClick={()=>setNote(true)}>
            <FiEdit style={{ fontSize: "18px" }} />
          </IconButton>
        </div>
        <h3
          className="header"
          style={{ paddingBottom: "10px", marginTop: "25px" }}
        >
          Additional information
        </h3>
        <Divider />

        <div style={{ marginTop: "15px" }}>
          <p className="text" style={{ textAlign: "start" }}>
            Estimates
          </p>
          <>
            {estimateData?.length > 0 ? (
              estimateData.map((item, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    variant="body2"
                  >
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "15px",
                          textAlign: "start",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          onClick={() =>
                            navigate(`/dashboard/quotes/${item?._id}`)
                          }
                        >
                          {`Estimate #${item?.numberOfQuote}`}{" "}
                        </Link>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          <span
                            style={{
                              color:
                                item?.status === "Waiting for confirmation"
                                  ? "#fa811f"
                                  : item?.status === "Draft"
                                  ? "#586171"
                                  : item?.status === "Rejected"
                                  ? "#c0113d"
                                  : "#29740f",
                            }}
                          >
                            ●{" "}
                          </span>{" "}
                          {item?.status === "Waiting for confirmation"
                            ? "Waiting"
                            : item?.status === "Rejected"
                            ? "Void"
                            : item?.status}
                        </span>
                      </h5>
                    </div>
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "20px",
                          textAlign: "start",
                        }}
                      >{`$${putCommaInvalue(
                        parseFloat(item?.totalAmount)?.toFixed(2)
                      )}`}</h5>
                    </div>
                  </div>
                  {invoiceData?.length > 1 &&
                    index !== invoiceData?.length - 1 && (
                      <Divider style={{ color: palette.smokeWhite }} />
                    )}
                </div>
              ))
            ) : (
              <InnerContainer>
                <RequestQuoteOutlined /> No Estimates{" "}
              </InnerContainer>
            )}
          </>
        </div>

        <div style={{ marginTop: "15px" }}>
          <p className="text" style={{ textAlign: "start" }}>
            Invoices
          </p>
          <>
            {invoiceData?.length > 0 ? (
              invoiceData.map((item, index) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    variant="body2"
                  >
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "15px",
                          textAlign: "start",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <Link
                          onClick={() =>
                            navigate(`/dashboard/invoices/${item?._id}`)
                          }
                        >
                          {`Invoice #${item?.numberOfInvoice}`}{" "}
                        </Link>
                        <span style={{ fontSize: "12px" }}>
                          <span
                            style={{
                              marginLeft: "5px",
                              color:
                                item?.invoiceStatus === "Rejected"
                                  ? "#c0113d"
                                  : item?.invoiceStatus === "Paid"
                                  ? "#29740f"
                                  : item?.invoiceStatus === "Draft"
                                  ? "#586171"
                                  : "#fa811f",
                            }}
                          >
                            {" "}
                            ●{" "}
                          </span>{" "}
                          {item?.invoiceStatus === "Paid"
                            ? item?.paymentMethod?.replace(
                                /\(In-person\)/g,
                                ""
                              ) || item?.invoiceStatus
                            : item?.invoiceStatus === "Rejected"
                            ? "Void"
                            : item?.invoiceStatus}
                        </span>
                      </h5>
                    </div>
                    <div>
                      <h5
                        style={{
                          marginBottom: "0px",
                          marginTop: "20px",
                          textAlign: "end",
                        }}
                      >{`$${putCommaInvalue(
                        parseFloat(item?.totalAmount)?.toFixed(2)
                      )}`}</h5>
                      {item?.deposit && (
                        <Label>(${item?.deposit} Deposit Paid)</Label>
                      )}
                    </div>
                  </div>
                  {invoiceData?.length > 1 &&
                    index !== invoiceData?.length - 1 && (
                      <Divider style={{ color: palette.smokeWhite }} />
                    )}
                </div>
              ))
            ) : (
              <InnerContainer>
                <PaidOutlined /> No Invoices{" "}
              </InnerContainer>
            )}
          </>
        </div>
      </Section>
      <AddNote
        isVisible={note}
        onClose={() => setNote(false)}
        value={description}
        onSubmit={(val) => {
          setdescription(val);
          updatefield(val);
          setNote(false)
        }}
      />
    </Container>
  );
};

// Styled Components
const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled.span`
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ status }) =>
    status === "Waiting for confirmation"
      ? "#045ad0"
      : status === "In Progress"
      ? "#29740f"
      : status === "Completed"
      ? "#004D4D"
      : "#fa811f"};
  background: ${({ status }) =>
    status === "Waiting for confirmation"
      ? "rgba(4, 90, 208, 0.1)"
      : status === "In Progress"
      ? "rgba(41, 116, 15, 0.1)"
      : status === "Completed"
      ? "rgba(0, 77, 77, 0.1)"
      : "rgba(250, 129, 31, 0.1)"};
`;

const Section = styled.div`
  margin-top: 16px;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .header {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-align: start;
  }
  .subheader {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    text-align: start;
    margin-top: 4px;
    color: grey;
    flex-wrap: wrap;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-align: start;
  }
`;

const JobCard = styled.div`
  display: flex;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 6px;
  margin-top: 8px;
  border-left: 5px solid
    ${({ status }) =>
      status === "Waiting for confirmation"
        ? "#045ad0"
        : status === "In Progress"
        ? "#29740f"
        : status === "Completed"
        ? "#004D4D"
        : "#fa811f"};
  .flex {
    display: flex;
    align-items: center;
    padding-top: 15px;
    gap: 40px;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-align: start;
  }
  .subtext {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    text-align: start;
    margin-top: 4px;
    color: grey;
    flex-wrap: wrap;
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const Tag = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
`;
const WrapperContainer = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 5px;
  background: #ddd;
  margin-bottom:5px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap;
  margin-top: 15px;

  width: 100%;
  gap: 10px;
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 13px;
  white-space: pre-line;
  color: #344054;
  line-height: 16px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: Inter;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin:50px;
  padding: 30px;
`;
const InnerContainer = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;
  background: #f1f3f5;
  padding: 8px 12px;
  border-radius: 6px;
  margin: 8px 0;
  font-size: 13px;
  .headerwidth {
    width: 33%;
    font-size: 13px;
    font-weight: 500;
  }
  .width {
    width: 33%;
    font-size: 13px;
    font-weight: 400;
  }
  svg {
    font-size: 20px;
  }
`;
const Link = styled.div`
  color: #0f52ba;
  font-size: 14px;
  cursor: pointer;
  margin: 6px 0;
  text-align: start;
  font-weight: 500;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
const StyledTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px; 
  border-radius: 10px;
  width:100px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  font-size: 12px; 
  color: #0f52ba;
  background-color: transparent; 

  &:hover {
    background-color: #dce8f5; /* Light blue background on hover */
  }

  svg {
    font-size: 16px; /* Smaller icon size */
    color: #0f52ba; /* Blue color for icon */
    transition: color 0.3s;

    &:hover {
      color: #083f7c; /* Darker blue on hover */
    }
  }
`;
export default JobDetails;
