import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { submitchecklist } from '../../store/Actions/dashboard.action';
import { submitEditchecklist } from '../../store/Actions/SettingsAction';
import { palette } from '../../utils/Palette';
import RippleButton from '../../ReuseableComponets/RippleButton';
import InputPicker from '../../ReuseableComponets/InputPicker';
import InputBox from '../../ReuseableComponets/InputBox';
import { FiTrash2, FiPlus, FiImage, FiCheck, FiX, FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionTitle = styled.h5`
  text-align: start;
  margin: 15px 5px 0px 5px;
  font-weight: 600;
  color: ${palette.charcoleBlack};
`;

const ChecklistItem = styled.div`
  display: flex;
  align-items: start;
  margin-top: 15px;
  background: #FAFBFC;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
`;

const SubHeaderItem = styled(ChecklistItem)`
  background: #F0F5FF;
`;

const InputItem = styled(InputBox)`
  width: 85%;
  margin-left: 10px;
`;

const AddButton = styled.button`
  border: 1px solid #0F52BA;
  margin-top: 20px;
  justify-content: center;
  display: flex;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  background: #fff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  align-items: center;

  &:hover {
    background-color: rgba(15, 82, 186, 0.1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonText = styled.h4`
  text-align: start;
  font-size: 14px;
  margin: 0;
  color: #0F52BA;
  cursor: pointer;
  font-weight: 500;
`;

const HeaderContainer = styled.div`
  position: sticky;
  width: 100%;
  height: 50px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; 
  z-index: 1000; 
  top: 0;
`;

const CloseButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 15px;
  width: 32px;
  height: 32px;
  align-self: center;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:hover {
    background: #f0f0f0;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  
  .title {
    color: ${palette.charcoleBlack};
    font-size: 18px;
    margin: 0;
    font-weight: 600;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 0px;
  margin-top:0;
  flex-wrap: wrap;
  border-top:1px solid #ddd;
`;

const ActionIcons = styled.div`
  display: flex;
  font-size: 18px;
  color: #707070;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 10px;
  margin-top: 8px;

  svg {
    &:hover {
      color: #ff5252;
    }
  }
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  background: white;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 12px;
  border-bottom: 1px solid #f0f0f0;
  
  &:last-child {
    border-bottom: none;
  }

  input {
    flex: 1;
    border: none;
    padding: 6px 0;
    outline: none;
    font-size: 14px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

const OptionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  margin-right: 0px;
  margin-bottom: 8px;
  
  h5 {
    font-size: 14px;
    margin: 0;
    color: #555;
    font-weight: 500;
  }
  
  button {
    background: transparent;
    border: none;
    color: #0F52BA;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-right: -15px;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-left: 10px;
`;

const SectionContainer = styled.div`
  margin: 10px 0;
  border-radius: 8px;
  overflow: hidden;
  margin-top:12px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background: #EFF2F7;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background: #E6EAF0;
  }
  
  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: ${palette.charcoleBlack};
    text-wrap:wrap;
    text-align:start;
  }
`;

const SectionContent = styled.div`
  padding: ${props => props.isOpen ? ' 0' : '0'};
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const SectionActions = styled.div`
  display: flex;
  align-items: center;
  
  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

const TypeLabel = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  background: ${props => props.isSubheader ? '#E3ECFF' : '#F1F1F1'};
  color: ${props => props.isSubheader ? '#0F52BA' : '#555'};
  border-radius: 4px;
  width:100px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
`;

const SaveButton = styled(RippleButton)`
  background: #0F52BA;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(15, 82, 186, 0.2);
  transition: all 0.3s ease;
  margin-right: 15px;
  
  &:hover {
    box-shadow: 0 4px 8px rgba(15, 82, 186, 0.3);
    background: #0A46A8;
  }
`;

const ItemsContainer = styled.div`
  padding: 5px 0;
`;

const AddChecklist = (props) => {
    const [boolValue, setBoolValue] = useState({ addchecklist: true });
    const [addChecklist, setAddChecklist] = useState({ title: props?.selectedlist?.title || '', data: [] });
    const [checklistItemData, setChecklistItemData] = useState(props?.selectedlist?.data ? [...props?.selectedlist?.data] : [
      {
        type: 'Subheader',
        value: '',
    },
      {
        type: 'Checkbox',
        value: '',
      }
    ]);
    const [selectedChecklist, setSelectedChecklist] = useState(props?.value?.checklistNo || []);
    const [openSections, setOpenSections] = useState({});
    const fileInputRefs = useRef({});
    const dispatch = useDispatch();

    const handleAddOption = (index) => {
        const data = [...checklistItemData];
        if (!data[index].options) {
            data[index].options = [];
        }
        data[index].options.push({ text: '' });
        setChecklistItemData([...data]);
    };

    const handleOptionChange = (itemIndex, optionIndex, value) => {
        const data = [...checklistItemData];
        data[itemIndex].options[optionIndex].text = value;
        setChecklistItemData([...data]);
    };

    const handleRemoveOption = (itemIndex, optionIndex) => {
        const data = [...checklistItemData];
        data[itemIndex].options.splice(optionIndex, 1);
        setChecklistItemData([...data]);
    };

    const handleImageUpload = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = [...checklistItemData];
                data[index].imageUrl = event.target.result;
                setChecklistItemData([...data]);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = (index) => {
        const data = [...checklistItemData];
        data[index].imageUrl = null;
        setChecklistItemData([...data]);
    };

    const toggleSection = (sectionId) => {
        setOpenSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
    };

    const addNewSection = () => {
      const newSectionIndex = checklistItemData.length;
      const newSectionId = `section-${newSectionIndex}`;
      
      // Add the new subheader to the data
      setChecklistItemData([
          ...checklistItemData,
          {
              type: 'Subheader',
              value: '',
          },
      ]);
      
      // Make sure this section is open
      setOpenSections(prev => ({
          ...prev,
          [newSectionId]: true
      }));
    };

    const addChecklistItem = (sectionIndex = null) => {
        const newItem = {
            type: 'Checkbox',
            value: '',
        };
        
        let newData = [...checklistItemData];
        
        // If no section is specified or there are no sections, add to the end
        if (sectionIndex === null || !hasSections()) {
            newData.push(newItem);
        } else {
            // Find the end of the section and insert there
            const sections = organizeItemsBySection();
            const targetSection = sections[sectionIndex];
            if (targetSection) {
                const lastItemIndex = targetSection.items[targetSection.items.length - 1].index;
                newData.splice(lastItemIndex + 1, 0, newItem);
            } else {
                newData.push(newItem);
            }
        }
        
        setChecklistItemData(newData);
    };

    // Check if there are any sections in the data
    const hasSections = () => {
        return checklistItemData.some(item => item.type === 'Subheader');
    };

    // Group items by subheaders
    const organizeItemsBySection = () => {
        const sections = [];
        let currentSection = { 
            id: 'default',
            title: 'Default Section',
            items: [] 
        };
        
        checklistItemData.forEach((item, index) => {
            if (item.type === 'Subheader') {
                // If there are items in the current section, add it to sections
                if (currentSection.items.length > 0) {
                    sections.push(currentSection);
                }
                // Create a new section
                currentSection = {
                    id: `section-${index}`,
                    title: item.value || 'Untitled Section',
                    subheaderIndex: index,
                    items: [{ item, index }]
                };
            } else {
                currentSection.items.push({ item, index });
            }
        });
        
        // Add the last section
        if (currentSection.items.length > 0) {
            sections.push(currentSection);
        }
        
        return sections;
    };

    const sections = organizeItemsBySection();

    // Initialize all sections as open
    useEffect(() => {
        const initialOpenState = {};
        sections.forEach(section => {
            initialOpenState[section.id] = true;
        });
        setOpenSections(initialOpenState);
    }, []);

    const handleSaveChecklist = async () => {
        try {
            if (props?.selectedlist?._id) {
                await dispatch(
                    submitEditchecklist({ 
                        ...addChecklist, 
                        data: checklistItemData, 
                        "checklistNo": props?.selectedlist?.checklistNo,
                    })
                );
            } else {
                const res1 = await dispatch(
                    submitchecklist({ ...addChecklist, data: checklistItemData })
                );
                setSelectedChecklist((prev) => [
                    ...prev,
                    { ...addChecklist, data: checklistItemData, checklistNo: res1?.data?.checklistNo },
                ]);
            }
            setBoolValue({ ...boolValue, addchecklist: false });
            props?.setSubmit();
        } catch (error) {
            console.error("Error saving checklist:", error);
            // Optionally handle the error (show an error message, etc.)
        }
    };

    return (
        <>
            <Container>
                <HeaderContainer>
                    <HeaderDiv>
                        <CloseButton onClick={() => props?.onClose(false)}>
                            <FiX style={{ fontSize: '24px' }} />
                        </CloseButton>
                        <h4 className="title">{props?.selectedlist?._id ? "Edit checklist" : "Add checklist"}</h4>
                    </HeaderDiv>
                    <SaveButton
                        onClick={handleSaveChecklist}
                        disabled={!addChecklist.title}
                    >
                        Save
                    </SaveButton>
                </HeaderContainer>
                <div style={{overflowY:'scroll', maxHeight:'70vh', padding: '0 15px'}}>
                
                <InputBox
                    header="Checklist name"
                    style={{ width: '95%', marginTop: '15px' }}
                    placeholder="Enter a name for this checklist"
                    value={addChecklist?.title}
                    onChange={(e) =>
                        setAddChecklist({ ...addChecklist, title: e.target.value })
                    }
                />
                
                <SectionTitle>Checklist items</SectionTitle>
                
                {sections.map((section, sectionIndex) => (
                    <SectionContainer key={section.id}>
                        {section.id !== 'default' && (
                            <SectionHeader onClick={() => toggleSection(section.id)}>
                                <h4>{section.title || 'Untitled Section'}</h4>
                                <SectionActions>
                                    {openSections[section.id] ? <FiChevronUp /> : <FiChevronDown />}
                                </SectionActions>
                            </SectionHeader>
                        )}
                        
                        <SectionContent isOpen={openSections[section.id] !== false}>
                            <ItemsContainer>
                                {section.items.map(({ item, index }) => (
                                    <React.Fragment key={index}>
                                        {item.type === 'Subheader' ? (
                                            <SubHeaderItem>
                                                <TypeLabel isSubheader>{"Section name"}</TypeLabel>
                                                <InputItem
                                                    placeholder="Enter section title"
                                                    value={item?.value}
                                                    style={{width:'87%'}}
                                                    onChange={(e) => {
                                                        const data = [...checklistItemData];
                                                        data[index].value = e.target.value;
                                                        setChecklistItemData([...data]);
                                                    }}
                                                />
                                                <ActionIcons>
                                                    <FiTrash2 onClick={() => {
                                                        const data = [...checklistItemData];
                                                        data.splice(index, 1);
                                                        setChecklistItemData(data);
                                                    }} />
                                                </ActionIcons>
                                            </SubHeaderItem>
                                        ) : (
                                            <ChecklistItem>
                                                <InputPicker
                                                    value={item?.type}
                                                    label="Type"
                                                    style={{ height: '38px', background: 'white', minWidth: '130px' }}
                                                    data={[
                                                        { label: 'Checkbox', value: 'Checkbox' },
                                                        { label: 'Text', value: 'Text' },
                                                        { label: 'Single Select', value: 'SingleSelect' },
                                                        { label: 'Multi Select', value: 'MultiSelect' },
                                                        { label: 'Image Upload', value: 'ImageUpload' },
                                                    ]}
                                                    onChange={(val) => {
                                                        const data = [...checklistItemData];
                                                        data[index].type = val;
                                                        if (val === 'SingleSelect' || val === 'MultiSelect') {
                                                            data[index].options = data[index].options || [{ text: '' }];
                                                        }
                                                        setChecklistItemData([...data]);
                                                    }}
                                                />
                                                
                                                {item.type === 'Checkbox' || item.type === 'Text' ? (
                                                    <InputItem
                                                        placeholder="Enter item description"
                                                        value={item?.value}
                                                        onChange={(e) => {
                                                            const data = [...checklistItemData];
                                                            data[index].value = e.target.value;
                                                            setChecklistItemData([...data]);
                                                        }}
                                                    />
                                                ) : item.type === 'SingleSelect' || item.type === 'MultiSelect' ? (
                                                    <OptionsContainer>
                                                        <InputItem
                                                            placeholder={`Enter ${item.type === 'SingleSelect' ? 'single select' : 'multi select'} question`}
                                                            value={item?.value}
                                                            onChange={(e) => {
                                                                const data = [...checklistItemData];
                                                                data[index].value = e.target.value;
                                                                setChecklistItemData([...data]);
                                                            }}
                                                        />
                                                        <OptionsHeader>
                                                            <h5>Options</h5>
                                                            <button onClick={() => handleAddOption(index)}>
                                                                <FiPlus size={14} style={{ marginRight: '4px' }} /> Add option
                                                            </button>
                                                        </OptionsHeader>
                                                        <OptionsList>
                                                            {item.options && item.options.map((option, optionIndex) => (
                                                                <OptionItem key={optionIndex}>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={`Option ${optionIndex + 1}`}
                                                                        value={option.text}
                                                                        onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                                                                    />
                                                                    <FiTrash2
                                                                        size={16}
                                                                        onClick={() => handleRemoveOption(index, optionIndex)}
                                                                        style={{ cursor: 'pointer', color: '#888' }}
                                                                    />
                                                                </OptionItem>
                                                            ))}
                                                        </OptionsList>
                                                    </OptionsContainer>
                                                ) : item.type === 'ImageUpload' ? (
                                                    <ImageUploadContainer>
                                                        <InputItem
                                                            placeholder="Enter image upload instruction"
                                                            value={item?.value}
                                                            onChange={(e) => {
                                                                const data = [...checklistItemData];
                                                                data[index].value = e.target.value;
                                                                setChecklistItemData([...data]);
                                                            }}
                                                        />
                                                    </ImageUploadContainer>
                                                ) : null}
                                                
                                                <ActionIcons>
                                                    <FiTrash2 onClick={() => {
                                                        const data = [...checklistItemData];
                                                        data.splice(index, 1);
                                                        setChecklistItemData(data);
                                                    }} />
                                                </ActionIcons>
                                            </ChecklistItem>
                                        )}
                                    </React.Fragment>
                                ))}
                                
                                {section.id !== 'default' && (
                                    <AddButton 
                                        style={{ marginLeft: '15px' }}
                                        onClick={() => {
                                            const newItem = {
                                                type: 'Checkbox',
                                                value: '',
                                            };
                                            const lastItemIndex = section.items[section.items.length - 1].index;
                                            const newData = [...checklistItemData];
                                            newData.splice(lastItemIndex + 1, 0, newItem);
                                            setChecklistItemData(newData);
                                        }}
                                    >
                                        <FiPlus style={{ marginRight: '6px', color: palette.newBlue }} />
                                        <ButtonText>Add item to this section</ButtonText>
                                    </AddButton>
                                )}
                            </ItemsContainer>
                        </SectionContent>
                    </SectionContainer>
                ))}
                
                <ButtonGroup>
                    {/* <AddButton
                        onClick={() => addChecklistItem()}
                    >
                        <FiPlus style={{ marginRight: '6px', color: palette.newBlue }} />
                        <ButtonText>Add checklist item</ButtonText>
                    </AddButton> */}
                    
                    <AddButton
                        onClick={addNewSection}
                    >
                        <FiPlus style={{ marginRight: '6px', color: palette.newBlue }} />
                        <ButtonText>Add section</ButtonText>
                    </AddButton>
                </ButtonGroup>
                </div>
            </Container>
        </>
    );
};

export default AddChecklist;