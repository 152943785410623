import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";

export const submitAdedVisit = (data, id) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(
    `${ServiceEnum.addVisit}?referenceNo=${id}`,
    data
  );
  return res;
};
export const submitEditVisit =
  (data, id, visitId) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(
      `${ServiceEnum.editVisit}?referenceNo=${id}&multiVistId=${visitId}`,
      data
    );
    return res;
  };

export const submitReoccuring = (data, id) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.createReoccuring}`, data);
  return res;
};

export const submitBulkDelete = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(`${ServiceEnum.bulkDelete}`, data);
  return res;
};

export const submitPaymentMethod =
  (id, accid) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(
      `${ServiceEnum.savePaymentMethod}?contactId=${id}&paymentMethod=${accid}`
    );
    return res;
  };
export const getAllServicePlan = () => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.getAllServicePlan}`);
  return res;
};
export const submitServicePlan = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.addServicePlan}`, data);
  console.log(res, data, "f");
  return res;
};

export const deleteAttachmentnotes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.delete(
    `${ServiceEnum.deleteAttachment}?contactId=${data?._id}&attachmentId=${data.attachmentId}`
  );
  return res;
};

export const routeAllData = (id,date) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.routesAll}?userId=${id}&date=${date}`);
  return res;
};
export const submitRoute = (data,date,id) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.createRoute}?userId=${id}`, {
        "jobLocation":data?.waypoints||[],
        date:date
    });
    return res;
  };

  export const submitBaseLocation = (id,data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.createBaseLocation}?userId=${id}`,data);
    return res;
  };
  export const getBaseLocation = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.getBaseLocation}?userId=${id}`);
    return res;
  };