import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { palette } from "../../../utils/Palette";
import { useToast } from "../../../Components/Toaster/ToasterContext";
import doneAnimation from "../../../assets/done.json";
import TopModal from "../../../Components/TopModal";
import ShareEmbedTabs from "./ShareEmbedTabs";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
const FormList = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isModal, setModal] = useState(false);
  const { showToast } = useToast();
  const navigate=useNavigate()
  useEffect(() => {
    if (props?.data) {
      setData(props.data);
    }
  }, [props?.data]);

  const getName = useMemo(
    () =>
      (channel = "") => {
        const val = channel.trim().split(" ");
        return val.length > 1
          ? `${val[0]?.charAt(0).toUpperCase()}${val[1]
              ?.charAt(0)
              .toUpperCase()}`
          : `${val[0]?.charAt(0).toUpperCase()}`;
      },
    []
  );

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    showToast("Copied form successfully!", doneAnimation);
  };

  return (
    <>
    <FlexboxContainer>
      <CustomTable>
        <div
          style={{
            overflowY: "auto",
            height: "99vh",
            paddingBottom: "100px",
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <CustomHead>
              <TableRow>
                <TableCell size="small">
                  <div>Name</div>
                </TableCell>
                <TableCell className={"customcell"} style={{ maxWidth: "200px" }} size="small">
                  Description
                </TableCell>
                <TableCell
                  style={{
                    color: palette.black,
                    position: "relative",
                    fontWeight: "700",
                  }}
                  size="small"
                >
                  Bookings
                </TableCell>
                <TableCell className={"customcell"} size="small">
                  Duration
                </TableCell>
                <TableCell className={"customcell"} size="small">
                  Requested at
                </TableCell>

                <TableCell size="small"></TableCell>
              </TableRow>
            </CustomHead>
            {data.map((form, index) => (
              <TableBody
                onClick={() => {
                  navigate()
                  navigate(`/dashboard/jobform/${form?.formNo}`);

                }}
                key={form?._id}
                style={{ background: "#fff", cursor: "pointer" }}
              >
                <TableRow>
                  <TableCell size="small">{form?.header}</TableCell>
                  <TableCell size="small" style={{ maxWidth: "250px", textWrap: "wrap"  }}>
                    {form?.subHeader}
                  </TableCell>
                  <TableCell size="small" style={{ width: "150px" }}>
                    {form?.customers}
                  </TableCell>
                  <TableCell size="small" style={{ width: "150px" }}>
                    {form?.duration?.hours} hours {form?.duration?.minutes} minutes
                  </TableCell>
                  <TableCell size="small">
                    {moment(form.createdAt).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell size="small">
                    <ActionIcons>
                     
                      <Tooltip title="Share">
                        <IconButton onClick={(e) =>{
                          e.stopPropagation()
                          props?.openModal(form)
                        }}>
                          <ShareIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete template">
                        <IconButton onClick={(e) =>{
                          e.stopPropagation()
                          props?.deleteForm(form)
                        }}>
                          <FiTrash style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </ActionIcons>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </div>
      </CustomTable>
    
    </FlexboxContainer>
  
    </>
  );
};

export default FormList;

const FlexboxContainer = styled.div`
  min-width: 84vw;
  background: #fff;
  display: flex;
  margin-left: -23px;
  margin-right: 30px;
  flex-direction: column;

  align-items: flex-start;
`;

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color: ${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;

    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right: 1px solid #f3f3f3;
    height: 10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color: ${palette.black};
    font-size: 13px;
    font-weight: 450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left: 1px solid #f3f3f3;
    font-weight: 700;
    color: ${palette.newBlue};
    z-index: 1;
    min-width: 150px;
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background: #f8f8f8;
  }

  .purple {
    background: #974ede;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  border-radius: 8px;
  font-size: 14px;
  color: ${palette.black};
  border-top: 1px solid #f3f3f3;
  color: #000;
  height: 10px;
  flex-wrap: wrap;
  background: #f9f9f9;
  .MuiTableCell-root:first-child {
    position: sticky;
    background: #f9f9f9;
    color: ${palette.black};
    z-index: 1;
    min-width: 150px;
  }
  .customcell {
    min-width: 150px;
    color: ${palette.black};
    font-weight: 700;
  }
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 20px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    &:hover {
      color: #333;
    }
  }
`;
