import { Player } from "@lottiefiles/react-lottie-player";
import React, { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {

  const [toast, setToast] = useState({ visible: false, message: "", animation: null, type: "success" });

  const showToast = (message,  animation = null,type = "success",) => {
    setToast({ visible: true, message, animation, type });
    setTimeout(() => setToast({ visible: false, message: "", animation: null, type: "success" }), 2500);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.visible && <Toast message={toast.message} animation={toast.animation} type={toast.type} />}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

const Toast = ({ message, animation, type }) => {
  return (
    <div style={type === "error" ? styles.errorOverlay : styles.successOverlay}>
      <div style={{ ...styles.toast, backgroundColor: type === "error" ? "#FFCCCC" : "#000" }}>
        {animation && (
          <Player
            autoplay
            src={animation}
            keepLastFrame
            style={{ height: "35px", width: "35px" }}
          />
        )}
        <h4 style={{ margin: "0px", marginLeft: "10px", fontWeight: "500", color:type === "error" ?"#D32F2F": "white" }}>{message}</h4>
      </div>
    </div>
  );
};

const styles = {
  successOverlay: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 9999,
    width: "100%",
  },
  errorOverlay: {
    position: "fixed",
    top: "30px",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 9999,
  },
  toast: {
    padding: "7px 25px",
    borderRadius: "4px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};

export default ToastProvider;
