import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { palette } from "../../utils/Palette";
import {
  ArrowDropDown,
  CalendarToday,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import moment from "moment";
import InputPicker from "../../ReuseableComponets/InputPicker";
import { useDispatch } from "react-redux";
import {
  createTimeChange,
  getAllCrew,
  getCardData,
  submitCreatejobChange,
  submitEditjobChange,
  submitTimeChange,
} from "../../store/Timesheet/TimesheetAction";
import {
  setselectedcrew,
  updatefields,
} from "../../store/Timesheet/TimesheetReducer";
import {
  calendarTeamColor,
  dayViewColor,
  getName,
} from "../../utils/Commonfunction";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useToast } from "../../Components/Toaster/ToasterContext";
import ErrorAnimation from "../../assets/error.json";
import RippleButton from "../../ReuseableComponets/RippleButton";

const TimesheetPage = (props) => {
  const { team, selectedCrew, value, fields, error, buttonLoader, allBooking } =
    useSelector((state) => state.timesheet);
  const userdata = useSelector((state) => state?.auth?.userData);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const { showToast } = useToast();
  const openAssign = Boolean(anchorEl1);
  const handleClickAssign = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseAssign = () => {
    setAnchorEl1(null);
  };
  const dispatch = useDispatch();
  const init = async () => {
    const res = await dispatch(getAllCrew());
    const startDate = moment(currentDate).format("YYYY-MM-DD");
    const endDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
    await dispatch(
      getCardData({
        startDate: startDate,
        userId: selectedCrew?.userId || userdata?.userId,
        endDate: endDate,
      })
    );
  };
  useEffect(() => {
    init();
  }, [userdata?.userId]);

  useEffect(() => {}, [error]);

  const handleRemoveItem = (index) => {
    const updatedLineItems = fields.filter((_, idx) => idx !== index);
    dispatch(updatefields(updatedLineItems));
  };

  const changeDate = async (direction) => {
    const newDate = new Date(currentDate);
    if (direction === "left") {
      newDate.setDate(currentDate.getDate() - 1);
    } else if (direction === "right") {
      newDate.setDate(currentDate.getDate() + 1);
    }

    setCurrentDate(newDate);
    const startDate = moment(newDate).format("YYYY-MM-DD");
    const endDate = moment(newDate).add(1, "days").format("YYYY-MM-DD");
    await dispatch(
      getCardData({
        startDate: startDate,
        userId: selectedCrew?.userId || userdata?.userId,
        endDate: endDate,
      })
    );
  };
  const changeTeam = async (id) => {
    const startDate = moment(currentDate).format("YYYY-MM-DD");
    const endDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
    await dispatch(
      getCardData({
        startDate: startDate,
        userId: id || userdata?.userId,
        endDate: endDate,
      })
    );
  };

  const onSubmitTime = async (item, index) => {
    const startDate = moment(currentDate).format("YYYY-MM-DD");
    const time = moment(`${startDate} ${item?.start}`,"YYYY-MM-DD HH:mm").toISOString();
    const formattedTime = time.substring(11);
    const finalFormat = `${startDate}T${formattedTime}`;
    const endtime = moment(`${startDate} ${item?.end}`,"YYYY-MM-DD HH:mm").toISOString();
    const formattedEndTime = endtime.substring(11);
    const finalEndFormat = `${startDate}T${formattedEndTime}`;
    var value = {};
    if (item?.key !== "checkIn"&&item?.key !== "checkOut"&&item?.key !== "pause") {
      if (item?.type!=='job') {
        const res1 = await dispatch(
          submitCreatejobChange({
            userId: selectedCrew?.userId || userdata?.userId,
            date: moment(currentDate)?.format("YYYY-MM-DD"),
            startTime: time,
            stopTime:endtime,
            referenceNo: item?.key,
            "key": "start",
          })
        );
        if (res1?.payload?.status !== false) {
          dispatch(
            updatefields(
              fields.map((el, i) =>
                i === index ? { ...el, editiable: true,type:'job' } : el
              )
            )
          );
        } else {
          showToast(`${res1?.payload?.message}`, ErrorAnimation, "error");
        }
      } else {
        const res1 = await dispatch(
          submitEditjobChange({
            userId: selectedCrew?.userId || userdata?.userId,
            startTime: moment(
              `${startDate} ${item?.start}`,
              "YYYY-MM-DD HH:mm"
            ).toISOString(),
            stopTime: moment(
              `${startDate} ${item?.end}`,
              "YYYY-MM-DD HH:mm"
            ).toISOString(),
            referenceNo: item?.key,
            "key": "start",
          })
        );
        if (res1?.payload?.status !== false) {
          dispatch(
            updatefields(
              fields.map((el, i) =>
                i === index ? { ...el, editiable: true,type:'job' } : el
              )
            )
          );
        } else {
          showToast(`${res1?.payload?.message}`, ErrorAnimation, "error");
        }
      }
    } else {
      if (item?.key === "checkIn") {
        value = {
          key: "checkIn",
          userId: selectedCrew?.userId || userdata?.userId,
          date: moment(currentDate)?.format("YYYY-MM-DD"),
          checkInTime: time,
          time:time,
        };
      } else if (item?.key === "pause") {
        value = {
          key: "pause",
          userId: selectedCrew?.userId || userdata?.userId,
          date: moment(currentDate)?.format("YYYY-MM-DD"),
          pauseTime: time,
          resumeTime: endtime,
          pauseId: item?._id,
        };
      } else if (item?.key === "checkOut") {
        value = {
          key: "checkOut",
          userId: selectedCrew?.userId || userdata?.userId,
          date: moment(currentDate)?.format("YYYY-MM-DD"),
          checkOutTime: time,
          time: time,
        };
      }

      if (item?._id) {
        const res = await dispatch(submitTimeChange(value));
        console.log(res, "scds");
        if (res?.payload?.statusCode === 200) {
          dispatch(
            updatefields(
              fields.map((el, i) =>
                i === index ? { ...el, editiable: true } : el
              )
            )
          );
        } else {
          showToast(`${res?.payload?.message}`, ErrorAnimation, "error");
        }
      } else {
        const res = await dispatch(createTimeChange(value));
        console.log(res, "scds");
        if (res?.payload?.statusCode === 200) {
          dispatch(
            updatefields(
              fields.map((el, i) =>
                i === index ? { ...el, editiable: true } : el
              )
            )
          );
        } else {
          showToast(`${res?.payload?.message}`, ErrorAnimation, "error");
        }
      }
    }
  };

  return (
    <>
      <BookingContainer>
        <ProfileContainer>
          <StyledAvatar>
            {(selectedCrew?.label || userdata?.businessName)
              ?.charAt(0)
              ?.toUpperCase()}
          </StyledAvatar>
          <div className="profile-info">
            <span className="name">
              {selectedCrew?.label || userdata?.businessName}
            </span>
            <span className="email">
              {selectedCrew?.email || userdata?.email}
            </span>
            {/* <span className="phone">
              {selectedCrew?.phone || userdata?.phone || "-"}
            </span> */}
          </div>
        </ProfileContainer>
        <OptionBox onClick={handleClickAssign}>
          Switch user <ArrowDropDown />
        </OptionBox>
        <Menu
          anchorEl={anchorEl1}
          id="account-menu"
          open={openAssign}
          onClose={handleCloseAssign}
          onClick={handleCloseAssign}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              minWidth: "180px",
              border: "1px solid #ddd",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.05))",
              mt: 1.0,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",

                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {[
              {
                name: `${userdata?.businessName || ""} ${
                  userdata?.lastName || ""
                }`,
                email: userdata?.email || "",
                userId: userdata?.userId || "",
                firstName: userdata?.businessName || "",
                lastName: userdata?.lastName || "",
              },
              ...team,
            ]?.map((item, index) => (
              <MenuItem
                style={{ color: palette.black, fontSize: "14px" }}
                onClick={async () => {
                  dispatch(setselectedcrew(item));
                  changeTeam(item?.userId);
                }}
              >
                <Avatar
                  style={{
                    height: "22px",
                    width: "22px",
                    background: calendarTeamColor[index]?.light,
                  }}
                  color={dayViewColor[index]}
                >
                  <Typography
                    style={{
                      fontSize: "10px",
                      fontWeight: "600",
                      color: calendarTeamColor[index]?.original,
                    }}
                  >
                    {getName(`${item?.firstName} ${item?.lastName || ""}`)}
                  </Typography>
                </Avatar>
                <Typography
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {item?.firstName} {item?.lastName || ""}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </Menu>

        {/* <InputPicker
          initial={true}
          label={"Switch user"}
          data={team}
          onChange={(val) => {
            dispatch(setselectedcrew(val));
          }}
        /> */}
      </BookingContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "25px 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <JobTitle style={{ marginRight: "10px" }}>
            {moment(currentDate).format("DD MMM YYYY")}
          </JobTitle>
          <StyledIconBox onClick={() => changeDate("left")}>
            <KeyboardArrowLeft />
          </StyledIconBox>
          <StyledIconBox onClick={() => changeDate("right")}>
            <KeyboardArrowRight />
          </StyledIconBox>
        </div>
        <StyledButton
          onClick={() => {
            dispatch(
              updatefields([
                ...fields,
                {
                  key: "",
                  start: "",
                  end: "",
                },
              ])
            );
          }}
        >
          <span>Add time</span>
        </StyledButton>
      </div>
      <div>
        <div style={{ overflow: "scroll", maxHeight: "50vh" }}>
          {fields?.map((item, index) => (
            <FieldContainer key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <div className="innerContainer">
                  <div className="fieldBox">
                    <Label>Category</Label>
                    <SelectDrpoDown
                      id={`category-${index}`}
                      style={{ width: "100%" }}
                      value={item?.key}
                      disabled={item?.editiable}
                      onChange={(e) => {
                        dispatch(
                          updatefields(
                            fields.map((el, i) =>
                              i === index ? { ...el, key: e.target.value } : el
                            )
                          )
                        );
                      }}
                    >
                      <Option value="" disabled>
                        Choose value
                      </Option>
                      {allBooking?.map((option) => (
                        <Option key={option?.key} value={option?.value}>
                          {option?.label}
                        </Option>
                      ))}
                    </SelectDrpoDown>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <Label>Start</Label>
                    <Inputbox
                      placeholder={"time"}
                      type="time"
                      disabled={item?.editiable}
                      value={item?.start || ""}
                      onChange={(e) => {
                        dispatch(
                          updatefields(
                            fields.map((el, i) =>
                              i === index
                                ? { ...el, start: e.target.value }
                                : el
                            )
                          )
                        );
                      }}
                    />
                  </div>
                  {item?.key !== "checkIn" && item?.key != "checkOut" && (
                    <div style={{ marginLeft: "20px" }}>
                      <Label>End</Label>
                      <Inputbox
                        placeholder={"time"}
                        type="time"
                        disabled={item?.editiable}
                        value={item?.end || ""}
                        onChange={(e) => {
                          dispatch(
                            updatefields(
                              fields.map((el, i) =>
                                i === index
                                  ? { ...el, end: e.target.value }
                                  : el
                              )
                            )
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <ButtonContainer>
                  {item?.editiable ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            updatefields(
                              fields.map((el, i) =>
                                i === index ? { ...el, editiable: false } : el
                              )
                            )
                          );
                        }}
                      >
                        <FiEdit style={{ fontSize: "20px" }} />
                      </IconButton>
                      <IconButton>
                        <FiTrash style={{ fontSize: "20px" }} />
                      </IconButton>
                    </div>
                  ) : (
                    <>
                      <RippleButton
                        className="button save"
                        onClick={async () => await onSubmitTime(item, index)}
                      >
                        Save
                      </RippleButton>
                      <button
                        className="button preview"
                        onClick={() => {
                          if (item?._id) {
                            dispatch(
                              updatefields(
                                fields.map((el, i) =>
                                  i === index ? { ...el, editiable: true } : el
                                )
                              )
                            );
                          } else {
                            handleRemoveItem(index);
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </ButtonContainer>
              </div>
            </FieldContainer>
          ))}
          {fields?.length > 0 ? null : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="60vh"
              sx={{ color: "gray" }}
            >
              <CalendarToday />
              <Typography variant="h5" align="center">
                No timesheet available
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                You can add new time to manage your data more effectively.
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

const BookingContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;

  .subtext {
    border-radius: 6px;
    font-size: 14px;
    text-align: start;
    margin-top: 5px;
    color: ${palette.grey};
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: start;
  }

  .info-box {
    display: flex;
    align-items: flex-start;
    background: #eaf3fc;
    padding: 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #0f52ba;
    margin-top: 10px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
  }

  .duration-container {
    margin-top: 15px;
  }

  .label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }

  .dropdowns {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 120px;
  align-items: end;

  .button {
    padding: 8px 18px;
    width: 100px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .preview {
    background-color: white;
    border: 1px solid #0f52ba;
    color: #0f52ba;
  }

  .save {
    background-color: #0f52ba;
    color: white;
  }
`;
const FieldContainer = styled.div`
  top: auto;
  left: auto;
  user-select: none;
  background: white;
  padding: 20px;
  border-bottom: 1px solid #eee;

  .innerContainer {
    display: flex;
    width: 80%;
  }

  .fieldBox {
    width: 38%;
  }
`;

const Inputbox = styled.input`
  font-family: Inter;
  height: 35px;
  font-size: 13px;
  padding-left: 10px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 1px solid #ddd;
  padding: 0px 10px;
  color: ${palette.charcoleBlack};
  border-radius: 4px;
  align-self: start;
  display: flex;
  &:focus {
    outline: 1.5px solid black;
    border: 0.5px solid ${palette.charcoleBlack};
  }
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &[readonly] {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  &:disabled {
    background-color: #eee;
    color: #2b373f;
    border-color: #ddd;
    cursor: not-allowed;
  }

  @media (min-width: 260px) and (max-width: 721px) {
    width: 90%;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  background: #fff;
  border-radius: 8px;
  max-width: 350px;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    text-align: start;
  }

  .email,
  .phone {
    font-size: 14px;
    color: #666;
    text-align: start;
  }
`;

const StyledButton = styled.button`
  color: ${palette.newBlue};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: ${({ marginLeft }) => marginLeft || "0px"};
  cursor: pointer;
  background-color: #dce8f5;
  padding: 7px 15px;
  border-radius: 4px;
  border: 1px solid ${palette.newBlue};

  svg {
    margin-right: 10px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  align-text: start;
  display: flex;
  margin-left: 3px;
  align-self: flex-start;
  font-family: Inter;
  text-align: flex-start;
`;

const Option = styled.option`
  padding: 8px;
  font-size: 13px;
`;
const SelectDrpoDown = styled.select`
  padding: 10.5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none;
  width: 90%;
  color: #344054;
  background: #fff
    url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23333' stroke-width='1.5' d='M4 6l4 4 4-4'/%3E%3C/svg%3E")
    no-repeat right 10px center;
  background-size: 12px 12px;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }
  &:disabled {
    background-color: #eee;
    color: #000;
    border-color: #ccc;
    cursor: not-allowed;
  }
`;
const JobTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;

  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #dcdcdc;
  }

  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.6);
    transition: color 0.3s;

    &:hover {
      color: black;
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.grey};
    font-size: 13px;
    width: 45px;
    height: 45px;
    margin-right: 18px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;
const OptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.85);
  font-weight: 600;
  background-color: #eeeeee;
  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.85);
    margin-left: 5px;
  }
  &:hover {
    color: #000;
    svg {
      color: #000;
    }
  }
`;

export default TimesheetPage;
