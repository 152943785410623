import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getQuotedata, saveSign } from '../store/Actions/Auth.action';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Box, Checkbox, Modal, TextField } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas'
import { changeQuatestatus, sendNotification } from '../store/Actions/dashboard.action';
import moment from 'moment'
import { totalprice, taxprice, calculateTotalTaxAmount } from '../utils/Commonfunction';
import { Player } from '@lottiefiles/react-lottie-player';
import { palette } from '../utils/Palette';
import NewInvoice from './NewInvoice';
import { pdf } from '@react-pdf/renderer';
import { FileDownloadOutlined } from '@mui/icons-material';
import { cross } from '../assets';
import Modals from '../Components/Modal'
import ImageCarousel from '../ReuseableComponets/CuroselImage';
// import { Button } from '@mui/material';


function Quote() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        px: 2,

    };

    let location = useLocation()

    const searchParams = new URLSearchParams(location.search);
    const userData = useSelector(state => state?.auth?.userNameData)
    const referenceNo = searchParams.get('referenceNo');
    const email = searchParams.get('email');
    const [sign, setSign] = useState('')
    const [isModal, setIsModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [pdf1, setPdf1] = useState('')
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [previewURL, setpreviewURL] = useState('')
    const [photoVisible,setPhotoVisible]=useState(false)
    const sigCanvas = useRef()
    let { id } = useParams()
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getQuotedata({ referenceNo: id }))
        setData(res.data?.data)
        setPdf1(res?.data?.pdf)
        await dispatch(sendNotification({
            referenceNo: id,
            date: moment(new Date()).format('DD MMM, hh:mm A'),
            quote: true
        }))
        setLoader(false)
    }
    useEffect(() => {
        init()
    }, [])
    const quotedata = useSelector(state => state?.auth?.quote)
    const quoteuserdata = useSelector(state => state?.auth?.userNameData)
    const [type, setType] = useState(true)

    const numberClean = (cleanText) => {
        const formatted = cleanText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return parseFloat(formatted);
    }
    const flattenItems = (items) => {
        return items?.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };
    const putCommaInvalue = (price, quantity) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }
    const addCustomFiled = async (index, val) => {


        const dataa = [...data?.items]
        if (val.target.checked) {
            dataa[index] = { ...dataa[index], optional: false };
            setData1([...data1, { ...dataa[index], optional: false }])
        }
        else {
            dataa[index] = { ...dataa[index], optional: true };
            const array = data1.filter(item => item._id !== dataa[index]?._id);
            setData1(array)
        }

        console.log(val.target.checked, index, { ...dataa[index], optional: false }, dataa, "index")
        setData({ ...data, items: dataa });



    }
    console.log(quotedata?.pdf)
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (quotedata?.discountType === '$') {
            discountedTotalValue = parseFloat(quotedata?.discount);
            discountPercentage = (quotedata?.discount / (flattenItems([...quotedata?.items])?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (quotedata?.discountType === '%') {
            discountedTotalValue = (flattenItems([...quotedata?.items])?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (quotedata?.discount / 100));
            discountPercentage = parseFloat(quotedata?.discount);
        }

        return discountedTotalValue.toFixed(2);
    };
    return (

        <Main>
            {loader ? <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}> <Player
                src={require('../assets/loaders.json')}
                className="player"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
            />
            </div> : <MainContainer>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {quoteuserdata?.logoImage ? <ProfilePic src={quoteuserdata?.logoImage} /> : <OutProfile>
                        {getName(quoteuserdata?.businessName)}
                    </OutProfile>}
                    <h3>{quoteuserdata?.businessName}</h3>
                </div>
                <Container>
                    <p style={{ fontSize: '14px', color: palette.charcoleBlack }}>Estimate #{quotedata?.numberOfQuote}</p>
                    <h1 style={{ fontWeight: '600', marginTop: '0px', paddingBottom: '15px', width: '100%', textAlign: 'start', borderBottom: '1px solid #ddd' }}>{`$${putCommaInvalue((flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                        if (!currentValue?.optional) {
                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                        }
                        return accumulator;
                    }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(data?.items), applyDiscount()))?.toFixed(2))}`}</h1>
                    <CustomButton style={{ background: '#fff', color: palette.charcoleBlack, padding: '0px', width: 'auto', height: 'auto', marginBottom: '10px', alignSelf: 'self-start' }} onClick={() => {
                        if (quotedata?.pdf) {
                            window.open(quotedata?.pdf)
                        }
                        else {
                            localStorage.setItem('sign', '')
                            localStorage.setItem('signType', '')
                            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                            // If it's a mobile device, open the URL in the same window
                            if (isMobileDevice || (navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Safari'))) {
                                window.location.href = `https://app.getnotifi.com/previewquote/${id}`
                            } else {
                                // If it's not a mobile device, open the URL in a new tab
                                window.open(`https://app.getnotifi.com/previewquote/${id}`, '_blank');
                            }
                            // window.open(`https://app.getnotifi.com/previewquote/${id}`);
                        }


                    }}><FileDownloadOutlined style={{ marginBottom: -5, marginRight: 5 }} />
                        View detailed quote
                    </CustomButton>
                    {!quotedata?.pdf ? <CustomButton style={{ marginTop: 10 }} onClick={() => {
                        setIsModal(true)

                    }}>
                        {data?.depositLink ? "Approve and Pay" : "Approve"}
                    </CustomButton> : null}
                    {
                        (quotedata?.pdf && data?.depositLink) && <CustomButton style={{ marginTop: 10 }} onClick={() => {
                            window.open(data?.depositLink)

                        }}>
                            {"Pay deposit"}
                        </CustomButton>
                    }

                </Container>
                {quotedata?.notesQuote && <ContainerRow>
                    <h4 style={{ textAlign: 'start', marginBottom: '10px', marginTop: '0px', fontWeight: '500' }}>Message</h4>
                    <div style={{ textAlign: 'start', color: palette.charcoleBlack }}>{quotedata?.notesQuote}</div>
                </ContainerRow>}
                <ContainerRow>
                    {quotedata?.items?.map((item) =>
                        item?.optional ? null : <>
                            {item?.picUrl && <ItemImg onClick={() => {
                                setpreviewURL(item?.picUrl)
                                setImageModalOpen(true)
                            }} src={item?.picUrl} />}
                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                <div>
                                    <p style={{ fontSize: '14px', textAlign: 'start', fontWeight: '500' }}>{item?.name} × {item?.quantity || '1'}</p>
                                    <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-5px',whiteSpace:'pre-wrap' }}>{item?.description}</p>
                                </div>
                                <p style={{ fontSize: '14px', fontWeight: '500' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                            </div>

                        </>

                    )
                    }
                    {quotedata?.items?.some(item => item.optional === true) ? <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Add on</p> : null}
                    {quotedata?.items?.map((item, index) =>
                        item?.optional ? <>
                            {item?.picUrl && <ItemImg onClick={() => {
                                setpreviewURL(item?.picUrl)
                                setImageModalOpen(true)
                            }} src={item?.picUrl} />}
                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                <div style={{ display: 'flex', alignItems: 'start' }}>
                                    <Checkbox
                                        key={index}
                                        style={{ marginTop: '5px' }}
                                        // value={item?.email}
                                        // checked={day.includes(item?.email)}
                                        onChange={(val) => { addCustomFiled(index, val) }}

                                    />
                                    <div>
                                        <p style={{ fontSize: '14px', textAlign: 'start', }}>{item?.name} × {item?.quantity || '1'}</p>
                                        <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-10px' }}>{item?.description}</p>
                                    </div>
                                </div>
                                <p style={{ fontSize: '14px' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                            </div> </> : null

                    )
                    }
                </ContainerRow>
                <TotalContainer >
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>Subtotal</p>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${putCommaInvalue(flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                            if (!currentValue?.optional) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }
                            return accumulator
                        }, 0)?.toFixed(2))}`}</p>
                    </div>
                    <TaxDiv>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>Tax</p>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${putCommaInvalue((calculateTotalTaxAmount(flattenItems(data?.items), applyDiscount()))?.toFixed(2))}`}</p>
                    </TaxDiv>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>Discount</p>
                        <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${applyDiscount()}`}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderTopWidth: '1px solid #F3F3F3' }}>
                        <p style={{ fontWeight: '500' }}>Total</p>
                        <p style={{ fontWeight: '500' }}>{`$${putCommaInvalue((flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                            if (!currentValue?.optional) {
                                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                            }
                            return accumulator;
                        }, 0) - applyDiscount() + calculateTotalTaxAmount(flattenItems(data?.items), applyDiscount()))?.toFixed(2))}`}</p>
                    </div>
                </TotalContainer>
                <ContainerRow>
                    <h4 style={{ textAlign: 'start', marginBottom: '0px',marginTop:'0px' }}>Attachments / Photos</h4>
                    <ValueContainer>
                        <PhotosWrapper>
                            {data?.attachment?.map((item) =>
                                <PhotoContainer onClick={()=>setPhotoVisible(true)}>
                                    <PhotosStyle src={item} />
                                </PhotoContainer>
                            )}
                        </PhotosWrapper>
                    </ValueContainer>
                </ContainerRow>
               {photoVisible&& <ImageCarousel
                visible={photoVisible}
                images={data?.attachment||[]}
                onClose={()=>setPhotoVisible(false)}
                />}
                <Modal
                    open={isModal}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"

                >
                    <MainContainer1>
                        <Box sx={{ ...style, width: 350 }}>
                            <ModalHeader>
                                {<h3 >Approve quote</h3>}
                                <CloseIcondiv onClick={() => setIsModal(false)}>
                                    <CloseIcon />
                                </CloseIcondiv>
                            </ModalHeader>


                            {!type ? <SignedBox>
                                <Inputbox size='medium' placeholder="Type name" variant='filled' onChange={(event) => {
                                    const canvas = document.createElement('canvas');

                                    const ctx = canvas.getContext('2d');

                                    ctx.font = "bold 68px Monsieur";
                                    ctx.fillStyle = "#4169E1";

                                    ctx.fillText(event.target.value, 30, 90);
                                    const dataUrl = canvas.toDataURL();

                                    setSign(dataUrl)
                                }} />
                                <div style={{ width: '100%', height: '100px', display: 'flex', paddingTop: 40, alignItems: 'center', background: '#f3f3f3' }}>
                                    {sign ? <img style={{ fontFamily: 'Monsieur' }} src={sign} className="App-logo" alt="logo" /> : null}
                                </div>

                            </SignedBox> :
                                <div className="sigPadContainer">
                                    <SignatureCanvas
                                        ref={sigCanvas}

                                        penColor="#4169E1
                                        " canvasProps={{ className: "sigCanvas" }} />

                                    <button onClick={() => sigCanvas.current.clear()}>Clear</button>
                                </div>}
                            <p style={{ fontSize: '14px', fontWeight: 'normal' }} id="parent-modal-description">
                                By writing your signature, you accept this quote, associated costs, and any terms and conditions that may apply.
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
                                <SaveButton
                                    onClick={async () => {
                                        const arrayOfIds = data1.map(item => item._id);
                                        await dispatch(changeQuatestatus({
                                            "optionalId": arrayOfIds,
                                            email: userData?.email
                                        }, id))

                                        if (type) {
                                            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
                                            localStorage.setItem('sign', URL)
                                            localStorage.setItem('signType', 'draw')

                                        }
                                        else {
                                            localStorage.setItem('sign', sign)
                                            localStorage.setItem('signType', 'type')
                                        }
                                        const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                                        // If it's a mobile device, open the URL in the same window
                                        if (isMobileDevice || (navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Safari'))) {
                                            if (data?.depositLink) {

                                                try {

                                                    await pdf(<NewInvoice
                                                        data={quotedata}
                                                        userData={userData}
                                                        type={'Quote'}
                                                    />).toBlob().then(async blob => {
                                                        const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });
                                                        const res = await dispatch(saveSign(pdfFile, id, email))
                                                        console.log(blob, "blob")
                                                    })



                                                } catch (error) {
                                                    console.log('Error generating PDF: ' + error.message);
                                                }


                                                window.location.href = `${data?.depositLink}`
                                            }
                                            else {
                                                window.location.href = `https://app.getnotifi.com/previewquote/${id}`
                                            }
                                        } else {
                                            // If it's not a mobile device, open the URL in a new tab
                                            if (data?.depositLink) {

                                                try {

                                                    await pdf(<NewInvoice
                                                        data={quotedata}
                                                        userData={userData}
                                                        type={'Quote'}
                                                    />).toBlob().then(async blob => {
                                                        const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });
                                                        const res = await dispatch(saveSign(pdfFile, id, email))
                                                        console.log(blob, "blob")
                                                    })



                                                } catch (error) {
                                                    console.log('Error generating PDF: ' + error.message);
                                                }
                                                window.open(data?.depositLink)
                                            }
                                            else {
                                                window.open(`https://app.getnotifi.com/previewquote/${id}`, '_blank');
                                            }
                                        }

                                        // window.open(`https://app.getnotifi.com/previewquote/${id}`);
                                        init()
                                        setIsModal(false)
                                    }}
                                >Save</SaveButton>
                            </div>
                        </Box>

                    </MainContainer1>
                </Modal>
            </MainContainer>}
            {imagemodalOpen && (
                <Modals
                    isOpen={imagemodalOpen}
                    onClose={() => setImageModalOpen(false)}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <CloseButton onClick={() => setImageModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>

                    </div>
                    <div style={{ width: '300px', height: 'auto' }}>
                        <Image onClick={() => {
                            window.open(previewURL)
                        }} src={previewURL} />
                    </div>

                </Modals>
            )}
        </Main>
    );
}

export default Quote;

const TaxDiv = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:15px;
border-bottom: 1px solid grey; 
`
const Main = styled.div`
display: flex;
  justify-content: center;
 
  background:#FAFAFA;
padding-bottom:40px;

`
const MainContainer1 = styled.div`
overflow:scroll;
.sigPadContainer {
    margin-top:15px;
    background-color: rgb(238, 235, 235);
    padding:  10px 10px;
    border:1px solid #000;
  }
  .sigCanvas{
    width: 100%;
    height: 150px;
  }
  .sigPadContainer button {
    border: none;
    margin-left: auto;
    color: rgb(78, 75, 75);
    padding: 0;
    display: block;
    margin-top: 5px;
  }
  .sigPadContainer button:hover {
    background-color: unset;
    color: black;
  }

@media (min-width: 768px) {
    body {
      zoom: 3;
    }
  }`

const ModalHeader = styled.div`
  display:flex;
  justify-content:space-between;
  border-bottom:1px solid #F3F3F3;
  padding-horizontal:15px;
  `
const CloseButton = styled.button`

  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:10px;
  border: none;
  background:#ddd;
  margin-top:15px;
  width:30px;
  height:30px;
  align-self:center;
  border-radius:25px;
  font-size: 14px;
  cursor: pointer;
  
  `
const Icons = styled.img`
  width: 20px;
  height: 20px;
  `
const MainContainer = styled.div`
  margin-top:15px;
  margin-bottom:15px;
  min-height:100vh;
  @media (min-width: 820px) and (max-width:9999px)
  { 
      width: 100%;
      max-width: 420px;
  }
  @media (min-width: 260px) and (max-width: 820px){
  
      width: 100%;
      padding-left:25px;
      padding-right:25px;
      }
  
  `
const Inputbox = styled(TextField)`
background-color:#fff;
border:1px solid #000;
border-radius:0px;
width:100%;

`
const Container = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:30px;
display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const ContainerRow = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:20px;
margin-top:25px;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const TotalContainer = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:20px;
margin-top:25px;
display: flex;
flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const CustomButton = styled.button`
width:100%;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:14px;
background:#000;
cursor:pointer;
`
const CloseIcondiv = styled.div`
display:flex;
justify-content:flex-end;
padding-top:15px;
cursor:pointer;
`
const Toggle = styled.div`
width:154px;
display:flex;

margin-top:20px;

background-color:#F3F3F3;
border-radius:10px;
cursor:pointer;

.activeclass{
    display:flex;
    justify-content:center;
  color:#ffffff;
  background-color:#000;
  font-size:15px;
  border-radius:10px;
  align-items:center;
  height:40px;
  width:77px;
  text-align:center;

}
.class{
    display:flex;
    justify-content:center;
    align-items:center;
  color:#000;
  background-color:#F3F3F3;
  font-size:15px;
  border-radius:10px;
  height:40px;
  width:77px;
  text-align:center;

}
`
const SignedBox = styled.div`
border:1px solid #000;

margin-top:15px;
`
const SaveButton = styled.button`
width:100px;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:16px;
background:#000;
cursor:pointer;
`
const ProfilePic = styled.img`
color:white;
height:85px;

display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
font-weight:bold;

`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:85px;
width:85px;
display:flex;
justify-content:center;
align-items:center;
font-size:20px;
padding-bottom:0px;
border-radius:45px;
font-weight:bold;
`
const ItemImg = styled.img`
background:#fff;
color:white;
width:50px;
margin-bottom:0px;
margin-top:10px;
height:50px;
display:flex;
justify-content:center;
align-items:center;
border-radius:25px;
font-weight:bold;
`
const Image = styled.img`
width:100%;
height:100%;
margin-top:20px;
margin-bottom:20px;

`
const ValueContainer = styled.div`
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
`;
const PhotoContainer = styled.div`
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:15px;
margin-bottom:10px;
width: 60px;
height: 60px;

`