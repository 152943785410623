import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Cancel, CancelOutlined } from "@mui/icons-material";
import RippleButton from "../../../../ReuseableComponets/RippleButton";

const Overlay = styled.div`
  z-index: 150;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  background-color: #fafafa;
  box-shadow: 0 12px 48px rgba(32, 40, 77, 0.4),
    0 32px 80px rgba(32, 40, 77, 0.1);
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  position: relative;
  height: auto;
  max-height: 100vh;
  width: 50%;
  z-index: 150;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 13px;
  color: #344054;
  display: flex;
  margin-bottom: 8px;
  align-self: flex-start;
  width: 85%;
  font-family: Inter;
  text-align: start;
  margin-left: 3px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Inputbox = styled.input`
  width: 100%;
  max-width: 100%;
  padding: 10.5px;
  margin-right: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const AddNote = ({ isVisible, onClose, onSubmit, value }) => {
  const [note, setNote] = useState(value || "");

  if (!isVisible) return null;

  return (
    <Overlay>
      <ModalWrapper>
        <HeaderContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CloseButton onClick={() => onClose(false)}>
              <CancelOutlined />
            </CloseButton>
            <h5>{`Add Notes`}</h5>
          </div>
        </HeaderContainer>
        <div style={{ overflowY: "scroll", scrollbarWidth: "none" }}>
          <InputBoxContainer style={{ marginLeft: "0px" }}>
           
            <InputText
              placeholder="Note"
              type="text"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </InputBoxContainer>
        </div>
        <div
          style={{
            alignSelf: "flex-end",
            display: "flex",
            marginRight: "15px",
            marginBottom: "20px",
            marginTop: "10px",
            justifyContent: "flex-end",
          }}
        >
          <RippleButton
            style={{
              background: "#ddd",
              color: "#000",
              border: "none",
              marginRight: "15px",
            }}
            onClick={() => onClose(false)}
          >
            Cancel
          </RippleButton>
          <RippleButton
            style={{ marginRight: -15 }}
            onClick={() => onSubmit(note)}
          >
            {"Add"}
          </RippleButton>
        </div>
      </ModalWrapper>
    </Overlay>
  );
};

AddNote.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddNote;

const InputText = styled.textarea`
  font-family: Inter;
  width: 88%;
  border-radius: 5px;
  font-size: 13px;
  height: 95px;
  font-weight: 400;
  color: #344054;
  background: #ffffff;
  border: 0.5px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 13px;
    font-weight: 300;
    color: #bbb;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;