import { CancelOutlined, OpenInFull } from "@mui/icons-material";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import FormDetails from "../../../Components/Dashboard/FormDetails";

const ModalContainer = styled.div`
  width: ${({ isOpen }) => (isOpen ? "320px" : "30px")};
  height: 100vh;
  background: #fff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  padding: 5px;
  transition: 0.3s ease-in-out;
  position: sticky; 
  top: 0;
  overflow-y: auto;
  flex-shrink: 0; 
  @media (min-width: 1700px) and (max-width: 4000px){
    width: ${({ isOpen }) => (isOpen ? "450px" : "30px")};
  }
`;

const ToggleButton = styled.button`

  background: #fff;
  color: black;
  border: none;
  padding: 2px 8px;
  cursor: pointer;
`;

const Instructions = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
`;

const CopyButton = styled.button`
  background: #0F52BA;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
`;

const StickyInstructionModal = ({details}) => {
    const [isOpen, setIsOpen] = useState(true);
    const instructions = "Here are the instructions for creating a job...";

    const handleCopy = () => {
        navigator.clipboard.writeText(instructions);
        alert("Instructions copied!");
    };

    return (
        <ModalContainer isOpen={isOpen}>
            <div style={{display:'flex',alignItems:'center'}}>
            <ToggleButton onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <FiX  style={{fontSize:'20px'}}/> : <OpenInFull/>}
            </ToggleButton>

            <h3>Form details</h3>
            </div>
            <div style={{marginBottom:'40px'}}>
            {isOpen&&<FormDetails
                formDetail={details}
                type={'Job'}
            />}
            </div>
        </ModalContainer>
    );
};

export default StickyInstructionModal;
