import moment from "moment-timezone";
import * as React from "react";
import { getCalendarDataV2 } from "../../../store/Actions/dashboard.action";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  MarkerF,
} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import {
  calendarTeamColor,
  dayViewColor,
  formatMobileNumber,
  getName,
} from "../../../utils/Commonfunction";
import { Avatar, Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import FullScreenModal from "../../FullScreenModal";
import CreateBooking from "../CreateBooking";
import { palette } from "../../../utils/Palette";
import { useNavigate } from "react-router-dom";
import RippleButton from "../../../ReuseableComponets/RippleButton";
import ToggleTabs from "../../../ReuseableComponets/ToggleTab";
import {
  ArrowBackIosNew,
  ArrowDropDown,
  ArrowForwardIos,
  LocationOn,
} from "@mui/icons-material";
import mapStyles from "../../Tracking/mapStyles";
import { useRef } from "react";
import { useEffect } from "react";
import DatePickerComponent from "../../../ReuseableComponets/DatePickerComponent";
import { Player } from "@lottiefiles/react-lottie-player";
import { useState } from "react";
import MapCard from "./Component/MapCard";
import { useSelector } from "react-redux";
import {
  getBaseLocation,
  routeAllData,
  submitBaseLocation,
  submitRoute,
} from "../../../store/Actions/JobAction";
import RightSideModal from "../../RightSideModal";
import DepotSetupPage from "../../Settings/RouteSettings";
import Modal from "../../Modal";
import TopModal from "../../TopModal";
import ReactDOMServer from "react-dom/server";
import CustomSVGMarker from "./Component/SvgComponent";

function MapView() {
  const [loader, setLoader] = React.useState([]);
  const [ismodal, setIsModal] = useState(false);
  const [boolValue, setBoolValue] = useState({});
  const [team, setTeam] = React.useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("Map");
  const [selectedCrew, setselectedcrew] = useState("Map");
  const [isRoute, setIsRoute] = useState(false);
  const [isCustomRoute, setIsCustomRoute] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [tabData, setTabData] = useState([]);
  const [value, setValue] = useState({});
  const [baseRoute, setBaseRoute] = useState({});
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);
  const userdata = useSelector((state) => state?.auth?.userData);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const openAssign = Boolean(anchorEl1);
  const handleClickAssign = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseAssign = () => {
    setAnchorEl1(null);
  };
  const startDate = (date) => {
    const start = moment(date).format("YYYY-MM-DD");
    const end = moment(date).add(1, "day").format("YYYY-MM-DD");

    return { start, end };
  };
  const fetchLocationData = async (date, id) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const { start, end } = startDate(date);
    const res1 = await dispatch(getCalendarDataV2(start, end, id));
    const res2 = await dispatch(getBaseLocation(id || userdata?.userId));
    setBaseRoute({
      ...res2?.data,
      endAddress: res2?.data?.endAddress
        ? res2?.data?.endAddress
        : res2?.data?.startAddress,
    });

    const dataForDate = res1?.data?.detailData?.filter((item) => {
      const newDate = item?.timezone
        ? moment.tz(item?.date, item?.timezone).format("YYYY-MM-DD")
        : moment(item?.date).format("YYYY-MM-DD");
      if (newDate === formattedDate) {
        return item;
      }
    });
    const promises = await Promise.all(
      dataForDate?.map((item, index) => {
        if (item?.latitude && item?.longitude) {
          return {
            name: item?.customerAddress,
            position: offsetMarkers(
              { lat: item?.latitude || 0, lng: item?.longitude || 0 },
              index
            ),
            start: new Date(
              new Date(item?.date).getFullYear(),
              new Date(item?.date).getMonth(),
              new Date(item?.date).getDate(),
              new Date(item?.time).getHours(),
              new Date(item?.time).getMinutes()
            ),
            end:
              item?.endDate && item?.endTime
                ? new Date(
                    new Date(item?.endDate).getFullYear(),
                    new Date(item?.endDate).getMonth(),
                    new Date(item?.endDate).getDate(),
                    new Date(item?.endTime).getHours(),
                    new Date(item?.endTime).getMinutes()
                  )
                : new Date(
                    new Date(item?.date).getFullYear(),
                    new Date(item?.date).getMonth(),
                    new Date(item?.date).getDate(),
                    new Date(item?.time).getHours() + 1,
                    new Date(item?.time).getMinutes()
                  ),
            data: item,
          };
        }
        return null;
      })
    );
    const filteredData = promises?.filter((item) => item !== null);
    filteredData.sort((a, b) => {
      const timeA = new Date(a?.start).getTime();
      const timeB = new Date(b?.start).getTime();

      return timeA - timeB;
    });
    const optimizedOrder = filteredData?.map((location, index) => ({
      ...location,
    }));
    await fetchAndDisplayRoute(
      formattedDate,
      optimizedOrder,
      id || userdata?.userId
    );

    return optimizedOrder;
  };

  const handlePrevDate = () => {
    setLoader(true);
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 1);
      fetchLocationData(newDate, selectedCrew?.userId)
        .then((arrData) => {
          //   setTabData(arrData);
          let mapArr = [];
          //   if (isRoute) {
          //     arrData?.map((item) => {
          //       mapArr.push(item?.position);
          //     });

          //     if (mapArr?.length > 1) {
          //       const waypoints = mapArr
          //         .slice(1, mapArr.length - 1)
          //         .map((location) => ({
          //           location,
          //           stopover: true,
          //         }));

          //       const directionsService =
          //         new window.google.maps.DirectionsService();

          //       const request = {
          //         origin: mapArr?.[0],
          //         destination: mapArr[mapArr.length - 1],
          //         waypoints: waypoints,
          //         travelMode: window.google.maps.TravelMode.DRIVING,
          //       };
          //       directionsService.route(request, (result, status) => {
          //         if (status === window.google.maps.DirectionsStatus.OK) {
          //           setDirectionsResponse(result);
          //         } else {
          //           setDirectionsResponse([]);
          //         }
          //       });
          //     } else if (arrData.length === 1) {
          //       setDirectionsResponse(null);
          //     } else {
          //       setDirectionsResponse([]);
          //     }
          //   }
        })
        .catch((error) => {
          console.error(
            "Error fetching geocode data for previous date:",
            error
          );
        });
      return newDate;
    });
    setLoader(false);
  };

  const handleNextDate = () => {
    setLoader(true);
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 1);
      fetchLocationData(newDate, selectedCrew?.userId)
        .then((arrData) => {
          //   setTabData(arrData);
          let mapArr = [];
          //   if (isRoute) {
          //     arrData?.map((item) => {
          //       mapArr.push(item?.position);
          //     });
          //     if (mapArr?.length > 1) {
          //       const waypoints = mapArr
          //         .slice(1, mapArr.length - 1)
          //         .map((location) => ({
          //           location,
          //           stopover: true,
          //         }));

          //       const directionsService =
          //         new window.google.maps.DirectionsService();

          //       const request = {
          //         origin: mapArr?.[0],
          //         destination: mapArr[mapArr.length - 1],
          //         waypoints: waypoints,

          //         travelMode: window.google.maps.TravelMode.DRIVING,
          //       };
          //       directionsService.route(request, (result, status) => {
          //         if (status === window.google.maps.DirectionsStatus.OK) {
          //           setDirectionsResponse(result);
          //         } else {
          //           setDirectionsResponse([]);
          //         }
          //       });
          //     } else if (arrData.length === 1) {
          //       setDirectionsResponse(null);
          //     } else {
          //       setDirectionsResponse([]);
          //     }
          //   }
        })
        .catch((error) => {
          console.error(
            "Error fetching geocode data for previous date:",
            error
          );
        });

      return newDate;
    });
    setLoader(false);
  };

  const handleMarkerClick = (
    location,
    index,
    isStartingPoint,
    originalData
  ) => {
    const getLocationId = (loc) =>
      `${loc.lat.toFixed(6)}_${loc.lng.toFixed(6)}`;
    const clickedLocationId = getLocationId(location);

    setSelectedLocations((prevSelected) => {
      const alreadySelectedIndex = prevSelected.findIndex(
        (loc) => getLocationId(loc) === clickedLocationId
      );

      let updatedLocations;

      if (alreadySelectedIndex >= 0) {
        updatedLocations = prevSelected.filter(
          (_, idx) => idx !== alreadySelectedIndex
        );
      } else {
        // const originalData = tabData.find((item) => getLocationId(item.position) === clickedLocationId);
        updatedLocations = [
          ...prevSelected,
          {
            ...location,
            referenceNo: originalData?.data?.referenceNo,
            originalIndex: index,
            selectionOrder: prevSelected.length + 1,
            index: prevSelected.length + 1,
          },
        ];
      }

      if (updatedLocations.length >= 2) {
        generateDirections(updatedLocations);
      } else {
        setDirectionsResponse(null);
      }

      return updatedLocations;
    });

    setTabData((prevTabData) => {
      return prevTabData.map((item, idx) => {
        if (idx === index - 1) {
          const selectionOrder = selectedLocations.findIndex(
            (loc) => getLocationId(loc) === clickedLocationId
          );

          const newSelectionOrder =
            selectionOrder >= 0 ? 0 : selectedLocations.length + 1;

          return {
            ...item,
            index: newSelectionOrder || "",
            referenceNo: item?.data?.referenceNo,
          };
        } else if (isStartingPoint) {
          return { ...item, index: item?.index || "" };
        } else {
          return item;
        }
      });
    });
  };

  // Then add a function to save the custom route
  const saveCustomRoute = async () => {
    if (!selectedLocations || selectedLocations.length < 2) {
      console.error(
        "At least two locations are required to save the custom route."
      );
      return;
    }

    // Construct the custom route
    const customRoute = {
      createdAt: new Date().toISOString(),
      startPoint: {
        referenceId:
          selectedLocations[0].referenceNo ||
          tabData[selectedLocations[0].originalIndex]?.data?.referenceNo,
        position: selectedLocations[0],
        originalData: tabData[selectedLocations[0].originalIndex] || {},
      },
      waypoints: selectedLocations.map((location, index) => {
        const originalIndex = location.originalIndex ?? index;
        const referenceId =
          location.referenceNo || tabData[originalIndex]?.data?.referenceNo;

        let distance = 0,
          duration = 0;
        if (
          directionsResponse &&
          index > 0 &&
          directionsResponse.routes[0].legs[index - 1]
        ) {
          const leg = directionsResponse.routes[0].legs[index - 1];
          distance = leg?.distance?.value || 0;
          duration = leg?.duration?.value || 0;
        }

        return {
          referenceId,
          lat: location.lat,
          lng: location.lng,
          waypoint: index,
          distance,
          duration,
          originalData: tabData[originalIndex] || {},
        };
      }),
    };

    console.log("Saving Custom Route:", customRoute);

    try {
      await dispatch(
        submitRoute(
          customRoute,
          moment(currentDate).format("YYYY-MM-DD"),
          selectedCrew?.userId || userdata?.userId
        )
      );
      setIsRoute(true);
      await fetchLocationData(currentDate, selectedCrew?.userId);
      setIsCustomRoute(false);
    } catch (error) {
      console.error("Error saving custom route:", error);
    }
  };

  const generateDirections = (locations) => {
    const waypoints = locations.map((location) => ({
      location,
      stopover: true,
    }));

    const directionsService = new window.google.maps.DirectionsService();

    const request = {
      origin: {
        lat: baseRoute?.startAddress?.latitude,
        lng: baseRoute?.startAddress?.longitude,
      },
      destination: {
        lat: baseRoute?.endAddress?.latitude,
        lng: baseRoute?.endAddress?.longitude,
      },
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirectionsResponse(result);
      } else {
        console.error("Error fetching directions", status);
      }
    });
  };
  const fetchAndDisplayRoute = async (date, tabdata, id) => {
    try {
      // Fetch route data from API
      const res = await dispatch(
        routeAllData(id || selectedCrew?.userId || userdata?.userId, date)
      );
      console.log(res?.data[0]?.jobLocation);

      // Check if we have data
      if (res?.data?.length > 0) {
        console.log(tabdata, "cdsdc");
        // Check if jobLocation data exists in the first item
        if (res?.data[0]?.jobLocation) {
          // Use the optimized route from the API
          const optimizedLocations = res.data[0].jobLocation;

          // Map the locations to Google Maps compatible format
          let mapArr = optimizedLocations.map((location) => ({
            lat: location.lat,
            lng: location.lng,
          }));

          console.log(mapArr, "dddccddc");

          // Reorder tabData based on jobLocation referenceId order
          if (optimizedLocations.length > 0 && tabdata.length > 0) {
            // Create a temporary map to look up tabData items by referenceId
            const tabDataMap = {};
            tabdata.forEach((item) => {
              // Get referenceId from item.data.referenceNo
              const refId = item.data?.referenceNo;
              if (refId) {
                tabDataMap[refId] = item;
              }
            });

            // Create a new ordered tabData array based on optimizedLocations
            const newTabData = [];
            const newSelectedLocations = [];
            // First, process items that exist in the optimized route
            optimizedLocations.forEach((location, index) => {
              const refId = location.referenceId;
              console.log(location, "sdc");
              if (tabDataMap[refId]) {
                // Found matching item - update its position and add route index
                newTabData.push({
                  ...tabDataMap[refId],

                  index: location?.waypoint,
                });
                newSelectedLocations.push({
                  ...location,
                  originalIndex: tabDataMap[refId]?.index || index,
                });

                // Remove from map to track which ones were processed
                delete tabDataMap[refId];
              } else {
                // Create new item if not found in tabData
                newTabData.push({
                  data: { referenceNo: refId },
                  index: location?.waypoint,

                  route: true,
                });
                newSelectedLocations.push({
                  referenceId: refId,
                  lat: location.lat,
                  lng: location.lng,
                  waypoint: location?.waypoint,
                  originalIndex: index,
                });
              }
            });

            // Then, add remaining items from original tabData with null routeIndex
            Object.values(tabDataMap).forEach((item) => {
              newTabData.push({
                ...item,
                index: null,
                route: false,
              });
            });
            console.log(newTabData, "sdsdscsdc");

            // Update tabData with the new ordered array
            setTabData(newTabData);
            setSelectedLocations(newSelectedLocations);
          } else {
            setTabData(tabdata);
            setSelectedLocations([]);
          }

          // If we have at least 2 points, create a route
          if (mapArr.length > 1) {
            const waypoints = mapArr.map((location) => ({
              location: location,
              stopover: true,
            }));

            const directionsService =
              new window.google.maps.DirectionsService();
            const request = {
              origin: {
                lat: baseRoute?.startAddress?.latitude,
                lng: baseRoute?.startAddress?.longitude,
              },
              destination: {
                lat: baseRoute?.endAddress?.latitude,
                lng: baseRoute?.endAddress?.longitude,
              },
              waypoints: waypoints,

              travelMode: window.google.maps.TravelMode.DRIVING,
            };

            console.log(request);

            // Get directions and update state
            directionsService.route(request, (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setIsRoute(true);
                setDirectionsResponse(result);
              } else {
                setDirectionsResponse([]);
                console.error("Error fetching directions", status);
              }
            });
          } else if (mapArr.length === 1) {
            // Single point case
            setDirectionsResponse(null);
            setIsRoute(false);
          } else {
            // No points case
            setDirectionsResponse([]);
            setIsRoute(false);
          }
        } else {
          // No job location data
          setDirectionsResponse(null);
          setIsRoute(false);
        }
      } else {
        // No data returned from API
        setTabData(tabdata);
        setDirectionsResponse(null);
        setIsRoute(false);
      }
    } catch (error) {
      console.error("Error fetching route data:", error);
      setDirectionsResponse(null);
      setIsRoute(false);
    }
  };

  const init = async () => {
    // await dispatch(submitRoute([], moment('2025-03-07')?.format('YYYY-MM-DD'), selectedCrew?.userId || userdata?.userId));
    setLoader(true);
    let locat = {};
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          locat = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },

        (error) => {
          setCurrentLocation({
            lat: 38.7946,
            lng: -106.5348,
          });
        }
      );
    } else {
      setCurrentLocation({
        lat: 38.7946,
        lng: -106.5348,
      });
    }
    setIsRoute(false);
    const { start, end } = startDate(new Date());
    const res1 = await dispatch(
      getCalendarDataV2(start, end, selectedCrew?.userId)
    );
    const res2 = await dispatch(
      getBaseLocation(selectedCrew?.userId || userdata?.userId)
    );
    setBaseRoute({
      ...res2?.data,
      endAddress: res2?.data?.endAddress
        ? res2?.data?.endAddress
        : res2?.data?.startAddress,
    });

    setTeam(res1?.data?.team);
    if (res1.status === true) {
      const data = [...res1?.data?.detailData]?.filter(
        (items) => items?.customerAddress
      );
      const data1 = data?.filter(
        (item) =>
          (item?.timezone
            ? moment.tz(item?.date, item?.timezone).format("YYYY-MM-DD")
            : moment(item?.date).format("YYYY-MM-DD")) ===
          moment(new Date())?.format("YYYY-MM-DD")
      );

      const promises = await Promise.all(
        data1?.map((item, index) => {
          if (item?.latitude && item?.longitude) {
            return {
              name: item?.customerAddress,
              position: offsetMarkers(
                { lat: item?.latitude || 0, lng: item?.longitude || 0 },
                index
              ),
              start: new Date(
                new Date(item?.date).getFullYear(),
                new Date(item?.date).getMonth(),
                new Date(item?.date).getDate(),
                new Date(item?.time).getHours(),
                new Date(item?.time).getMinutes()
              ),
              end:
                item?.endDate && item?.endTime
                  ? new Date(
                      new Date(item?.endDate).getFullYear(),
                      new Date(item?.endDate).getMonth(),
                      new Date(item?.endDate).getDate(),
                      new Date(item?.endTime).getHours(),
                      new Date(item?.endTime).getMinutes()
                    )
                  : new Date(
                      new Date(item?.date).getFullYear(),
                      new Date(item?.date).getMonth(),
                      new Date(item?.date).getDate(),
                      new Date(item?.time).getHours() + 1,
                      new Date(item?.time).getMinutes()
                    ),
              data: item,
              index: index + 1,
            };
          }
          return null;
        })
      );
      const filteredData = promises?.filter((item) => item !== null);
      filteredData.sort((a, b) => {
        const timeA = new Date(a?.start).getTime();
        const timeB = new Date(b?.start).getTime();

        return timeA - timeB;
      });
      const optimizedOrder = filteredData?.map((location, index) => ({
        ...location,
        index: index + 1,
      }));
      await fetchAndDisplayRoute(
        moment(new Date())?.format("YYYY-MM-DD"),
        optimizedOrder
      );
      //   setTabData(optimizedOrder);
    }

    setLoader(false);
  };
  React.useEffect(() => {
    init();
  }, []);
  const handleSelectEvent = (event) => {
    setValue({});
    navigate(`/dashboard/calander/${event?.data?.referenceNo}`);
  };

  const eventStyleGetter = (event, start, color, end, isSelected) => {
    const style = {
      backgroundColor: event?.color ? event?.color : palette.newBlue,
      borderRadius: "2px",
      padding: "4px",
      opacity: 0.8,
      color: "white",
      fontSize: "12px",
      border: "0px solid #fff",
      opacity: 1.0,
      display: "block",
      marginLeft: "5px",
      overflowY: "hidden",
      marginLeft: "3px",
    };

    return {
      style,
    };
  };
  const goToWeek = () => {
    navigate("/dashboard/calander?type=week");
  };

  const goToMonth = () => {
    navigate("/dashboard/calander?type=month");
  };

  const handleOnLoad = (map) => {
    mapRef.current = map;
    fitBoundsToMarkers(map);
  };

  const fitBoundsToMarkers = (map) => {
    if (!isCustomRoute) {
      const bounds = new window.google.maps.LatLngBounds();
      if (tabData?.length > 0) {
        tabData?.forEach(({ position }) => {
          bounds?.extend(
            new window.google.maps.LatLng(position.lat, position.lng)
          );
        });

        map?.fitBounds(bounds);
        if (tabData.length > 1) {
          map?.fitBounds(bounds);
          const listener = map?.addListener("bounds_changed", () => {
            const currentZoom = map?.getZoom();
            if (currentZoom > 8) {
              map?.setZoom(8);
            }
            window.google.maps.event.removeListener(listener);
          });
        } else {
          map?.setCenter(bounds.getCenter());
          map?.setZoom(8);
        }
      } else {
        bounds?.extend(
          new window.google.maps.LatLng(
            currentLocation?.lat,
            currentLocation?.lng
          )
        );
        map?.fitBounds(bounds);
        const listener = map?.addListener("bounds_changed", () => {
          const currentZoom = map?.getZoom();
          if (currentZoom > 8) {
            map?.setZoom(8);
          }
          window.google.maps.event.removeListener(listener);
        });
      }
    }
  };
  useEffect(() => {
    if (mapRef.current && tabData && tabData.length > 0) {
      fitBoundsToMarkers(mapRef.current);
    }
  }, [tabData, isCustomRoute]);

  const offsetMarkers = (position, index) => {
    const offset = 0.00005 * index;
    return {
      lat: position.lat + offset,
      lng: position.lng + offset,
    };
  };
  const handleRouteOptimization = async (data) => {
   let baseLocation=data?data:baseRoute;
    // Toggle route state
    if (isRoute || isCustomRoute) {
      // Clear the current route
      await dispatch(
        submitRoute(
          [],
          moment(currentDate)?.format("YYYY-MM-DD"),
          selectedCrew?.userId || userdata?.userId
        )
      );
      setIsCustomRoute(false);
      setDirectionsResponse(null);
      setSelectedLocations([]);
      setIsRoute(false);
      return; // Exit early to prevent further processing
    }

    // Set route flag first
    setIsRoute(true);

    // Validate data
    if (!tabData || tabData.length <= 1) {
      setDirectionsResponse(tabData.length === 1 ? null : []);
      return;
    }

    // Create a local copy of data to prevent state mutations during processing
    const currentTabData = [...tabData];

    // Map locations once
    const mapLocations = currentTabData.map((item, index) => ({
      id: item.data?.referenceNo,
      position: item.position,
      index,
      originalData: item,
    }));

    try {
      const waypoints = mapLocations.map((location) => ({
        location: location.position,
        stopover: true,
      }));

      const directionsService = new window.google.maps.DirectionsService();
      const request = {
        origin: {
          lat: baseLocation?.startAddress?.latitude,
          lng: baseLocation?.startAddress?.longitude,
        },
        destination: {
          lat: baseLocation?.endAddress?.latitude,
          lng: baseLocation?.endAddress?.longitude,
        },
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      };

      // Get route from Google
      const result = await new Promise((resolve, reject) => {
        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            resolve(result);
          } else {
            reject(new Error(`Directions request failed: ${status}`));
          }
        });
      });

      // Store the directions result first
      setDirectionsResponse(result);

      // Create optimized route data structure
      const optimizedRoute = {
        createdAt: new Date().toISOString(),
        // startPoint: {
        //   referenceId: mapLocations[0].id,
        //   position: mapLocations[0].position,
        //   originalData: mapLocations[0].originalData,
        // },
        waypoints: [],
      };

      // Get the waypoint order from the optimized route
      const waypointOrder = result.routes[0]?.waypoint_order || [];

      // Process the first waypoint (starting point)
      //   optimizedRoute.waypoints.push({
      //     referenceId: mapLocations[0].id,
      //     lat: result.routes[0].legs[0].start_location.lat(),
      //     lng: result.routes[0].legs[0].start_location.lng(),
      //     waypoint: 0,
      //     distance: 0,
      //     duration: 0,
      //     originalData: mapLocations[0].originalData,
      //   });

      // Process remaining waypoints based on optimized order
      let newTabDataOrder = [mapLocations[0].originalData];

      for (let i = 0; i < waypointOrder.length; i++) {
        const actualIndex = waypointOrder[i];
        const leg = result.routes[0].legs[i];
        console.log(actualIndex, leg, mapLocations[actualIndex], "condddd");

        optimizedRoute.waypoints.push({
          referenceId: mapLocations[actualIndex].id,
          lat: leg.end_location.lat(),
          lng: leg.end_location.lng(),
          waypoint: i + 1,
          distance: leg.distance.value,
          duration: leg.duration.value,
          originalData: mapLocations[actualIndex].originalData,
        });

        newTabDataOrder.push(mapLocations[actualIndex].originalData);
      }

      // Add final destination
      const finalIndex = mapLocations.length - 1;
      const finalLeg = result.routes[0].legs[result.routes[0].legs.length - 1];

      //   optimizedRoute.waypoints.push({
      //     referenceId: mapLocations[finalIndex].id,
      //     lat: finalLeg.end_location.lat(),
      //     lng: finalLeg.end_location.lng(),
      //     waypoint: waypointOrder.length + 1,
      //     distance: finalLeg.distance.value,
      //     duration: finalLeg.duration.value,
      //     originalData: mapLocations[finalIndex].originalData,
      //   });

      //   newTabDataOrder.push(mapLocations[finalIndex].originalData);

      // Update the tab data with the new optimized order
      setTabData(newTabDataOrder);

      // If you need to save the route, uncomment this:
      await dispatch(
        submitRoute(
          optimizedRoute,
          moment(currentDate)?.format("YYYY-MM-DD"),
          selectedCrew?.userId || userdata?.userId
        )
      );
      await fetchLocationData(currentDate, selectedCrew?.userId);
    } catch (error) {
      console.error("Error in route optimization:", error);
      // Implement user-friendly error handling here
      // setErrorMessage("Could not optimize route. Please try again.");
    }
  };
  const svgIcon = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
   <svg
  width="20"
  height="50"
  viewBox="0 0 50 50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <!-- Marker Background (Pin Shape) -->
  <path
    d="M25 0C13.4 0 4 9.4 4 21c0 10.9 16 26.9 19.7 30.3a2 2 0 0 0 2.6 0C30 47.9 46 31.9 46 21 46 9.4 36.6 0 25 0z"
    fill="white"
    stroke="#0F52BA"
    stroke-width="3"
  />
  
  <!-- Garage Icon -->
  <rect x="16" y="22" width="18" height="12" fill="white" stroke="#0F52BA" stroke-width="3"/>
  <path d="M12 22L25 12L38 22" stroke="#0F52BA" stroke-width="3" fill="none"/>
</svg>
  `)}`;

  return (
    <Body>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginLeft: "-1px",
          height: "100vh",
          paddingTop: "15px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <InnerDiv className="inner-div">
                <Button type="button" onClick={() => handlePrevDate()}>
                  <ArrowBackIosNew style={{ fontSize: "18px" }} />
                </Button>
                <Button type="button" onClick={() => handleNextDate()}>
                  <ArrowForwardIos style={{ fontSize: "18px" }} />
                </Button>

                <DatePickerComponent
                  selectedDate={currentDate}
                  onDateChange={(text) => {
                    setCurrentDate(text);
                    fetchLocationData(text, selectedCrew?.userId)
                      .then((arrData) => {
                        let mapArr = [];
                        // if (isRoute) {
                        //   arrData?.map((item) => {
                        //     mapArr.push(item?.position);
                        //   });

                        //   if (arrData?.length > 1) {
                        //     const waypoints = mapArr
                        //       .slice(1, mapArr.length - 1)
                        //       .map((location) => ({
                        //         location,
                        //         stopover: true,
                        //       }));

                        //     const directionsService =
                        //       new window.google.maps.DirectionsService();

                        //     const request = {
                        //       origin: mapArr?.[0],
                        //       destination: mapArr[mapArr.length - 1],
                        //       waypoints: waypoints,

                        //       travelMode: window.google.maps.TravelMode.DRIVING,
                        //     };
                        //     directionsService.route(
                        //       request,
                        //       (result, status) => {
                        //         if (
                        //           status ===
                        //           window.google.maps.DirectionsStatus.OK
                        //         ) {
                        //           setDirectionsResponse(result);
                        //         } else {
                        //           setDirectionsResponse([]);
                        //           console.error(
                        //             "Error fetching directions",
                        //             status
                        //           );
                        //         }
                        //       }
                        //     );
                        //   } else if (arrData.length === 1) {
                        //     setDirectionsResponse(null);
                        //   } else {
                        //     setDirectionsResponse([]);
                        //   }
                        // }
                        // setTabData(arrData);
                      })
                      .catch((error) => {
                        console.error(
                          "Error fetching geocode data for previous date:",
                          error
                        );
                      });
                  }}
                  iconStyle={{ fontSize: "20px", marginTop: "-2px" }}
                  customStyle={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "40px",
                    fontWeight: "550",
                    fontSize: "14px",
                    border: "none",
                  }}
                />
             
              </InnerDiv>
            </div>
          </div>

          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}
          >
            <OptionBox onClick={handleClickAssign}>
              Switch user <ArrowDropDown />
            </OptionBox>
            <Menu
              anchorEl={anchorEl1}
              id="account-menu"
              open={openAssign}
              onClose={handleCloseAssign}
              onClick={handleCloseAssign}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  minWidth: "180px",
                  border: "1px solid #ddd",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.05))",
                  mt: 1.0,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",

                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                {[
                  //   {
                  //     name: `${userdata?.businessName || ""} ${
                  //       userdata?.lastName || ""
                  //     }`,
                  //     email: userdata?.email || "",
                  //     firstName: userdata?.businessName || "",
                  //     lastName: userdata?.lastName || "",
                  //   },
                  ...team,
                ]?.map((item, index) => (
                  <MenuItem
                    style={{ color: palette.black, fontSize: "14px" }}
                    onClick={async () => {
                      setselectedcrew(item);
                      fetchLocationData(currentDate, item?.userId);
                    }}
                  >
                    <Avatar
                      style={{
                        height: "22px",
                        width: "22px",
                        background: calendarTeamColor[index]?.light,
                      }}
                      color={dayViewColor[index]}
                    >
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          color: calendarTeamColor[index]?.original,
                        }}
                      >
                        {getName(`${item?.firstName} ${item?.lastName || ""}`)}
                      </Typography>
                    </Avatar>
                    <Typography
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      {item?.firstName} {item?.lastName || ""}
                    </Typography>
                  </MenuItem>
                ))}
              </div>
            </Menu>

            <ToggleTabs
              labels={["Week", "Month", "Day", "Map"]}
              selected={filter}
              onToggle={(val) => {
                if (val === "Week") {
                  goToWeek();
                } else if (val === "Month") {
                  goToMonth();
                } else if (val === "Day") {
                  setFilter("Day");
                  navigate("/dashboard/timeline");
                } else {
                  setFilter("Map");
                }
              }}
            />
          </div>
        </div>

        {!loader && currentLocation?.lat ? (
          <div style={{ display: "flex", height: "100vh" }}>
            <MapCardContainer>
              <MapCard
                data={tabData}
                isRoute={isRoute}
                selectedLocations={selectedLocations}
                onClickMarker={(item, index) => {
                  setIsCustomRoute(true);
                  handleMarkerClick(item?.position, index + 1, true, item);
                  setActiveMarker(null);
                }}
              />
            </MapCardContainer>
            {currentLocation?.lat && (
              <div
                style={{
                  width: "100%",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ProfileContainer>
                    <StyledAvatar>
                      {(selectedCrew?.firstName || userdata?.businessName)
                        ?.charAt(0)
                        ?.toUpperCase()}
                    </StyledAvatar>
                    <div className="profile-info">
                      <span className="name">
                        {selectedCrew?.firstName || userdata?.businessName}
                      </span>
                      <span className="email">
                        {selectedCrew?.email || userdata?.email}
                      </span>
                      {/* <span className="phone">
              {selectedCrew?.phone || userdata?.phone || "-"}
            </span> */}
                    </div>
                  </ProfileContainer>
                  {tabData?.length > 0 &&
                    (isCustomRoute ? (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <RippleButton
                          style={{
                            marginRight: "10px",
                            marginBottom: "15px",
                            marginTop: "5px",
                            border: "1px solid #ddd",
                            background: "#eee",
                            color: "#000",
                          }}
                          onClick={handleRouteOptimization}
                        >
                          {"Cancel"}
                        </RippleButton>
                        <RippleButton
                          style={{
                            marginRight: "10px",
                            marginBottom: "15px",
                            marginTop: "5px",
                          }}
                          onClick={saveCustomRoute}
                        >
                          {"Save"}
                        </RippleButton>
                      </div>
                    ) : (
                      <RippleButton
                        style={{
                          marginRight: "10px",
                          marginBottom: "15px",
                          marginTop: "5px",
                        }}
                        onClick={() => {
                          if (isRoute || isCustomRoute) {
                            handleRouteOptimization();
                          } else {
                            setBoolValue({ ...boolValue, isSetting: true });
                          }
                        }}
                      >
                        {isRoute || isCustomRoute ? "Reset Route" : "Optimize"}
                      </RippleButton>
                    ))}
                </div>
                <GoogleMap
                  onLoad={handleOnLoad}
                  onClick={() => setActiveMarker(null)}
                  mapContainerStyle={{ width: "100%", height: "100vh" }}
                  options={{
                    styles: mapStyles,
                  }}
                  zoom={4}
                >
                  {(isRoute || isCustomRoute) &&
                    directionsResponse &&
                    tabData?.length && (
                      <DirectionsRenderer
                        directions={directionsResponse}
                        options={{
                          suppressMarkers: true,

                          polylineOptions: {
                            strokeColor: "#0F52BA",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,

                            zIndex: 0,
                            icons: [
                              {
                                icon: {
                                  path: window.google.maps.SymbolPath
                                    .FORWARD_CLOSED_ARROW,
                                  scale: 1.5,
                                  strokeColor: "#0F52BA",
                                  fillColor: "#0F52BA",
                                  fillOpacity: 0.5,
                                },
                                offset: "0%",
                                repeat: "70px",
                              },
                            ],
                          },
                        }}
                      />
                    )}
                             {baseRoute?.endAddress && (
                    <MarkerF
                      position={{
                        lat: baseRoute?.endAddress?.latitude,
                        lng: baseRoute?.endAddress?.longitude,
                      }}
                      icon={{
                        url:
                          "data:image/svg+xml;charset=UTF-8," +
                          encodeURIComponent(
                            ReactDOMServer.renderToString(
                              <CustomSVGMarker text="⚑" textSize={22} />
                            )
                          ),
                        scaledSize: new window.google.maps.Size(30, 40),
                      }}
                    />
                  )}{" "}
                  {baseRoute?.startAddress && (
                    <MarkerF
                      position={{
                        lat: baseRoute?.startAddress?.latitude,
                        lng: baseRoute?.startAddress?.longitude,
                      }}
                      icon={{
                        url:
                          "data:image/svg+xml;charset=UTF-8," +
                          encodeURIComponent(
                            ReactDOMServer.renderToString(
                              <CustomSVGMarker text="𖠿" textSize={22} />
                            )
                          ),
                        scaledSize: new window.google.maps.Size(30, 40),
                      }}
                    />
                  )}{" "}
            
                  {tabData?.map((item, index) => (
                    <MarkerF
                      key={index}
                      position={item.position}
                      onClick={() => {
                        if (isCustomRoute) {
                          handleMarkerClick(
                            item.position,
                            index,
                            index + 1,
                            item
                          );
                        } else {
                          setActiveMarker(index);
                        }
                      }}
                      icon={{
                        url:
                          "data:image/svg+xml;charset=UTF-8," +
                          encodeURIComponent(
                            ReactDOMServer.renderToString(
                              <CustomSVGMarker
                                text={`${item?.index || ""}` || ""}
                                textSize={22}
                              />
                            )
                          ),
                        scaledSize: new window.google.maps.Size(30, 40),
                      }}
                      zIndex={1000}
                    >
                      {activeMarker === index ? (
                        <InfoWindow
                          key={index}
                          onCloseClick={() => setActiveMarker(null)}
                        >
                          <div
                            key={index}
                            style={{
                              width: "320px",
                              height: "100%",
                              maxHeight: "400px",
                              paddingRight: "10px",
                              overflowY: "scroll",
                            }}
                          >
                            <EventBox>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{
                                  color: palette.newBlue,
                                  textAlign: "start",
                                  fontSize: "12px",
                                }}
                              >
                                {`${moment(item?.data?.date).format(
                                  "DD MMM YYYY"
                                )}, ${
                                  item?.data?.timezone
                                    ? moment(item?.data?.time)
                                        .tz(item?.data?.timezone)
                                        .format("hh:mm A")
                                    : moment(item?.data?.time).format("hh:mm A")
                                }`}
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "start",
                                }}
                              >
                                <div>
                                  <EventTitle>{item?.data?.name}</EventTitle>
                                  {item?.data?.email && (
                                    <StyledTypography
                                      style={{ marginBottom: "6px" }}
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item?.data?.email}
                                    </StyledTypography>
                                  )}
                                  <StyledTypography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {formatMobileNumber(
                                      item?.data?.customerNumber
                                    )}
                                  </StyledTypography>
                                </div>
                                <BookingStatus
                                  status={item?.data?.bookingStatus}
                                >
                                  <span style={{ marginRight: "5px" }}>●</span>
                                  {item?.data?.bookingStatus ===
                                  "Waiting for confirmation"
                                    ? "Open"
                                    : item?.data?.bookingStatus}
                                </BookingStatus>
                              </div>
                              <Divider />
                              <LocationTitle>Location</LocationTitle>
                              <StyledTypography>
                                {item?.data?.customerAddress}
                              </StyledTypography>
                              {/* {item?.data?.team?.length > 0 && (
                                <>
                                  <Divider />
                                  <LocationTitle>Crew</LocationTitle>
                                  {item?.data?.team?.map((item) => (
                                    <StyledTypography
                                      variant="body2"
                                      color="textSecondary"
                                      key={item.id}
                                    >
                                      {item?.firstName} {item?.lastName}
                                    </StyledTypography>
                                  ))}
                                </>
                              )}
                              {item?.data?.items?.length > 0 && (
                                <>
                                  <Divider />
                                  <LocationTitle>Line items</LocationTitle>
                                  {item?.data?.items?.map((item) => (
                                    <StyledTypography
                                      variant="body2"
                                      color="textSecondary"
                                      key={item.id}
                                    >
                                      {item?.name} x {item?.quantity}
                                    </StyledTypography>
                                  ))}
                                </>
                              )} */}
                            </EventBox>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: "13px",
                                background: "#fff",
                              }}
                            >
                              <RippleButton
                                onClick={() =>
                                  navigate(
                                    `/dashboard/calander/${item?.data?.referenceNo}`
                                  )
                                }
                                variant="contained"
                                color="primary"
                                style={{
                                  width: "150px",
                                  fontWeight: "500",
                                  fontSize: "13px",
                                }}
                              >
                                View details
                              </RippleButton>
                              <RippleButton
                                onClick={() => {
                                  setIsCustomRoute(true);
                                  handleMarkerClick(
                                    item?.position,
                                    index + 1,
                                    true,
                                    item
                                  );
                                  setActiveMarker(null);
                                }}
                                variant="contained"
                                color="primary"
                                style={{
                                  width: "150px",
                                  fontWeight: "500",
                                  fontSize: "13px",
                                  background: "#f3f3f3",
                                  color: "black",
                                  border: "1px solid #ddd",
                                }}
                              >
                                {selectedLocations.some(
                                  (loc) =>
                                    loc.referenceId === item.data?.referenceNo
                                )
                                  ? "Remove"
                                  : isRoute
                                  ? "Add Routing"
                                  : "Start Routing"}
                              </RippleButton>
                            </div>
                          </div>
                        </InfoWindow>
                      ) : null}
                    </MarkerF>
                  ))}
                </GoogleMap>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <Player
              src={require("../../../assets/loaders.json")}
              className="player"
              style={{ width: "200px", height: "200px" }}
              loop
              autoplay
            />
          </div>
        )}
        {ismodal && (
          <FullScreenModal onClose={() => setIsModal(false)}>
            <CreateBooking
              value={value?.edit ? value : null}
              team={team}
              refresh={() => init()}
              serviceRequest={value?.edit ? false : true}
              newService={true}
              setCreateBookingModal={() => {
                setIsModal(false);
              }}
              onSubmitsw={(val, editValue) => {
                // sendchat(val, editValue)
                init();
                setIsModal(false);
              }}
            />
          </FullScreenModal>
        )}
        {boolValue?.isSetting && (
          <TopModal
            isOpen={boolValue?.isSetting}
            overlayStyle={{ alignItems: "center", paddingTop: "0px" }}
            customStyle={{ maxHeight: "80%" }}
            onClose={() => setBoolValue({ ...boolValue, isSetting: false })}
          >
            <DepotSetupPage
              onclose={() => setBoolValue({ ...boolValue, isSetting: false })}
              baseData={baseRoute}
              onsubmit={async (data) => {
                console.log({
                    ...data,
                    endAddress: data?.endAddress?.fullAddress
                      ? data?.endAddress
                      : data?.startAddress,
                }, "dscd");
             
                setBaseRoute({
                    ...data,
                    endAddress: data?.endAddress?.fullAddress
                      ? data?.endAddress
                      : data?.startAddress,
                });
                
                await dispatch(
                  submitBaseLocation(selectedCrew?.userId || userdata?.userId, {
                    ...data,
                    endAddress: data?.endAddress?.fullAddress
                      ? {...data?.endAddress,type:data?.endLocationType}
                      : data?.startAddress,
                  })
                );
                await handleRouteOptimization({
                    ...data,
                    endAddress: data?.endAddress?.fullAddress
                      ? {...data?.endAddress,type:data?.endLocationType}
                      : data?.startAddress,
                  });
                setBoolValue({ ...boolValue, isSetting: false });
              }}
            />
          </TopModal>
        )}
      </div>
    </Body>
  );
}
export default MapView;
const Body = styled.div`
  display: flex;
  width: calc(100% - 240px);
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 240px;
`;

const EventBox = styled.div`
  padding: 4px;
  overflow-y: scroll;
`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
  text-align: start;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
    status === "Waiting for confirmation"
      ? "#045ad0"
      : status === "In Progress"
      ? "#29740f"
      : status === "Completed"
      ? "#004D4D"
      : "#fa811f"};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
  text-align: start;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(32, 32, 32, 0.75);
  text-align: start;
`;

const MapCardContainer = styled.div`
  width: 370px;
  padding: 10px;
  overflow-y: scroll;
  padding-bottom: 100px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const OptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  color: rgba(32, 32, 32, 0.85);
  font-weight: 600;
  background-color: #eeeeee;
  svg {
    font-size: 20px;
    color: rgba(32, 32, 32, 0.85);
    margin-left: 5px;
  }
  &:hover {
    color: #000;
    svg {
      color: #000;
    }
  }
`;

const InnerDiv = styled.div`
  display: flex;
  margin-left: 20px;
  gap: 15px;
  margin-top: -15px;
`;

const Button = styled.button`
  width: 30px;
  display: flex;
  border: 0px solid gray;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  background: #fff;
  border-radius: 8px;
  max-width: 350px;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    text-align: start;
  }

  .email,
  .phone {
    font-size: 14px;
    color: #666;
    text-align: start;
  }
`;
const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${palette.grey};
    font-size: 13px;
    width: 35px;
    height: 35px;
    margin-right: 18px;
  }

  @media (max-width: 768px) {
    && {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
  }
`;

const DepotButton = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${palette.newBlue};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 16px;
  color: ${palette.newBlue};
  background: rgba(37, 99, 235, 0.05);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(37, 99, 235, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-right: 6px;
  }
`;
