import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";
import { Device } from '@twilio/voice-sdk';
import { getAllconversations } from "./Auth.action";
import { getUserDetails } from "./dashboard.action";


export const getQuoteData = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.fetchQuote)
    dispatch({ type: "QUOTEINVOICE", payload: { "quote": res?.data?.quotes } })
    return res
  }
  

  export const getInvoiceData = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.fetchInvoice)
    dispatch({ type: "QUOTEINVOICE", payload: { "invoice": res?.data?.invoices } })
    return res
  }

  export const getfetchQuoteId = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.fetchQuoteData}?quoteId=${id}`)
    return res
  }

  export const sendEstimate = (data,type) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.sendEstimate}?communicationOption=${type}`,data)
    return res
  }
  
  export const getfetchInvoiceId = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.fetchNewInvoice}?invoiceId=${id}`)
    return res
  }
  export const editEstimate = (data, id,option) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.editEstimates}?quoteId=${id}&communicationOption=${option}`, data)
    return res
}
export const sendNewInvoice = (data,type) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.sendNewInvoice}?communicationOption=${type}`,data)
    return res
  }

  export const editNewInvoice = (data, id,option) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.editNewInvoice}?invoiceId=${id}&communicationOption=${option}`, data)
    return res
}
export const getServiceFee = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.getServiceFee}`)
    return res
  }

  export const addServiceFee = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.addServiceFee}`,data)
    return res
  }

  export const updateServiceFee = (data, id,option) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.updateServiceFee}`, data)
    return res
}
export const getTips = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.getTips}`)
    return res
  }
  export const SubmitTips = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.addTips}`,data)
    return res
  }
  export const updateTips = (data, id,option) => async (dispatch, getstate) => {
    const res = await NetworkOps.put(`${ServiceEnum.updateTips}`, data)
    return res
}
export const deleteTips = (id) => async (dispatch, getstate) => {
    const res = await NetworkOps.delete(`${ServiceEnum.deleteTips}?tipValue=${id}`)
    return res
  }

  export const getTipReport = (start,end) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.tipReport}`)
    return res
  }

  export const getCommisiopReport = (start,end) => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.commisionReport}?startDate=${start}&endDate=${end}`)
    return res
  }

