import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import DatePickerComponent from "../../../../ReuseableComponets/DatePickerComponent";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import ToggleTabs from "../../../../ReuseableComponets/ToggleTab";
import { useNavigate } from "react-router-dom";

const CustomToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  background-color: #fff;
  border-radius: 8px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .title-container {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    flex: 1;
    text-align: center;
  }

  .nav-buttons {
    display: flex;
    gap: 14px;
    background-color: #fff;
  }

  .view-buttons {
    display: flex;
    gap: 2px;
  }
`;
const TodayButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px; 
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight:500;
  margin-right:10px;
  font-size: 14px; 
  color: #0f52ba;
  background-color: transparent; 

  &:hover {
    background-color: #dce8f5; 
  }

  svg {
    font-size: 16px; 
    color: #0f52ba;
    transition: color 0.3s;

    &:hover {
      color: #083f7c; 
    }
  }
`;

const PrevButton = styled.button`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #ddd;
  }
`;
const CustomToolbar = ({ calendarInstance, currentView,onChangeDate, onViewChange }) => {
  const [title, setTitle] = useState("");
  const navigate=useNavigate()
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (calendarInstance) {
      const updateTitle = () => {
        const viewTitle = calendarInstance.view.title;
        setTitle(viewTitle);
        setSelectedDate(
          moment(calendarInstance.getDate()).format("YYYY-MM-DD")
        );
      };
      updateTitle();
      calendarInstance.on("datesSet", updateTitle);

      return () => {
        calendarInstance.off("datesSet", updateTitle);
      };
    }
  }, [calendarInstance]);

  const handlePrev = () => {
    if (calendarInstance) {
      calendarInstance.prev();
      onChangeDate(moment(calendarInstance.getDate()).format("YYYY-MM-DD"))

    }
  };

  const handleNext = () => {
    if (calendarInstance) {
      calendarInstance.next();
      onChangeDate(moment(calendarInstance.getDate()).format("YYYY-MM-DD"))

    }
  };

  const handleToday = () => {
    if (calendarInstance) {
      calendarInstance.today();
      onChangeDate(moment(calendarInstance.getDate()).format("YYYY-MM-DD"))

    }
  };

  const handleViewButtonClick = (viewName) => {
    if (calendarInstance) {
      calendarInstance.changeView(viewName);
      onViewChange(viewName);
    }
  };

  const handleDateChange = (value) => {
    const newDate = value;
    setSelectedDate(newDate);
    if (calendarInstance) {
      calendarInstance.gotoDate(newDate);
      onChangeDate(moment(calendarInstance.getDate()).format("YYYY-MM-DD"))

    }
  };

  const goToWeek = () => {
    navigate('/dashboard/calander?type=week')
};

const goToDay = () => {
    navigate('/dashboard/calander?type=day')
};
const goToMonth = () => {
    navigate('/dashboard/calander?type=month')
};
  return (
    <CustomToolbarContainer>
      <div className="nav-buttons">
        <TodayButton
          onClick={handleToday}
        >
          Today
        </TodayButton>
        <DatePickerComponent
          type="date"
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          customStyle={{ fontSize: "15px", width: "150px"}}
          iconStyle={{ fontSize: "20px" }}
        />
        <PrevButton
          onClick={handlePrev}
        >
          <ArrowBackIosNew style={{ fontSize: "18px" }} />
        </PrevButton>
        <PrevButton
          onClick={handleNext}
        >
          <ArrowForwardIos style={{ fontSize: "18px" }} />
        </PrevButton>
      </div>
      <div>
        <ToggleTabs
          labels={["Week", "Month", "Day", "Map"]}
          selected={"Day"}
          onToggle={(val) => {
            if (val === "Week") {
              goToWeek();
            } else if (val === "Month") {
              goToMonth();
            } else if (val === "Day") {
            
            } else {
       
              navigate("/dashboard/calander/map");
            }
          }}
        />
      </div>

      {/* <div className="view-buttons">
        <button
          className={currentView === "resourceTimelineDay" ? "active" : ""}
          onClick={() => handleViewButtonClick("resourceTimelineDay")}
        >
          Day
        </button>
        <button
          className={currentView === "resourceTimelineWeek" ? "active" : ""}
          onClick={() => handleViewButtonClick("resourceTimelineWeek")}
        >
          Week
        </button>
        <button
          className={currentView === "resourceTimelineMonth" ? "active" : ""}
          onClick={() => handleViewButtonClick("resourceTimelineMonth")}
        >
          Month
        </button>
      </div> */}
    </CustomToolbarContainer>
  );
};

export default CustomToolbar;
